import HomeOutlined from '@ant-design/icons/HomeOutlined'
import allPaths from '../Config/paths'
import { CgProfile, CgDatabase } from 'react-icons/cg'
import { BiDollarCircle } from 'react-icons/bi'
import { HiOutlineMenu } from 'react-icons/hi'
import { BsCheckCircleFill, BsQuestionCircle } from 'react-icons/bs'
import { FaAward } from 'react-icons/fa'
import { IoMdSettings } from 'react-icons/io'
import { FiSearch } from "react-icons/fi";
import { GiGolfTee } from "react-icons/gi";
import { PiDotsNineBold } from "react-icons/pi";
import { CiBank } from "react-icons/ci";


const bgColor = '#0adc00'

const drawerRoutes = [
  {
    title: 'Membership and Membership Management',
    route: allPaths.MEMBERSHIP,
    icon: <CgProfile />,
  },
  {
    title: 'Golf Club',
    route: allPaths.GOLFCLUB,
    icon: <GiGolfTee />,
  },
  {
    title: 'Settlement management',
    icon: <BiDollarCircle />,
    route: allPaths?.OFFLINE_TRANSACTION,
    key: 1,
    children: [
      {
        title: 'Offline transaction management',
        route: allPaths?.OFFLINE_TRANSACTION,
      },
    ],
  },
  {
    title: 'Ticket sales management',
    icon: <HiOutlineMenu />,
    key: 2,
    children: [
      {
        title: 'Shared rental request/Shared rental post',
        route:
          allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_RENTAL_REQUEST,
      },
      {
        title: 'Shared exchange request/Shared exchange post',
        route:
          allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_EXCHANGE_REQUEST,
      },
      {
        title: 'Membership sales request/Membership purchase request',
        route:
          allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_MEMBERSHIP_SALES_REQUEST,
      },
      {
        title: 'Group buy purchase request/Group sales purchase request',
        route:
          allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_GROUP_BUY_PURCHASE_REQUEST,
      },
    ],
  },
  {
    title: 'Golf reservation transaction management',
    route: allPaths?.GOLF_RESERVATION_POST_MANAGEMENT,
    icon: <BsCheckCircleFill />,
  },
  {
    title: 'Golf auction management',
    route: allPaths.GOLF_AUCTION_POST_MANAGEMENT,
    icon: <FaAward />,
  },
  {
    title: 'Setting',
    route: allPaths.SETTINGS,
    icon: <IoMdSettings />,
  },
  {
    title: 'Board Management',
    icon: <PiDotsNineBold />,
    key: 3,
    children: [
      {
        title: 'Notices',
        route:
          allPaths?.NOTICE,
      },
      {
        title: 'Events',
        route:
          allPaths?.EVENT,
      },
      {
        title: 'Banners',
        route:
          allPaths?.BANNER,
      },]
  },
  {
    title: 'Bank Info',
    route: allPaths.BANKINFO,
    icon: <CiBank />,
  },
  {
    title: 'FAQ',
    route: allPaths.FAQ,
    icon: <BsQuestionCircle />,
  },
  {
    title: 'Company Intro',
    route: allPaths.COMPANY_INTRO,
    icon: <CgDatabase />,
  },
]
const koreanDrawerRoutes = [
  {
    title: '회원 및 회원권 관리',
    route: allPaths.MEMBERSHIP,
    icon: <CgProfile />,
  },
  {
    title: '골프 클럽',
    route: allPaths.GOLFCLUB,
    icon: <GiGolfTee />,
  },
  {
    title: '정산 관리',
    icon: <BiDollarCircle />,
    route: allPaths?.OFFLINE_TRANSACTION,
    key: 1,
    children: [
      {
        title: '오프라인 거래 관리',
        route: allPaths?.OFFLINE_TRANSACTION,
      },
    ],
  },
  {
    title: '회원권 관리',
    icon: <HiOutlineMenu />,
    key: 2,
    children: [
      {
        title: '공유대여 요청건/공유대여 게시건',
        route:
          allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_RENTAL_REQUEST,
      },
      {
        title: '공유교환 요청건/공유교환 게시건',
        route:
          allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_EXCHANGE_REQUEST,
      },
      {
        title: '회원권판매요청건/회원권구매요청건',
        route:
          allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_MEMBERSHIP_SALES_REQUEST,
      },
      {
        title: '공동구매구매요청건/공동구매판매요청건',
        route:
          allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_GROUP_BUY_PURCHASE_REQUEST,
      },
    ],
  },
  {
    title: '골프 예약 게시글 관리',
    route: allPaths?.GOLF_RESERVATION_POST_MANAGEMENT,
    icon: <BsCheckCircleFill />,
  },
  {
    title: '골프 경매 게시글 관리',
    route: allPaths.GOLF_AUCTION_POST_MANAGEMENT,
    icon: <FaAward />,
  },
  {
    title: '설정',
    route: allPaths.SETTINGS,
    icon: <IoMdSettings />,
  },
  {
    title: '게시판 관리',
    icon: <PiDotsNineBold />,
    key: 3,
    children: [
      {
        title: '공지사항',
        route:
          allPaths?.NOTICE,
      },
      {
        title: '이벤트',
        route:
          allPaths?.EVENT,
      },
      {
        title: '배너관리',
        route:
          allPaths?.BANNER,
      },
    ]
  },
  {
    title: '은행 정보',
    route: allPaths.BANKINFO,
    icon: <CiBank />,
  },
  {
    title: 'FAQ',
    route: allPaths.FAQ,
    icon: <BsQuestionCircle />,
  },
  {
    title: '기업소개',
    route: allPaths.COMPANY_INTRO,
    icon: <CgDatabase />,
  }
]

const regions = ['All regions', 'Seoul and Gyeonggi northern', 'Seoul and Gyeonggi southern', 'Seoul and Gyeonggi western', 'Seoul and Gyeonggi eastern', 'Gangwon', 'Chungbuk', 'Chungnam (Daejeon)', 'Gyeongnam', 'Jeonbuk', 'Jeonnam', 'Gyeongbuk', 'Jeju']
const regionsKr = [
  {
    TitleKr: '모든 지역',
    TitleEn: 'All regions'
  },
  {
    TitleKr: '서울경기 북부',
    TitleEn: 'Seoul and Gyeonggi northern'
  },
  {
    TitleKr: '서울경기 남부',
    TitleEn: 'Seoul and Gyeonggi southern'
  },
  {
    TitleKr: '서울경기 서부',
    TitleEn: 'Seoul and Gyeonggi western'
  }
  , {
    TitleKr: '서울경기 동부',
    TitleEn: 'Seoul and Gyeonggi eastern'
  },
  {
    TitleKr: '강원',
    TitleEn: 'Gangwon'
  },
  {
    TitleKr: '충북',
    TitleEn: 'Chungbuk'
  },
  {
    TitleKr: '충남(대전)',
    TitleEn: 'Chungnam (Daejeon)'
  },
  {
    TitleKr: ' 경남',
    TitleEn: 'Gyeongnam'
  },
  {
    TitleKr: '전북',
    TitleEn: 'Jeonbuk'
  },
  {
    TitleKr: '전남',
    TitleEn: 'Jeonnam'
  },
  {
    TitleKr: '경북',
    TitleEn: 'Gyeongbuk',
  },
  {
    TitleKr: '제주',
    TitleEn: 'Jeju'
  }]

const types = ['Individual member', 'Family member', 'Individual weekday', 'Family weekday', 'Company']

const typesKr = [
  {
    typesEn: 'Individual member',
    typesKr: '개인회원',
  },
  {
    typesEn: 'Family member',
    typesKr: '가족회원',
  },
  {
    typesEn: 'Individual weekday',
    typesKr: '개인주중',
  },
  {
    typesEn: 'Family weekday',
    typesKr: '가족주중',
  },
  {
    typesEn: 'Company',
    typesKr: '법인회원',
  },
]

const typesOnlyKr = ['개인회원', '가족회원', '개인주중', '가족주중', '법인회원']

export { bgColor, drawerRoutes, allPaths, koreanDrawerRoutes, regions, regionsKr, types, typesKr, typesOnlyKr }
