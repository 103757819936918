import { Button, Input } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useEffect, useState } from 'react'
import { ButtonLogout, CoustomDatePicker } from '../../Components'
import { Radio } from 'antd';
import moment from 'moment-timezone';
import axios from 'axios';
import { ADMIN } from '../../utils/apis';
import { errorMessage, infoMessage, successMessage } from '../../utils/helpers';
import { useSelector } from 'react-redux';
import { allPaths, types, typesKr } from '../../utils/constants';

const TicketsVeiwDetail = (props) => {
    let Bid = props?.history?.location?.state[0]
    console.log('bid', props?.history?.location?.state)
    let title = props?.history?.location?.state[1].title
    let disableCondition = Bid?.paymentId?._id ? true : false
    const userSelectedLanguage = props?.language
    const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
    const lanKorCondition = SelectedLanguage === 'english'
    const history = props?.history
    const { user } = props
    const [loading, setLoading] = useState(false)
    const [dateOFTr, setDateOFTr] = useState(Bid?.paymentId?.dateOfTransaction);
    const [tranNum, setTranNum] = useState(null);
    const [value, setValue] = useState('Bank Wire');
    const [bankName, setBankName] = useState('');
    const [bankAccNum, setBankAccNum] = useState(null);
    const [bankAccount, setBankAccount] = useState('');
    const [personInCharge, setPersonInCharge] = useState('');
    const [memo, setMemo] = useState('');

    console.log('title', title)
    const createDate = (d) => {
        setDateOFTr(d.format('YYYY-MM-DD'))
    }
    const options = [
        { label: lanKorCondition ? 'Bank Wire' : '계좌이체', value: 'Bank Wire' },
        { label: lanKorCondition ? 'Cash' : '현금', value: 'Cash' },
        { label: lanKorCondition ? 'ETC' : '기타', value: 'ETC' },
    ];


    useEffect(() => {
        const paymentData = Bid?.paymentId
        setDateOFTr(paymentData?.dateOfTransaction)
        setMemo(paymentData?.memo)
        setValue(paymentData?.transactionType)
        setPersonInCharge(paymentData?.paymentIncharge)
        setBankName(paymentData?.bankInfo?.bankName)
        setBankAccNum(paymentData?.bankInfo?.bankAccountNumber)
        setBankAccount(paymentData?.bankInfo?.bankAccount)
        setTranNum(paymentData?.transactionNumber)
    }, [])

    const onChange = ({ target: { value } }) => {
        setValue(value);
    };


    const resetFeild = () => {
        setDateOFTr(null)
        setTranNum(null)
        setValue('Bank Wire')
        setBankName('')
        setBankAccNum(null)
        setBankAccount('')
        setPersonInCharge('')
        setMemo('')
    }


    const goback = () => {
        title === 'share rental auction' ?
            history.push(allPaths?.GOLF_AUCTION_POST_MANAGEMENT)
            : title === 'auctionSale' ?
                history.push(allPaths?.GOLF_AUCTION_POST_MANAGEMENT) :
                title === 'golfReservation' ?
                    history.push(allPaths?.GOLF_AUCTION_POST_MANAGEMENT) :
                    title === 'Golf Reservation' ?
                        history.push(allPaths?.GOLF_RESERVATION_POST_MANAGEMENT) : '----'
    }

    const payment_Request_ticket_share = () => {
        const obj = {
            adminId: user?._id,
            userId: Bid?.userId?._id,
            ownerAdminId: user?._id,
            buyerId: Bid?.buyerId?._id || null,
            bankInfo: {
                bankName: bankName,
                bankAccount: bankAccount,
                bankAccountNumber: bankAccNum,
            },
            transactionType: value,
            dateOfTransaction: dateOFTr,
            transactionNumber: tranNum,
            paymentIncharge: personInCharge,
            memo: memo,
            ticketShareId: Bid?._id,
            amount: Bid?.rightPrice
        }

        setLoading(true)
        axios.post(ADMIN.CREATE_PAYMENT_REQUEST, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? Bid?.message : Bid?.messageKr)
                    resetFeild()
                    goback()
                }
                else {
                    errorMessage(lanKorCondition ? Bid?.message : Bid?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                console.log('e', e)
                setLoading(false)
                resetFeild()
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '아차! 문제가 발생했습니다!')
            })
    }
    const payment_Request_ticket_share_exchange = () => {
        const obj = {
            adminId: user?._id,
            buyerId: Bid?.buyerId?._id || null,
            userId: Bid?.userId?._id,
            ownerAdminId: user?._id,
            bankInfo: {
                bankName: bankName,
                bankAccount: bankAccount,
                bankAccountNumber: bankAccNum,
            },
            transactionType: value,
            dateOfTransaction: dateOFTr,
            transactionNumber: tranNum,
            paymentIncharge: personInCharge,
            memo: memo,
            exchangeRequestId: Bid?._id,
            amount: Bid?.rightPrice
        }
        console.log(obj, "====obj")
        setLoading(true)
        axios.post(ADMIN.CREATE_PAYMENT_REQUEST, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    resetFeild()
                    goback()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
                resetFeild()
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '아차! 문제가 발생했습니다!')
            })
    }
    const payment_Request_ticket_share_rent = () => {
        const obj = {
            adminId: user?._id,
            buyerId: Bid?.buyerId?._id || null,
            userId: Bid?.userId?._id,
            ownerAdminId: user?._id,
            bankInfo: {
                bankName: bankName,
                bankAccount: bankAccount,
                bankAccountNumber: bankAccNum,
            },
            transactionType: value,
            dateOfTransaction: dateOFTr,
            transactionNumber: tranNum,
            paymentIncharge: personInCharge,
            memo: memo,
            rentRequestId: Bid?._id,
            amount: Bid?.ticketShareId?.rightPrice
        }
        console.log(obj, "====obj")
        setLoading(true)
        axios.post(ADMIN.CREATE_PAYMENT_REQUEST, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    resetFeild()
                    goback()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
                resetFeild()
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '아차! 문제가 발생했습니다!')
            })
    }
    const payment_Request_ticket_sell = () => {
        const obj = {
            adminId: user?._id,
            buyerId: Bid?.buyerId?._id || null,
            userId: Bid?.userId?._id,
            ownerAdminId: user?._id,
            bankInfo: {
                bankName: bankName,
                bankAccount: bankAccount,
                bankAccountNumber: bankAccNum,
            },
            transactionType: value,
            dateOfTransaction: dateOFTr,
            transactionNumber: tranNum,
            paymentIncharge: personInCharge,
            memo: memo,
            ticketSaleId: Bid?._id,
            amount: Bid?.ticketId?.rightPrice
        }
        console.log(obj, "====obj")
        setLoading(true)
        axios.post(ADMIN.CREATE_PAYMENT_REQUEST, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    resetFeild()
                    goback()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
                resetFeild()
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '아차! 문제가 발생했습니다!')
            })
    }
    const payment_Request_ticket_sell_group = () => {
        const obj = {
            adminId: user?._id,
            buyerId: Bid?.buyerId?._id || null,
            userId: Bid?.userId?._id,
            ownerAdminId: user?._id,
            bankInfo: {
                bankName: bankName,
                bankAccount: bankAccount,
                bankAccountNumber: bankAccNum,
            },
            transactionType: value,
            dateOfTransaction: dateOFTr,
            transactionNumber: tranNum,
            paymentIncharge: personInCharge,
            memo: memo,
            requestBuyId: Bid?._id,
            amount: Bid?.userTicketId?.rightPrice
        }
        console.log(obj, "====obj")
        setLoading(true)
        axios.post(ADMIN.CREATE_PAYMENT_REQUEST, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    resetFeild()
                    goback()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
                resetFeild()
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '아차! 문제가 발생했습니다!')
            })
    }
    const payment_Request_group_buy = () => {
        const obj = {
            adminId: user?._id,
            buyerId: Bid?.buyerId?._id || null,
            userId: Bid?.userId?._id,
            ownerAdminId: user?._id,
            bankInfo: {
                bankName: bankName,
                bankAccount: bankAccount,
                bankAccountNumber: bankAccNum,
            },
            transactionType: value,
            dateOfTransaction: dateOFTr,
            transactionNumber: tranNum,
            paymentIncharge: personInCharge,
            memo: memo,
            groupBuyId: Bid?._id,
            amount: Bid?.ticketId?.rightPrice
        }
        console.log(obj, "====obj-group buy")
        setLoading(true)
        axios.post(ADMIN.CREATE_PAYMENT_REQUEST, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    resetFeild()
                    goback()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
                resetFeild()
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '아차! 문제가 발생했습니다!')
            })
    }
    const payment_Request_group_buy_participate = () => {
        const obj = {
            adminId: user?._id,
            buyerId: Bid?.buyerId?._id || null,
            userId: Bid?.userId?._id,
            ownerAdminId: user?._id,
            bankInfo: {
                bankName: bankName,
                bankAccount: bankAccount,
                bankAccountNumber: bankAccNum,
            },
            transactionType: value,
            dateOfTransaction: dateOFTr,
            transactionNumber: tranNum,
            paymentIncharge: personInCharge,
            memo: memo,
            groupBuyParticipateId: Bid?._id,
            amount: Bid?.totalPrice || Bid?.groupBuy?.rightPrice
        }
        setLoading(true)
        console.log(obj, "======obj========obj")

        axios.post(ADMIN.CREATE_PAYMENT_REQUEST, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    resetFeild()
                    goback()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
                resetFeild()
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '아차! 문제가 발생했습니다!')
            })
    }

    console.log('Bid', Bid)

    return (
        <>
            <ButtonLogout props={props} />
            <div className='settingDiv'>

                <div className='nameEmailDiv'>
                    <div className='divHeader'>
                        <h3 className='headerText'>
                            {lanKorCondition ? 'View details' : '회원권 거래정보'}
                        </h3>
                        <div className='borderBottom'></div>
                    </div>

                    <div className='ViewTransactionCardsDiv '>
                        <div className='ViewTransactionCard paddingTopnone'>
                            <h4 className='ViewTransactionCardTextBold size'>
                                {lanKorCondition ? 'Seller Info' : '판매자 정보'}
                            </h4>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {'ID'}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {Bid?.userId?._id ? Bid?.userId?._id : Bid?.ownerAdminId || Bid?.adminId ? user?._id : '----'}
                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {userSelectedLanguage?.Name}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {Bid?.userId?.fullName ? Bid?.userId?.fullName : Bid?.ownerAdminId || Bid?.adminId ? `${user?.userName}-${lanKorCondition ? 'admin' : '어드민'}` : '----'}
                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {userSelectedLanguage?.Email}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBoldEmail textElipsis'>
                                        {Bid?.userId?.email ? Bid?.userId?.email : Bid?.ownerAdminId || Bid?.adminId ? user?.email : '----'}

                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {userSelectedLanguage?.PhoneNumber}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {Bid?.userId?.telNo ? Bid?.userId?.telNo : '----'}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className='ViewTransactionCardCenter paddingTopnone'>
                            <h4 className='ViewTransactionCardTextBold size'>
                                {lanKorCondition ? 'Buyer Info' : '구매자 정보'}
                            </h4>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {'ID'}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {Bid?.buyerId?._id ? Bid?.buyerId?._id : '----'}
                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {userSelectedLanguage?.Name}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {Bid?.buyerId?.fullName ? Bid?.buyerId?.fullName : '----'}
                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {userSelectedLanguage?.Email}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBoldEmail textElipsis '>
                                        {Bid?.buyerId?.email ? Bid?.buyerId?.email : '----'}
                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {userSelectedLanguage?.PhoneNumber}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {Bid?.buyerId?.telNo ? Bid?.buyerId?.telNo : '----'}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className='ViewTransactionCardLast'>
                            <div className='TransactionAmountInputDiv'>
                                <div className='inputTransactionAmountLabelDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        01){lanKorCondition ? 'Person in charge' : '담당자 이름'}
                                    </h5>
                                </div>
                                <div className='inputTransactionAmountDiv'>
                                    <Input disabled={disableCondition} onChange={(e) => setPersonInCharge(e.target?.value)} value={personInCharge} className='inputTransactionAmount' />
                                </div>
                            </div>
                            <div className='TransactionAmountInputDiv'>
                                <div className='inputTransactionAmountLabelDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        02){userSelectedLanguage?.Memo}
                                    </h5>
                                </div>
                                <div className='inputTransactionAmountDiv'>
                                    <TextArea
                                        disabled={disableCondition}
                                        onChange={(e) => setMemo(e.target?.value)}
                                        value={memo}
                                        autoSize={{ minRows: 4, maxRows: 4 }}
                                        className='inputTransactionAmount'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='nameEmailDiv'>
                    <div className='ticketsInfoCards'>
                        <div className='ticketsInfoCard'>
                            <div className='divHeader'>
                                <h3 className='headerText'>
                                    {lanKorCondition ? 'Payment details' : '결제 정보'}
                                </h3>
                                <div className='borderBottom'></div>
                            </div>
                            <div className='TicketForm'>
                                <div>
                                    <h4>{lanKorCondition ? 'Date of transaction' : '거래일자'}</h4>
                                    {
                                        disableCondition ?
                                            <Input disabled={disableCondition} value={moment(dateOFTr).format('YYYY-MM-DD')} className='inputTransactionAmount' />
                                            :
                                            <CoustomDatePicker disabled={disableCondition} setDateOFTr={setDateOFTr} />
                                    }

                                </div>
                                <div>
                                    <h4>{lanKorCondition ? 'Transaction Number' : '거래금액'}</h4>
                                    <Input disabled={disableCondition} onChange={(e) => setTranNum(e.target?.value)} value={tranNum} className='inputTransactionAmount' suffix='원' />
                                </div>
                                <div>
                                    <h4>{lanKorCondition ? 'Transaction Type' : '거래방법'}</h4>
                                    <Radio.Group
                                        disabled={disableCondition}
                                        options={options}
                                        onChange={onChange}
                                        value={value}
                                        optionType='button'
                                        buttonStyle='solid'
                                    />
                                </div>
                            </div>
                            <div className='TicketForm'>
                                <h3>{lanKorCondition ? ' Bank Info' : '계좌정보'}</h3>
                                <div>
                                    <h4>{lanKorCondition ? 'Bank Name' : '은행명'}</h4>
                                    <Input disabled={disableCondition} onChange={(e) => setBankName(e.target?.value)} value={bankName} className='inputTransactionAmount' />
                                </div>
                                <div>
                                    <h4>{lanKorCondition ? 'Bank Account' : '예금주'}</h4>
                                    <Input disabled={disableCondition} onChange={(e) => setBankAccount(e.target?.value)} value={bankAccount} className='inputTransactionAmount' />
                                </div>
                                <div>
                                    <h4>{lanKorCondition ? 'Bank Account Number' : '계좌번호'}</h4>
                                    <Input disabled={disableCondition} onChange={(e) => setBankAccNum(e.target?.value)} value={bankAccNum} className='inputTransactionAmount' />
                                </div>
                            </div>
                        </div>
                        <div className='ticketsInfoCard'>
                            <div className='divHeader'>
                                <h3 className='headerText'>
                                    {lanKorCondition ? 'Ticket Info' : '회원권 정보'} -

                                    {title === 'Ticket sharing rent' ?
                                        lanKorCondition ? 'Ticket Sharing Rent' : '회원권 공유 대여' :
                                        title === 'Ticket Sharing Exchange' ?
                                            lanKorCondition ? 'Ticket Sharing Exchange' : '회원권 공유 교환' :
                                            title === 'Ticket sharing' ?
                                                lanKorCondition ? 'Ticket sharing' : '회원권 공유 교환' :
                                                title === 'Ticket sales' ?
                                                    lanKorCondition ? 'Ticket sales ' : '회원권 매매 ' :
                                                    title === 'Ticket sales Request Buy' ?
                                                        lanKorCondition ? 'Ticket sales Request Buy' : '회원권 매매' :
                                                        title === 'Group buy' ?
                                                            lanKorCondition ? 'Group buy' : '공동구매' :
                                                            title === 'Group buy Participate' ?
                                                                lanKorCondition ? 'Group buy Participate' : '공동구매 참여' : '---'}
                                </h3>
                                <div className='borderBottom'></div>
                            </div>
                            <div className='extraMargina' style={{ marginTop: '30px' }} >
                                <div style={{ display: 'flex' }}>
                                    <h5 className='participantKeyBold'>{userSelectedLanguage?.GolfClubName}</h5>
                                    <h5 className='participantKeyTic'>{
                                        title === 'Ticket Sharing Exchange' ? 

                                            Bid?.buyerTicketId?.golfClub?.clubName :

                                            Bid?.userTicketId?.golfClub?.clubName
                                            || Bid?.ticketId?.golfClub?.clubName
                                            || Bid?.groupBuy?.golfClub?.clubName
                                            || '----'}</h5>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5 className='participantKeyBold'>{userSelectedLanguage?.Type}</h5>
                                    <h5 className='participantKeyTic'>
                                        {lanKorCondition ? Bid?.userTicketId?.membershipType || Bid?.ticketId?.membershipType || Bid?.groupBuy?.membershipType : typesKr[types?.indexOf(Bid?.userTicketId?.membershipType || Bid?.ticketId?.membershipType || Bid?.groupBuy?.membershipType)]?.typesKr || '----'}</h5>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5 className='participantKeyBold'>{userSelectedLanguage?.JoiningFee}</h5>
                                    <h5 className='participantKeyTic tableEmail'>{Bid?.userTicketId?.joiningFee ? Bid?.userTicketId?.joiningFee + ' ' + userSelectedLanguage?.KRW : Bid?.ticketId?.joiningFee ? (Bid?.ticketId?.joiningFee) + ' ' + userSelectedLanguage?.KRW : Bid?.groupBuy?.joiningFee ? (Bid?.groupBuy?.joiningFee) + ' ' + userSelectedLanguage?.KRW : '----'}</h5>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5 className='participantKeyBold'>{userSelectedLanguage?.JoiningDate}</h5>
                                    <h5 className='participantKeyTic'>{Bid?.userTicketId?.joiningDate?.date1 || Bid?.userTicketId?.joiningDate?.date2 ? moment(Bid?.userTicketId?.joiningDate?.date1).format('YYYY-MM-DD') + ' / ' + moment(Bid?.userTicketId?.joiningDate?.date2).format('YYYY-MM-DD') :
                                        Bid?.ticketId?.joiningDate?.date1 || Bid?.ticketId?.joiningDate?.date2 ? moment(Bid?.ticketId?.joiningDate?.date1).format('YYYY-MM-DD') + ' / ' + moment(Bid?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD') :
                                            Bid?.groupBuy?.joiningDate?.date1 || Bid?.groupBuy?.joiningDate?.date2 ?
                                                moment(Bid?.groupBuy?.joiningDate?.date1).format('YYYY-MM-DD') + ' / ' + moment(Bid?.groupBuy?.joiningDate?.date2).format('YYYY-MM-DD') :

                                                '----'}</h5>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5 className='participantKeyBold'>{userSelectedLanguage?.sellingPrice}</h5>
                                    <h5 className='participantKeyTic tableEmail'>{(title === 'Ticket sharing' || title === 'Ticket Sharing Exchange') ? Bid?.rightPrice : title === 'Ticket sharing rent' ? Bid?.ticketShareId?.rightPrice : Bid?.ticketId?.rightPrice ? Bid?.ticketId?.rightPrice + ' ' + userSelectedLanguage?.KRW : Bid?.ticketId?.rightPrice ? Bid?.ticketId?.rightPrice + ' ' + userSelectedLanguage?.KRW :
                                        Bid?.groupBuy?.rightPrice ? Bid?.groupBuy?.rightPrice + ' ' + userSelectedLanguage?.KRW :
                                            '0'}</h5>
                                </div>
                            </div>
                            <div className=' extraMargina'  >

                                <div style={{ display: 'flex' }}>
                                    <h5 className='participantKeyBold'>{lanKorCondition ? ' Seat Price' : '즉시공유/구매가격'}</h5>
                                    {/* <h5 className='participantKeyTic'>{Bid?.groupBuy?.totalSeatsPrice}{userSelectedLanguage?.KRW}</h5> */}
                                    <h5 className='participantKeyTic'>{Bid?.groupBuy?.totalSeatsPrice ? Bid?.groupBuy?.totalSeatsPrice : Bid?.userTicketId?.totalSeatsPrice ? Bid?.userTicketId?.totalSeatsPrice : 0 + "" ||
                                        Bid?.ticketId?.totalSeatsPrice ? Bid?.ticketId?.totalSeatsPrice : 0 + ""}{userSelectedLanguage?.KRW}</h5>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5 className='participantKeyBold'>{lanKorCondition ? 'Special gift' : '특전'}</h5>
                                    <h5 className='participantKeyTic tableEmail'>
                                        주중 {Bid?.userTicketId?.specialGiftWeekDay || Bid?.ticketId?.specialGiftWeekDay || Bid?.groupBuy?.specialGiftWeekDay || '--'} 회 | {Bid?.userTicketId?.specialGiftWeekDayKRW || Bid?.ticketId?.specialGiftWeekDayKRW || Bid?.groupBuy?.specialGiftWeekDayKRW || '--'} 만원
                                        <br />
                                        주말 {Bid?.userTicketId?.specialGiftWeekEnd || Bid?.ticketId?.specialGiftWeekEnd || Bid?.groupBuy?.specialGiftWeekEnd || '--'} 회 | {Bid?.userTicketId?.specialGiftWeekEndKRW || Bid?.ticketId?.specialGiftWeekEndKRW || Bid?.groupBuy?.specialGiftWeekEndKRW || '--'} 만원
                                    </h5>
                                </div>


                            </div>
                            <>
                                <div className='divHeader ' style={{ marginTop: '40px' }}>
                                    <h3 className='headerText'>
                                        {lanKorCondition ? ' Buyer’s bank info' : '구매자 계좌정보'}
                                    </h3>
                                    <div className='borderBottom'></div>
                                </div>
                                <div className='extraMargina' style={{ marginTop: '40px' }} >
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Bank Name' : '은행명'}</h5>
                                        <h5 className='participantKeyTic'>{Bid?.buyerId?.bank || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.Name}</h5>
                                        <h5 className='participantKeyTic'>{Bid?.buyerId?.fullName || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Bank Account Numnber' : '계좌번호'}</h5>
                                        <h5 className='participantKeyTic'>{Bid?.buyerId?.bankAccountNumber || '----'}</h5>
                                    </div>
                                </div>
                            </>
                        </div>




                    </div>
                    <div className='TransactionAmountInputDiv'>
                        <div className='saveTransactionButtonDiv '>
                            <Button loading={loading}
                                disabled={disableCondition}
                                onClick={
                                    title === 'Ticket sharing' ?
                                        payment_Request_ticket_share :
                                        title === 'Ticket Sharing Exchange' ?
                                            payment_Request_ticket_share_exchange :
                                            title === 'Ticket sharing rent' ?
                                                payment_Request_ticket_share_rent :
                                                title === 'Ticket sales' ?
                                                    payment_Request_ticket_sell :
                                                    title === 'Ticket sales Request Buy' ?
                                                        payment_Request_ticket_sell_group :
                                                        title === 'Group buy' ?
                                                            payment_Request_group_buy :
                                                            title === 'Group buy Participate' ?
                                                                payment_Request_group_buy_participate :
                                                                '---'
                                } className='saveTransactionBtnTic'>
                                {userSelectedLanguage?.Save}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TicketsVeiwDetail
