import { Button, Input, Tooltip } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useEffect, useState } from 'react'
import { ButtonLogout, CoustomDatePicker } from '../../Components'
import { Radio } from 'antd';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { types, typesKr } from '../../utils/constants';
import { stringLimiter } from '../../utils/helpers';

const VeiwDetailMembershipOffline = (props) => {
  const { user } = props
  let data = props?.history?.location?.state[0]
  let disableCondition = true
  let ticketData =
    data?.exchangeRequestId?.userTicketId ||
    data?.rentRequestId?.userTicketId ||
    data?.ticketShareId?.ticketId ||
    data?.ticketSaleId?.ticketId ||
    data?.groupBuyId?.ticketId ||
    data?.groupBuyParticipateId?.groupBuyId ||
    data?.golfReservationId ||
    data?.auctionRentalBidId?.ticketId ||
    data?.auctionSaleBidId?.ticketId ||
    data?.auctionGolfResBidId ||
    data?.requestBuyId?.userTicketId
  const userSelectedLanguage = props?.language
  const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const lanKorCondition = SelectedLanguage === 'english'
  const [loading, setLoading] = useState(false)
  const [dateOFTr, setDateOFTr] = useState(data?.dateOfTransaction)
  const [tranNum, setTranNum] = useState(null)
  const [value, setValue] = useState('Bank Wire')
  const [bankName, setBankName] = useState('')
  const [bankAccNum, setBankAccNum] = useState(null)
  const [bankAccount, setBankAccount] = useState('')
  const [personInCharge, setPersonInCharge] = useState('')
  const [memo, setMemo] = useState('')
  let getAdvanceFee = data?.auctionRentalBidId?.advanceFee || data?.auctionSaleBidId?.advanceFee || data?.auctionGolfResBidId?.advanceFee
  let getAdvanceFeeDate = moment(data?.auctionRentalBidId?.updatedAt || data?.auctionSaleBidId?.updatedAt || data?.auctionGolfResBidId?.updatedAt).format('YYYY-MM-DD')
  const [advanceFeeAmount, setAdvanceFeeAmount] = useState(getAdvanceFee)
  const [advanceFeeDate, setAdvanceFeeDate] = useState(getAdvanceFeeDate)
  
  const createDate = (d) => {
    setDateOFTr(d.format('YYYY-MM-DD'))
  }
  const options = [
    { label: lanKorCondition ? 'Bank Wire' : '계좌이체', value: 'Bank Wire' },
    { label: lanKorCondition ? 'Cash' : '현금', value: 'Cash' },
    { label: lanKorCondition ? 'ETC' : '기타', value: 'ETC' },
  ]

  useEffect(() => {
    const paymentData = data
    setDateOFTr(paymentData?.dateOfTransaction)
    setMemo(paymentData?.memo)
    setValue(paymentData?.transactionType)
    setPersonInCharge(paymentData?.paymentIncharge)
    setBankName(paymentData?.bankInfo?.bankName)
    setBankAccNum(paymentData?.bankInfo?.bankAccountNumber)
    setBankAccount(paymentData?.bankInfo?.bankAccount)
    setTranNum(paymentData?.transactionNumber)
  }, [])

  const onChange = ({ target: { value } }) => {
    setValue(value);
  }

  return (
    <>
      <ButtonLogout props={props} />
      <div className='settingDiv'>

        <div className='nameEmailDiv'>
          <div className='divHeader'>
            <h3 className='headerText'>
              {lanKorCondition ? 'View details' : '회원권 거래정보'}
            </h3>
            <div className='borderBottom'></div>
          </div>

          <div className='ViewTransactionCardsDiv '>
            <div className='ViewTransactionCard paddingTopnone'>
              <h4 className='ViewTransactionCardTextBold size'>
                {lanKorCondition ? 'Seller Info' : '판매자 정보'}
              </h4>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {'ID'}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                    {!data?.userId?._id ? user?._id : data?.userId?._id || '----'}
                  </h5>
                </div>
              </div>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.Name}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                    {!data?.userId?._id ? `${user?.userName}-${lanKorCondition ? 'admin' : '어드민'}` : data?.userId?.fullName || '----'}

                  </h5>
                </div>
              </div>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.Email}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBoldEmail textElipsis'>
                    {!data?.userId?._id ? user?.email : data?.userId?.email || '----'}

                  </h5>
                </div>
              </div>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.PhoneNumber}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                    {data?.userId?.telNo ? data?.userId?.telNo : '----'}
                  </h5>
                </div>
              </div>
            </div>
            <div className='ViewTransactionCardCenter paddingTopnone'>
              <h4 className='ViewTransactionCardTextBold size'>
                {lanKorCondition ? 'Buyer Info' : '구매자 정보'}
              </h4>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {'ID'}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                    {data?.buyerId?._id ? data?.buyerId?._id : '----'}
                  </h5>
                </div>
              </div>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.Name}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                    {data?.buyerId?.fullName ? data?.buyerId?.fullName : '----'}
                  </h5>
                </div>
              </div>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.Email}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBoldEmail textElipsis '>
                    {data?.buyerId?.email ? data?.buyerId?.email : '----'}
                  </h5>
                </div>
              </div>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.PhoneNumber}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                    {data?.buyerId?.telNo ? data?.buyerId?.telNo : '----'}
                  </h5>
                </div>
              </div>
            </div>
            <div className='ViewTransactionCardLast'>
              <div className='TransactionAmountInputDiv'>
                <div className='inputTransactionAmountLabelDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    01){lanKorCondition ? 'Person in charge' : '담당자 이름'}
                  </h5>
                </div>
                <div className='inputTransactionAmountDiv'>
                  <Input disabled={disableCondition} onChange={(e) => setPersonInCharge(e.target?.value)} value={personInCharge} className='inputTransactionAmount' />
                </div>
              </div>
              <div className='TransactionAmountInputDiv'>
                <div className='inputTransactionAmountLabelDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    02){userSelectedLanguage?.Memo}
                  </h5>
                </div>
                <div className='inputTransactionAmountDiv'>
                  <TextArea
                    disabled={disableCondition}
                    onChange={(e) => setMemo(e.target?.value)}
                    value={memo}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    className='inputTransactionAmount'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='nameEmailDiv'>
          <div className='ticketsInfoCards'>
            <div className='ticketsInfoCard'>
              {
                advanceFeeDate && advanceFeeAmount && value ?
                  <>
                    < div className='divHeader'>
                      <h3 className='headerText'>
                        {lanKorCondition ? 'Advance Fee Payment details' : '선금 결제 정보'}
                      </h3>
                      <div className='borderBottom'></div>
                    </div>
                    <div className='TicketForm'>
                      <div>
                        <h4>{lanKorCondition ? 'Date of transaction' : '거래일자'}</h4>
                        <Input disabled={true} value={advanceFeeDate} className='inputTransactionAmount' />

                      </div>
                      <div>
                        <h4>{lanKorCondition ? 'Transaction Number' : '거래번호'}</h4>
                        <Input disabled={true} value={advanceFeeAmount} className='inputTransactionAmount' suffix='원' />
                      </div>
                      <div>
                        <h4>{lanKorCondition ? 'Transaction Type' : '거래방법'}</h4>
                        <Radio.Group
                          disabled={disableCondition}
                          options={options}
                          onChange={onChange}
                          value={value}
                          optionType='button'
                          buttonStyle='solid'
                        />
                      </div>
                    </div>
                  </>
                  : null}

              <div className='divHeader'>
                <h3 className='headerText'>
                  {lanKorCondition ? 'Auction Payment details' : '경매 결제 정보'}
                </h3>
                <div className='borderBottom'></div>
              </div>
              <div className='TicketForm'>
                <div>
                  <h4>{lanKorCondition ? 'Date of transaction' : '거래일자'}</h4>
                  {
                    disableCondition ?
                      <Input disabled={disableCondition} value={moment(dateOFTr).format('YYYY-MM-DD')} className='inputTransactionAmount' />
                      :
                      <CoustomDatePicker disabled={disableCondition} setDateOFTr={setDateOFTr} />
                  }

                </div>
                <div>
                  <h4>{lanKorCondition ? 'Transaction Number' : '거래번호'}</h4>
                  <Input disabled={disableCondition} onChange={(e) => setTranNum(e.target?.value)} value={tranNum} className='inputTransactionAmount' suffix='원' />
                </div>
                <div>
                  <h4>{lanKorCondition ? 'Transaction Type' : '거래방법'}</h4>
                  <Radio.Group
                    disabled={disableCondition}
                    options={options}
                    onChange={onChange}
                    value={value}
                    optionType='button'
                    buttonStyle='solid'
                  />
                </div>
              </div>


              <div className='TicketForm'>
                <h3>{lanKorCondition ? ' Bank Info' : '계좌정보'}</h3>
                <div>
                  <h4>{lanKorCondition ? 'Bank Name' : '은행명'}</h4>
                  <Input disabled={disableCondition} onChange={(e) => setBankName(e.target?.value)} value={bankName} className='inputTransactionAmount' />
                </div>
                <div>
                  <h4>{lanKorCondition ? 'Bank Account' : '예금주'}</h4>
                  <Input disabled={disableCondition} onChange={(e) => setBankAccount(e.target?.value)} value={bankAccount} className='inputTransactionAmount' />
                </div>
                <div>
                  <h4>{lanKorCondition ? 'Bank Account Number' : '계좌번호'}</h4>
                  <Input disabled={disableCondition} onChange={(e) => setBankAccNum(e.target?.value)} value={bankAccNum} className='inputTransactionAmount' />
                </div>
              </div>
            </div>
            <div className='ticketsInfoCard'>
              <div className='divHeader'>
                <h3 className='headerText'>
                  {lanKorCondition ? ' Ticket Info' : '회원권 정보 '}
                </h3>
                <div className='borderBottom'></div>
              </div>
              {!data?.golfReservationId && !data?.auctionGolfResBidId ?
                <>

                  <div className='extraMargina' style={{ marginTop: '30px' }} >
                    <div style={{ display: 'flex' }}>
                      <h5 className='participantKeyBold'>{userSelectedLanguage?.GolfClubName}</h5>
                      <h5 className='participantKeyTic'>{ticketData?.golfClub?.clubName || '----'}</h5>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <h5 className='participantKeyBold'>{userSelectedLanguage?.Type}</h5>
                      <h5 className='participantKeyTic'>{lanKorCondition ? ticketData?.membershipType : typesKr[types?.indexOf(ticketData?.membershipType)]?.typesKr || '----'}</h5>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <h5 className='participantKeyBold'>{userSelectedLanguage?.JoiningFee}</h5>
                      <h5 className='participantKeyTic tableEmail'>{ticketData?.joiningFee ? ticketData?.joiningFee + ' ' + userSelectedLanguage?.KRW : ticketData?.joiningFee ? ticketData?.joiningFee + ' ' + userSelectedLanguage?.KRW : '----'}</h5>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <h5 className='participantKeyBold'>{userSelectedLanguage?.JoiningDate}</h5>
                      <h5 className='participantKeyTic'>{moment(ticketData?.joiningDate?.date1).format('YYYY-MM-DD') + ' / ' + moment(ticketData?.joiningDate?.date2).format('YYYY-MM-DD')} </h5>
                    </div>
                  </div>
                  <div className=' extraMargina'  >
                    <div style={{ display: 'flex' }}>
                      <h5 className='participantKeyBold'>{lanKorCondition ? ' Seat Price' : '즉시공유/구매가격'}</h5>
                      <h5 className='participantKeyTic'>{ticketData?.totalSeatsPrice ? ticketData?.totalSeatsPrice : 0 + ""}{userSelectedLanguage?.KRW}</h5>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <h5 className='participantKeyBold'>{lanKorCondition ? 'Special gift' : '특전'}</h5>
                      <h5 className='participantKeyTic tableEmail'>
                        주중 {ticketData?.specialGiftWeekDay || '--'} 회 | {ticketData?.specialGiftWeekDayKRW || '--'} 만원
                        <br />
                        주말 {ticketData?.specialGiftWeekEnd || '--'} 회 | {ticketData?.specialGiftWeekEndKRW || '--'} 만원
                      </h5>
                    </div>
                  </div>
                </> :
                <div className='extraMargina' style={{ marginTop: '30px' }} >
                  <div style={{ display: 'flex' }}>
                    <h5 className='participantKeyBold'>{userSelectedLanguage?.GolfClubName}</h5>
                    <h5 className='participantKey'>{ticketData?.golfClub?.clubName || '----'}</h5>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h5 className='participantKeyBold'>{userSelectedLanguage?.address}</h5>
                    <h5 className='participantKey'>{ticketData?.golfClub?.clubAddress || '----'}</h5>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h5 className='participantKeyBold'>{userSelectedLanguage?.Region}</h5>
                    <h5 className='participantKey '>{ticketData?.golfClub?.region || '----'}</h5>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h5 className='participantKeyBold'>{userSelectedLanguage?.Memo}</h5>
                    <h5 className='participantKey'>{ticketData?.memo || '----'}</h5>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h5 className='participantKeyBold'>{lanKorCondition ? 'Reservation Date' : '예약일자'}</h5>
                    <h5 className='participantKey'>{moment(ticketData?.reservedDate?.date1).format('YYYY-MM-DD') + ' ' + '|' + ' ' + moment(ticketData?.reservedDate?.date2).format('YYYY-MM-DD')}</h5>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h5 className='participantKeyBold'>{lanKorCondition ? 'Reservation Time' : '예약시간'}</h5>
                    <h5 className='participantKey'>{ticketData?.reservedTime || '----'}</h5>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h5 className='participantKeyBold'>{lanKorCondition ? 'Green fee' : '그린피'}</h5>
                    <h5 className='participantKey'>{ticketData?.greenFee ? ticketData?.greenFee + ' ' + userSelectedLanguage?.KRW : '----'}</h5>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h5 className='participantKeyBold'>{lanKorCondition ? 'Transfer fee' : '양도비'}</h5>
                    <h5 className='participantKey'>{ticketData?.transferFee ? ticketData?.transferFee + ' ' + userSelectedLanguage?.KRW : '----'}</h5>
                  </div>
                </div>
              }
              {
                data?.buyerId?._id ?
                  <>
                    <div className='divHeader ' style={{ marginTop: '40px' }}>
                      <h3 className='headerText'>
                        {lanKorCondition ? ' Buyer’s bank info' : '구매자 계좌정보'}
                      </h3>
                      <div className='borderBottom'></div>
                    </div>
                    <div className='extraMargina' style={{ marginTop: '40px' }} >
                      <div style={{ display: 'flex' }}>
                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Bank Name' : '은행명'}</h5>
                        <h5 className='participantKeyTic'>{data?.buyerId?.bank || '----'}</h5>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <h5 className='participantKeyBold'>{userSelectedLanguage?.Name}</h5>
                        <h5 className='participantKeyTic'>{data?.buyerId?.fullName || '----'}</h5>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Bank Account Numnber' : '계좌번호'}</h5>
                        <h5 className='participantKeyTic'>{data?.buyerId?.bankAccountNumber || '----'}</h5>
                      </div>
                    </div>
                  </> :
                  null}
            </div>




          </div>
          <div className='TransactionAmountInputDiv'>
            <div className='saveTransactionButtonDiv '>
              <Button loading={loading}
                disabled={disableCondition}
                className='saveTransactionBtnTic'>
                {userSelectedLanguage?.Save}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VeiwDetailMembershipOffline
