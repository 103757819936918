import React, { useState } from 'react'
import { Button, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { ButtonLogout } from '../../Components'
import { Table, Divider } from 'antd'
import { FiSearch } from 'react-icons/fi'
import { allPaths } from '../../utils/constants'

const { Option } = Select
const OnlineTransactionManagement = (props) => {
  const userSelectedLanguage = props?.language
  const history = props?.history
  const [dropDown, setdropDown] = useState( 6+'개 보기')
  const [tableRow, setTableRow] = useState(6)
  let arr = [{
    value: 10, Viewby: '10' +   '개 보기'
  }, {
    value: 8, Viewby: ' 8' +   '개 보기'
  }, {
    value: 6, Viewby: ' 6' +   '개 보기'
  },]

  const columns = [
    {
      title: userSelectedLanguage?.MembershipNumber,
      dataIndex: 'MembershipNumber',
      key: 'Membership number',
      render: (text) => <button className='tableBtn'>{text}</button>,
    },
    {
      title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
      dataIndex: 'NameIDphoneOfUploader',
      key: 'Name and Phone Number',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          {text.map((v, i) => {
            return <p className='namePhoneAndID'>{v}</p>
          })}
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.MembershipType,
      dataIndex: 'MembershipType',
      key: 'Membership Type',
      render: (text) => (
        <div>
          <p className='tableColoumWidthBig'>{text}</p>
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.TicketType,
      dataIndex: 'TicketType',
      key: 'Ticket Type',
      render: (text) => (
        <>
          {text === 'Shared rental auction' ? (
            <button className='cancelAuctionTableBtn'>{text}</button>
          ) : text === 'Shared rental' ? (
            <button className='soldOutAuctionTableBtn'>{text}</button>
          ) : text === 'Golf reservation auction' ? (
            <button className='waitingAuctionTableBtn'>{text}</button>
          ) : text === 'Shared exchange' ? (
            <button className='postingAuctionTableBtn'>{text}</button>
          ) : text === 'Golf reservation' ? (
            <button className='GolfreservationTableBtn'>{text}</button>
          ) : null}
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Card,
      dataIndex: 'Card',
      key: 'Card',
      render: (text) => (
        <div>
          <p className='tableColoumWidth'>{text}</p>
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.PaymentInfo,
      dataIndex: 'PaymentInfo',
      key: 'View Membership',
      render: (text) => (
        <>
          {text ? (
            <div className='tableColoumWidthBig'>
              <div className='searchIconDiv' onClick={() => { history?.push(allPaths?.ONLINE_TRANSACTION_VIEW_TRADING) }}>
                <FiSearch />
              </div>
            </div>
          ) : null}
        </>
      ),
    },

    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        <div className='tableBtnsDiv'>
          {text ? (
            <>
              <Button className='DeleteBtn'> {userSelectedLanguage?.Delete} </Button>
            </>
          ) : null}
        </div>
      ),
    },
  ]

  const dataSource = [
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'MembershipType',
      MembershipType: 'individual ',
      Card: 'Bank',
      PaymentInfo: 'search',
      TicketType: 'Shared rental',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'individual ',
      Card: 'Card',
      PaymentInfo: 'search',
      TicketType: 'Shared exchange',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'individual ',
      Card: 'Card',
      PaymentInfo: 'search',
      TicketType: 'Shared rental auction',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'individual ',
      Card: 'Bank',
      PaymentInfo: 'search',
      TicketType: 'Golf reservation auction',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'individual ',
      Card: 'Card',
      PaymentInfo: 'search',
      TicketType: 'Golf reservation',
      Action: ['Edit', 'Delete', 'Post approval'],
    }, {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'MembershipType',
      MembershipType: 'individual ',
      Card: 'Card',
      PaymentInfo: 'search',
      TicketType: 'Shared rental',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'individual ',
      Card: 'Bank',
      PaymentInfo: 'search',
      TicketType: 'Shared exchange',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'individual ',
      Card: 'Card',
      PaymentInfo: 'search',
      TicketType: 'Shared rental auction',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'individual ',
      Card: 'Card',
      PaymentInfo: 'search',
      TicketType: 'Golf reservation auction',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'individual ',
      Card: 'Card',
      PaymentInfo: 'search',
      TicketType: 'Golf reservation',
      Action: ['Edit', 'Delete', 'Post approval'],
    }, {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'MembershipType',
      MembershipType: 'individual ',
      Card: 'Card',
      PaymentInfo: 'search',
      TicketType: 'Shared rental',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'individual ',
      Card: 'Card',
      PaymentInfo: 'search',
      TicketType: 'Shared exchange',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'individual ',
      Card: 'Card',
      PaymentInfo: 'search',
      TicketType: 'Shared rental auction',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'individual ',
      Card: 'Card',
      PaymentInfo: 'search',
      TicketType: 'Golf reservation auction',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
    {
      MembershipNumber: '#123456',
      NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
      MembershipType: 'individual ',
      Card: 'Card',
      PaymentInfo: 'search',
      TicketType: 'Golf reservation',
      Action: ['Edit', 'Delete', 'Post approval'],
    },
  ]
  return (
    <>
      <ButtonLogout props={props} />
      <div className='membershipBox'>
        <div className='goldReservationHeader'>
          <div className='golfHeaderText'>
            <h3>{userSelectedLanguage?.SettlementManagement}</h3>
            <p>{'>'}{userSelectedLanguage?.OnlineTransactionManagement}</p>
          </div>
          <div className='headersearch'>
            <Select
              className='dropdown'
              defaultValue={dropDown}
              onChange={(e) => {
                setTableRow(e)
              }}
            >
              {arr.map((v, i) => {
                return <Option key={v.value}>{v?.Viewby}</Option>
              })}
            </Select>
            <Input
              className='searchgolfReservation'
              placeholder={userSelectedLanguage?.PleaseSearchATerm}
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
        <div className='tableDivManagement'>
          <Divider />
          <Table
            pagination={{ pageSize: tableRow }}
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: 700 }}
            size='middle'
          />
        </div>
      </div>
    </>
  )
}
export default OnlineTransactionManagement
