import { MinusCircleOutlined, PlusCircleFilled, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Form, Image, Input, InputNumber, Modal, Select, Space, Upload } from 'antd';
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import moment from 'moment';
import { ADMIN, POST } from '../../utils/apis';
import { regions, regionsKr, typesKr } from '../../utils/constants';
import { errorMessage, requiredMessage, successMessage, successNotification, warningMessage } from '../../utils/helpers';
import CoustomDatePicker2 from '../../Components/DatePicker/DatePicker2';
import CoustomRangePicker2 from '../../Components/RangePicker/RangePicker2';
import DaumPostcodeEmbed from 'react-daum-postcode'


const { RangePicker } = DatePicker


const { Option } = Select


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
        md: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
        md: {
            span: 18,
        },
    },
}

const EditUsersTicket = (props) => {

    const { user, ticketData, onCancel, getTickets, language, lanKorCondition, userSelectedLanguage } = props
    const [form] = Form.useForm()
    const [selectedRegion, setselectedRegion] = useState('')
    const [form2] = Form.useForm()
    let geocoder = new kakao.maps.services.Geocoder()
    const [allRegions, setAllRegions] = useState(regionsKr)
    const types = ['Individual member', 'Family member', 'Individual weekday', 'Family weekday', 'Company']
    const [am, setAm] = useState('am')
    const [hr, setHr] = useState('1')
    const [min, setMin] = useState('1')
    const [joiningFee, setjoiningFee] = useState(ticketData?.joiningFee || null)
    const [weekdayFee, setWeekdayFee] = useState(ticketData?.specialGiftWeekDayKRW || null)
    const [weekday, setWeekday] = useState(ticketData?.specialGiftWeekDay || null)
    const [weekendFee, setWeekendFee] = useState(ticketData?.specialGiftWeekEnd || null)
    const [weekend, setWeekend] = useState(ticketData?.specialGiftWeekEndKRW || null)
    const [memberFee, setMemberFee] = useState(ticketData?.memberFee || null)
    const [nonMemberFee, setNonMemberFee] = useState(ticketData?.NONmemberFee || null)
    const [caddyFee, setCaddyFee] = useState(ticketData?.caddyFee || null)
    const [cartFee, setcartFee] = useState(ticketData?.cartFee || null)
    const [greenFeeWeekday, setGreenFeeWeekday] = useState(ticketData?.greenFeeWeekDay || null)
    const [greenFeeWeekend, setGreenFeeWeekend] = useState(ticketData?.greenFeeWeekEnd || null)
    const [formContinue, setFormContinue] = useState(false)
    const [phoneValue, setPhoneValue] = useState(ticketData?.phoneNumber || '')
    const [address, setAddress] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingReg, setLoadingReg] = useState(false)
    const [fileList, setFileList] = useState([])
    const [golfClub, setGolfClubName] = useState(null)
    const [golfClubData, setGolfClubData] = useState(null)
    const [fee, setFee] = useState(ticketData?.totalSeatsPrice || null)
    const [seats, setSeats] = useState(ticketData?.totalSeats || null)
    const [seatPrice, setSeatPrice] = useState((ticketData?.totalSeatsPrice / ticketData?.totalSeats) || null)
    const [searchAddress, setSearchAddress] = useState('')
    const [showAddressApi, setShowAddressApi] = useState(false)
    console.log('ticketData', ticketData)
    const handleComplete = (data) => {
        let fullAddress = data?.address
        let extraAddress = ''
        if (data?.addressType === 'R') {
            if (data?.bname !== '') {
                extraAddress += data?.bname
            }
            if (data?.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data?.buildingName}` : data?.buildingName
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
        }

        setSearchAddress(fullAddress)
        setShowAddressApi(false)
        // onFinishEditAdd(fullAddress)
    }

    const handleChange = (value) => {
        setselectedRegion(value)
    }
    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: language?.Pleaseselectdate,
            },
        ],
    }
    const rangeConfig = {
        rules: [
            {
                type: 'array',
                required: true,
                message: language?.Pleaseselectdate
            },
        ],
    }

    const allStatesNull = () => {
        setjoiningFee(null)
        setWeekday(null)
        setWeekdayFee(null)
        setWeekendFee(null)
        setWeekend(null)
        setMemberFee(null)
        setNonMemberFee(null)
        setCaddyFee(null)
        setcartFee(null)
        setGreenFeeWeekday(null)
        setGreenFeeWeekend(null)
        setPhoneValue('')
        setAddress(null)
        setFileList([])
        setGolfClubData(null)

    }

    const onSearch = (value) => {
        // console.log('search:', value)
    }

    const onFinish = (fieldsValue) => {
        const formData = new FormData()
        const values = { ...fieldsValue }
        // console.log('fieldsValue', fieldsValue)

        if (!fieldsValue?.Benefits || !fieldsValue?.Benefits?.length) {
            return errorMessage('혜택 정보를 입력해주세요')
        }
        !formContinue ? setLoadingReg(true) : setLoading(true)

        const files = values?.file?.file
        // console.log('files', files)
        let joinDate = {
            date1: fieldsValue['joiningDate'][0].format('YYYY-MM-DD'),
            date2: fieldsValue['joiningDate'][1].format('YYYY-MM-DD')
        }
        formData.append('_id', ticketData?._id)
        formData.append('file', files)
        // formData.append('userId', user?._id)
        formData.append('golfClub', golfClubData?._id)
        formData.append('membershipType', values?.membershipType)
        formData.append('joiningDate', JSON.stringify(joinDate))
        formData.append('joiningFee', values?.joiningFee)
        formData.append('specialGiftWeekDay', weekday)
        formData.append('specialGiftWeekDayKRW', weekdayFee)
        formData.append('specialGiftWeekEnd', weekend)
        formData.append('specialGiftWeekEndKRW', weekendFee)
        formData.append('memberFee', memberFee)
        formData.append('NONmemberFee', nonMemberFee)
        formData.append('address', golfClubData?.clubAddress)
        formData.append('openingDate', values?.openingDate)
        formData.append('greenFeeWeekDay', greenFeeWeekday)
        formData.append('greenFeeWeekEnd', greenFeeWeekend)
        formData.append('caddyFee', values?.caddyFee)
        formData.append('cartFee', values?.cartFee)
        formData.append('phoneNumber', values?.phoneNumber)
        formData.append('homepage', values?.homepage)
        formData.append('totalSeats', Number(values?.totalSeats))
        formData.append('totalSeatsPrice', Number(values?.totalSeatsPrice))
        formData.append('seatPrice', Number(values?.seatPrice))
        formData.append('additionalBenefits', JSON.stringify(values?.Benefits))
        if (ticketData?.membershipCardUrl) {
            formData.append('membershipCardUrl', ticketData?.membershipCardUrl)
        }
        formData.append('lang', lanKorCondition ? 'eng' : 'kr')

        axios.post(POST?.EDIT_GROUPBUY_TICKET, formData)
            .then((res) => {
                const { data } = res
                // console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getTickets()
                    // form.resetFields()
                    allStatesNull()
                    onCancel()
                    setFormContinue(false)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                !formContinue ? setLoadingReg(false) : setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setFormContinue(false)
                setLoading(false)
                !formContinue ? setLoadingReg(false) : setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)
            })
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage('You can only upload Images!')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const handlePhoneInput = (e) => {
        let value = e?.target?.value?.replace(/[^0-9-]/g, '')
        let event = e?.nativeEvent?.data
        if (event && ((value?.length === 3 || value?.length === 8) || (phoneValue?.length === 3 || phoneValue?.length === 8))) {
            if (phoneValue?.length === 3 || phoneValue?.length === 9) {
                value = `${phoneValue}-${event}`
            }
            else {
                value = `${value}-`
            }
        }
        setPhoneValue(value)
    }

    const onChanges = (value) => {

        let new_value = golfClub?.find((v) => v?._id === value)
        setGolfClubData(new_value)
        setAddress(new_value?.clubAddress)

    }


    let golfClubName = () => {
        axios.get(ADMIN.GOLF_CLUB)
            .then((res) => {

                const { data } = res
                // console.log('data====>',data)
                if (data.success) {
                    setGolfClubName(data?.data)
                    setGolfClubData(data?.data?.find((v) => v?._id === ticketData?.golfClub?._id))
                    form.setFieldsValue({ golfClub: data?.data?.find((v) => v?._id === ticketData?.golfClub?._id)?._id })
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
            })
    }

    useEffect(() => {
        golfClubName()
        form.setFieldsValue({
            totalSeatsPrice: ticketData?.totalSeatsPrice,
            totalSeats: ticketData?.totalSeats,
            totalSeats: ticketData?.totalSeats,
            seatPrice: (ticketData?.totalSeatsPrice / ticketData?.totalSeats) || null
        })

        if (ticketData?.joiningDate?.date1) {
            form.setFieldsValue({
                joiningDate: [moment(ticketData?.joiningDate?.date1), moment(ticketData?.joiningDate?.date2)],
                openingDate: moment(ticketData?.openingDate),
            })
        }
    }, [])

    const setExpiryDate = (e) => {
        let date = moment(e?._d).add(-1, 'days').format('YYYY-MM-DD')
        form.setFieldValue('openingDate', moment(date, 'YYYY-MM-DD'))
    }
    const setDateRange = (e) => {
        let date1 = moment(e[0]?._d).add(-1, 'days').format('YYYY-MM-DD')
        let date2 = moment(e[1]?._d).add(-1, 'days').format('YYYY-MM-DD')
        form.setFieldValue('joiningDate', [moment(date1, 'YYYY-MM-DD'), moment(date2, 'YYYY-MM-DD')])
    }

    const onFinishGolfClub = (values) => {

        if (!values?.file) {
            return warningMessage(lanKorCondition ? 'Please Upload Image' : '파일을 업로드 해주세요')
        }
        setLoading(true)

        let formData = new FormData()

        formData.append('clubName', values?.clubName)
        formData.append('clubAddress', searchAddress)
        geocoder.addressSearch(searchAddress, (result, status) => {
            if (status === kakao.maps.services.Status.OK) {
                formData.append('latitude', result[0]?.y || 0)
                formData.append('logitude', result[0]?.x || 0)
                formData.append('file', values?.file?.file)
                formData.append('region', values?.region)
                formData.append('request', true)
                formData.append('ticketId', ticketData?._id)

                axios.post(ADMIN?.CREATE_GOLF_CLUB, formData)
                    .then((res) => {
                        const { data } = res
                        setLoading(false)
                        if (data.success) {
                            successNotification(lanKorCondition ? data.message : data.messageKr)
                            ticketData.golfClub = data?.data
                            getTickets()
                            // onCancel()
                            setGolfClubData(data?.data)
                            setFileList([])
                        } else {
                            errorMessage(lanKorCondition ? data.message : data.messageKr)
                        }
                    })
                    .catch((e) => {
                        console.log('e====>', e)
                        setLoading(false)
                        errorMessage(language?.OppsError)
                    })

            }
            else {
                setLoading(true)
                return warningMessage(lanKorCondition ? 'please select Address' : 'please select Address')
            }
        })
    }

    const handleClose = () => {
        setShowAddressApi(false)

    }

    return (
        <>
            <Modal
                open={ticketData?._id}
                onCancel={onCancel}
                // className='addholdingModal'
                className='responsiveModal '
                footer={null}
                // width={'80%'}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div'>
                        <center><h2>{language?.RegisterYourMembership}</h2></center>
                        <Modal title={language?.ChangeAddress} open={showAddressApi} footer={null} onCancel={handleClose}>
                            <DaumPostcodeEmbed autoClose={false} loading={true} style={{ width: '100%', height: 400 }} onClose={handleClose} onCancel={handleClose} onComplete={handleComplete} {...props} />
                        </Modal>
                        {!ticketData?.golfClub?._id &&
                            <Form
                                {...formItemLayout}
                                form={form2}
                                name='basic'
                                initialValues={{ remember: true }}
                                onFinish={onFinishGolfClub}
                                autoComplete='off'
                            >
                                <h2>{lanKorCondition ? 'Golf Club Request' : '골프예약 신청건'}</h2>
                                <Form.Item
                                    label={language?.ClubName}
                                    name='clubName'
                                    initialValue={ticketData?.golfClubRequest}
                                    rules={[{ required: true, message: userSelectedLanguage?.PleaseinputClubName }]}
                                >
                                    <Input /* className='GolfClubInputFeild' */ />
                                </Form.Item>
                                <Form.Item
                                    initialValue={searchAddress}
                                    label={language?.Clubaddress}
                                    name='clubAddress'
                                >
                                    <div>
                                        <Input value={searchAddress} onClick={() => setShowAddressApi(true)} />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label={language?.Region}
                                    name='region'
                                    initialValue={allRegions?.[0]?.TitleEn}
                                    rules={[{ required: true, message: userSelectedLanguage?.Pleaseinputregion }]}
                                >
                                    <Select style={{ width: '100%' }}
                                        defaultValue={lanKorCondition ? allRegions[0].TitleEn : allRegions[0].TitleKr}
                                        // className='GolfClubInputFeild SelectDropDown'
                                        onChange={handleChange}>
                                        {
                                            allRegions?.map((v, i) => {
                                                return (
                                                    <Option value={v?.TitleEn}>{lanKorCondition ? v?.TitleEn : v?.TitleKr}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={language?.File}
                                    name='file'
                                // rules={[{ required: true, message: 'Please input  File!' }]}
                                >
                                    <Upload
                                        name='file'
                                        multiple={false}
                                        beforeUpload={() => false}
                                        accept='image/png, image/jpeg'
                                        onChange={normFile}
                                        fileList={fileList}
                                    >
                                        <Button style={{ width: '100%' }} className='BtnUpload' icon={<UploadOutlined color='#5EA29F' />}>Upload</Button>
                                    </Upload>
                                </Form.Item>

                                <Button style={{ width: '100%' }} block loading={loading} className='AddClubCardBtn' htmlType='submit'>{language?.AddgolfClub}</Button>

                            </Form>
                        }<br />
                        <Form disabled={!golfClubData?._id} name='time_related_controls' form={form} className='formClass' requiredMark={false}  {...formItemLayout} onFinish={onFinish}>
                            <h2>{language?.Info}</h2>

                            <Form.Item
                                name='golfClub'
                                label={language?.GolfCourseName}
                            >
                                <Select
                                    style={{ width: '70%' }}
                                    showSearch
                                    // defaultValue={golfClub?.find((v) => v?._id === ticketData?.golfClub)?._id}
                                    value={golfClubData?.id}
                                    placeholder={language?.SearchGolfCourseName}
                                    optionFilterProp='children'
                                    onChange={onChanges}
                                    onSearch={onSearch}
                                    filterOption={(input, option) => option.children.toLowerCase()?.includes(input?.toLowerCase())}
                                >
                                    {golfClub?.map((v, i) => (<Option key={i} value={v?._id}>{v?.clubName}</Option>))}
                                </Select>
                            </Form.Item>

                            {golfClubData?._id ?
                                <div className='row_fields1' style={{ maxWidth: 300, marginBottom: 20 }}>
                                    <Image width={100} height={100} src={golfClubData?.imgUrl} />
                                    <div style={{ marginTop: -10 }}>
                                        <h2>{golfClubData?.clubName}</h2>
                                        <p>
                                            {lanKorCondition ? golfClubData?.region : regionsKr[regions?.indexOf(golfClubData?.region)]?.TitleKr}
                                        </p>
                                        {/* <p>{golfClubData?.region}</p> */}

                                        <Button onClick={() => { setGolfClubData(null); setAddress(null) }}>{language?.Delete}</Button>
                                    </div>
                                </div> : null}
                            <Form.Item
                                name='file'
                                label={language?.AttachmentMembershipCar}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input Membership Card!',
                            //         whitespace: false
                            //     },
                            // ]}
                            >
                                <Upload
                                    disabled={ticketData?.membershipCardUrl}
                                    style={{ width: '100%' }}
                                    type='file'
                                    name='file'
                                    multiple={false}
                                    beforeUpload={() => false}
                                    accept='image/png, image/jpeg'
                                    onChange={normFile}
                                    fileList={fileList}
                                >
                                    <Button disabled={ticketData?.membershipCardUrl} style={{ width: '100%' }} icon={<UploadOutlined />}></Button>
                                </Upload>

                            </Form.Item>

                            <Form.Item
                                name='membershipType'
                                label={language?.membershipType}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseSelectType,
                                    },
                                ]}
                                initialValue={types?.find((v) => v === ticketData?.membershipType)}
                            >
                                <Select placeholder={language?.membershipType}>
                                    {language?.login === "Login" ?
                                        types?.map((v, i) => (<Option key={i} value={v}>{v}</Option>)) :
                                        typesKr?.map((v, i) => (
                                            <Option key={i} value={v?.typesEn}>{v?.typesKr}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name='joiningFee'
                                label={language?.entrancefee}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputJoiningFee
                                    },
                                ]}
                                initialValue={ticketData?.joiningFee}
                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        placeholder={language?.entrancefee}
                                        maxLength={10}
                                        value={joiningFee}
                                        onChange={(e) => setjoiningFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>
                            {!ticketData?.joiningDate?.date1 ?
                                <Form.Item name='joiningDate' label={language?.Timeperiod} {...rangeConfig}>
                                    <CoustomRangePicker2 noDefault={true} format={'YYYY-MM-DD'} /* date={dateRange1} */ setDate={setDateRange} />
                                </Form.Item>
                                :
                                <Form.Item name='joiningDate' label={language?.Timeperiod} {...rangeConfig}>
                                    <RangePicker disabled style={{ width: '100%' }} placeholder={[language?.Startdate, language?.Enddate]} />
                                </Form.Item>
                            }
                            <Form.Item
                                name='specialGift'
                                label={language?.SpecialGift}
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_, names) => {
                                            if (!weekday) {
                                                return Promise.reject(new Error(language?.PleaseEnterWeekday))
                                            }
                                            if (!weekdayFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterWeekdayFee))
                                            }
                                            if (!weekend) {
                                                return Promise.reject(new Error(language?.PleaseEnterWeekend))
                                            }
                                            if (!weekendFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterWeekendFee))
                                            }
                                        }
                                    }
                                ]}
                            >
                                <div className='holdingDiv' style={{ display: 'flex' }}>
                                    <div className='holding_member_fee'  >

                                        {/* <label style={{ marginRight: '4%' }} htmlFor=''>{language?.Weekday}</label> */}

                                        <div style={{ width: 'auto', display: 'flex' }}>
                                            <p className='p_weekday' >{language?.Weekday}</p>
                                            <Input
                                                placeholder={language?.episode}
                                                className='input_weekday'
                                                maxLength={3}
                                                value={weekday}
                                                onChange={(e) => setWeekday(e.target.value.replace(/[^0-9]/g, ''))}
                                            />
                                            <Input
                                                suffix={language?.KRW}
                                                className='input_weekend'
                                                value={weekdayFee}
                                                onChange={(e) => setWeekdayFee(e.target.value.replace(/[^0-9]/g, ''))}
                                                maxLength={10}

                                            />
                                        </div>
                                    </div>
                                    <div className='holding_member_fee'>
                                        {/* <label style={{ marginRight: '4%' }} htmlFor=''>{language?.Weekend}</label> */}
                                        <div style={{ width: 'auto', display: 'flex' }}>
                                            <p className='p_weekday'>{language?.Weekend}</p>

                                            <Input
                                                placeholder={language?.episode}
                                                className='input_weekday'
                                                maxLength={4}
                                                value={weekend}
                                                onChange={(e) => setWeekend(e.target.value.replace(/[^0-9]/g, ''))}
                                            />
                                            <Input
                                                suffix={language?.KRW}
                                                className='input_weekend'
                                                maxLength={9}
                                                value={weekendFee}
                                                onChange={(e) => setWeekendFee(e.target.value.replace(/[^0-9]/g, ''))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='fee'
                                label={language?.usageFee}
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_, names) => {
                                            if (!memberFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterMemberFee))
                                            }
                                            if (!nonMemberFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterNon_MemberFee))
                                            }
                                        }
                                    }
                                ]}
                            >
                                <div className='usageFeeMemberNonmember' style={{ display: 'flex' }}>
                                    <div style={{ width: '50%', textAlign: 'center' }}>
                                        <label style={{ marginRight: '4%' }} htmlFor=''>{language?.Member}</label>
                                        <Input
                                            suffix={language?.KRW}
                                            style={{
                                                width: 'auto',
                                                // marginLeft: '4%',
                                            }}
                                            maxLength={10}
                                            value={memberFee}
                                            onChange={(e) => setMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                    </div>

                                    <div style={{ width: '50%', marginLeft: 'auto', textAlign: 'center' }}>
                                        <label htmlFor='' style={{ marginRight: '4%' }}>{language?.NonMember}</label>

                                        <Input
                                            suffix={language?.KRW}
                                            style={{
                                                width: 'auto',
                                                // marginLeft: '4%',

                                            }}
                                            maxLength={10}
                                            value={nonMemberFee}
                                            onChange={(e) => setNonMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                    </div>
                                </div>
                            </Form.Item>

                            <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                            <h2>{language?.GolfCourseInformation}</h2>
                            <Form.Item
                                name='totalSeatsPrice'
                                label={language?.membershipAmount}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputFee,
                                    },
                                ]}
                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        placeholder={language?.membershipAmount}
                                        maxLength={10}
                                        value={fee}
                                        onChange={(e) => { setFee(e.target.value?.replace(/[^0-9]/g, '')), setSeatPrice(e.target.value / seats) }}
                                    />
                                </div>
                            </Form.Item>

                            <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                            <Form.Item
                                name='totalSeats'
                                label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />{language?.TotalDesiredAccount}</h4>}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputSeats,
                                    },
                                ]}
                            >
                                <div style={{ width: '100%' }}>
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        type='number'
                                        // suffix={language?.KRW}
                                        placeholder={language?.TotalDesiredAccount}
                                        max={100}
                                        maxLength={7}
                                        value={seats}
                                        onChange={(e) => { setSeats(e), setSeatPrice(fee / e) }}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='seatPrice'
                                label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />{lanKorCondition ? 'Seat Price' : '구좌당 금액'}</h4>}
                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        placeholder={language?.AmoutperAccount}
                                        maxLength={7}
                                        disabled
                                        value={seatPrice}
                                    />
                                </div>
                            </Form.Item>
                            <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                            <h2>{language?.GolfCourseInformation}</h2>

                            {ticketData?.openingDate ?
                                <Form.Item name='openingDate' label={language?.OpeningDate}{...config}>
                                    <DatePicker disabled placeholder={language?.Selectdate} style={{ width: '100%' }} />
                                </Form.Item>
                                :
                                <Form.Item name='openingDate' label={language?.OpeningDate}{...config}>
                                    <CoustomDatePicker2 placeholder={language?.Selectdate} setDate={setExpiryDate} />
                                </Form.Item>
                            }
                            <Form.Item
                                name='greenFee'
                                label={language?.GreenFee}
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_, names) => {
                                            if (!greenFeeWeekday) {
                                                return Promise.reject(new Error(language?.Pleaseentergreenfeeweekday))
                                            }
                                            if (!greenFeeWeekend) {
                                                return Promise.reject(new Error(language?.Pleaseentergreenfeeweekend))
                                            }
                                        }
                                    }
                                ]}
                            >
                                <div style={{ display: 'flex' }}>
                                    <Input
                                        style={{ width: '49.5%', marginRight: '0.5%' }}
                                        suffix={language?.KRW}
                                        addonBefore={language?.Weekday}
                                        maxLength={7}
                                        value={greenFeeWeekday}
                                        onChange={(e) => setGreenFeeWeekday(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                    <Input
                                        style={{ width: '49.5%', marginLeft: '0.5%' }}
                                        suffix={language?.KRW}
                                        addonBefore={language?.Weekend}
                                        maxLength={7}
                                        value={greenFeeWeekend}
                                        onChange={(e) => setGreenFeeWeekend(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='caddyFee'
                                label={language?.Caddy}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputCaddyFee,
                                    },
                                ]}
                                initialValue={ticketData?.caddyFee || null}
                            >
                                <Input
                                    suffix={language?.KRW}
                                    placeholder={language?.Caddy}
                                    maxLength={7}
                                    value={caddyFee}
                                    onChange={(e) => setCaddyFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                />
                            </Form.Item>

                            <Form.Item
                                name='cartFee'
                                label={language?.CartFee}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputCartFee,
                                    },
                                ]}
                                initialValue={ticketData?.cartFee || null}
                            >
                                <Input
                                    suffix={language?.KRW}
                                    placeholder={language?.CartFee}
                                    maxLength={7}
                                    value={cartFee}
                                    onChange={(e) => setcartFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                />
                            </Form.Item>

                            <Form.Item
                                name='phoneNumber'
                                label={language?.PhoneNumber}
                                rules={[
                                    {
                                        required: true,
                                        message: requiredMessage(language?.PhoneNumber)
                                    }
                                ]}
                                initialValue={ticketData?.phoneNumber || null}
                            >
                                <div>
                                    <Input
                                        required
                                        maxLength={13}
                                        minLength={13}
                                        value={phoneValue}
                                        onChange={handlePhoneInput}
                                        placeholder={language?.phonePlaceholder}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item
                                name='homepage'
                                label={language?.HomePage}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.Pleaseinputyourhomepage,
                                    }
                                ]}
                                initialValue={ticketData?.homepage || null}
                            >
                                <Input placeholder={language?.HomePage} />
                            </Form.Item>

                            <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                            <h2>{language?.Additionalbenefits}</h2>

                            <Form.List name='Benefits' initialValue={ticketData?.additionalBenefits || []}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            < Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: 8,
                                                    width: '100%'
                                                }}
                                                align='baseline'
                                            >
                                                <Form.Item
                                                    wrapperCol={{ span: 24 }}
                                                    {...restField}
                                                    // label='Benefits'
                                                    name={[name, 'benefitName']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: language?.Missingfirstname,
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={language?.BenefitName} />
                                                </Form.Item>
                                                <Form.Item
                                                    wrapperCol={{ span: 24 }}

                                                    {...restField}
                                                    name={[name, 'benefitContent']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: language?.Missinglastname,
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={language?.BenefitContents} />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </Space>
                                        ))}

                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <Button className='req_exc_btn1' onClick={() => add()} block>
                                                {language?.EnterAdditionalBenefits}
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <Button
                                loading={loadingReg}
                                className='req_exc_btn'
                                type='primary'
                                htmlType='submit'>{language?.Register}</Button>
                        </Form>


                    </div>
                </div>
            </Modal>
        </>
    )
}

export default EditUsersTicket