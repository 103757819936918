import { Button, Form, Input, Modal, Popconfirm } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ButtonLogout } from '../../Components'
import { ADMIN } from '../../utils/apis'
import { errorMessage, successMessage } from '../../utils/helpers'
import EditFaq from './EditFaq'
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    }
}

const Faq = (props) => {
    const { user } = props
    const userSelectedLanguage = props?.language
    const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
    const lanKorCondition = SelectedLanguage === 'english'
    const [dataSource, setDataSource] = useState([])

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [editData, setEditData] = useState({});
    const [form] = Form.useForm();

    useEffect(() => {
        getAllFaqs()
    }, [])

    const getAllFaqs = () => {
        // setLoading(true)
        axios.get(`${ADMIN?.GET_ALL_FAQS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setDataSource(data?.data)
                }
                // setLoading(false)
            }).catch((e) => {
                // setLoading(false)
                console.log('e', e)
            })
    }

    const showModal = () => {
        setOpen(true);
    }

    const handleOk = () => {
        setConfirmLoading(true);
        setOpen(false);
        setConfirmLoading(false);
    }
    const handleCancel = () => {
        setOpen(false)
    }

    const onFinish = (values) => {
        setConfirmLoading(true)
        values.adminId = user?._id
        axios.post(ADMIN?.CREATE_FAQ, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data.message : data.messageKr)
                    getAllFaqs()
                    handleCancel()
                    form?.resetFields()
                } else {
                    errorMessage(lanKorCondition ? data.message : data.messageKr)
                }
                setConfirmLoading(false)
            })
            .catch((e) => {
                console.log('e====>', e)
                setConfirmLoading(false)
                errorMessage(userSelectedLanguage?.OppsError)
            })

    }

    const deleteQuestion = (_id) => {
        setDeleteLoading(true)
        let obj = {
            _id,
            adminId: user?._id
        }
        axios.post(ADMIN?.DELETE_FAQ, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setDataSource(data?.data)
                    setDeleteLoading(false)
                    getAllFaqs()
                }
                else {
                    setDeleteLoading(false)
                    return errorMessage()
                }
                // setLoading(false)
            }).catch((e) => {
                // setLoading(false)
                setDeleteLoading(false)
                console.log('e', e)
                return errorMessage()
            })
    }
    return (
        <>
            <ButtonLogout props={props} />
            <div className='settingDiv'>
                {/* Name Email ID etc */}
                <div className='nameEmailDiv'>
                    <div className='divHeader'>
                        <h3 className='headerText'>{lanKorCondition ? 'FAQ Management' : ' FAQ관리'}</h3>
                        <div className='borderBottom'></div>
                    </div>
                    <div className='divHeader'>
                        <h3 className='headerText'>{'Q&A'}</h3>
                    </div>
                    {dataSource?.map((v, i) => {
                        return (
                            <>
                                <div className='textFeildsDiv1'>
                                    <div className='textFeildDivBank1'>
                                        <h5>{lanKorCondition ? 'QUESTION' : ' 질문'}</h5>
                                        <Input disabled value={v?.question} className='inputFeild' /* style={{ width: '100%' }}  */ />
                                    </div>
                                </div>
                                <div className='textFeildsDiv1'>
                                    <div className='textFeildDivBank1'>
                                        <h5>{lanKorCondition ? 'ANSWER' : '답변'}</h5>
                                        <TextArea disabled value={v?.answer} className='inputFeild' autoSize />
                                        <br />
                                        <br />
                                        <Popconfirm
                                            title={userSelectedLanguage?.sureToDelete}
                                            onConfirm={() => deleteQuestion(v?._id)}
                                            okText={userSelectedLanguage?.Yes}
                                            cancelText={userSelectedLanguage?.No}
                                        >
                                            <Button
                                                loading={deleteLoading}
                                                className='btnTableDelete2'
                                                type='primary'>
                                                {userSelectedLanguage?.Delete}
                                            </Button>
                                        </Popconfirm>
                                        &nbsp;
                                        &nbsp;
                                        <Button
                                            style={{ height: '31px' }}
                                            onClick={() => setEditData(v)}
                                            /* loading={editLoading} */
                                            className='saveBtn'
                                            type='primary'>
                                            {userSelectedLanguage?.Edit}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                    <div className='saveButtonDiv'>
                        <Button onClick={showModal} className='saveBtn'>{lanKorCondition ? '+Add' : ' 추가'}</Button>
                    </div>
                </div>
                <Modal
                    title={userSelectedLanguage?.AddQuestion}
                    open={open}
                    onOk={handleOk}
                    // confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Form form={form} requiredMark={false} {...layout} name='questionAnswer' onFinish={onFinish}>
                        <Form.Item
                            name='question'
                            label={lanKorCondition ? 'Question' : '질문'}
                            rules={[
                                {
                                    required: true,
                                    message: lanKorCondition ? 'Question' : '질문'
                                },
                            ]}
                        >
                            <Input placeholder={lanKorCondition ? 'Question' : '질문'} />
                        </Form.Item>
                        <Form.Item
                            name={'answer'}
                            label={lanKorCondition ? 'Answer' : ' 답변'}
                            rules={[
                                {
                                    required: true,
                                    message: lanKorCondition ? 'Answer' : ' 답변'
                                }
                            ]}
                        >
                            <TextArea placeholder={lanKorCondition ? 'Answer' : ' 답변'} autoSize />
                        </Form.Item>
                        <Form.Item
                        >
                            <Button loading={confirmLoading} htmlType="submit" style={{ width: '100%' }} onClick={showModal} className='saveBtn2'>{lanKorCondition ? '+ Add' : '추가'}</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
            {editData?._id ?
                <EditFaq getAllFaqs={getAllFaqs} editData={editData} onCancel={() => setEditData({})} lanKorCondition={lanKorCondition}{...props} userSelectedLanguage={userSelectedLanguage} />
                : null
            }
        </>
    )
}

export default Faq
