import { Button } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { IoIosAddCircle } from 'react-icons/io'
import { ButtonLogout } from '../../Components'
import { ADMIN, POST } from '../../utils/apis'
import { errorMessage, successMessage } from '../../utils/helpers'
import NoData from '../../assets/images/noData.gif'
import moment from 'moment'
import { useSelector } from 'react-redux'
import RevisionHoldingModal from './RevisionHoldingModal'
import HoldingMembershipModal from './HoldingMembershipModal'
import { types, typesKr } from '../../utils/constants'
import AddHoldingMembership from './AddHoldingMembership'

const AdminMembership = (props) => {
  const { user, language } = props
  let selectedUser = props?.history?.location?.state
  let selectedUserName = props?.history?.location?.state?.fullName
  const userSelectedLanguage = props?.language
  const [memberTickets, setMemberTicket] = useState([])
  const [loading, setLoading] = useState(false)
  const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const lanKorCondition = SelectedLanguage === 'english'
  const [allData, setAllData] = useState({})
  const [editData, setEditData] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [showEditHolding, setshowEditHolding] = useState(false)
  console.log('user', user)
  useEffect(() => {
    getMemberTickets()
  }, [])

  const getMemberTickets = () => {
    setLoading(true)
    axios.get(`${ADMIN?.GET_ALL_USER_TICKET}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data?.success) {
          setMemberTicket(data?.data)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setLoading(false)
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }


  const deleteTicket = (v) => {
    setLoading(true)
    let obj = {
      userId: v?.userId,
      _id: v?._id
    }
    console.log('obj', obj)
    axios.post(POST.DELETE_TICKET, obj)
      .then((res) => {
        const { data } = res
        if (data.success) {
          successMessage(lanKorCondition ? data?.message : data?.messageKr)
          getMemberTickets()
          setLoading(false)

        }
        else {
          setLoading(false)
          console.log(lanKorCondition ? data?.message : data?.messageKr)
        }
      }).catch((err) => {
        console.log('err', err)
        setLoading(false)
      })

  }

  return (
    <>
      <ButtonLogout props={props} />
      <div className='membershipBox'>
        <h4 className='MainText'>{userSelectedLanguage?.MembershipandMembershipManagement}</h4>
        <p className='breadcum'> {'>'} {userSelectedLanguage?.MembershipandMembershipManagement}</p>
        <div className='borderBottomMembership'></div>
        <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', float: 'right', marginRight: 10 }} onClick={() => setAddModal(true)}>{lanKorCondition?'Add Membership':' 회원권 추가'}</Button>

        <h4 className='MainText'>{user?.userName || '----'}</h4>
        <div className='memberShipBoxDiv'>
          <h3 className='myMemberShipText'>{`(${user?.userName ? user?.userName?.slice(0, 8) : '----'}) 님 의 회원권 목록`}</h3>

          {editModal ?
            <RevisionHoldingModal getMemberTickets={getMemberTickets} language={userSelectedLanguage} allData={allData} setEditModal={setEditModal} editData={editData} editModal={editModal} {...props} />
            : null}

          {
            addModal &&
            < AddHoldingMembership showHoldingModal={addModal} setShowHoldingModal={setAddModal} getTickets={getMemberTickets} {...props} />
          }

          {showEditHolding ?
            <HoldingMembershipModal lanKorCondition={lanKorCondition} language={userSelectedLanguage} allData={allData} editData={editData} editModal={editModal} showEditHolding={showEditHolding} setshowEditHolding={setshowEditHolding} {...props} />
            : null
          }
          <div className='row'>
            {memberTickets?.length ?
              memberTickets?.map((v, i) => {
                return (
                  <div className='column'>

                    <div className='cards'>
                      <span style={{ cursor: 'pointer' }} onClick={() => {
                        setshowEditHolding(true)
                        setEditData(v?._id)
                        setAllData(v)
                      }}>
                        <div className='cardPenal'>
                          <h5 className='notBold'>{userSelectedLanguage?.GolfClubName}</h5>
                          <h5 className='bold'>{v?.golfClub?.clubName ? v?.golfClub?.clubName : '------'}</h5>
                        </div>
                        <div className='cardBorder'></div>
                        <div className='cardPenal'>
                          <h5 className='notBold'>{userSelectedLanguage?.Type}</h5>
                          <h5 className='bold'>
                            {v?.membershipType ? lanKorCondition ? ' ' + v?.membershipType : typesKr[types?.indexOf(v?.membershipType)]?.typesKr : '----'}
                          </h5>
                        </div>
                        <div className='cardBorder'></div>
                        <div className='cardPenal'>
                          <h5 className='notBold'>{userSelectedLanguage?.JoiningFee}</h5>
                          <h5 className='bold'>{v?.joiningFee ? v?.joiningFee : 0} {userSelectedLanguage?.KRW}</h5>
                        </div>
                        <div className='cardBorder'></div>
                        <div className='cardPenal'>
                          <h5 className='notBold'>{userSelectedLanguage?.Timeperiod}</h5>
                          <h5 className='bold'>{v?.joiningDate?.date1 ? moment(v?.joiningDate?.date1)?.format('YYYY-MM-DD') : '--'}{' / '}{v?.joiningDate?.date2 ? moment(v?.joiningDate?.date2)?.format('YYYY-MM-DD') : '--'}</h5>
                        </div>
                        <div className='cardBorder'></div>
                        <div className='cardPenal'>
                          <div className='icontextDivCard'>
                            <IoIosAddCircle size={18} className='plus_icon' />
                            <h5 className='notBoldWithIcon'>{userSelectedLanguage?.SpecialGiftTIKSALE}</h5>
                          </div>
                          {/* <h5 className='bold'>{v?.rightPrice ? v?.rightPrice : 0}{' ' + userSelectedLanguage?.MillonWon}</h5> */}
                        </div>
                        <div>
                          <div className='cardBorder'></div>
                          <div style={{ textAlign: 'start', marginLeft: '4px' }}>
                            {lanKorCondition ?
                              <h5 className='not_bold'>8 times a week({v && v?.specialGiftWeekDay}times)/{v && v?.specialGiftWeekDayKRW} won</h5>
                              : <h5 className='not_bold'>주중 8 회 ({v && v?.specialGiftWeekDay} 회)/  {v && v?.specialGiftWeekDayKRW}만원</h5>
                            }
                          </div>
                          <div style={{ textAlign: 'start', marginLeft: '4px' }}>
                            {lanKorCondition ?
                              <h5 className='not_bold'>Weekend 4times({v && v?.specialGiftWeekEnd}times)/{v && v?.specialGiftWeekEndKRW}won</h5>
                              :
                              <h5 className='not_bold'>주말 4 회 ({v && v?.specialGiftWeekEnd} 회)/ {v && v?.specialGiftWeekEndKRW} 만원</h5>
                            }
                          </div>
                        </div>
                      </span>
                      <div>
                        <Button
                          onClick={() => {
                            setAllData(v)
                            setEditData(v && v?._id)
                            setEditModal(true)
                          }}
                          className='cardEditButton'
                          type='primary'>
                          {userSelectedLanguage?.Edit}
                        </Button>
                        <Button loading={loading} onClick={() => deleteTicket(v)} className='cardDeleteButton' type='primary'> {userSelectedLanguage?.Delete}</Button>
                      </div>
                    </div>
                  </div>
                )
              }) :
              <div className='noDataDiv'>
                <div className='noDataCard'>
                  <img src={NoData} className='noCardImg' />
                  <h3>{userSelectedLanguage?.NoTicketsCreated}</h3>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminMembership
