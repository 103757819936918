import { Button, Form, Input, Modal, Select,Switch } from 'antd'
import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { ADMIN } from '../../utils/apis';
import { errorMessage, successNotification } from '../../utils/helpers';

const formLayout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const { Option } = Select
const EditEvents = (props) => {
    const { user, editData, onCancel, language, lanKorCondition } = props
    const [isActive, setIsActive] = useState(editData?.isCompleted || false)


    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const handleCancel = () => {
        onCancel()
        form.resetFields()
    }

    const editEvent = (values) => {
        setLoading(true)
        values._id = editData?._id,
            values.adminId = user?._id
        values.isCompleted = isActive

        axios.post(ADMIN?.EDIT_EVENT, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(lanKorCondition ? data.message : data.messageKr)
                    handleCancel()
                } else {
                    errorMessage(lanKorCondition ? data.message : data.messageKr)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                setLoading(false)
                errorMessage(language?.OppsError)
            })
    }

    return (
        <Modal footer={null} title={language?.EditEvents} open={editData?._id} onCancel={handleCancel}>
            <Form
                form={form}
                {...formLayout}
                onFinish={editEvent}
                requiredMark={false}
            >
                <Form.Item
                    label={language?.Title}
                    rules={[
                        {
                            required: true,
                            message: language?.PleaseEnterTitle,
                        }
                    ]}
                    name='title'
                    initialValue={editData?._id ? editData?.title : null}
                >
                    <Input placeholder={language?.Title} />
                </Form.Item>
                <Form.Item
                    label={language?.Content}
                    name='content'
                    rules={[
                        {
                            required: true,
                            message: language?.PleaseEnterContent,
                        }
                    ]}
                    initialValue={editData?._id ? editData?.content : null}
                >
                    <Input.TextArea rows={4} placeholder={language?.Content} />
                </Form.Item>
                <Form.Item
                    label={language?.activeBanner}
                    name='active'>
                    <Switch onChange={(e) => setIsActive(e)} checkedChildren={lanKorCondition ? 'activated ' : '활성'} unCheckedChildren={lanKorCondition ? 'unactivated ' : '비활성 '} defaultChecked={isActive} />
                </Form.Item>
                <Button className='req_exc_btn1' htmlType='submit' loading={loading} type='primary'>{language?.Update}</Button>
            </Form>
        </Modal>
    )
}
export default EditEvents
