import { Button, Form, Input, Modal, Popconfirm, Upload } from 'antd'
import { FileOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ButtonLogout } from '../../Components'
import { ADMIN } from '../../utils/apis'
import { errorMessage, requiredMessage, successMessage } from '../../utils/helpers'


const CompanyIntro = (props) => {
    const [form] = Form.useForm()
    const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
    const lanKorCondition = SelectedLanguage === 'english'
    const [data, setData] = useState({})
    const [edit, setEdit] = useState({})
    const [loading, setLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [addLoading, setAddLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [fileList, setFileList] = useState([]);


    const getData = () => {
        setLoading(true)
        axios.get(ADMIN.GET_COMPANY_INFO)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setData(data?.data)
                    setLoading(false)
                }
                else {
                    setLoading(false)
                    console.log('runing')
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
            })
    }

    const getDatas = () => {
        axios.get(ADMIN.GET_COMPANY_INFO)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setEdit(data?.data)
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
            })
    }


    useEffect(() => {
        getData()
    }, [])

    const onFinish = (values) => {

        values.fileList = fileList
        values.id = edit?._id && edit?._id
        if (!edit?._id) {
            if (!values?.fileList?.length || !values?.content) {
                return errorMessage(lanKorCondition ? 'Please provide All fields!0' : '모든 항목을 작성해주세요!')
            }
        }

        setAddLoading(true)

        let formData = new FormData()
        for (let file of values?.fileList) {
            formData.append('file', file?.originFileObj)
        }
        formData.append('content', values?.content)

        edit?._id && formData.append('_id', edit?._id)

        axios?.post(edit?._id ? ADMIN?.EDIT_INFO : ADMIN?.ADDED_INTO, formData)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setAddLoading(false)
                    successMessage(data?.messageKr)
                    getData()
                    setEdit({})
                    setFileList([])
                }
                else {
                    setAddLoading(false)
                    console.log('runing')
                }
                setOpen(false)
            })
            .catch((e) => {
                setOpen(false)
                setAddLoading(false)
                console.log('err', e)
            })
    }

    const deleteIntro = (id) => {
        let obj = {
            _id: id
        }

        setDeleteLoading(true)
        axios?.post(ADMIN?.DELETE_INTRO, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setDeleteLoading(false)
                    setData({})
                    setEdit({})
                    successMessage(data?.messageKr)
                }
                else {
                    setDeleteLoading(false)
                    console.log('runing')
                }
                getData()
            })
            .catch((e) => {
                setDeleteLoading(false)
                console.log('err', e)
            })
    }

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }

    const deleteImage = (imageId) => {
        let obj = {
            _id: edit?._id,
            imageId
        }
        axios?.post(ADMIN?.REMOVE_INTRO_IMAGE, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setDeleteLoading(false)
                    getDatas()
                    successMessage(data?.messageKr)
                }
                else {
                    setDeleteLoading(false)
                }
                getData()
            })
            .catch((e) => {
                setDeleteLoading(false)
                console.log('err', e)
            })
    }
    return (
        <>
            <ButtonLogout props={props} />
            <div className='settingDiv'>
                {/* Name Email ID etc */}
                <div className='nameEmailDiv'>
                    <div className='divHeader'>
                        <h3 className='headerText'>
                            {edit?._id ?
                                lanKorCondition ? 'Edit Company Introduction ' : '회사 프로필 편집' :
                                lanKorCondition ? 'Add Compnay Introduction' : '회사 소개 추가'
                            }
                        </h3>
                        <div className='borderBottom'></div>
                    </div>
                    {
                        !data?._id ?
                            <div style={{ margin: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <div className='saveButtonDiv'>
                                    <Button style={{ width: '130px' }} onClick={() => setOpen(true)} className=' width-200'>{lanKorCondition ? 'Add Introduction' : '소개 추가'}</Button>
                                </div>
                            </div> :
                            <div style={{ margin: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <div className='saveButtonDiv'>
                                    <Button style={{ width: '100px' }}
                                        onClick={
                                            () => {
                                                setOpen(true)
                                                setEdit(data)
                                                setFileList([])
                                            }
                                        }
                                        className=' width-200'>{lanKorCondition ? '정보 변경' : '정보 변경'}</Button>

                                    <Button style={{ width: '100px', marginLeft: '10px' }} loading={deleteLoading} onClick={() => deleteIntro(data?._id)} className=' width-200'>{lanKorCondition ? 'Delete' : ' 삭제'}</Button>
                                </div>
                            </div>
                    }
                    <div style={{ margin: '20px' }}>
                        <b>설명</b>:  {data?.content || '---'}
                    </div>
                    <div style={{ margin: '20px' }}>
                        {data?._id ?
                            data?.imageUrls?.map((vv, ii) => {
                                return (
                                    <div key={ii} style={{ height: '200px', width: '300px', marginBottom: 10 }}>
                                        <img src={vv?.url} style={{ height: '200px', width: '300px', minWidth: '100%' }} />
                                    </div>
                                )
                            })
                            : null
                        }

                    </div>
                </div>
            </div >
            <Modal
                title={edit._id ?
                    lanKorCondition ? 'Edit Company Introduction ' : '회사 프로필 편집' :
                    lanKorCondition ? 'Add Compnay Introduction' : '회사소개 추가'}
                open={open}
                onCancel={() => { setOpen(false); setEdit({}); setFileList([]) }}
                footer={null}
            >
                <Form
                    name='form'
                    onFinish={onFinish}
                    form={form}
                    layout={'vertical'}
                    requiredMark={true}
                >
                    <Form.Item
                        name='content'
                        initialValue={edit?.content}
                        label={lanKorCondition ? 'Content' : '설명'}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: lanKorCondition ? 'Input Company Content' : '회사 설명 추가'
                            }
                        ]}
                    >
                        <Input
                            placeholder={lanKorCondition ? 'Input Company Content' : '회사 설명 추가'}
                        />
                    </Form.Item>

                    <Form.Item
                        name='otherFiles'
                        label={lanKorCondition ? 'File' : '골프장 이미지'}
                    >
                        <Upload
                            name='otherFiles'
                            multiple={true}
                            beforeUpload={() => false}
                            maxCount={10}
                            accept='image/png, image/jpeg'
                            onChange={onChange}
                            showUploadList={true}
                        >
                            <Button icon={<UploadOutlined />}>{lanKorCondition ? 'Click To Upload File' : '파일 업로드'}</Button>
                        </Upload>
                        {edit?._id ?
                            edit?.imageUrls?.map((v, i) => (
                                <div key={i} style={{ justifyContent: 'space-between', display: 'flex' }}>
                                    <span><FileOutlined />&nbsp;{v?.name}</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <p style={{ cursor: 'pointer' }} onClick={() => deleteImage(v?._id)}> x</p>
                                    <br />
                                </div>
                            ))
                            : null}
                    </Form.Item>
                    <Form.Item>
                        <Button
                            className='saveBtn inc-width'
                            block
                            htmlType='submit'
                            loading={addLoading}
                        >
                            {edit?._id ?
                                lanKorCondition ? 'Edit' : '업데이트 ' :
                                lanKorCondition ? 'Add' : '더하다'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

        </>
    )
}

export default CompanyIntro
