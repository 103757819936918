import { PlusCircleFilled } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Modal, Radio } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { ADMIN } from '../../utils/apis'
import { errorMessage, successMessage } from '../../utils/helpers'
import englishLanguage from '../../utils/englishLanguage.json'
import moment from 'moment-timezone'

// const formItemLayout = {
//     labelCol: {
//         xs: {
//             span: 24,
//         },
//         sm: {
//             span: 12,
//         },
//         lg: {
//             span: 11,
//         },
//     },
//     wrapperCol: {
//         xs: {
//             span: 24,
//         },
//         sm: {
//             span: 12,
//         },
//         lg: {
//             span: 13,
//         },
//     },
// }

const SaleEdit = (props) => {
    const { user, language, salesAuction, lanKorCondition, onCancel, editSaleData } = props
    const [loading, setLoading] = useState(false)
    const [sellingPrice, setSellingPrice] = useState(editSaleData?.price)
    const [advanceFee, setadvanceFee] = useState(editSaleData?.advanceFee)


    const handleCancel = () => {
        onCancel()
    }
    console.log(editSaleData)

    const onFinish = (fieldsValue) => {
        setLoading(true)
        let obj = {
            adminId: user?._id,
            _id: editSaleData?._id,
            price: fieldsValue?.price,
            advanceFee: fieldsValue?.advanceFee,
            category: fieldsValue?.category === 'both' ? [englishLanguage[0]?.NormalSell, englishLanguage[0].GroupBuySell] : fieldsValue?.category === 'normal' ? [englishLanguage[0]?.NormalSell] : [englishLanguage[0]?.GroupBuySell],
        }
        console.log(obj)


        axios.post(ADMIN.EDIT_SALE_AUCTION, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    salesAuction()
                    onCancel()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                console.log('e====>', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : ' 문제가 발생했습니다!')
            })

    }

    return (
        <>
            <center>
                <Modal
                    open={editSaleData?._id}
                    onCancel={handleCancel}
                    className='responsiveModal'
                    footer={null}
                    style={{ top: '10px', }}
                >
                    <div
                        className='scroll_class'
                        style={{
                            height: 'auto',
                            width: '100%',
                            maxHeight: '85vh',
                            overflowY: 'scroll',
                            marginTop: '12px'
                        }}>
                        <div className='modal_main_div' >
                            <center>
                                <h2>{lanKorCondition ? 'Edit ticket Sell' : '티켓 판매 편집'}</h2>
                                {/* <p style={{ color: '#1890ff' }}> Please enter the membership information you wish to sell</p> */}
                            </center>
                            <div className='editModal'>
                                <Form requiredMark={false} name='time_related_controls' onFinish={onFinish}>

                                    <Form.Item
                                        name='gender'
                                        label='Membership'
                                    >
                                        <Input disabled defaultValue={editSaleData?.ticketId?.membershipCardName} ></Input>
                                    </Form.Item>


                                    <div className='disabled_text scroll_class'>
                                        <table>

                                            <tr>
                                                <td>{language?.GolfClubName}</td>
                                                <td>{editSaleData?.ticketId && editSaleData?.ticketId?.golfClub?.clubName || '-----------------'}</td>
                                            </tr>
                                            <br />
                                            <tr>
                                                <td>{language?.Addres}</td>
                                                <td>{editSaleData?.ticketId && editSaleData?.ticketId?.golfClub?.clubAddress || '-----------------'}</td>
                                            </tr>
                                            <br />

                                            <tr>
                                                <td>{language?.Type}</td>
                                                <td>{editSaleData?.ticketId && editSaleData?.ticketId?.membershipType || '-----------------'}</td>
                                            </tr>
                                            <br />

                                            <tr>
                                                <td>{language?.JoiningFee} </td>
                                                <td>{editSaleData?.ticketId && editSaleData?.ticketId?.joiningFee || '-----------------'}</td>
                                            </tr>
                                            <br />

                                            <tr>
                                                <td>{language?.JoiningDate}</td>
                                                <td>{editSaleData?.ticketId ? `${moment(editSaleData?.ticketId?.joiningDate?.date1).format('YYYY-MM-DD')} / ${moment(editSaleData?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD')}` : '-----------------'}</td>
                                            </tr>
                                            <br />
                                        </table>
                                    </div>
                                    <br />

                                    <Form.Item
                                        name={language?.category}
                                        rules={[
                                            {
                                                required: true,
                                                message: language?.category,
                                            }
                                        ]}
                                        initialValue={(editSaleData?.category.length === 1 && editSaleData?.category
                                            ?.includes('Normal Sell')) ? 'normal' : (editSaleData?.category
                                                .length === 1 && editSaleData?.category
                                                    ?.includes('Group Buy Sell')) ? 'group' : 'both'}

                                    >
                                        <Radio.Group buttonStyle='solid'>
                                            <Radio.Button value={'both'} style={{ marginRight: 10, borderRadius: 8 }}>{lanKorCondition ? 'Both' : '일반 판매와 공동구매로 판매'}</Radio.Button>
                                            <Radio.Button value={'normal'} style={{ marginRight: 10, borderRadius: 8 }}>{lanKorCondition ? 'Normal Sell' : '일반 판매'}</Radio.Button>
                                            <Radio.Button value={'group'} style={{ marginRight: 10, borderRadius: 8 }}>{lanKorCondition ? 'Group Buy Sell' : '그룹 구매 판매'}</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        name='price'
                                        label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />
                                            {language?.WantedSellingTicket}
                                        </h4>}
                                        rules={[
                                            {
                                                required: true,
                                                message: language?.PleaseInputSellingPrice,
                                            },
                                        ]}
                                        initialValue={editSaleData?.price}
                                    >
                                        <div>
                                            <Input
                                                suffix={language?.KRW}
                                                // placeholder='Joining Fee'
                                                maxLength={10}
                                                value={sellingPrice}
                                                onChange={(e) => setSellingPrice(e.target.value?.replace(/[^0-9]/g, ''))}
                                            />
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        name='advanceFee'
                                        initialValue={editSaleData?.advanceFee}
                                        label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.advanceFee}</h4>}
                                        rules={[
                                            {
                                                required: true,
                                                message: language?.advanceFeeAmountHere,
                                            },
                                        ]}
                                    >
                                        <div>
                                            <Input
                                                suffix={language?.KRW}
                                                placeholder={language?.advanceFeeAmountHere}
                                                value={advanceFee}
                                                onChange={(e) => setadvanceFee(e.target.value?.replace(/[^0-9]/g, ''))}

                                            />
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        name='TermAndConditions'
                                        initialValue={true}
                                        label={language?.TermCondition}
                                        valuePropName='checked'
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                validator: (_, value) =>
                                                    value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                            }
                                        ]}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                    <div className='agreement scroll_class' style={{ marginBottom: 20 }}>
                                        <p>{language?.TAC1}</p>
                                        <p>{language?.TAC2}</p>
                                        <p>{language?.TAC3}</p>
                                        <p>{language?.TAC4}</p>
                                        <p>{language?.TAC5}</p>
                                        <p>{language?.TAC6}</p>
                                        <p>{language?.TAC7}</p>
                                    </div>


                                    <Button
                                        loading={loading}
                                        className='req_exc_btn'
                                        type='primary'
                                        htmlType='submit'>{language?.URequestSell}</Button>

                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal>
            </center>
        </>
    )
}
export default SaleEdit