import { PlusCircleFilled } from '@ant-design/icons';
import { Button, DatePicker, Divider, Form, Input, Modal, Select } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { ADMIN, POST } from '../../utils/apis';
import { regions, regionsKr } from '../../utils/constants';
import { errorMessage, successMessage } from '../../utils/helpers';
const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 8,
        },
        lg: {
            span: 8,
        }
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 16,
        },
        lg: {
            span: 16,
        },
    },
}

const RentalEdit = (props) => {
    const { user, language, getShareRentalAuction, lanKorCondition, editRentalData, onCancel, shareRentalModal } = props
    const options = [
        {
            eng: '-From low to high (based on current price)',
            kr: ' 저가순 '
        },
        {
            eng: '-From high to low (based on current price)',
            kr: '고가순'
        },
        {
            eng: 'Latest (based on current price)',
            kr: '최신순'
        },
        {
            eng: 'Order of number of Transactions',
            kr: '거래건이 많은 순'
        }]
    const [loading, setLoading] = useState(false);
    const [memberFee, setMemberFee] = useState(editRentalData?.memberFee)
    const [nonMemberFee, setNonMemberFee] = useState(editRentalData?.nonMemberFee)
    const [rightPrice, setRightPrice] = useState(editRentalData?.rightPrice)
    const [expiry, setExpiry] = useState(editRentalData?.expirationDate)
    const [form] = Form.useForm();

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: language?.Pleaseselectdate,
            },
        ],
    }

    const handleCancel = () => {
        onCancel()
    }


    const onFinish = (fieldsValue) => {
        setLoading(true)
        const values = {
            ...fieldsValue,
            'expirationDate': moment(expiry)?.format('YYYY-MM-DD'),
            'usableDate': fieldsValue['usableDate']?.format('YYYY-MM-DD'),
            'locationPreference': fieldsValue['locationPreference']
        }
        console.log('values', values)
        // return true
        values.ticketId = editRentalData?.ticketId?._id
        values._id = editRentalData?._id
        values.memberFee = (+memberFee)
        values.nonMemberFee = (+nonMemberFee)
        values.rightPrice = (+rightPrice)
        values.termsAndCondition = 'termsAndCondition'
        values.auctionType = 'share rental auction'
        delete values.Fee
        console.log('v', values)
        axios.post(ADMIN.EDI_SHARE_RENTAL_AUCTION, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    getShareRentalAuction()
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    onCancel()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('ee====>', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : ' 문제가 발생했습니다!')
            })
    }

    const setExpiryDate = (e) => {
        let date = moment(e?._d).add(-1, 'days').format('YYYY-MM-DD HH:MM')
        // console.log('date', date)
        form.setFieldValue('usableDate', moment(date, 'YYYY-MM-DD HH:MM'))
        const weekday = moment(date).format('dddd')
        const isWeekend = weekday === 'Sunday' || weekday === 'Saturday' || weekday === '토' || weekday === '일' ? true : false
        // console.log('*****', isWeekend, weekday)
        let setExpDate
        if (!isWeekend) {
            setExpDate = moment(date).subtract(3, 'days').format('YYYY-MM-DD HH:MM')
            form.setFieldValue('expirationDate', moment(setExpDate, 'YYYY-MM-DD HH:MM'))
            setExpiry(setExpDate)
        } else {
            setExpDate = moment(date).subtract(7, 'days').format('YYYY-MM-DD HH:MM')
            form.setFieldValue('expirationDate', moment(setExpDate, 'YYYY-MM-DD HH:MM'))
            setExpiry(setExpDate)
        }
    }
    return (
        <>
            <center >
                <Modal
                    open={editRentalData?._id || shareRentalModal}
                    // open={true}
                    onCancel={handleCancel}
                    className='responsiveModal'
                    footer={null}
                >
                    <div className='modal_main_div' >
                        <center><h2>{language?.EditShareRentalAuction}</h2></center>
                        <br />
                        <div className='editModal'>
                            <Form form={form} requiredMark={false} name='time_related_controls'  {...formItemLayout} onFinish={onFinish}>

                                <Form.Item
                                    name='membership'
                                    label={language?.Membership}
                                >
                                    <Input disabled defaultValue={editRentalData?.ticketId?.membershipCardName} ></Input>
                                </Form.Item>

                                <Form.Item
                                    name='Fee'
                                    label={language?.Fee}
                                    rules={[
                                        {
                                            required: true,
                                            validator: async (_, names) => {
                                                if (!memberFee) {
                                                    return Promise.reject(new Error(language?.PleaseEnterMemberFee))
                                                }
                                                if (!nonMemberFee) {
                                                    return Promise.reject(new Error(language?.PleaseEnterNon_MemberFee))
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '47%', textAlign: 'center' }}>
                                            <label style={{ marginRight: '4%' }} htmlFor=''>{language?.Member}</label>
                                            {/* <label style={{ marginRight: '4%' }} htmlFor=''>{Member}</label> */}
                                            <Input
                                                suffix={language?.KRW}
                                                style={{
                                                    width: 'auto',
                                                    // marginLeft: '4%',
                                                }}
                                                maxLength={7}
                                                value={memberFee}
                                                onChange={(e) => setMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                            />
                                        </div>

                                        <div style={{ width: '50%', marginLeft: 'auto', textAlign: 'center' }}>
                                            <label htmlFor='' style={{ marginRight: '4%' }}>{language?.NonMember}</label>
                                            {/* <label style={{ marginRight: '4%' }} htmlFor=''>Non Member</label> */}

                                            <Input
                                                suffix={language?.KRW}
                                                style={{
                                                    width: 'auto',
                                                    // marginLeft: '4%',

                                                }}
                                                maxLength={7}
                                                value={nonMemberFee}
                                                onChange={(e) => setNonMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                            />
                                        </div>
                                    </div>
                                </Form.Item>


                                <div className='disabled_text scroll_class'>
                                    <table>
                                        <tr>
                                            <td>{language?.GolfClubName}</td>
                                            <td>{editRentalData?.ticketId?.golfClub?.clubName || '-----------------'}</td>
                                        </tr>
                                        <br />
                                        <tr>
                                            <td>{language?.address}</td>
                                            <td>{editRentalData?.ticketId?.golfClub?.clubAddress || '-----------------'}</td>
                                        </tr>
                                        <br />
                                        <tr>
                                            <td>{language?.Type}</td>
                                            <td>{editRentalData?.ticketId?.membershipType || '-----------------'}</td>
                                        </tr>
                                    </table>
                                </div>

                                <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />


                                <Form.Item
                                    name='rightPrice'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.RightPrice}</h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.PleaseInputRightPrice,
                                        },
                                    ]}
                                    initialValue={rightPrice}
                                >
                                    <div>
                                        <Input
                                            suffix={language?.KRW}
                                            maxLength={7}
                                            value={rightPrice}
                                            onChange={(e) => setRightPrice(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name='usableDate'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />

                                        {language?.Usabledate}
                                    </h4>}
                                    {...config}
                                    initialValue={moment(editRentalData?.usableDate, 'YYYY-MM-DD')}
                                >
                                    <DatePicker onChange={setExpiryDate} placeholder={language?.Selectdate} style={{ width: '100%' }} />
                                </Form.Item>

                                {/* <Form.Item
                                    name='expirationDate'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />{language?.ExpirationDate}</h4>}
                                    {...config}
                                    initialValue={moment(editRentalData?.expirationDate, 'YYYY-MM-DD')}
                                >
                                    <DatePicker style={{ width: '100%' }} />
                                </Form.Item> */}
                                <Form.Item
                                    name='expirationDate'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.ExpirationDate}</h4>}
                                    {...config}
                                    initialValue={expiry}
                                >
                                    <div>
                                        <Input value={moment(expiry)?.format('YYYY-MM-DD')} disabled placeholder={language?.Selectdate} style={{ width: '100%' }} />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name='locationPreference'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />
                                        {language?.LocationPreference}
                                    </h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.PleaseselectLocation,
                                        },
                                    ]}
                                    initialValue={editRentalData?.locationPreference}
                                >
                                    <Select mode='multiple' placeholder={language?.selectLocation}>
                                        {/* {regions?.slice(1)?.map((v, i) => (<Option value={v}>
                                            {v}
                                        </Option>))} */}
                                        {lanKorCondition ?
                                            regions?.slice(1)?.map((v, i) => (<Option value={v}>{v}</Option>))
                                            : regionsKr?.slice(1)?.map((v, i) => (<Option value={v?.TitleEn}>{v?.TitleKr}</Option>))
                                        }
                                    </Select>
                                </Form.Item>

                                <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit'>
                                    {language?.Update}
                                </Button>
                                {/* <Button style={{ width: '100%', marginTop: 10 }} onClick={handleCancel}>{language?.Cancel}</Button> */}
                            </Form>
                        </div>

                    </div>
                </Modal>
            </center>
        </>
    )
}

export default RentalEdit