import React, { useEffect, useState } from 'react'
import { Button, Table, Tooltip } from 'antd'
import { ButtonLogout } from '../../Components'
import moment from 'moment-timezone'
import { convertTitle, errorMessage, stringLimiter, successNotification, infoMessage } from '../../utils/helpers'
import { BsCheckCircleFill } from 'react-icons/bs'
import { FiSearch } from 'react-icons/fi'
import { allPaths, regions, regionsKr, types, typesKr } from '../../utils/constants'
import axios from 'axios'
import { ADMIN } from '../../utils/apis'
import { useDispatch, useSelector } from 'react-redux'
import { setAuction } from '../../Redux/actions/genralAction'

const GroupBuyDetail = (props) => {
    const { user } = props
    const history = props?.history
    const dispatch = useDispatch()
    const userSelectedLanguage = props?.language
    let Data = props?.history?.location?.state[0]
    const language = useSelector(state => state?.languageReducer?.language)
    const auction = useSelector(state => state?.setAuction?.val)
    const lanKorCondition = language === 'english'
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [cancelLoader, setCancelLoader] = useState(false)
    const [partcipateRequests, setPartcipateRequests] = useState([])
    let cond = Data?.groupBuyParticipateId?.length ? Data?.groupBuyParticipateId : partcipateRequests
    console.log('Data', Data, 'cond', cond)
    let bidsData = cond?.map((v, i) => {
        console.log('v', v)
        return {
            // ticketId:groupBuyId?.ticketId,
            _id: v?._id,
            NameIDphoneOfUploader: [v?.buyerId?.fullName, v?.buyerId?.email, v?.buyerId?.telNo],
            totalSeats: v?.totalSeats,
            totalPrice: v?.totalPrice,
            Status: v,
            Payment: { ...v, ...{ groupBuy: Data } },
            ViewDetail: v,
        }
    })
    useEffect(() => {
        getRequests()
    }, [Data?.groupBuyId?._id])

    const getRequests = () => {
        console.log('push', Data)
        axios.get(`${ADMIN.GROUP_BUY_PARTICIPATE_REQUEST}/${Data?._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setPartcipateRequests(data?.uploadGroupBuyReq)
                }
            })
    }

    const Approve = (id, groupBuyId) => {
        let obj = {
            _id: id, adminId: user?._id, groupBuyId: groupBuyId
        }
        console.log('OBJ', obj)
        setLoading(true)
        axios.post(ADMIN?.APPROVE_PARTICIPATE_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(lanKorCondition ? data.message : data.messageKr)
                    history.push(allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_GROUP_BUY_PURCHASE_REQUEST)
                } else {
                    errorMessage(data.message)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('e====>', e)
                errorMessage(userSelectedLanguage?.OppsError)
            })
    }
    const cancelGroupReq = (id, groupBuyId) => {
        let obj = {
            _id: id, adminId: user?._id, groupBuyId: groupBuyId
        }
        console.log('OBJ', obj)
        setCancelLoader(true)
        axios.post(ADMIN?.CANCEL_PARTICIPATE_REQUEST, obj)
            .then((res) => {
                const { data } = res
                setCancelLoader(false)
                if (data.success) {
                    successNotification(lanKorCondition ? data.message : data.messageKr)
                    history.push(allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_GROUP_BUY_PURCHASE_REQUEST)
                } else {
                    errorMessage(data.message)
                }
            })
            .catch((e) => {
                setCancelLoader(false)
                console.log('e====>', e)
                errorMessage(userSelectedLanguage?.OppsError)
            })
    }

    const DeleteGroupBuyReq = (id, requestType) => {
        setDeleteLoader(true)
        let obj = {
            id: id,
            requestType: requestType
        }
        axios.post(ADMIN?.DELETE_GROUP_BUY_REQ, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoader(false)
                if (data.success) {
                    successNotification(lanKorCondition ? data.message : data.messageKr)
                    history.push(allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_GROUP_BUY_PURCHASE_REQUEST)
                } else {
                    errorMessage(lanKorCondition ? data.message : data.messageKr)
                    setDeleteLoader(false)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                setDeleteLoader(false)
                errorMessage(userSelectedLanguage?.OppsError)
            })
    }

    const getToPayment = (d) => {
        console.log('d', d)
        d?.buyerId?._id ?
            history.push({ pathname: allPaths?.TICKETSVIEWINFO, state: [d, { title: 'Group buy Participate' }] }) :
            history.push({ pathname: allPaths?.TICKETSVIEWINFO, state: [d, { title: 'Group buy' }] })
    }
    const columns = [
        {
            title: userSelectedLanguage?.TransactionNumber,
            dataIndex: '_id',
            key: 'Transaction number',
            render: (text) => <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
                <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 12)}</Button>
            </Tooltip>
        },
        {
            title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
            dataIndex: 'NameIDphoneOfUploader',
            key: 'Name and Phone Number',
            render: (text) => (
                <div className='namePhoneAndIDiv'>
                    {text?.map((v, i) => {
                        return (
                            <Tooltip placement='top' title={convertTitle(v || '----')} color={'#5EA29F'}>
                                <p className='namePhoneAndID'>{stringLimiter(convertTitle(v || '----'), 12)}</p>
                            </Tooltip>
                        )
                    })}
                </div>
            ),
        },
        {
            title: lanKorCondition ? 'Requesting Seats' : '요청 구좌수',
            dataIndex: 'totalSeats',
            key: 'totalSeats',
            render: (text) => <p className='tableColoumWidth'>{text || '----'}</p>,
        },
        {
            title: lanKorCondition ? 'Total amount' : '총 금액',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            // render: (text) => <>
            //     <div className='tableColoumWidth'>
            //         <div className='searchIconDiv' style={{ cursor: 'pointer' }}>
            //             <FiSearch
            //                 onClick={() => {
            //                     !text?.isAccepted ?
            //                         infoMessage(lanKorCondition ? 'Auction Not Accepted Yet! ' : '아직 승인을 하지 않았습니다')
            //                         : history.push({ pathname: allPaths?.TICKETSINFO, state: [text, Data, { Bank: 'Show', title: Data?.auctionType }] })
            //                 }} />
            //         </div>
            //     </div>
            //     {/* <div style={{ height: 30, width: 30, backgroundColor: 'white', borderRadius: 8, boxShadow: ' 2px 2px #888888', textAlign: 'center', paddingTop: '5px' }}>
            //         <BsCheckCircleFill />
            //     </div> */}
            // </>
            render: (text) => <p className='tableColoumWidth'>{text || '----'}</p>,
        },
        {
            title: lanKorCondition ? 'Status' : '상태',
            dataIndex: 'Status',
            key: 'Status',

            render: (text) => (
                <>
                    <div style={{ width: '220px' }}>
                        {text?.isCanceled ?
                            <div style={{ height: 30, width: 30, backgroundColor: 'white', borderRadius: 8, boxShadow: ' 2px 2px #888888', textAlign: 'center', paddingTop: '5px' }}>
                                <Button className='cancelAuctionTableBtn'>{lanKorCondition ? 'Canceled Request' : '매매취소'}</Button> :
                            </div> :
                            !text?.isCompleted ?
                                <div style={{ height: 30, width: 30, backgroundColor: 'white', borderRadius: 8, boxShadow: ' 2px 2px #888888', textAlign: 'center', paddingTop: '5px' }}>
                                    <Button className='soldTableBtn'>{lanKorCondition ? 'Waiting for participate' : '참여 요청중'}</Button> :
                                </div>
                                :
                                text?.isCompleted && text?.paymentId ?
                                    <div style={{ height: 30, width: 30, backgroundColor: 'white', borderRadius: 8, boxShadow: ' 2px 2px #888888', textAlign: 'center', paddingTop: '5px' }}>
                                        <Button className='cancelAuctionTableBtn'>{lanKorCondition ? 'Paid' : '지불 완료'}</Button> :
                                    </div>
                                    :
                                    text?.isCompleted ?
                                        <div style={{ height: 30, width: 30, backgroundColor: 'white', borderRadius: 8, boxShadow: ' 2px 2px #888888', textAlign: 'center', paddingTop: '5px' }}>
                                            <Button className='cancelAuctionTableBtn'>{lanKorCondition ? 'Participation confirmed' : '참여 승인'}</Button> :
                                        </div> : null
                        }
                    </div>
                </>
            ),
        },
        {
            title: userSelectedLanguage?.Action,
            dataIndex: 'Payment',
            key: 'Action',
            render: (text) => (
                console.log('text---->', text),
                <div className='tableBtnsDiv'>
                    <>
                        {!text?.isCompleted ?

                            <>
                                <Button loading={loading} onClick={() => { Approve(text?._id, text?.groupBuyId?._id || text?.groupBuyId) }} className='postApproval'>{lanKorCondition ? 'Confirm' : '승인'}</Button>
                                <Button loading={deleteLoader} onClick={() => { DeleteGroupBuyReq(text?._id, 'Participate') }} className='DeleteBtn'> {userSelectedLanguage?.Delete} </Button>
                                <Button disabled className='editBtn' type="primary" onClick={() => getToPayment(text)}>{userSelectedLanguage?.Edit}</Button>

                            </>
                            :
                            <>
                                <Button disabled className='postApproval'>{lanKorCondition ? 'Confirm' : '낙찰'}</Button>
                                {/* <Button disabled className='DeleteBtn'> {userSelectedLanguage?.Delete} </Button> */}
                                <Button loading={deleteLoader} onClick={() => { DeleteGroupBuyReq(text?._id, 'Participate') }} className='DeleteBtn'> {userSelectedLanguage?.Delete} </Button>
                                <Button className='editBtn' type="primary" onClick={() => getToPayment(text)}>{userSelectedLanguage?.Edit}</Button>
                            </>
                        }
                        <Button disabled={text?.isCompleted} loading={cancelLoader} onClick={() => { cancelGroupReq(text?._id, text?.groupBuyId?._id || text?.groupBuyId) }} className='DeleteBtn'> {userSelectedLanguage?.CancelRequest} </Button>
                        <Button className='editBtn' type="primary" onClick={
                            () => {
                                window.open('http://pf.kakao.com/_xdxebxjxj/chat', '_blank')
                            }}>{lanKorCondition ? 'Chat Bot' : '문의중 '}</Button>
                    </>
                </div >
            ),
        },
    ]
    return (
        <>
            <ButtonLogout props={props} />
            <div className='settingDiv'>
                <div className='nameEmailDiv'>
                    <div className='divHeader'>
                        <h3 className='headerText'>{lanKorCondition ? 'ticket Details' : '회원권 상세보기'}</h3>
                    </div>
                    <div className='divHeader detailMemberInfo'>
                        <div className='MemberInfo' >
                            <h4>{userSelectedLanguage?.TicketInfo}</h4>
                            <div className='participantdetailMemberInfo'>
                                <div className='participantdetailMember'  >
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.GolfClubName}</h5>
                                        <h5 className='participantKey'>
                                            {
                                                Data?.groupBuyId?.golfClub?.clubName
                                                ||
                                                Data?.golfClub?.clubName
                                                || '----'}
                                        </h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.Type}</h5>
                                        <h5 className='participantKey'>

                                            {
                                                Data?.membershipType ?
                                                    Data?.membershipType ?
                                                        lanKorCondition ? Data?.membershipType : typesKr[types?.indexOf(Data?.membershipType)]?.typesKr
                                                        : lanKorCondition ? Data?.golfClub?.region : regionsKr[regions?.indexOf(Data?.membershipType)]?.TitleKr
                                                    :
                                                    Data?.membershipType ?
                                                        lanKorCondition ? Data?.membershipType : typesKr[types?.indexOf(Data?.membershipType)]?.typesKr
                                                        : lanKorCondition ? Data?.golfClub?.region : regionsKr[regions?.indexOf(Data?.membershipType)]?.TitleKr
                                            }</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.JoiningFee}</h5>
                                        <h5 className='participantKey tableEmail'>{
                                            Data?.groupBuyId?.joiningFee ?
                                                Data?.groupBuyId?.joiningFee ?
                                                    Data?.groupBuyId?.joiningFee + ' ' + userSelectedLanguage?.KRW :
                                                    Data?.reservedTime
                                                        ? Data?.reservedTime
                                                        : null
                                                :
                                                Data?.joiningFee ?
                                                    Data?.joiningFee + ' ' + userSelectedLanguage?.KRW :
                                                    Data?.reservedTime
                                                        ? Data?.reservedTime
                                                        : null

                                        }</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>
                                            {userSelectedLanguage?.JoiningDate}</h5>
                                        <h5 className='participantKey'>
                                            {
                                                Data?.joiningDate?.date1 ?

                                                    Data?.joiningDate?.date1 || Data?.joiningDate?.date2 ?
                                                        moment(Data?.joiningDate?.date1).format('YYYY-MM-DD') + ' / ' + moment(Data?.joiningDate?.date2).format('YYYY-MM-DD') : Data?.reservedDate?.date1 || Data?.reservedDate?.date2 ? moment(Data?.reservedDate?.date1).format('YYYY-MM-DD') + '/' + moment(Data?.reservedDate?.date2).format('YYYY-MM-DD')
                                                            : '----'
                                                    : Data?.groupBuyId?.joiningDate?.date1 || Data?.groupBuyId?.joiningDate?.date2 ?
                                                        moment(Data?.groupBuyId?.joiningDate?.date1).format('YYYY-MM-DD') + ' / ' + moment(Data?.groupBuyId?.joiningDate?.date2).format('YYYY-MM-DD') : Data?.reservedDate?.date1 || Data?.reservedDate?.date2 ? moment(Data?.reservedDate?.date1).format('YYYY-MM-DD') + '/' + moment(Data?.reservedDate?.date2).format('YYYY-MM-DD')
                                                            : '----'
                                            }
                                        </h5>
                                    </div>
                                </div>
                                <div className='participantdetailMember extraMargin'  >
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.SpecialGift}</h5>
                                        <h5 className='participantKeyTic tableEmail'>
                                            주중 {Data?.specialGiftWeekDay || Data?.userTicketId?.specialGiftWeekDay} 회 | {Data?.specialGiftWeekDayKRW || Data?.userTicketId?.specialGiftWeekDayKRW} 만원
                                            <br />
                                            주말 {Data?.specialGiftWeekEnd || Data?.userTicketId?.specialGiftWeekEnd} 회 | {Data?.specialGiftWeekEndKRW || Data?.userTicketId?.specialGiftWeekEndKRW} 만원
                                        </h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Total Seats' : '모집 구좌'}</h5>
                                        <h5 className='participantKey'>{Data?.totalSeats || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Seat Price' : '구좌당 금액'}</h5>
                                        <h5 className='participantKey'> {Data?.totalPrice || Data?.totalSeatsPrice + ' ' + userSelectedLanguage?.KRW || '----'}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* Password Confirm Password etc */}

                <div className='nameEmailDiv'>
                    <div className='divHeader '>
                        <h3 className='headerText'>{userSelectedLanguage?.ParticipantDetail}</h3>
                    </div>
                    <div className='divHeader detailMemberInfo borderNone'>
                        <Table
                            pagination={{ pageSize: 500 }}
                            dataSource={bidsData}
                            columns={columns}
                            scroll={{ x: 700 }}
                            size='middle'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupBuyDetail
