import { Divider, Modal, Typography } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { POST } from '../../utils/apis';
import { types, typesKr } from '../../utils/constants';
const { Text } = Typography;

const HoldingMembershipModal = (props) => {

    const { setshowEditHolding, showEditHolding, user, editData, language, lanKorCondition } = props
    const [ticketInfo, setTicketInfo] = useState([])
    const isValidUrl = urlString => {
        var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    }

    const handleCancel = () => {
        setshowEditHolding(false)
    }

    const getSingleTicket = () => {
        let obj = {
            _id: editData,
            userId: user?._id
        }
        axios.post(`${POST.SINGLE_TICKET_INFO}`, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setTicketInfo(data?.data)
                }
                else {
                    console.log(data?.message)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    useEffect(() => {
        getSingleTicket()
    }, [editData])

    return (
        <>
            <Modal
                open={showEditHolding}
                // open={true}
                onCancel={handleCancel}
                className='paymentModal'
                footer={null}
            >

                <div className='modal_main_div'>
                    <center><h2>{language?.HoldingMembership}</h2></center>
                    <h2>{lanKorCondition ? 'Info' : '기본 정보'}</h2>

                    <div className='static_text'>
                        <table>
                            <tr>
                                <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>{lanKorCondition ? 'GolfCourse' : '골프장1'}</Text></td>
                                <td className='values_style'><Text>{ticketInfo?.golfClub?.clubName}</Text></td>
                            </tr>
                            <tr>
                                <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>{lanKorCondition ? 'Type' : '회원권 종류'}</Text></td>
                                <td className='values_style'><Text>{lanKorCondition ? ticketInfo?.membershipType : typesKr[types?.indexOf(ticketInfo?.membershipType)]?.typesKr}</Text></td>
                            </tr>
                            <tr>
                                <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>{lanKorCondition ? 'JoiningFee' : '입회금'}</Text></td>
                                <td className='values_style'><Text>{ticketInfo?.joiningFee}{' '}{language?.MillonWon}</Text></td>
                            </tr>
                            <tr>
                                <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>{lanKorCondition ? 'JoiningDate' : '입회 기간'}</Text></td>
                                <td className='values_style'><Text>{`${moment(ticketInfo?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(ticketInfo?.joiningDate?.date2).format('YYYY-MM-DD')}`}</Text></td>
                            </tr>
                            <tr>
                                <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>{lanKorCondition ? 'SpecialGift' : '특전'}</Text></td>
                                <td className='values_style'>
                                    <Text>
                                        주중 {ticketInfo?.specialGiftWeekDay} 회 | {ticketInfo?.specialGiftWeekDayKRW} 만원
                                        <br />
                                        주말 {ticketInfo?.specialGiftWeekEnd} 회 {ticketInfo?.specialGiftWeekEndKRW}만원
                                    </Text>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ minWidth: 100, maxWidth: 'auto' }}>
                                    <Text>
                                        {lanKorCondition ? 'Fee' : '이용금액'}
                                    </Text>
                                </td>
                                <td className='values_style'>
                                    <Text>
                                        회원 | {ticketInfo?.memberFee || 0} 만원 비회원 | {ticketInfo?.NONmemberFee || 0} 만원
                                    </Text>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                    <h2>{language?.GolfCourseInformation}</h2>

                    <div className='static_text'>
                        <table>
                            <tr>
                                <td style={{}}><Text>{lanKorCondition ? 'address' : '주소'}</Text></td>
                                <td className='values_style'><Text>{ticketInfo?.address}</Text></td>
                            </tr>
                            <tr>
                                <td style={{}}><Text>{lanKorCondition ? 'OpeningDate' : '개장일'}</Text></td>
                                <td className='values_style'><Text>{moment(ticketInfo?.openingDate).format('YYYY-MM-DD')}</Text></td>
                            </tr>
                            <tr>
                                <td style={{}}><Text>{lanKorCondition ? 'GreenFee' : '그린피'}</Text></td>
                                <td className='values_style'>
                                    <Text>
                                        {ticketInfo?.greenFeeWeekDay ? language?.Weekday + ' ' + ticketInfo?.greenFeeWeekDay + '만원' + ' ' + ' ' + '|  ' + language?.Weekend + ' ' + ticketInfo?.greenFeeWeekEnd + ' 만원' : '- - - -'}
                                    </Text>
                                </td>
                            </tr>
                            <tr>
                                <td style={{}}><Text>{lanKorCondition ? 'CaddyFee' : '캐디비'}</Text></td>
                                <td className='values_style'><Text>{ticketInfo?.caddyFee || 0}{' '}만원</Text></td>
                            </tr>
                            <tr>
                                <td style={{}}><Text>{lanKorCondition ? 'cartFee' : '카트비'}</Text></td>
                                <td className='values_style'><Text>{ticketInfo?.cartFee || 0}{' '}만원</Text></td>
                            </tr>
                            <tr>
                                <td style={{ width: '75px', }}><Text>{lanKorCondition ? 'PhoneNumber' : '휴대폰번호'}</Text></td>
                                <td className='values_style'><Text>{ticketInfo?.phoneNumber}</Text></td>
                            </tr>
                            <tr>
                                <td style={{}}><Text>{lanKorCondition ? 'Homepage' : '홈페이지'}</Text></td>
                                {isValidUrl(ticketInfo?.homepage)
                                    ? <td
                                        onClick={() => {
                                            window.open(`${ticketInfo?.homepage}`, '_blank')
                                        }}
                                        className='values_style'><Text style={{ color: 'blue', cursor: 'pointer' }}>{ticketInfo?.homepage}</Text></td> :
                                    <td className='values_style'><Text>{ticketInfo?.homepage}</Text></td>}
                            </tr>
                        </table>
                    </div>

                    <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />
                    <h2>{lanKorCondition ? 'AdditionalBenefits' : '부가혜택'}</h2>

                    <div className='static_text'>

                        <table>
                            <tr>
                                {ticketInfo?.additionalBenefits?.map((v, i) => {
                                    return (
                                        <div className='static_text'>
                                            <table>
                                                <tr>
                                                    <td><Text>{v?.benefitName}</Text></td>
                                                    <td className='values_style'><Text>{v?.benefitContent}</Text></td>
                                                </tr>
                                            </table>
                                        </div>

                                    )
                                })}

                            </tr>
                        </table>
                    </div>
                </div>

            </Modal>

        </>
    )
}

export default HoldingMembershipModal