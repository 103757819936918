import React, { useState, useEffect } from 'react'
import { Button, Select } from 'antd'
import { ButtonLogout } from '../../Components'
import { Table, Divider, Tooltip, Modal, Form, Input } from 'antd'
import moment from 'moment-timezone'
import axios from 'axios'
import { ADMIN } from '../../utils/apis'
import { requiredMessage, errorMessage, stringLimiter, errorNotification, successNotification, convertTitle } from '../../utils/helpers'
import EditEvents from './EditEvents'
import EditNotice from './EditNotice'
import { useSelector } from 'react-redux'
import { allPaths } from '../../utils/constants'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'

const formLayout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const { Option } = Select
const NoticeBoard = (props) => {
    const { user } = props
    const userSelectedLanguage = props?.language
    const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
    const lanKorCondition = SelectedLanguage === 'english'
    const history = props?.history
    const [loading, setLoading] = useState(false)
    const [notice, setNotice] = useState([])
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [editNoticeData, setEditNoticeData] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState({})
    const [view, setView] = useState(false)

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleCancel = () => {
        setEditNoticeData({})
        form.setFieldValue({
            title: null,
            content: null,
        })
        setIsModalOpen(false)
    }
    useEffect(() => {
        getEventsAndNotices()
    }, [editNoticeData])


    const deleteNotice = (id) => {
        setDeleteLoader(true)
        let obj = {
            _id: id, adminId: user?._id
        }
        axios.post(ADMIN?.DELETE_NOTICE, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoader(false)
                if (data.success) {
                    successNotification(lanKorCondition ? data?.message : data?.messageKr)
                    getEventsAndNotices()
                } else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                setDeleteLoader(false)
                errorMessage(userSelectedLanguage?.OppsError)
            })
    }


    const getEventsAndNotices = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_NOTICES_AND_EVENTS}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setNotice(data?.getNotices)
                    // setEvents(data?.getEvents)
                }
                else {
                    console.log('runnig')
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
            })
    }


    const columnsNotice = [
        {
            title: lanKorCondition ? 'Number' : '번호',
            dataIndex: '_id',
            key: '_id',
            render: (text) => <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
                <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 13)}</Button>
            </Tooltip>,
        },
        {
            title: `${userSelectedLanguage?.Title}`,
            dataIndex: 'title',
            key: 'title',
            render: (text) => (
                <div className='namePhoneAndIDiv'>
                    <p className='namePhoneAndID'>{text ? text : '----'}</p>
                </div>
            ),
        },
        {
            title: userSelectedLanguage?.Content,
            dataIndex: 'content',
            key: 'content',
            render: (text) => (
                <div>
                    <p className='namePhoneAndID' style={{ color: '#1890ff', lineHeight: '18px' }}>{text ? stringLimiter(text) : '----'}</p>
                </div>
            ),
        },
        {
            title: lanKorCondition ? 'Date' : '업로드날짜',
            dataIndex: 'created',
            key: 'created',
            render: (text) => (
                <>
                    <div className='tableColoumWidthBig'>
                        {text ? moment(text).utc().format('YYYY-MM-DD') : '----'}
                    </div>
                </>
            ),
        },
        {
            title: userSelectedLanguage?.Management,
            key: 'action',
            render: (text) => (
                <div className='tableBtnsDiv'>
                    <>
                        <Button onClick={() => setEditNoticeData(text)} className='btnTableEdit'> {userSelectedLanguage?.Edit} </Button>
                        <Button loading={deleteLoader} onClick={() => deleteNotice(text?._id)} className='DeleteBtn'> {userSelectedLanguage?.Delete} </Button>
                    </>
                </div>
            ),
        },
    ]

    const [form] = Form.useForm()
    const onFinishNotice = (values) => {
        let obj = {
            adminId: user?._id,
            title: values?.title,
            content: values?.content,
        }
        setLoading(true)
        axios.post(ADMIN?.ADD_NOTICE, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    handleCancel()
                    form.resetFields()
                    successNotification(lanKorCondition ? data?.message : data?.messageKr)
                    getEventsAndNotices()
                } else {
                    // handleCancel()
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                setLoading(false)
                errorMessage(userSelectedLanguage?.OppsError)
            })
    }
    console.log('s', selectedRowData)
    return (
        <>
            <ButtonLogout props={props} />
            <div className='membershipBox'>
                <div className='goldReservationHeader'>

                    <div className='golfHeaderText'>
                        <h3>
                            <span
                                style={{ color: 'black', cursor: 'pointer' }}
                            >{userSelectedLanguage?.Notice}</span>
                            /
                            <span
                                style={{ color: '#999999', cursor: 'pointer' }}
                                onClick={() => { history.push(allPaths?.EVENT) }}
                            >{userSelectedLanguage?.Events}
                            </span >
                            /
                            <span
                                style={{ color: '#999999', cursor: 'pointer' }}
                                onClick={() => { history.push(allPaths?.BANNER) }}
                            >{userSelectedLanguage?.Events === 'Event' ? 'Banner' : '배너관리'}
                            </span >
                        </h3 >
                    </div>

                </div>

                < div style={{ display: 'flex', justifyContent: 'end', marginRight: '10px' }}>
                    <div className='ButtonDiv'>
                        <Button onClick={showModal} className='AddClubCardBtn' htmlType='submit'>{lanKorCondition ? 'AddNotice' : '공지사항 추가'}</Button>
                    </div>
                </div>

                <div className='tableDivManagement'>
                    <Divider />
                    {
                        view && selectedRowData?._id ?
                            <>
                                <div className='card-parent2 flex-new'>
                                    <div className='card3' style={{ marginTop: 0 }}>
                                        <p style={{ textAlign: 'right' }}>
                                            <Button style={{ border: 'none' }} onClick={() => {
                                                setView(false)
                                                setSelectedRowData({})
                                            }} icon={<CloseOutlined />}></Button>
                                        </p>
                                        <h2 style={{ textAlign: 'center' }}>{userSelectedLanguage?.Title}</h2>
                                        <br />
                                        {selectedRowData?.title}
                                        <br />
                                        <br />
                                        <h2 style={{ textAlign: 'center' }}>
                                            {userSelectedLanguage?.Content}
                                        </h2>
                                        <br />
                                        {selectedRowData?.content}
                                    </div>
                                </div>
                            </>
                            :
                            <Table
                                onRow={(record, rowIndex) => ({
                                    onClick: () => {
                                        setView(true)
                                        setSelectedRowData(record)
                                    },
                                })}
                                loading={loading}
                                pagination={{ pageSize: 6 }}
                                dataSource={notice}
                                columns={columnsNotice}
                                scroll={{ x: 700 }}
                                size='middle'
                            />
                    }


                    <Modal footer={null} title={lanKorCondition ? 'CreateNotice' : '공지사항 추가'} open={isModalOpen} onCancel={handleCancel}>
                        <Form
                            form={form}
                            {...formLayout}
                            onFinish={onFinishNotice}
                            requiredMark={false}
                        >
                            <Form.Item
                                label={lanKorCondition ? 'Title' : '제목'}
                                rules={[
                                    {
                                        required: true,
                                        message: lanKorCondition ? 'Please Enter Title' : '제목 입력'
                                    }
                                ]}
                                name='title'>
                                <Input placeholder={lanKorCondition ? 'Input title' : '제목 입력'} />
                            </Form.Item>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: lanKorCondition ? 'Please Enter Content' : '내용 입력',
                                    }
                                ]}
                                label={lanKorCondition ? 'Content' : ' 내용'} name='content'>
                                <Input.TextArea rows={4} placeholder={lanKorCondition ? 'Input content' : '내용 입력'} />
                            </Form.Item>
                            {/* <Form.Item> */}
                            <Button className='req_exc_btn1' htmlType='submit' loading={loading} type='primary'>{lanKorCondition ? 'Add' : '저장'}</Button>
                            {/* </Form.Item> */}
                        </Form>
                    </Modal>

                    {editNoticeData?._id ?
                        <EditNotice editData={editNoticeData} onCancel={() => setEditNoticeData({})} lanKorCondition={lanKorCondition}{...props} />
                        : null
                    }
                </div>
            </div>
        </>
    )
}
export default NoticeBoard
