import { Select, Button, Modal } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ADMIN } from '../../utils/apis';
const { Option } = Select;

const SearchGolfClub = (props) => {

    const { setAddress, showGolfClub, setShowGolfClub, golfClubData, setGolfClubData, language } = props
    const [golfClub, setGolfClubName] = useState(null)

    const handleCancel = () => {
        setShowGolfClub(false)
    }


    const onChange = (value) => {

        let new_value = golfClub?.find((v) => v?._id === value)
        setGolfClubData(new_value)
        setAddress(new_value?.clubAddress)

    };



    const onSearch = (value) => {
        // console.log('search:', value);
    };

    let golfClubName = () => {
        axios.get(ADMIN.GOLF_CLUB)
            .then((res) => {

                const { data } = res
                // console.log('data====>',data)
                if (data.success) {
                    setGolfClubName(data?.data)
                }
                else {
                    // console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
            })
    }

    useEffect(() => {
        golfClubName()
    }, [])

    return (
        <Modal footer={null} title={language?.GolfCourseName} open={showGolfClub} onCancel={handleCancel}>
            <Select
                style={{ width: '100%',top:13 }}
                showSearch
                value={golfClubData?.id}
                placeholder={language?.SearchGolfCourseName}
                optionFilterProp='children'
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) => option.children.toLowerCase()?.includes(input?.toLowerCase())}
            >
                {golfClub?.map((v, i) => (<Option key={i} value={v?._id}>{v?.clubName}</Option>))}
            </Select>
        </Modal>
    )

}

export default SearchGolfClub;