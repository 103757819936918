import { Button, Checkbox, DatePicker, Form, Image, Input, Modal, Select } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../utils/apis';
import { errorMessage, successNotification } from '../../utils/helpers';
import moment from 'moment-timezone'

const { RangePicker } = DatePicker
const { Option } = Select

const { TextArea } = Input

const dateFormat = 'YYYY/MM/DD';


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 18,
        },
    },
}

const rangeConfig = {
    rules: [
        {
            type: 'array',
            required: true,
            message: 'Please select date!',
        },
    ],
}


const GolfReservationEdit = (props) => {
    const { user, golfReservationAuction, editGolfResAuctionData, language, onCancel, lanKorCondition } = props
    const userSelectedLanguage = language

    const [loading, setLoading] = useState(false)
    const [greenFee, setGreenFee] = useState(editGolfResAuctionData?.greenFee)
    const [advanceFee, setAdvanceFee] = useState(editGolfResAuctionData?.advanceFee)
    const [am, setAm] = useState(editGolfResAuctionData?.reservedTime?.slice(-2) || 'am')
    const [hr, setHr] = useState(editGolfResAuctionData?.reservedTime?.split(':')[0] || '2')
    const [min, setMin] = useState(editGolfResAuctionData?.reservedTime?.split(':')[1]?.slice(0, -3) || '1')
    const [totalTime, setTotalTime] = useState(hr + ':' + min + ' ' + am)
    const [transferFee, setTransferFee] = useState(editGolfResAuctionData?.transferFee)
    const hrs = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    const mins = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60']
    const [form] = Form.useForm()

    const handleCancel = () => {
        onCancel()
        form.resetFields()
    }



    const editGolfReservationAuction = (values) => {
        setLoading(true)
        const rangeValue = values['reservedDate']
        values.reservedDate = { date1: rangeValue[0].format('YYYY-MM-DD'), date2: rangeValue[1].format('YYYY-MM-DD') }
        values.greenFee = Number(values.greenFee)
        values.advanceFee = Number(values.advanceFee)
        values.transferFee = transferFee
        values.reservedTime = totalTime
        values._id = editGolfResAuctionData?._id
        values.adminId = user?._id

        axios.post(ADMIN?.EDIT_GOLF_RES_AUCTION, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    golfReservationAuction()
                    successNotification(lanKorCondition ? data.message : data.messageKr)
                    handleCancel()
                } else {
                    errorMessage(lanKorCondition ? data.message : data.messageKr)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                setLoading(false)
                errorMessage(language?.OppsError)
            })
    }

    useEffect(() => {
        form.setFieldsValue({
            'reservedDate': [moment(editGolfResAuctionData?.reservedDate?.date1, dateFormat), moment(editGolfResAuctionData?.reservedDate?.date2, dateFormat)],
            'greenFee': greenFee,
            'transferFee': transferFee,
            'memo': editGolfResAuctionData?.memo
        })
    }, [])
    useEffect(() => {
        setTotalTime(hr + ':' + min + ' ' + am)
    }, [am, min, hr])
    useEffect(() => {
        form.setFieldsValue({
            'reservedDate': [moment(editGolfResAuctionData?.reservedDate?.date1, dateFormat), moment(editGolfResAuctionData?.reservedDate?.date2, dateFormat)],
            'greenFee': greenFee,
            'transferFee': transferFee,
            'memo': editGolfResAuctionData?.memo
        })
    }, [])
    useEffect(() => {
        setTotalTime(hr + ':' + min + ' ' + am)
    }, [am, min, hr])


    return (
        <Modal
            open={editGolfResAuctionData?._id}
            onCancel={handleCancel}
            className='paymentModal'
            footer={null}
        >
            <div className='modal_main_div'>
                <center><h2>{userSelectedLanguage?.EditGolfReservationAuction}</h2></center>

                <div className='row_fields' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                    <p style={{ marginLeft: 10 }}>{editGolfResAuctionData?.golfClub?.clubName || '----'}</p>

                </div>

                <div className='row_fields1' style={{ maxWidth: 300, marginBottom: 20 }}>
                    {console.log(editGolfResAuctionData, '=editGolfResAuctionData')}
                    <Image width={100} height={100} src={editGolfResAuctionData?.golfClub?.imgUrl} />
                    <div style={{ marginTop: -10 }}>
                        <h2>{editGolfResAuctionData?.golfClub?.clubName}</h2>
                        <p>{editGolfResAuctionData?.golfClub?.clubAddress}</p>
                    </div>
                </div>

                <Form name='time_related_controls' form={form} requiredMark={false} {...formItemLayout} onFinish={editGolfReservationAuction}>

                    <Form.Item name='reservedDate' label={userSelectedLanguage?.ReservedDate} {...rangeConfig}>
                        <RangePicker style={{ width: '100%' }} defaultValue={[moment(editGolfResAuctionData?.reservedDate?.date1, dateFormat), moment(editGolfResAuctionData?.reservedDate?.date2, dateFormat)]} />
                    </Form.Item>
                    <Form.Item
                        name='reservedTime'
                        label={userSelectedLanguage?.ReservationTime}
                    >
                        <div className='row_fields1'>
                            <Select
                                defaultValue={editGolfResAuctionData?.reservedTime?.slice(4)}
                                className='special_width'
                                value={am}
                                onChange={(value) => { setAm(value); setTotalTime(hr + ':' + min + ' ' + am) }}
                            >
                                <Option value='am'>{lanKorCondition ? 'AM' : '오전'}</Option>
                                <Option value='pm'>{lanKorCondition ? 'PM' : '오후'}</Option>
                            </Select>
                            <Select
                                style={{
                                    width: '35%'
                                }}
                                value={hr}
                                onChange={(value) => { setHr(value); setTotalTime(hr + ':' + min + ' ' + am) }}
                            >
                                {hrs?.map((v, i) => (<Option key={i} value={v}>{v} 시</Option>))}
                            </Select>
                            <Select
                                style={{
                                    width: '35%'
                                }}
                                value={min}
                                onChange={(value) => { setMin(value); setTotalTime(hr + ':' + min + ' ' + am) }}
                            >
                                {mins?.map((v, i) => (<Option key={i} value={v}>{v} 분</Option>))}
                            </Select>
                        </div>
                    </Form.Item>

                    <Form.Item
                        name='greenFee'
                        label={userSelectedLanguage?.GreenFee}
                        rules={[
                            {
                                required: true,
                                message: userSelectedLanguage?.GreenFee,
                            }
                        ]}
                    >
                        <div>
                            <Input
                                suffix={userSelectedLanguage?.KRW}
                                min={0}
                                maxLength={10}
                                value={greenFee}
                                onChange={(e) => setGreenFee(e.target.value?.replace(/[^0-9]/g, ''))}
                            />
                        </div>
                    </Form.Item>

                    <Form.Item
                        name='transferFee'
                        label={userSelectedLanguage?.TransferFee}
                        rules={[
                            {
                                required: true,
                                message: userSelectedLanguage?.TransferFee,
                            }
                        ]}
                    >
                        <div className='row_fields1'>

                            <Input
                                suffix={userSelectedLanguage?.KRW}
                                min={0}
                                maxLength={7}
                                value={transferFee}
                                onChange={(e) => setTransferFee(e.target.value?.replace(/[^0-9]/g, ''))}
                            />
                            <p
                                className='noTransferr'
                                onClick={() => setTransferFee(0)}
                            // style={{ width: 150, marginLeft: 10, border: 'none' }}
                            >{userSelectedLanguage?.NoTransferFee}</p>

                            {/* <p >no transfer Fee</p> */}

                        </div>
                    </Form.Item>

                    <Form.Item
                        name='advanceFee'
                        initialValue={advanceFee}
                        label={<h4 style={{ marginBottom: 0 }}>{language?.advanceFee}</h4>}
                        rules={[
                            {
                                required: true,
                                message: language?.advanceFeeAmountHere,
                            },
                        ]}
                    >
                        <div>
                            <Input
                                min={0}
                                maxLength={10}
                                value={advanceFee}
                                onChange={(e) => setAdvanceFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                placeholder={language?.advanceFeeAmountHere}
                            />
                        </div>
                    </Form.Item>

                    <Form.Item
                        name='memo'
                        label={userSelectedLanguage?.Memo}
                        rules={[
                            {
                                required: true,
                                message: userSelectedLanguage?.Memo,
                            }
                        ]}
                    >
                        <div className='row_fields1'>
                            <TextArea rows={4} defaultValue={editGolfResAuctionData?.memo} />
                        </div>
                    </Form.Item>

                    <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit'>{userSelectedLanguage?.Update}</Button>
                </Form>
            </div>
        </Modal>
    )
}
export default GolfReservationEdit
