import { Modal } from "antd";
import moment from "moment-timezone";
import React from "react";


const UserInfoModal = (props) => {
    const { visible, data, handleCancel, SelectedLanguage, lanKorCondition } = props
    console.log(data)
    return (
        <>
            <Modal
                footer={null}
                open={visible}
                onOk={handleCancel}
                onCancel={handleCancel}
            >
                <div>
                    <p style={{ textAlign: 'center', fontWeight: 'bold' ,color:'#5ea29f'}}>{lanKorCondition ? 'User information ' : '사용자 정보'}</p>
                    <div>
                        <div className="border-bottom-dotted" />
                        <div className="info-detail-div">
                            <p className="p-bolf">{lanKorCondition ? 'Name' : ' 이름'}:</p>
                            <p className="p-unbold">{data?.fullName || '----'}</p>
                        </div>
                        <div className="info-detail-div">
                            <p className="p-bolf">{lanKorCondition ? 'email' : '아이디'}:</p>
                            <p className="p-unbold">{data?.email || '----'}</p>
                        </div>
                        <div className="info-detail-div">
                            <p className="p-bolf">{lanKorCondition ? 'Phone number' : '휴대폰 번호 '}:</p>
                            <p className="p-unbold">{data?.telNo || '----'}</p>
                        </div>
                        <div className="info-detail-div">
                            <p className="p-bolf">{lanKorCondition ? ' DOB ' : ' 생년월일'}:</p>
                            <p className="p-unbold">{data?.DOB ? moment(data?.DOB).format('YYYY-MM-DD') : '----'}</p>
                        </div>
                        <div className="info-detail-div">
                            <p className="p-bolf">{lanKorCondition ? 'Gender ' : '성별'}:</p>
                            <p className="p-unbold">
                                {data?.gender ?
                                    data?.gender === 'female' ?
                                        lanKorCondition ? "Female" : "여 성"
                                        : lanKorCondition ? "Male" : "남성"
                                    : '----'}</p>
                        </div>
                        <div className="info-detail-div">
                            <p className="p-bolf">{lanKorCondition ? 'Address ' : '주소 '}:</p>
                            <p className="p-unbold">
                                {data?.address ? data?.address : '----'}</p>
                        </div>
                        <div className="info-detail-div">
                            <p className="p-bolf">{lanKorCondition ? ' Bank Account information  ' : '계좌정보 '}:</p>
                        </div>
                        {/* marginLeft */}
                        <div className="info-detail-div" style={{ marginLeft: '20px' }}>
                            <p className="p-bolf">{lanKorCondition ? ' Bank Name' : '은행 '}:</p>
                            <p className="p-unbold">{data?.bank ? data?.bank : '----'}</p>
                        </div>
                        {/* marginLeft */}
                        <div className="info-detail-div" style={{ marginLeft: '20px' }}>
                            <p className="p-bolf">{lanKorCondition ? ' Account Number' : '계좌번호 '}:</p>
                            <p className="p-unbold">{data?.bankAccountNumber ? data?.bankAccountNumber : '----'}</p>
                        </div>
                        <div className="info-detail-div">
                            <p className="p-bolf">{lanKorCondition ? 'Golf club preference' : '선호골프장  '}:</p>
                        </div>
                        {data?.preferredGolfCourse?.length &&
                            data?.preferredGolfCourse?.map((v, i) => {
                                return (
                                    <div className="info-detail-div" style={{ marginLeft: '20px' }}>
                                        <p className="p-bolf">{`${(i + 1)}`}:</p>
                                        <p className="p-unbold">{v?.clubName || '----'}</p>
                                    </div>
                                )
                            })

                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default UserInfoModal 