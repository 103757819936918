import React from 'react'
import { Button, Table } from 'antd'
import { ButtonLogout } from '../../Components'

const ParticipantsDetail = (props) => {
    const userSelectedLanguage = props?.language

    const columns = [
        {
            title: userSelectedLanguage?.TransactionNumber,
            dataIndex: 'TransactionNumber',
            key: 'Transaction number',
            render: (text) => <Button className='tableBtn'>{text}</Button>,
        },
        {
            title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
            dataIndex: 'NameIDphoneOfUploader',
            key: 'Name and Phone Number',
            render: (text) => (
                <div className='namePhoneAndIDiv'>
                    {text.map((v, i) => {
                        return <p className='namePhoneAndID'>{v}</p>
                    })}
                </div>
            ),
        },
        {
            title: userSelectedLanguage?.Seats,
            dataIndex: 'Seats',
            key: 'Seats',
            render: (text) => <p className='tableColoumWidth'>{text}</p>,
        },

        {
            title: userSelectedLanguage?.TotalPrice,
            dataIndex: 'TotalAmount',
            key: 'TotalAmount',
            render: (text) => <p className='tableColoumWidthBig'>{text}</p>,
        },

        {
            title: userSelectedLanguage?.Status,
            dataIndex: 'Status',
            key: 'Status',
            render: (text) => (
                <>
                    {text === 'Participation confirmed' ? (
                        <Button className='Participationconfirmed'>{text}</Button>
                    ) : text === 'Waiting for Participate' ? (
                        <Button className='soldOutAuctionTableBtn'>{text}</Button>
                    ) : null}
                </>
            ),
        },
        {
            title: userSelectedLanguage?.Action,
            dataIndex: 'Action',
            key: 'Action',
            render: (text) => (
                <div className='tableBtnsDiv'>
                    {text ? (
                        <>
                            <Button className='postApproval'>{userSelectedLanguage?.Confirm}</Button>
                            <Button className='editBtn'> {userSelectedLanguage?.Edit}</Button>
                            <Button className='DeleteBtn'> {userSelectedLanguage?.Delete} </Button>
                        </>
                    ) : null}
                </div>
            ),
        },
    ]
    const dataSource = [
        {
            TransactionNumber: '#123456',
            NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
            Seats: '2',
            Type: 'individual ',
            TotalAmount: '120,000,000 KRW',
            ViewMembership: 'search',
            Status: 'Waiting for Participate',
            Action: ['Edit', 'Delete'],
        },
        {
            TransactionNumber: '#123456',
            NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
            Seats: '2',
            Type: 'individual ',
            TotalAmount: '120,000,000 KRW',
            ViewMembership: 'search',
            Status: 'Participation confirmed',
            Action: ['Edit', 'Delete'],
        },
        {
            TransactionNumber: '#123456',
            NameIDphoneOfUploader: ['Hong Gil Dong', 123456789044, '010-1234-5678'],
            Seats: '2',
            Type: 'individual ',
            TotalAmount: '120,000,000 KRW',
            ViewMembership: 'search',
            Status: 'Waiting for Participate',
            Action: ['Edit', 'Delete'],
        }
    ]
    return (
        <>
            <ButtonLogout props={props} />
            <div className='settingDiv'>
                {/* Name Email ID etc */}
                <div className='nameEmailDiv'>
                    <div className='divHeader'>
                        <h3 className='headerText'>{userSelectedLanguage?.MembershipDetail}</h3>
                        <div className='borderBottom'></div>
                    </div>
                    <div className='divHeader detailMemberInfo'>
                        <div className='MemberInfo' >
                            <h4>{userSelectedLanguage?.PostedByMemberInfo}</h4>
                            <div className='participantdetailMemberInfo'>
                                <div className='participantdetailMember'  >
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.GolfClubName}</h5>
                                        <h5 className='participantKey'>times a week (2 times) / 90,000 KRW </h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.Type}</h5>
                                        <h5 className='participantKey'>Colf Club Name</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.JoiningFee}</h5>
                                        <h5 className='participantKey tableEmail'>Colf Club Name</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.JoiningDate}</h5>
                                        <h5 className='participantKey'>Colf Club Name</h5>
                                    </div>

                                </div>
                                <div className='participantdetailMember extraMargin'  >
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.TotalSeats}</h5>
                                        <h5 className='participantKey'>Colf Club Name</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.SeatPrice}</h5>
                                        <h5 className='participantKey'>Colf Club Name</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.Specialgift}</h5>
                                        <h5 className='participantKey tableEmail'>times a week (2 times) / 90,000 KRW </h5>
                                    </div>


                                </div>


                            </div>

                        </div>


                    </div>






                </div>
                {/* Password Confirm Password etc */}

                <div className='nameEmailDiv'>
                    <div className='divHeader '>
                        <h3 className='headerText'>{userSelectedLanguage?.ParticipantsDetails}</h3>
                    </div>
                    <div className='divHeader detailMemberInfo borderNone'>
                        <Table
                            pagination={{ pageSize: 500 }}
                            dataSource={dataSource}
                            columns={columns}
                            scroll={{ x: 700 }}
                            size='middle'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ParticipantsDetail
