import { Button, Input } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useEffect, useState } from 'react'
import { ButtonLogout, CoustomDatePicker } from '../../Components'
import { Radio } from 'antd';
import moment from 'moment-timezone';
import axios from 'axios';
import { ADMIN } from '../../utils/apis';
import { errorMessage, successMessage } from '../../utils/helpers';
import { useSelector } from 'react-redux';
import { allPaths, types, typesKr } from '../../utils/constants';

const TicketsInfo = (props) => {
    const history = props?.history
    const { user } = props
    let Bid = props?.history?.location?.state[0]
    let Data = props?.history?.location?.state[1]
    let Bank = props?.history?.location?.state[2].Bank
    let title = props?.history?.location?.state[2].title
    let disableCondition = Data?.paymentId?._id ? true : false
    const userSelectedLanguage = props?.language
    const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
    const lanKorCondition = SelectedLanguage === 'english'
    const [loading, setLoading] = useState(false)
    const [dateOFTr, setDateOFTr] = useState(Data?.paymentId?.dateOfTransaction);
    const [tranNum, setTranNum] = useState(null);
    const [value, setValue] = useState('Bank Wire');
    const [bankName, setBankName] = useState('');
    const [bankAccNum, setBankAccNum] = useState(null);
    const [bankAccount, setBankAccount] = useState('');
    const [personInCharge, setPersonInCharge] = useState('');
    const [memo, setMemo] = useState('');


    const createDate = (d) => {
        setDateOFTr(d.format('YYYY-MM-DD'))
    }
    const options = [
        { label: lanKorCondition ? 'Bank Wire' : '계좌이체', value: 'Bank Wire' },
        { label: lanKorCondition ? 'Cash' : '현금', value: 'Cash' },
        { label: lanKorCondition ? 'ETC' : '기타', value: 'ETC' },
    ];

    useEffect(() => {
        const paymentData = Data?.paymentId
        setDateOFTr(paymentData?.dateOfTransaction)
        setMemo(paymentData?.memo)
        setValue(paymentData?.transactionType)
        setPersonInCharge(paymentData?.paymentIncharge)
        setBankName(paymentData?.bankInfo?.bankName)
        setBankAccNum(paymentData?.bankInfo?.bankAccountNumber)
        setBankAccount(paymentData?.bankInfo?.bankAccount)
        setTranNum(paymentData?.transactionNumber)

    }, [])
    const onChange = ({ target: { value } }) => {
        setValue(value);
    };


    const resetFeild = () => {
        setDateOFTr(null)
        setTranNum(null)
        setValue('Bank Wire')
        setBankName('')
        setBankAccNum(null)
        setBankAccount('')
        setPersonInCharge('')
        setMemo('')
    }


    const goback = () => {
        title === 'share rental auction' ?
            history.push(allPaths?.GOLF_AUCTION_POST_MANAGEMENT)
            : title === 'auctionSale' ?
                history.push(allPaths?.GOLF_AUCTION_POST_MANAGEMENT) :
                title === 'golfReservation' ?
                    history.push(allPaths?.GOLF_AUCTION_POST_MANAGEMENT) :
                    title === 'Golf Reservation' ?
                        history.push(allPaths?.GOLF_RESERVATION_POST_MANAGEMENT) : '----'
    }

    const payment_Request_golf_res = () => {
        const obj = {
            adminId: user?._id,
            buyerId: Bid?.buyerId?._id,
            userId: Data?.userId?._id,
            ownerAdminId: user?._id,
            bankInfo: {
                bankName: bankName,
                bankAccount: bankAccount,
                bankAccountNumber: bankAccNum,
            },
            transactionType: value,
            dateOfTransaction: dateOFTr,
            transactionNumber: tranNum,
            paymentIncharge: personInCharge,
            memo: memo,
            golfReservationId: Bid?.golfReservationId ? Bid?.golfReservationId : null,
            bid_id: Bid?._id,
            amount: 1,

        }

        setLoading(true)
        axios.post(ADMIN.CREATE_PAYMENT_REQUEST, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    resetFeild()
                    goback()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                resetFeild()
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '아차! 문제가 발생했습니다!')
            })
    }
    const payment_Request_share_rent = () => {
        const obj = {
            adminId: user?._id,
            buyerId: Bid?.buyerId?._id,
            userId: Data?.userId?._id,
            bankInfo: {
                bankName: bankName,
                bankAccount: bankAccount,
                bankAccountNumber: bankAccNum,
            },
            transactionType: value,
            dateOfTransaction: dateOFTr,
            transactionNumber: tranNum,
            paymentIncharge: personInCharge,
            memo: memo,
            auctionRentalBidId: Data?._id,
            bid_id: Bid?._id,
            amount: Bid?.price

        }
        setLoading(true)
        axios.post(ADMIN.CREATE_PAYMENT_REQUEST, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    resetFeild()
                    goback()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                resetFeild()
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '아차! 문제가 발생했습니다!')
            })
    }
    const payment_Request_sell_rent = () => {
        const obj = {
            adminId: user?._id,
            buyerId: Bid?.buyerId?._id,
            userId: Data?.userId?._id,
            bankInfo: {
                bankName: bankName,
                bankAccount: bankAccount,
                bankAccountNumber: bankAccNum,
            },
            transactionType: value,
            dateOfTransaction: dateOFTr,
            transactionNumber: tranNum,
            paymentIncharge: personInCharge,
            memo: memo,
            auctionSaleBidId: Data?._id,
            bid_id: Bid?._id,
            amount: Bid?.price
        }
        setLoading(true)
        axios.post(ADMIN.CREATE_PAYMENT_REQUEST, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    resetFeild()
                    goback()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                resetFeild()
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '아차! 문제가 발생했습니다!')
            })
    }
    const payment_Request_golf_rent = () => {
        const obj = {
            adminId: user?._id,
            buyerId: Bid?.buyerId?._id,
            userId: Data?.userId?._id,
            bankInfo: {
                bankName: bankName,
                bankAccount: bankAccount,
                bankAccountNumber: bankAccNum,
            },
            transactionType: value,
            dateOfTransaction: dateOFTr,
            transactionNumber: tranNum,
            paymentIncharge: personInCharge,
            memo: memo,
            auctionGolfResBidId: Data?._id,
            bid_id: Bid?._id,
            amount: Bid?.price

        }
        setLoading(true)
        axios.post(ADMIN.CREATE_PAYMENT_REQUEST, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    resetFeild()
                    goback()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                resetFeild()
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '아차! 문제가 발생했습니다!')
            })
    }
    return (
        <>
            <ButtonLogout props={props} />
            <div className='settingDiv'>
                {/* Name Email ID etc */}


                <div className='nameEmailDiv'>
                    <div className='divHeader'>
                        <h3 className='headerText'>
                            {lanKorCondition ? 'View details' : '회원권 거래정보'}
                        </h3>
                        <div className='borderBottom'></div>
                    </div>

                    <div className='ViewTransactionCardsDiv '>
                        <div className='ViewTransactionCard paddingTopnone'>
                            <h4 className='ViewTransactionCardTextBold size'>
                                {lanKorCondition ? 'Seller Info' : '판매자 정보'}
                            </h4>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {'ID'}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {Data?.userId?._id ? Data?.userId?._id : Data?.ownerAdminId || Data?.adminId ? user?._id : '----'}

                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {userSelectedLanguage?.Name}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {Data?.userId?.fullName ? Data?.userId?.fullName : Data?.ownerAdminId || Data?.adminId ? `${user?.userName}-${lanKorCondition ? 'admin' : '어드민'}` : '----'}


                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {userSelectedLanguage?.Email}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBoldEmail textElipsis'>
                                        {Data?.userId?.email ? Data?.userId?.email : Data?.ownerAdminId || Data?.adminId ? user?.email : '----'}
                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {userSelectedLanguage?.PhoneNumber}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {Data?.userId?.telNo ? Data?.userId?.telNo : '----'}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className='ViewTransactionCardCenter paddingTopnone'>
                            <h4 className='ViewTransactionCardTextBold size'>
                                {lanKorCondition ? 'Buyer Info' : '구매자 정보'}
                            </h4>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {'ID'}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {Bid?.buyerId?._id ? Bid?.buyerId?._id : '----'}
                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {userSelectedLanguage?.Name}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {Bid?.buyerId?.fullName ? Bid?.buyerId?.fullName : '----'}
                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {userSelectedLanguage?.Email}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBoldEmail textElipsis '>
                                        {Bid?.buyerId?.email ? Bid?.buyerId?.email : '----'}
                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {userSelectedLanguage?.PhoneNumber}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {Bid?.buyerId?.telNo ? Bid?.buyerId?.telNo : '----'}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className='ViewTransactionCardLast'>
                            <div className='TransactionAmountInputDiv'>
                                <div className='inputTransactionAmountLabelDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        01){lanKorCondition ? 'Person in charge' : '담당자 이름'}
                                    </h5>
                                </div>
                                <div className='inputTransactionAmountDiv'>
                                    <Input disabled={disableCondition} onChange={(e) => setPersonInCharge(e.target?.value)} value={personInCharge} className='inputTransactionAmount' />
                                </div>
                            </div>
                            <div className='TransactionAmountInputDiv'>
                                <div className='inputTransactionAmountLabelDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        02){userSelectedLanguage?.Memo}
                                    </h5>
                                </div>
                                <div className='inputTransactionAmountDiv'>
                                    <TextArea
                                        disabled={disableCondition}
                                        onChange={(e) => setMemo(e.target?.value)}
                                        value={memo}
                                        autoSize={{ minRows: 4, maxRows: 4 }}
                                        className='inputTransactionAmount'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='nameEmailDiv'>
                    <div className='ticketsInfoCards'>
                        <div className='ticketsInfoCard'>
                            <div className='divHeader'>
                                <h3 className='headerText'>
                                    {lanKorCondition ? 'Payment details' : '결제 정보'}
                                </h3>
                                <div className='borderBottom'></div>
                            </div>
                            <div className='TicketForm'>
                                <div>
                                    <h4>{lanKorCondition ? 'Date of transaction' : '거래일자'}</h4>
                                    {
                                        disableCondition ?
                                            <Input disabled={disableCondition} value={moment(dateOFTr).format('YYYY-MM-DD')} className='inputTransactionAmount' />
                                            :
                                            <CoustomDatePicker disabled={disableCondition} setDateOFTr={setDateOFTr} />
                                    }
                                </div>
                                <div>
                                    <h4>{lanKorCondition ? 'Transaction Number' : '거래금액'}</h4>
                                    <Input disabled={disableCondition} onChange={(e) => setTranNum(e.target?.value)} value={tranNum} className='inputTransactionAmount' suffix='원' />
                                </div>
                                <div>
                                    <h4>{lanKorCondition ? 'Transaction Type' : '거래방법'}</h4>
                                    <Radio.Group
                                        disabled={disableCondition}
                                        options={options}
                                        onChange={onChange}
                                        value={value}
                                        optionType='button'
                                        buttonStyle='solid'
                                    />
                                </div>



                            </div>
                            <div className='TicketForm'>
                                <h3>{lanKorCondition ? ' Bank Info' : '계좌정보'}</h3>
                                <div>
                                    <h4>{lanKorCondition ? 'Bank Name' : '은행명'}</h4>
                                    <Input disabled={disableCondition} onChange={(e) => setBankName(e.target?.value)} value={bankName} className='inputTransactionAmount' />
                                </div>
                                <div>
                                    <h4>{lanKorCondition ? 'Bank Account' : '예금주'}</h4>
                                    <Input disabled={disableCondition} onChange={(e) => setBankAccount(e.target?.value)} value={bankAccount} className='inputTransactionAmount' />
                                </div>
                                <div>
                                    <h4>{lanKorCondition ? 'Bank Account Number' : '계좌번호'}</h4>
                                    <Input disabled={disableCondition} onChange={(e) => setBankAccNum(e.target?.value)} value={bankAccNum} className='inputTransactionAmount' />
                                </div>
                            </div>
                        </div>
                        {Bank === 'Hide' ?
                            <div className='ticketsInfoCard'>
                                <div className='divHeader'>
                                    <h3 className='headerText'>
                                        {lanKorCondition ? 'Ticket Info' : '회원권 정보'} -
                                        {title === 'share rental auction' ?
                                            lanKorCondition ? 'Ticket Sharing Rent' : '회원권 공유 대여'
                                            : title === 'auctionSale' ?
                                                lanKorCondition ? 'Ticket Sale Rent' : '회원권 매매'
                                                : title === 'golfReservation' ?
                                                    lanKorCondition ? 'Golf Reservation Rent' : '골프예약'
                                                    : title === 'Golf Reservation' ?
                                                        lanKorCondition ? 'Golf Reservation' : '골프예약' : '---'}
                                    </h3>
                                    <div className='borderBottom'></div>
                                </div>
                                <div className='extraMargina' style={{ marginTop: '30px' }} >
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.GolfClubName}</h5>
                                        <h5 className='participantKey'>{Data?.golfClub?.clubName || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.address}</h5>
                                        <h5 className='participantKey'>{Data?.golfClub?.clubAddress || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.Region}</h5>
                                        <h5 className='participantKey '>{Data?.golfClub?.region || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.Memo}</h5>
                                        <h5 className='participantKey'>{Data?.memo || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Reservation Date' : '예약일자'}</h5>
                                        <h5 className='participantKey'>{moment(Data?.reservedDate?.date1).format('YYYY-MM-DD') + ' ' + '|' + ' ' + moment(Data?.reservedDate?.date2).format('YYYY-MM-DD')}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Reservation Time' : '예약시간'}</h5>
                                        <h5 className='participantKey'>{Data?.reservedTime || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Green fee' : '그린피'}</h5>
                                        <h5 className='participantKey'>{Data?.greenFee ? Data?.greenFee + ' ' + userSelectedLanguage?.KRW : '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Transfer fee' : '양도비'}</h5>
                                        <h5 className='participantKey'>{Data?.transferFee ? Data?.transferFee + ' ' + userSelectedLanguage?.KRW : '----'}</h5>
                                    </div>
                                </div>


                                {title === 'golfReservation' &&
                                    <>
                                        <div className='divHeader ' style={{ marginTop: '40px' }}>
                                            <h3 className='headerText'>
                                                {lanKorCondition ? ' Buyer’s bank info' : '구매자 계좌정보'}
                                            </h3>
                                            <div className='borderBottom'></div>
                                        </div>
                                        <div className='extraMargina' style={{ marginTop: '40px' }} >
                                            <div style={{ display: 'flex' }}>
                                                <h5 className='participantKeyBold'>{lanKorCondition ? 'Bank Name' : '은행명'}</h5>
                                                <h5 className='participantKeyTic'>{Bid?.bankDetail?.bankName || '----'}</h5>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <h5 className='participantKeyBold'>{userSelectedLanguage?.Name}</h5>
                                                <h5 className='participantKeyTic'>{Bid?.bankDetail?.name || '----'}</h5>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <h5 className='participantKeyBold'>{lanKorCondition ? 'Bank Account Numnber' : '계좌번호'}</h5>
                                                <h5 className='participantKeyTic'>{Bid?.bankDetail?.bankAccountNo || '----'}</h5>
                                            </div>
                                        </div>
                                    </>}
                            </div> :
                            <div className='ticketsInfoCard'>
                                <div className='divHeader'>
                                    <h3 className='headerText'>
                                        {lanKorCondition ? 'Ticket Info' : '회원권 정보'} -

                                        {title === 'share rental auction' ?
                                            lanKorCondition ? 'Ticket Sharing Rent' : '회원권 공유 대여'
                                            : title === 'auctionSale' ?
                                                lanKorCondition ? 'Ticket Sale Rent' : '회원권 매매'
                                                : title === 'golfReservation' ?
                                                    lanKorCondition ? 'Golf Reservation Rent' : '골프예약'
                                                    : title === 'Golf Reservation' ?
                                                        lanKorCondition ? 'Golf Reservation' : '골프예약' : '---'}
                                    </h3>
                                    <div className='borderBottom'></div>
                                </div>
                                <div className='extraMargina' style={{ marginTop: '30px' }} >
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.GolfClubName}</h5>
                                        <h5 className='participantKeyTic'>{Data?.ticketId?.golfClub?.clubName || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.Type}</h5>
                                        <h5 className='participantKeyTic'>
                                            {Data?.ticketId?.membershipType ? lanKorCondition ? ' ' + Data?.ticketId?.membershipType : typesKr[types?.indexOf(Data?.ticketId?.membershipType)]?.typesKr : '----'}
                                        </h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.JoiningFee}</h5>
                                        <h5 className='participantKeyTic tableEmail'>{Data?.ticketId?.joiningFee ? Data?.ticketId?.joiningFee + ' ' + userSelectedLanguage?.KRW : '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.JoiningDate}</h5>
                                        <h5 className='participantKeyTic'>{Data?.ticketId?.joiningDate.date1 || Data?.ticketId?.joiningDate.date2 ? moment(Data?.ticketId?.joiningDate.date1).format('YYYY-MM-DD') + ' / ' + moment(Data?.ticketId?.joiningDate.date2).format('YYYY-MM-DD') : '----'}</h5>
                                    </div>
                                </div>
                                <div className=' extraMargina'  >
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? ' Seat Price' : '즉시공유/구매가격'}</h5>
                                        <h5 className='participantKeyTic'>{Data?.ticketId?.totalSeatsPrice ? Data?.ticketId?.totalSeatsPrice : 0 + ' ' + userSelectedLanguage?.KRW}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Special gift' : '특전'}</h5>

                                        <h5 className='participantKeyTic tableEmail'>
                                            주중 {Data?.ticketId?.specialGiftWeekDay} 회 | {Data?.ticketId?.specialGiftWeekDayKRW} 만원
                                            <br />
                                            주말 {Data?.ticketId?.specialGiftWeekEnd} 회 | {Data?.ticketId?.specialGiftWeekEndKRW} 만원
                                        </h5>
                                    </div>


                                </div>
                                {/* ///////// */}
                                <>
                                    <div className='divHeader ' style={{ marginTop: '40px' }}>
                                        <h3 className='headerText'>
                                            {lanKorCondition ? ' Buyer’s bank info' : '구매자 계좌정보'}
                                        </h3>
                                        <div className='borderBottom'></div>
                                    </div>
                                    <div className='extraMargina' style={{ marginTop: '40px' }} >
                                        <div style={{ display: 'flex' }}>
                                            <h5 className='participantKeyBold'>{lanKorCondition ? 'Bank Name' : '은행명'}</h5>
                                            <h5 className='participantKeyTic'>{Bid?.bankDetail?.bankName || '----'}</h5>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <h5 className='participantKeyBold'>{userSelectedLanguage?.Name}</h5>
                                            <h5 className='participantKeyTic'>{Bid?.bankDetail?.name || '----'}</h5>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <h5 className='participantKeyBold'>{lanKorCondition ? 'Bank Account Numnber' : '계좌번호'}</h5>
                                            <h5 className='participantKeyTic'>{Bid?.bankDetail?.bankAccountNo || '----'}</h5>
                                        </div>
                                    </div>
                                </>
                            </div>
                        }
                    </div>
                    <div className='TransactionAmountInputDiv'>
                        <div className='saveTransactionButtonDiv '>
                            <Button loading={loading}
                                disabled={disableCondition}
                                onClick={title === 'share rental auction' ?
                                    payment_Request_share_rent : title === 'auctionSale' ?
                                        payment_Request_sell_rent : title === 'golfReservation' ?
                                            payment_Request_golf_rent : title === 'Golf Reservation' ?
                                                payment_Request_golf_res : '---'} className='saveTransactionBtnTic'>
                                {userSelectedLanguage?.Save}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TicketsInfo
