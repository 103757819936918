import apiUrl from '../Config/api'

const adminApi = `${apiUrl}/api/admin`
const postApi = `${apiUrl}/api/post`
const authApi = `/api/user`
const getApi = `${apiUrl}/api/get`

const AUTH = {
    GET_PASS_VERIFICATION_RES: `${authApi}/get-pass-verification-result-admin`,
    FAIL_URL: `${authApi}/fail-url-admin`,//params user id
}
const ADMIN = {
    LOGIN: `${adminApi}/login`,
    UPDATE_ADMIN_INFO: `${adminApi}/update-admin-info`,
    UPDATE_ADMIN_BANK_INFO: `${adminApi}/update-admin-bank-info`,
    CHANGE_PASSWORD: `${adminApi}/change-admin-password`,
    GET_ADMIN: `${adminApi}/get-admin`,
    GET_ALL_USERS: `${adminApi}/get-all-users`,
    DELETE_USERS: `${adminApi}/delete-user`,
    BLOCK_OR_UNBLOCK_USERS: `${adminApi}/block-or-unblock-user`,
    GET_ALL_USER_TICKET: `${adminApi}/get-user-all-membership-tickets`,
    GET_ALL_GOLF_CLUB: `${adminApi}/get-all-golf-club`,
    CREATE_GOLF_CLUB: `${adminApi}/create-golf-club`,
    EDIT_GOLF_CLUB: `${adminApi}/edit-golf-club`,
    DELETE_GOLF_CLUB: `${adminApi}/delete-golf-club`,
    GET_ALL_GOLF_RESERVATION: `${adminApi}/get-all-golf-reservations`,
    EDIT_GOLF_RESERVATION: `${adminApi}/edit-golf-reservations`,
    DELETE_GOLF_RESERVATION: `${adminApi}/delete-golf-reservations`,
    GET_SHARE_RENTAL_AUCTION: `${adminApi}/get-share-rental-auction`,
    EDI_SHARE_RENTAL_AUCTION: `${adminApi}/edit-share-rental-auction`,
    DELETE_SHARE_RENTAL_AUCTION: `${adminApi}/delete-share-rental-auction`,
    GET_SALE_AUCTION: `${adminApi}/get-sale-auction`,
    EDIT_SALE_AUCTION: `${adminApi}/edit-sale-auction`,
    DELETE_SALE_AUCTION: `${adminApi}/delete-sale-auction`,
    GET_GOLF_RES_AUCTION: `${adminApi}/get-golf-res-auction`,
    EDIT_GOLF_RES_AUCTION: `${adminApi}/edit-golf-res-auction`,
    DELETE_GOLF_RES_AUCTION: `${adminApi}/delete-golf-res-auction`,
    GET_ALL_TICKETS: `${adminApi}//get-all-ticket-requests`,
    GET_NOTICES_AND_EVENTS: `${adminApi}/get-notice-and-events`,
    DELETE_EVENT: `${adminApi}/delete-event`,
    DELETE_NOTICE: `${adminApi}/delete-notice`,
    EDIT_EVENT: `${adminApi}/edit-event`,
    EDIT_NOTICE: `${adminApi}/edit-notice`,
    ADD_EVENTS: `${adminApi}/create-event`,
    ADD_NOTICE: `${adminApi}/create-notice`,
    CHANGE_STATUS_EVENT: `${adminApi}/change-event-status`,
    GET_TICKET_SHARING_REQ: `${adminApi}/get-ticket-share-req`,
    EDIT_TICKET_SHARING_REQ: `${adminApi}/edit-ticket-share-req`,
    APPROVE_TICKET_SHARING_REQ: `${adminApi}/approve-ticket-share-req`,
    CANCEL_TICKET_SHARING_REQ: `${adminApi}/cancel-ticket-share-req`,
    DELETE_TICKET_SHARING_REQ: `${adminApi}/delete-ticket-share-req`,
    GET_TICKET_SELL_REQ: `${adminApi}/get-ticket-sale-new`,
    APPROVE_TICKET_SELL_REQ: `${adminApi}/approve-ticket-sale-new`,
    CANCEL_TICKET_SELL_REQ: `${adminApi}/cancel-ticket-sale-new`,
    DELETE_TICKET_SELL_REQ: `${adminApi}/delete-ticket-sale-new`,
    GET_TICKET_SHARE_RENT_REQ: `${adminApi}/get-ticket-sharing-rent-req`,
    APPROVE_TICKET_SHARE_RENT_REQ: `${adminApi}/approve-ticket-sharing-rent-req`,
    CANCEL_TICKET_SHARE_RENT_REQ: `${adminApi}/cancel-ticket-sharing-rent-req`,
    DELETE_TICKET_SHARE_RENT_REQ: `${adminApi}/delete-ticket-sharing-rent-req`,
    GET_GROUP_BUY_REQ: `${adminApi}/get-group-buy-req`,
    DELETE_GROUP_BUY_REQ: `${adminApi}/delete-group-buy-req`,
    APPROVE_GROUP_BUY_REQ: `${adminApi}/approve-group-buy-req`,
    CANCEL_GROUP_BUY_REQ: `${adminApi}/cancel-group-buy-req`,
    CLOSED_GROUP_BUY_REQ: `${adminApi}/closed-group-buy-req`,
    ACCEPT_AUCTION_ADVANCE_FEE: `${adminApi}/accept-auction-advance-fee`,
    DELETE_RESERVATION_REQUEST: `${adminApi}/delete-reservation-request`,
    CANCEL_RESERVATION_REQUEST: `${adminApi}/cancel-reservation-request`,
    CREATE_PAYMENT_REQUEST: `${adminApi}/create-payment-request`,
    GET_ALL_BANNERS: `${adminApi}/get-all-banner`,
    UPLOAD_BANNER: `${adminApi}/upload-banner`,
    DELETE_BANNER: `${adminApi}/delete-banner`,
    EDIT_BANNER: `${adminApi}/edit-banner`,
    GET_ALL_OFFLINE_PAYMENT_HISTORY: `${adminApi}/get-all-offline-payment-history`,
    DELETE_OFFLINE_PAYMENT_HISTORY: `${adminApi}/delete-payment-history`,
    EDIT_OFFLINE_PAYMENT_HISTORY: `${adminApi}/edit-payment-history`,
    CREATE_FAQ: `${adminApi}/create-Faq`,/* POST */
    GET_ALL_FAQS: `${adminApi}/get-all-Faqs`,/* GET  */
    DELETE_FAQ: `${adminApi}/delete-Faq`,/* DELETE  */
    EDIT_FAQ: `${adminApi}/edit-Faq`,/* EDIT  */
    GOLF_CLUB: `${adminApi}/get-all-golf-club`,
    APPROVE_PARTICIPATE_REQUEST: `${adminApi}/approve-participate-request`,
    CANCEL_PARTICIPATE_REQUEST: `${adminApi}/cancel-participate-request`,
    GROUP_BUY_PARTICIPATE_REQUEST: `${adminApi}/get-group-buy-partcipate-request`,
    GET_COMPANY_INFO: `${adminApi}/get-company-intro`,
    DELETE_INTRO: `${adminApi}/delete-company-intro`,
    ADDED_INTO: `${adminApi}/add-company-intro`,
    EDIT_INFO: `${adminApi}/update-company-intro`,
    REMOVE_INTRO_IMAGE: `${adminApi}/delete-company-intro-image`,
    EDIT_TICKET_SHARE_NEW: `${adminApi}/edit-ticket-share`,
    EDIT_TICKET_SALE_NEW: `${adminApi}/edit-ticket-sale`,
    EDIT_TICKET_EXCHANGE: `${adminApi}/edit-exchange`,

}

const POST = {
    GOLF_RESERVATION_REGISTRATION: `${postApi}/golf-reservation-registration`, // params user id
    DELETE_TICKET: `${postApi}/delete-ticket`,
    SINGLE_TICKET_INFO: `${postApi}/get-single-ticket-info`,
    EDIT_TICKET: `${postApi}/edit-ticket`,
    EDIT_GOLF_RESERVATION: `${postApi}/edit-golf-reservation`,
    PASS_VERIFICATION: `${postApi}/pass-verification`,
    CREATE_AUCTION_RENTAL: `${postApi}/create-auction-rental`,
    CREATE_AUCTION_SALE: `${postApi}/create-auction-sale`,
    CREATE_AUCTION_GOLF_RESERVATION: `${postApi}/create-auc-golf-res`,
    ADD_TICKET: `${postApi}/add-ticket`,
    SHARE_TICKET: `${postApi}/share-ticket`,
    SALE_TICKET: `${postApi}/sale-ticket`,
    EDIT_GROUPBUY_TICKET: `${postApi}/admin-edit-groupbuy-ticket`,
    ADD_ADMIN_GROUPBUY_TICKET: `${postApi}/admin-upload-groupbuy-ticket`
}
const GET = {
    GET_TICKTS: `${getApi}/get-tickets`, // params user id
}

export {
    ADMIN, POST, AUTH, GET
}