import { Button, Form, Input, Modal, Upload, Switch } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { ADMIN } from '../../utils/apis'
import { errorMessage, successNotification } from '../../utils/helpers'
import TextArea from 'antd/lib/input/TextArea'

const formLayout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
}

const EditFaq = (props) => {
    const { getAllFaqs, editData, onCancel, language, lanKorCondition, userSelectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [fileList, setFileList] = useState([])
    const [isActive, setIsActive] = useState(editData?.active || false)


    const handleCancel = () => {
        onCancel()
        form.resetFields()
    }
    const onFinishFaq = (values) => {
        // return true
        let obj = {
            _id: editData?._id,
            question: values?.question || editData?.question,
            answer: values?.answer || editData?.answer,
        }
        setLoading(true)
        axios.post(ADMIN?.EDIT_FAQ, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(lanKorCondition ? data.message : data.messageKr)
                    handleCancel()
                    getAllFaqs()
                } else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                setLoading(false)
                errorMessage(language?.OppsError)
            })
    }

    return (
        <Modal footer={null} title={language?.Edit} open={editData?._id} onCancel={handleCancel}>
            <Form
                form={form}
                {...formLayout}
                onFinish={onFinishFaq}
                requiredMark={false}
            >

                <Form.Item
                    name='question'
                    label={lanKorCondition ? 'Question' : '질문'}
                    rules={[
                        {
                            required: true,
                            message: lanKorCondition ? 'Question' : '질문'
                        },
                    ]}
                    initialValue={editData?.question}
                >
                    <Input placeholder={lanKorCondition ? 'Question' : '질문'} />
                </Form.Item>
                <Form.Item
                    name={'answer'}
                    label={lanKorCondition ? 'Answer' : ' 답변'}
                    rules={[
                        {
                            required: true,
                            message: lanKorCondition ? 'Answer' : ' 답변'
                        }
                    ]}
                    initialValue={editData?.answer}
                >
                    <TextArea placeholder={lanKorCondition ? 'Answer' : ' 답변'} autoSize />
                </Form.Item>
                <Button className='req_exc_btn1' htmlType='submit' loading={loading} type='primary'>{userSelectedLanguage?.Edit}</Button>
            </Form>
        </Modal>
    )
}
export default EditFaq