const SHOW_SIDE_MENU_DRAWER = 'SHOW_SIDE_MENU_DRAWER'
const LANGUAGE = 'LANGUAGE'
const REMOVE_USER = 'REMOVE_USER'
const LOGIN_USER = 'LOGIN_USER'
const GET_ALL_AUCTION = 'GET_ALL_AUCTION'
export {
    SHOW_SIDE_MENU_DRAWER,
    LANGUAGE,
    LOGIN_USER,
    REMOVE_USER,
    GET_ALL_AUCTION
}
