import { message, notification } from 'antd'
import allPaths from '../Config/paths'
import { types, typesKr } from './constants'

const requiredMessage = (value) => `Please input your ${value}!`

const inputPlace = (value) => `Input your ${value} Here...!`

const setActiveMenu = (path) =>
  path === allPaths?.MEMBERSHIP || path === allPaths?.MY_MEMBERSHIP
    ? allPaths?.MEMBERSHIP :
    path === allPaths?.GOLFCLUB
      ? allPaths?.GOLFCLUB
      : path === allPaths?.GOLF_AUCTION_POST_MANAGEMENT
        ? allPaths?.GOLF_AUCTION_POST_MANAGEMENT
        : path === allPaths?.GOLF_RESERVATION_POST_MANAGEMENT
          ? allPaths?.GOLF_RESERVATION_POST_MANAGEMENT
          : path === allPaths?.SETTINGS
            ? allPaths?.SETTINGS
            : path === allPaths?.OFFLINE_TRANSACTION || path === allPaths?.OFFLINE_TRANSACTION_VIEW_TRADING
              ? allPaths?.OFFLINE_TRANSACTION
              : path === allPaths?.ONLINE_TRANSACTION || path === allPaths?.AUCTIONBIDDETAIL || path === allPaths?.ONLINE_TRANSACTION_VIEW_TRADING
                ? allPaths?.ONLINE_TRANSACTION
                : path === allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_RENTAL_REQUEST
                  ? allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_RENTAL_REQUEST
                  : path === allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_EXCHANGE_REQUEST
                    ? allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_EXCHANGE_REQUEST
                    : path === allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_MEMBERSHIP_SALES_REQUEST
                      ? allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_MEMBERSHIP_SALES_REQUEST
                      : path === allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_GROUP_BUY_PURCHASE_REQUEST
                        ? allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_GROUP_BUY_PURCHASE_REQUEST
                        : path === allPaths?.EVENT
                          ? allPaths?.EVENT
                          : path === allPaths?.NOTICE
                            ? allPaths?.NOTICE
                            : path === allPaths?.BANNER
                              ? allPaths?.BANNER
                              : path === allPaths?.BANKINFO
                                ? allPaths?.BANKINFO
                                : path === allPaths?.FAQ
                                  ? allPaths?.FAQ
                                  : 0

const setKeyMenu = (path) =>
  path === allPaths?.OFFLINE_TRANSACTION || path === allPaths?.ONLINE_TRANSACTION || path === allPaths?.OFFLINE_TRANSACTION_VIEW_TRADING || path === allPaths?.ONLINE_TRANSACTION_VIEW_TRADING
    ? 1
    : path ===
      allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_RENTAL_REQUEST ||
      path ===
      allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_EXCHANGE_REQUEST ||
      path ===
      allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_MEMBERSHIP_SALES_REQUEST ||
      path ===
      allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_GROUP_BUY_PURCHASE_REQUEST
      ? 2
      : 0

const successMessage = (desc = 'Successfully Complete!') => {
  return message.success(desc)
}

const infoMessage = (desc = 'Successfully Complete!') => {
  return message.info(desc)
}

const errorMessage = (desc = 'Oops Something Went Wrong!') => {
  return message.error(desc)
}

const warningMessage = (desc = 'Warning!') => {
  return message.warning(desc)
}

const successNotification = (message = 'Successfully Complete!') => {
  return notification.success({ message })
}

const errorNotification = (message = 'Oops Something Went Wrong!') => {
  return notification.error({ message })
}

const convertTitle = (val) => val?.charAt(0)?.toUpperCase() + val?.slice(1)

const stringLimiter = (val, limit = 50) =>
  val?.length > limit ? `${val.slice(0, limit)}...` : val

const typeTranslate = (membershipType, lanKorCondition) => {
  return lanKorCondition ? membershipType : typesKr[types?.indexOf(membershipType)]?.typesKr
}

const ticketTranslate = (ticketName, lanKorCondition) => {
  let tempArr = ticketName?.split(',')
  tempArr[1] = typeTranslate(tempArr[1], lanKorCondition)
  return tempArr?.join()
}

function formatTime(time) {
  var hour = parseInt(time.split(':')[0]);
  var minute = time.split(':')[1].split(' ')[0];
  var meridiem = time.split(':')[1].split(' ')[1].toUpperCase();

  if (meridiem === 'AM') {
    return hour + ':' + minute + ' 오전';
  } else if (meridiem === 'PM') {
    return hour + ':' + minute + ' 오후';
  } else {
    return time;
  }
}


export {
  requiredMessage,
  inputPlace,
  setActiveMenu,
  successMessage,
  infoMessage,
  errorMessage,
  warningMessage,
  successNotification,
  errorNotification,
  convertTitle,
  stringLimiter,
  setKeyMenu,
  ticketTranslate,
  formatTime
}
