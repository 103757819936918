import React, { useEffect, useRef, useState, } from 'react'
import LOGO from '../../assets/images/logoVM.png'
import { Button, Input, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { RiSoundModuleFill, RiChat3Line } from 'react-icons/ri'

const Header = (props) => {
    const { filter } = props
    const { Search } = Input
    const [tags, setTags] = useState(['Tag', 'hello', 'search', 'korea', 'Eng',])
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [editInputIndex, setEditInputIndex] = useState(-1)
    const [editInputValue, setEditInputValue] = useState('')
    const [ticketnameFilter, setTicketnameFilter] = useState('')

    const inputRef = useRef(null)
    const editInputRef = useRef(null)
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus()
        }
    }, [inputVisible])
    useEffect(() => {
        editInputRef.current?.focus()
    }, [inputValue])

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag)
        setTags(newTags)
    }

    const showInput = () => {
        setInputVisible(true)
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
        setTicketnameFilter(e.target.value)
    }

    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue])
        }

        setInputVisible(false)
        setInputValue('')
    }

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value)
    }

    const handleEditInputConfirm = () => {
        const newTags = [...tags]
        newTags[editInputIndex] = editInputValue
        setTags(newTags)
        setEditInputIndex(-1)
        setInputValue('')
    }

   
    return (
        <>
            <div style={{ height: filter ? '230px' : '120px' }} className='nav_bar relative'>
                <div className='container_nav'>
                    <div className='layout_nav'>
                        <div className='col_nav col_main_nav'>
                            <img src={LOGO} className='logo' />
                            <h4 style={{ color: 'white' }}>VALUEMARKET</h4>
                        </div>
                    </div>
                </div>
                {filter ?
                    <div className='FiltersSearchDiv'>
                        <div className='ticket_share_cont' style={{ backgroundColor: 'transparent', marginTop: 15 }}>
                            <div className='search_filter_width'>
                                <div className='search_div'>
                                    <Search
                                        bordered={false}
                                        placeholder={'PleaseEnterSearchTerm'}

                                        onChange={handleInputChange}
                                        onBlur={() => { handleInputConfirm() }}
                                        onPressEnter={() => { handleInputConfirm() }}
                                        // onChange={(e) => }
                                        className='search_width'
                                        style={{
                                            backgroundColor: 'white',
                                            borderRadius: 2
                                        }}
                                    />
                                </div>
                                <div className='tags_filter1'>
                                    <span className='fltrDiv' style={{ fontWeight: 'bold' }}>
                                        <RiSoundModuleFill className='filter_icon' />
                                        {'Filter'}
                                    </span>
                                    <span className='tags_width scroll_class'>
                                        {tags?.map((tag, index) => {
                                            if (editInputIndex === index) {
                                                return (
                                                    <Input
                                                        ref={editInputRef}
                                                        key={tag}
                                                        size='small'
                                                        className='tag-input'
                                                        value={editInputValue}
                                                        onChange={handleEditInputChange}
                                                        onBlur={handleEditInputConfirm}
                                                        onPressEnter={handleEditInputConfirm}
                                                    />
                                                )
                                            }
                                            const isLongTag = tag?.length > 20
                                            const tagElem = (
                                                <Tag
                                                    className='edit-tag'
                                                    key={tag}
                                                    closable={index !== 0}
                                                    onClose={() => handleClose(tag)}
                                                >
                                                    <span
                                                        onDoubleClick={(e) => {
                                                            if (index !== 0) {
                                                                setEditInputIndex(index)
                                                                setEditInputValue(tag)
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                    >
                                                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                                    </span>
                                                </Tag>
                                            )
                                            return isLongTag ? (
                                                <Tooltip title={tag} key={tag}>
                                                    {tagElem}
                                                </Tooltip>
                                            ) : (
                                                tagElem
                                            )
                                        })}
                                        {inputVisible && (
                                            <Input
                                                ref={inputRef}
                                                type='text'
                                                size='small'
                                                className='tag-input'
                                                value={inputValue}
                                                onChange={handleInputChange}
                                                onBlur={handleInputConfirm}
                                                onPressEnter={handleInputConfirm}
                                            />
                                        )}
                                        {/* {!inputVisible && (
                                            <Tag className='site-tag-plus' onClick={showInput}>
                                                <PlusOutlined /> {'NewTag'}
                                            </Tag>
                                        )} */}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> : null}


            </div>
        </>

    )
}

export default Header