import React, { useState, useEffect } from 'react'
import { Button, Popconfirm, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { ButtonLogout } from '../../Components'
import { Table, Divider, Tooltip } from 'antd'
import { FiSearch } from 'react-icons/fi'
import { allPaths, types, typesKr } from '../../utils/constants'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { ADMIN } from '../../utils/apis'
import { convertTitle, errorMessage, stringLimiter, successMessage, successNotification } from '../../utils/helpers'
import moment from 'moment-timezone'

const { Option } = Select
const OfflineTransactionManagement = (props) => {
  const userSelectedLanguage = props?.language
  const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const lanKorCondition = SelectedLanguage === 'english'
  const history = props?.history
  const user = props?.user
  const [dropDown, setdropDown] = useState(6 + "개 보기")
  const [tableRow, setTableRow] = useState(6)
  const [dataSource, setDataSource] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [search, setSearch] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState({})

  useEffect(() => {
    getAllSettlement()
  }, [])
  
  useEffect(() => {
    if (search) {
      let filterData = offlineTransactionData?.filter((v) => v?.NameIDphoneOfUploader?.[0]?.includes(search) || v?.NameIDphoneOfUploader?.[1]?.includes(search) || v?.NameIDphoneOfUploader?.[2]?.includes(search)) || []
      setFilteredData(filterData)
    }else{
      setFilteredData(offlineTransactionData)
    }
  }, [dataSource,search])

  const getAllSettlement = () => {
    setLoading(true)
    axios.get(`${ADMIN?.GET_ALL_OFFLINE_PAYMENT_HISTORY}/${user?._id}`)
      .then((res) => {
        const { data } = res
        if (data?.success) {
          setDataSource(data?.data)
        }
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log('err', err)
      })
  }

  const deleteHistory = (_id) => {
    let obj = {
      adminId: user?._id,
      _id: _id
    }
    setLoadingDelete({ [_id]: true })
    axios.post(`${ADMIN?.DELETE_OFFLINE_PAYMENT_HISTORY}`, obj)
      .then((res) => {
        const { data } = res
        if (data?.success) {
          successMessage(lanKorCondition ? data?.message : data?.messageKr)
          getAllSettlement()
        }
        setLoadingDelete({})
      }).catch((err) => {
        errorMessage(language?.OppsError)
        setLoadingDelete({})
        console.log('err', err)
      })
  }



  let arr = [
    {
      value: 10, Viewby: '10' + '개 보기'
    },
    {
      value: 8, Viewby: ' 8' + '개 보기'
    },
    {
      value: 6, Viewby: ' 6' + '개 보기'
    }
  ]


  let offlineTransactionData = dataSource?.length && dataSource?.map((v, i) => {
    return {
      MembershipNumber: v?._id?.slice(-7),
      NameIDphoneOfUploader: !v?.userId?._id ? [`${user?.userName}-${lanKorCondition ? 'admin' : '어드민'}`, user?._id,] : [v?.userId?.fullName, v?.userId?._id, v?.userId?.telNo],
      paymentMethod: v?.transactionType,
      amount: v?.amount,
      TicketType: v,
      MembershipType: v,
      Action: v,
      ViewInfo: v,
    }
  })



  const columns = [
    {
      title: userSelectedLanguage?.MembershipNumber,
      dataIndex: 'MembershipNumber',
      key: 'Membership Number',
      render: (text) => <Tooltip placement='top' title={convertTitle(text || 'null')} color={'#5EA29F'}>
        <Button className='tableBtn'>{stringLimiter(convertTitle(text || 'null'), 12)}</Button>
      </Tooltip>
    },
    {
      title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
      dataIndex: 'NameIDphoneOfUploader',
      key: 'Name and Phone Number',
      render: (text) => (
        console.log('text',text),
        <div className='namePhoneAndIDiv'>
          {text?.map((v, i) => {
            return (
              <Tooltip placement='top' title={convertTitle(v || '----')} color={'#5EA29F'}>
                <p className='namePhoneAndID'>{stringLimiter(convertTitle(v || '----'), 12)}</p>
              </Tooltip>
            )
          })}
        </div>
      )
    },
    {
      title: userSelectedLanguage?.MembershipType,
      dataIndex: 'MembershipType',
      render: (e) => (
        <p className='tableColoumWidthBig'>
          {
            lanKorCondition ?
              e?.exchangeRequestId?.userTicketId?.membershipType ||
              e?.rentRequestId?.userTicketId?.membershipType ||
              e?.ticketShareId?.ticketId?.membershipType ||
              e?.ticketSaleId?.ticketId?.membershipType ||
              e?.groupBuyId?.ticketId?.membershipType ||
              e?.groupBuyParticipateId?.groupBuyId?.membershipType ||
              e?.auctionRentalBidId?.ticketId?.membershipType ||
              e?.auctionSaleBidId?.ticketId?.membershipType ||
              e?.auctionGolfResBidId?.ticketId?.membershipType ||
              e?.requestBuyId?.userTicketId?.membershipType ||
              '----' : typesKr[types?.indexOf(
                e?.exchangeRequestId?.userTicketId?.membershipType ||
                e?.rentRequestId?.userTicketId?.membershipType ||
                e?.ticketShareId?.ticketId?.membershipType ||
                e?.ticketSaleId?.ticketId?.membershipType ||
                e?.groupBuyId?.ticketId?.membershipType ||
                e?.groupBuyParticipateId?.groupBuyId?.membershipType ||
                e?.auctionRentalBidId?.ticketId?.membershipType ||
                e?.auctionSaleBidId?.ticketId?.membershipType ||
                e?.auctionGolfResBidId?.ticketId?.membershipType ||
                e?.requestBuyId?.userTicketId?.membershipType ||
                '----')]?.typesKr || '----'
          }
        </p>
      ),
    },
    {
      title: lanKorCondition ? 'payment Method' : '지불 방법',
      dataIndex: 'paymentMethod',
      render: (e) => (
        <p className='tableColoumWidthBig'>
          {
            e === 'Cash' ? lanKorCondition ? 'Cash' : '현금' :
              e === 'Bank Wire' ? lanKorCondition ? 'Bank Wire' : '계좌이체' :
                e === 'ETC' ? lanKorCondition ? 'ETC' : '기타' : '---'
          }
        </p>
      ),
    },
    {
      title: lanKorCondition ? 'TicketType' : '거래 유형',
      dataIndex: 'TicketType',
      key: 'Ticket Type',
      render: (text) => (
        <div style={{ width: '200px' }}>
          {text?.ticketShareId?._id ? (
            <button className='cancelAuctionTableBtn'>{lanKorCondition ? 'Ticket Share' : '회원권 공유 대여'}</button>
          ) : text?.exchangeRequestId?._id ? (
            <button className='soldOutAuctionTableBtn'>{lanKorCondition ? 'Ticket Exchange' : '회원권 공유 교환'}</button>)
            : text?.rentRequestId?._id ? (
              <button className='soldOutAuctionTableBtn'>{lanKorCondition ? 'Ticket Rent' : '멤버십 대여 요청'}</button>) :
              text?.groupBuyParticipateId?._id ? (
                <button className='soldOutAuctionTableBtn'>{lanKorCondition ? 'Ticket Participate' : '공동구매 참여'}</button>
              ) : text?.groupBuyId?._id ? (
                <button className='soldOutAuctionTableBtn'>{lanKorCondition ? 'Ticket Group Buy' : '공동구매'}</button>
              ) : text?.ticketSaleId?._id ? (
                <button className='postingAuctionTableBtn'>{lanKorCondition ? 'Ticket Sale' : '회원권 매매'}</button>
              ) : text?.auctionRentalBidId?.paymentId?._id ? ( /* ok */
                <button className='postingAuctionTableBtn'>{lanKorCondition ? 'Auction Rental Bid' : '공유대여 경매'}</button>
              ) : text?.auctionSaleBidId?.paymentId?._id ? (
                <button className='postingAuctionTableBtn'>{lanKorCondition ? 'Auction Sale Bid' : '매매 경매'}</button>
              ) : text?.auctionGolfResBidId?.paymentId?._id ? (
                <button className='soldOutAuctionTableBtn'>{lanKorCondition ? 'Golf Res Auction' : '골프 예약 경매'}</button>
              ) : text?.golfReservationId?.paymentId?._id ? ( /* ok */
                <button className='GolfreservationTableBtn'>{lanKorCondition ? 'Golf Reservation' : '골프예약'}</button>
              ) : text?.requestBuyId?.paymentId?._id ? (
                <button className='cancelAuctionTableBtn'>{lanKorCondition ? 'Ticket Sale Request' : '회원권 구매 요청'}</button>
              ) : '---'}
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.Fee,
      dataIndex: 'amount',
      key: 'Fee',
      render: (text) => (
        <div>
          <p className='tableColoumWidthBig'>{text ? text + ' ' + '만원' : '----'}</p>
        </div>
      ),
    },

    {
      title: userSelectedLanguage?.Status,
      key: 'Status',
      render: (text) => (
        <div>
          <p className='tableColoumWidthBig'>{lanKorCondition ? 'Completed' : '저장'}</p>
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.ViewInfo,
      dataIndex: 'ViewInfo',
      key: 'View Membership',
      render: (text) => (
        <>
          <div className='tableColoumWidthBig'>
            <div className='searchIconDiv' onClick={() => {
              history.push({ pathname: allPaths?.OFFLINE_TRANSACTION_VIEW_TRADING, state: [text] })
            }}>
              <FiSearch />
            </div>
          </div>
        </>
      ),
    },

    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        <div className='tableBtnsDiv'>
          {text ? (
            <>
              {/* <Button className='editBtn' type="primary">{userSelectedLanguage?.Edit}</Button> */}
              <Popconfirm
                title={lanKorCondition ? 'you really want to delete this item?' : '이 거래내용을 삭제하시겠습니까'}
                onConfirm={() => deleteHistory(text?._id)}
                okText={userSelectedLanguage?.Yes}
                cancelText={userSelectedLanguage?.No}
              >
                <Button loading={loadingDelete[text?._id]} className='DeleteBtn' type="primary">{userSelectedLanguage?.Delete}</Button>
              </Popconfirm>
            </>
          ) : null}
        </div>
      ),
    },
  ]

  console.log('dataSource', dataSource)

  return (
    <>
      <ButtonLogout props={props} />
      <div className='membershipBox'>
        <div className='goldReservationHeader'>
          <div className='golfHeaderText'>
            <h3>{userSelectedLanguage?.SettlementManagement}</h3>
            <p>{'>'} {userSelectedLanguage?.OfflineTransactionManagement}</p>
          </div>
          <div className='headersearch'>
            <Select
              className='dropdown'
              defaultValue={dropDown}
              onChange={(e) => {
                setTableRow(e)
              }}
            >
              {arr?.map((v, i) => {
                return <Option key={v?.value}>{v?.Viewby}</Option>
              })}
            </Select>
            <Input
              className='searchgolfReservation'
              onChange={(e) => setSearch(e?.target?.value)}
              placeholder={userSelectedLanguage?.PleaseSearchATerm}
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
        <div className='tableDivManagement'>
          <Divider />
          <Table
            pagination={{ pageSize: tableRow }}
            dataSource={filteredData}
            columns={columns}
            loading={loading}
            scroll={{ x: 700 }}
            size='middle'
          />
        </div>
      </div>
    </>
  )
}
export default OfflineTransactionManagement
