import React, { useState, useEffect } from 'react'
import { Button, Image, Select } from 'antd'
import { ButtonLogout } from '../../Components'
import { Table, Divider, Tooltip, Modal, Form, Input, Upload, Switch } from 'antd'
import moment from 'moment-timezone'
import axios from 'axios'
import { ADMIN } from '../../utils/apis'
import { requiredMessage, errorMessage, stringLimiter, errorNotification, successNotification, convertTitle, warningMessage } from '../../utils/helpers'
import EditEvents from './EditEvents'
// import EditBanners from './EditBanner' 
import { useSelector } from 'react-redux'
import { allPaths } from '../../utils/constants'
import { UploadOutlined } from '@ant-design/icons'
import EditBanners from './EditBanner'

const formLayout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const { Option } = Select
const Banner = (props) => {
    const { user, language } = props
    const userSelectedLanguage = props?.language
    const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
    const lanKorCondition = SelectedLanguage === 'english'
    const history = props?.history
    const [loading, setLoading] = useState(false)
    const [banner, setBanner] = useState([])
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [editNoticeData, setEditNoticeData] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isActive, setIsActive] = useState(true)
    const [fileList, setFileList] = useState([])

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleCancel = () => {
        setEditNoticeData({})
        form.setFieldValue({
            title: null,
            content: null,
        })
        setIsModalOpen(false)
    }
    useEffect(() => {
        getEventsAndNotices()
    }, [editNoticeData])


    const deleteBanners = (id) => {
        setDeleteLoader(true)
        let obj = {
            _id: id, adminId: user?._id
        }
        axios.post(ADMIN?.DELETE_BANNER, obj)
            .then((res) => {
                const { data } = res
                setDeleteLoader(false)
                if (data.success) {
                    successNotification(lanKorCondition ? data?.message : data?.messageKr)
                    getEventsAndNotices()
                } else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                setDeleteLoader(false)
                errorMessage(userSelectedLanguage?.OppsError)
            })
    }

    const getEventsAndNotices = () => {
        setLoading(true)
        axios.get(`${ADMIN?.GET_ALL_BANNERS}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setBanner(data?.data)
                }
                else {
                    console.log('running')
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
            })
    }


    const columnsNotice = [
        {
            title: userSelectedLanguage?.Content,
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (text) => (
                <div>
                    {/* <a className='tableEmail'>{text ? text : '----'}</a> */}
                    <Image src={text} />
                </div>
            ),
        },
        {
            title: userSelectedLanguage?.Title,
            dataIndex: 'fileName',
            key: 'fileName',
            render: (fileName) => (
                <div>
                    <a className='tableEmail'>{fileName ? fileName : '----'}</a>
                </div>
            ),
        },
        {
            title: userSelectedLanguage?.Active,
            dataIndex: 'active',
            key: 'active',
            render: (active) => (
                <div>
                    <a className='tableEmail'>{active ? 'True' : 'False'}</a>
                </div>
            ),
        },
        {
            title: lanKorCondition ? 'Date' : '업로드 날짜',
            dataIndex: 'created',
            key: 'created',
            render: (text) => (
                <>
                    <div className='tableColoumWidthBig'>
                        {text ? moment(text).utc().format('YYYY-MM-DD') : '----'}
                    </div>
                </>
            ),
        },
        {
            title: lanKorCondition ? 'Upload File' : '파일 업로드',
            key: 'action',
            render: (text) => (
                <div className='tableBtnsDiv'>
                    <>
                        <Button onClick={() => setEditNoticeData(text)} className='btnTableEdit'> {userSelectedLanguage?.Edit} </Button>
                        <Button loading={deleteLoader} onClick={() => deleteBanners(text?._id)} className='DeleteBtn'> {userSelectedLanguage?.Delete} </Button>
                    </>
                </div>
            ),
        },
    ]

    const [form] = Form.useForm()
    const onFinishNotice = (values) => {
        if (!values?.file) {
            return warningMessage(lanKorCondition ? 'Please Upload Image' : '파일을 업로드 해주세요')
        }
        let formData = new FormData()
        formData.append('adminId', user?._id)
        formData.append('file', values?.file?.file)
        formData.append('active', isActive)

        setLoading(true)
        axios.post(ADMIN?.UPLOAD_BANNER, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    handleCancel()
                    form.resetFields()
                    successNotification(lanKorCondition ? data?.message : data?.messageKr)
                    getEventsAndNotices()
                } else {
                    // handleCancel()
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                setLoading(false)
                errorMessage(userSelectedLanguage?.OppsError)
            })
    }
    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(lanKorCondition ? 'You can only upload Images!' : '이미지만 업로드 해주세요')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }
    return (
        <>
            <ButtonLogout props={props} />
            <div className='membershipBox'>
                <div className='goldReservationHeader'>

                    <div className='golfHeaderText'>
                        <h3>
                            <span
                                style={{ color: 'black', cursor: 'pointer' }}
                            // onClick={() => { setEventTable(false); setNoticeTable(true) }}
                            >{userSelectedLanguage?.Events === 'Event' ? 'Banner' : '배너관리'}</span>
                            /
                            <span
                                style={{ color: '#999999', cursor: 'pointer' }}
                                onClick={() => { history.push(allPaths?.EVENT) }}
                            >{userSelectedLanguage?.Events}
                            </span >
                            /
                            <span
                                style={{ color: '#999999', cursor: 'pointer' }}
                                onClick={() => { history.push(allPaths?.NOTICE) }}
                            >{userSelectedLanguage?.Notice}
                            </span >
                        </h3 >
                    </div>

                </div>

                < div style={{ display: 'flex', justifyContent: 'end', marginRight: '10px' }}>
                    <div className='ButtonDiv'>
                        <Button onClick={showModal} className='AddClubCardBtn' htmlType='submit'>{lanKorCondition ? 'Create Banner' : ' 배너추가'}</Button>
                    </div>
                </div>

                <div className='tableDivManagement'>
                    <Divider />
                    <Table
                        loading={loading}
                        pagination={{ pageSize: 6 }}
                        dataSource={banner}
                        columns={columnsNotice}
                        scroll={{ x: 700 }}
                        size='middle'
                    />

                    <Modal footer={null} title={userSelectedLanguage?.CreateBanner} open={isModalOpen} onCancel={handleCancel}>
                        <Form
                            form={form}
                            {...formLayout}
                            onFinish={onFinishNotice}
                            requiredMark={false}
                        >
                            <Form.Item
                                label={language?.File}
                                name='file'
                                rules={[{ required: true, message: 'Please input  File!' }]}
                            >
                                <Upload
                                    name='file'
                                    multiple={false}
                                    beforeUpload={() => false}
                                    accept='image/png, image/jpeg'
                                    onChange={normFile}
                                    fileList={fileList}
                                >
                                    <Button className='GolfClubInputFeild BtnUpload' icon={<UploadOutlined color='#5EA29F' />}>Upload</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                label={language?.activeBanner}
                                name='active'>
                                <Switch onChange={(e) => setIsActive(e)} checkedChildren={lanKorCondition ? 'activated ' : '활성'} unCheckedChildren={lanKorCondition ? 'unactivated ' : '비활성 '} defaultChecked />
                            </Form.Item>
                            <Button className='req_exc_btn1' htmlType='submit' loading={loading} type='primary'>{lanKorCondition ? 'Add' : '추가'}</Button>
                        </Form>
                    </Modal>

                    {editNoticeData?._id ?
                        <EditBanners editData={editNoticeData} onCancel={() => setEditNoticeData({})} lanKorCondition={lanKorCondition}{...props} />
                        : null
                    }
                </div>
            </div>
        </>
    )
}
export default Banner
