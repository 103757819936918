import axios from 'axios'
import React, { useState } from 'react'
import { ButtonLogout } from '../../Components'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { requiredMessage, errorMessage, errorNotification, successNotification } from '../../utils/helpers'
import { allPaths } from '../../utils/constants'
import { ADMIN } from '../../utils/apis'
import { loginUser } from '../../Redux/actions/authActions'

const Setting = (props) => {
  const { user } = props
  const dispatch = useDispatch()
  const userSelectedLanguage = props?.language
  const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const lanKorCondition = SelectedLanguage === 'english'
  let [email, setEmail] = useState(user?.email)
  let [telNo, setTelNo] = useState(user?.telNo)
  let [userName, setUserName] = useState(user?.userName)
  let [oldPassword, setOldPassword] = useState(null)
  let [newPassword, setNewPassword] = useState(null)
  let [confirmPassword, setConfirmPassword] = useState(null)
  let [loading, setLoading] = useState(false)
  let [loading2, setLoading2] = useState(false)


  const updateAdmininfo = () => {
    setLoading(true)
    if (email || telNo || userName) {
      let obj = {
        email, telNo, userName, _id: user?._id
      }
      axios.post(ADMIN?.UPDATE_ADMIN_INFO, obj)
        .then((res) => {
          const { data } = res
          setLoading(false)
          if (data.success) {
            successNotification(lanKorCondition ? data.message : data.messageKr)
            dispatch(loginUser(data?.admin))
          } else {
            errorMessage(lanKorCondition ? data.message : data.messageKr)
          }
        })
        .catch((e) => {
          console.log('e====>', e)
          setLoading(false)
          errorMessage(userSelectedLanguage?.OppsError)
        })
    }

  }

  const changeAdminPassword = () => {
    setLoading2(true)

    if (!oldPassword || !confirmPassword || !newPassword) {
      errorMessage(lanKorCondition ? 'Please provide All fields!' : '모든 항목을 작성해주세요!')
    }
    let obj = { oldPassword, newPassword, confirmPassword, _id: user?._id }
    axios.post(ADMIN?.CHANGE_PASSWORD, obj)
      .then((res) => {
        const { data } = res
        setLoading2(false)
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          // dispatch(loginUser(data?.admin))
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setLoading2(false)
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }

  return (
    <>
      <ButtonLogout props={props} />
      <div className='settingDiv'>
        {/* Name Email ID etc */}
        <div className='nameEmailDiv'>
          <div className='divHeader'>
            <h3 className='headerText'>{userSelectedLanguage?.Setting}</h3>
            <div className='borderBottom'></div>
          </div>
          <div className='textFeildsDiv'>
            <div className='textFeildDiv'>
              <h5>{userSelectedLanguage?.Name}</h5>
              <input onChange={(e) => setUserName(e?.target?.value)} value={userName} className='inputFeild' />
            </div>
            <div className='textFeildDiv'>
              <h5>{userSelectedLanguage?.Email}</h5>
              <input onChange={(e) => setEmail(e?.target?.value)} value={email} className='inputFeild' />
            </div>
          </div>
          <div className='textFeildsDiv'>
            <div className='textFeildDiv'>
              <h5>{userSelectedLanguage?.PhoneNumber}</h5>
              <input onChange={(e) => setTelNo(e?.target?.value)} value={telNo} className='inputFeild' />
            </div>
            <div className='textFeildDiv'>
              <h5>{/* {userSelectedLanguage?.ID} */}</h5>
              {/* <input value={user?._id} disabled={true} className='inputFeild' /> */}
            </div>
          </div>
          <div className='saveButtonDiv'>
            <Button loading={loading} onClick={updateAdmininfo} className='saveBtn'>{userSelectedLanguage?.Save}</Button>
          </div>
        </div>
        {/* Password Confirm Password etc */}

        <div className='nameEmailDiv'>
          <div className='divHeader'>
            <h3 className='headerText'>{userSelectedLanguage?.ChangePassword}</h3>
            <div className='borderBottom'></div>
          </div>
          <div className='textFeildsDiv'>
            <div className='textFeildDiv'>
              <h5>{userSelectedLanguage?.CurrentPassword}</h5>
              <input onChange={(e) => setOldPassword(e.target?.value)} className='inputFeild' />
            </div>
            <div className='textFeildDiv'>
              <h5>{userSelectedLanguage?.NewPassword}</h5>
              <input onChange={(e) => setNewPassword(e.target?.value)} className='inputFeild' />
            </div>
          </div>
          <div className='textFeildsDiv'>
            <div className='textFeildDiv'>
            </div>
            <div className='textFeildDiv'>
              <h5>{userSelectedLanguage?.ConfirmNewPassword}</h5>
              <input onChange={(e) => setConfirmPassword(e.target?.value)} className='inputFeild' />
            </div>
          </div>
          <div className='saveButtonDiv'>
            <Button loading={loading2} onClick={changeAdminPassword} className='saveBtn'>{userSelectedLanguage?.Save}</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Setting
