
const LOGIN = '/'
const MEMBERSHIP = '/membership'
const MY_MEMBERSHIP = '/my-membership'
const ADMIN_MEMBERSHIP = '/admin-membership'
const OFFLINE_TRANSACTION = '/offline-transaction'
const OFFLINE_TRANSACTION_VIEW_TRADING = '/offline-transaction-view-trading'
const ONLINE_TRANSACTION = '/online-transaction'
const ONLINE_TRANSACTION_VIEW_TRADING = '/online-transaction-view-trading'
const MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_RENTAL_REQUEST = '/membership-transaction-management-shared-rental-request'
const MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_EXCHANGE_REQUEST = '/membership-transaction-management-shared-exchange-request'
const MEMBERSHIP_TRANSACTION_MANAGEMENT_MEMBERSHIP_SALES_REQUEST = '/membership-transaction-management-membership-sales-request'
const MEMBERSHIP_TRANSACTION_MANAGEMENT_GROUP_BUY_PURCHASE_REQUEST = '/membership-transaction-management-group-buy-purchase-request'
const GOLF_RESERVATION_POST_MANAGEMENT = '/golf-reservation-post-management'
const GOLF_AUCTION_POST_MANAGEMENT = '/golf-auction-post-menegement'
const SETTINGS = '/settings'
const PARTICIPANTSDETAILS = '/participants-details'
const AUCTIONBIDDETAIL = '/auction-bid-details'
const GROUP_BUY_DETAIL = '/group-buy-details'
const GOLFBIDDETAIL = '/golf-bid-details'
const GOLFCLUB = '/golf-club'
const EVENT = '/event'
const NOTICE = '/notice'
const BANNER = '/banner'
const BOARDMANAGEMENT = '/board-menegement'
const BANKINFO = '/bank-info'
const TICKETSINFO = '/tickets-info'
const TICKETSVIEWINFO = '/tickets-view-info'
const FAQ = '/faq'
const COMPANY_INTRO = '/company-intro'

export default {
    GROUP_BUY_DETAIL,
    MEMBERSHIP,
    MY_MEMBERSHIP,
    OFFLINE_TRANSACTION,
    OFFLINE_TRANSACTION_VIEW_TRADING,
    ONLINE_TRANSACTION,
    ONLINE_TRANSACTION_VIEW_TRADING,
    MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_RENTAL_REQUEST,
    MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_EXCHANGE_REQUEST,
    MEMBERSHIP_TRANSACTION_MANAGEMENT_MEMBERSHIP_SALES_REQUEST,
    MEMBERSHIP_TRANSACTION_MANAGEMENT_GROUP_BUY_PURCHASE_REQUEST,
    GOLF_RESERVATION_POST_MANAGEMENT,
    GOLF_AUCTION_POST_MANAGEMENT,
    SETTINGS,
    LOGIN,
    PARTICIPANTSDETAILS,
    GOLFCLUB,
    EVENT,
    NOTICE,
    BOARDMANAGEMENT,
    AUCTIONBIDDETAIL,
    BANKINFO,
    TICKETSINFO,
    GOLFBIDDETAIL,
    BANNER,
    TICKETSVIEWINFO,
    FAQ,
    COMPANY_INTRO,
    ADMIN_MEMBERSHIP
}