import React, { useState } from 'react'
import { DatePicker } from 'antd'
import { ConfigProvider } from 'antd'
import deDE from 'antd/lib/locale/ko_KR'
import moment from 'moment'
import 'antd/dist/antd.min.css'


const DatePickerTicketShare = ({ showTime, setDate, format, placeholder,defaultValue }) => {
    // if (value === 'de') {
    moment.locale('de', {
        months: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
        monthsShort: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
        monthsParseExact: true,
        weekdays: '일_월_화_수_목_금_토_일'.split('_'),
        weekdaysShort: '일._월._화._수._목._금._토._일.'.split('_'),
        weekdaysMin: '일_월_화_수_목_금_토_일'.split('_'),
        weekdaysParseExact: true,
        


        longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD/MM/YYYY',
            LL: 'D MMMM YYYY',
            LLL: 'D MMMM YYYY HH:mm',
            LLLL: 'dddd D MMMM YYYY HH:mm'
        },
        calendar: {
            sameDay: '[Aujourd’hui à] LT',
            nextDay: '[Demain à] LT',
            nextWeek: 'dddd [à] LT',
            lastDay: '[Hier à] LT',
            lastWeek: 'dddd [dernier à] LT',
            sameElse: 'L',
        },
        relativeTime: {
            future: 'dans %s',
            past: 'il y a %s',
            s: 'quelques secondes',
            m: 'une minute',
            mm: '%d minutes',
            h: 'une heure',
            hh: '%d heures',
            d: 'un jour',
            dd: '%d jours',
            M: 'un mois',
            MM: '%d mois',
            y: 'un an',
            yy: '%d ans'
        },

        dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
        ordinal: function (number) {
            return number + (number === 1 ? 'er' : 'e')
        },
        meridiemParse: /PD|MD/,
        isPM: function (input) {
            return input.charAt(0) === 'M'
        },
        // In case the meridiem units are not separated around 12, then implement
        // this function (look at locale/id.js for an example).
        // meridiemHour : function (hour, meridiem) {
        //     return /* 0-23 hour, given meridiem token and hour 1-12 */ 
        // },
        meridiem: function (hours, minutes, isLower) {
            return hours < 12 ? '오전' : '오후'
        },
        week: {
            dow: 1, // Monday is the first day of the week.
            doy: 4  // Used to determine first week of the year.
        }
    })
    // }

    return (
        <div>
            <ConfigProvider locale={deDE} >
                <DatePicker
                    style={{ width: '100%' }}
                    showTime={showTime}
                    format={format}
                    allowClear={false}
                    defaultValue={defaultValue ?moment(defaultValue): null}
                    onChange={e => setDate(e)}
                    className='datepicker'
                    placeholder={placeholder ? placeholder : ''}
                    // placeholder={true}
                />
            </ConfigProvider>
        </div>
    )
}
export default DatePickerTicketShare