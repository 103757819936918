import { MinusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Form, Image, Input, Modal, Select, Space, Typography, Upload } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { loginUser } from '../../Redux/actions/authActions';
import { ADMIN, POST } from '../../utils/apis';
import { errorMessage, requiredMessage, successMessage } from '../../utils/helpers';
const { Text } = Typography;

const { RangePicker } = DatePicker

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
        md: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
        md: {
            span: 18,
        },
    },
}

const dateFormat = 'YYYY/MM/DD';
const types = [
    {
        kr: "개인회원",
        eng: "Individual member"

    },
    {
        kr: "가족회원",
        eng: "Family member"
    },
    {
        kr: "개인주중",
        eng: "Individual weekday"
    },
    {
        kr: "가족주중",
        eng: "Family weekday"
    },
    {
        kr: "법인회원",
        eng: "Company"
    },
]
const config = {
    rules: [
        {
            type: 'object',
            // required: true,
            message: 'Please select date!',
        },
    ],
}
const RevisionHoldingModal = (props) => {
    const { editModal, setEditModal, editData, allData, language, lanKorCondition, getMemberTickets } = props
    const [memberFee, setMemberFee] = useState(2)
    const [rightPrice, setRightPrice] = useState('')
    const [typess, setType] = useState('')
    const [nonMemberFee, setNonMemberFee] = useState(editData?.NONmemberFee)
    const [joiningFee, setjoiningFee] = useState('')
    const [loading, setLoading] = useState(false)
    const [ticketInfo, setTicketInfo] = useState([])
    const [golfClubData, setGolfClubData] = useState(null)
    const [golfClub, setGolfClubName] = useState(null)
    const [fileList, setFileList] = useState([])
    const [weekdayFee, setWeekdayFee] = useState(null)
    const [weekday, setWeekday] = useState(null)
    const [weekendFee, setWeekendFee] = useState(null)
    const [weekend, setWeekend] = useState(null)
    const [greenFeeWeekday, setGreenFeeWeekday] = useState(null)
    const [greenFeeWeekend, setGreenFeeWeekend] = useState(null)
    const [caddyFee, setCaddyFee] = useState(null)
    const [cartFee, setcartFee] = useState(null)
    const [phoneValue, setPhoneValue] = useState('')
    const [homepage, setHomepage] = useState('')
    const [membershipType, setMembershipType] = useState('')

    const [form] = Form.useForm();
    const rangeConfig = {
        rules: [
            {
                type: 'array',
                required: true,
                message: language?.Pleaseselecttime
            },
        ],
    }
    const handlePhoneInput = (e) => {
        let value = e?.target?.value?.replace(/[^0-9-]/g, '')
        let event = e?.nativeEvent?.data
        if (event && ((value?.length === 3 || value?.length === 8) || (phoneValue?.length === 3 || phoneValue?.length === 8))) {
            if (phoneValue?.length === 3 || phoneValue?.length === 9) {
                value = `${phoneValue}-${event}`
            }
            else {
                value = `${value}-`
            }
        }
        setPhoneValue(value)
    }


    const onChanges = (value) => {

        let new_value = golfClub?.find((v) => v?._id === value)
        setGolfClubData(new_value)

    }

    // console.log('golfClubData', golfClubData)
    // console.log('allData', allData)

    let golfClubName = () => {
        axios.get(ADMIN.GOLF_CLUB)
            .then((res) => {

                const { data } = res
                if (data.success) {
                    setGolfClubName(data?.data)
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
            })
    }

    useEffect(() => {
        golfClubName()
    }, [ticketInfo])

    const getSingleTicket = () => {
        let obj = {
            _id: editData,
            userId: allData?.userId
        }
        axios.post(`${POST.SINGLE_TICKET_INFO}`, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setTicketInfo(data?.data)
                    // setFileList([{
                    //     uid: '1',
                    //     name: 'image.'+ data?.data?.membershipCardUrl.split('.')[-1],
                    //     status: 'done',
                    //     url: data?.data?.membershipCardUrl,
                    // }])
                }
                else {
                    console.log(lanKorCondition ? data?.message : data?.messageKr)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }


    useEffect(() => {
        getSingleTicket()
    }, [editData])

    useEffect(() => {
        setMemberFee(ticketInfo?.memberFee)
        setHomepage(ticketInfo?.homepage)
        setMembershipType(ticketInfo?.membershipType)
        setRightPrice(ticketInfo?.caddyFee)
        setNonMemberFee(ticketInfo?.NONmemberFee)
        setType(ticketInfo?.membershipType)
        setPhoneValue(ticketInfo?.phoneNumber)
        setjoiningFee(ticketInfo?.joiningFee)
        setcartFee(ticketInfo?.cartFee)
        setCaddyFee(ticketInfo?.caddyFee)
        setGreenFeeWeekend(ticketInfo?.greenFeeWeekEnd)
        setGreenFeeWeekday(ticketInfo?.greenFeeWeekDay)
        setWeekdayFee(ticketInfo?.specialGiftWeekEndKRW)
        setWeekday(ticketInfo?.specialGiftWeekDay)
        setWeekendFee(ticketInfo?.specialGiftWeekDayKRW)
        setWeekend(ticketInfo?.specialGiftWeekEnd)

        form.setFieldsValue({
            joiningDate: [moment(ticketInfo?.joiningDate?.date1), moment(ticketInfo?.joiningDate?.date2)],
            openingDate: moment(ticketInfo?.openingDate),
            membershipType: ticketInfo?.membershipType,
            golfClub: ticketInfo?.golfClub?.clubName,
            joiningFee: ticketInfo?.joiningFee,
            caddyFee: ticketInfo?.caddyFee,
            cartFee: ticketInfo?.cartFee,
            phoneNumber: ticketInfo?.phoneNumber,
            homepage: ticketInfo?.homepage,
            Benefits: ticketInfo?.additionalBenefits
        })
    }, [ticketInfo?._id])
    // const getUser = () => {
    //     axios.get(`${GET.GET_USER}/${user?._id}`)
    //         .then((res) => {
    //             const { data } = res
    //             if (data.success) {
    //                 updateUser(data?.user)
    //             }
    //         })
    //         .catch((e) => {
    //         })
    // }
    const normFile = (e) => {
        setFileList([])
        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage('You can only upload Images!')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    // const updateUser = (data) => {
    //     dispatch(loginUser(data))
    // }

    const handleCancel = () => {
        setEditModal(false);
    };
    const onSearch = (value) => {
    }

    const handleChange = (value) => {
        setType(value)
    }


    const onFinish = (fieldsValue) => {
        setLoading(true)
        let obj = {
            _id: ticketInfo?._id,
            userId: allData?.userId,
            caddyFee: rightPrice,
            membershipType: typess,
            memberFee: memberFee,
            NONmemberFee: nonMemberFee,
            // joiningDate: joinDate,
            joiningFee: joiningFee,
            phoneNumber: phoneValue,
            homepage: homepage,
            specialGiftWeekDay: weekday,
            specialGiftWeekDayKRW: weekdayFee,
            specialGiftWeekEnd: weekend,
            specialGiftWeekEndKRW: weekendFee,
            greenFeeWeekDay: greenFeeWeekday,
            greenFeeWeekEnd: greenFeeWeekend,
            cartFee: cartFee,
            caddyFee: caddyFee,
            additionalBenefits:fieldsValue?.Benefits
        }
        // console.log('obj', obj)
        axios.post(POST.EDIT_TICKET, obj)
            .then((res) => {
                const { data } = res
                // console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getMemberTickets()
                    setLoading(false)
                    setEditModal(false)
                    // getUser()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    return (
        <>
            <center>
                <Modal
                    open={editModal}
                    onCancel={handleCancel}
                    className='addholdingModal'
                    footer={null}
                    width={'100%'}
                >
                    <div className='modal_main_div'>
                        <center><h2>{language?.RegisterYourMembership}</h2></center>

                        <h2>{language?.Info}</h2>
                        <Form name='time_related_controls' form={form} className='formClass' requiredMark={false}  {...formItemLayout} onFinish={onFinish}>

                            <Form.Item
                                name='golfClub'
                                label={language?.GolfCourseName}
                            >
                                <div className='row_fields' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                                    <Select
                                        style={{ width: '70%' }}
                                        showSearch
                                        value={golfClubData?.id}
                                        placeholder={ticketInfo?.golfClub?.clubName}
                                        optionFilterProp='children'
                                        onChange={onChanges}
                                        onSearch={onSearch}
                                        filterOption={(input, option) => option.children.toLowerCase()?.includes(input?.toLowerCase())}
                                    >
                                        {golfClub?.map((v, i) => (<Option key={i} value={v?._id}>{v?.clubName}</Option>))}
                                    </Select>
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        className='golfSearchButton'
                                        size='middle'
                                        onClick={() => setShowGolfClub(true)}
                                    >{language?.searchbygolf}</Button>
                                </div>
                            </Form.Item>

                            {golfClubData?._id ?
                                <div className='row_fields1' style={{ maxWidth: 300, marginBottom: 20 }}>
                                    <Image width={100} height={100} src={golfClubData?.imgUrl} />
                                    <div style={{ marginTop: -10 }}>
                                        <h2>{golfClubData?.clubName}</h2>
                                        <p>{golfClubData?.region}</p>
                                        <Button onClick={() => { setGolfClubData(null) }}>{language?.Delete}</Button>
                                    </div>
                                </div> : null}

                            <Form.Item
                                name='file'
                                label={language?.AttachmentMembershipCar}
                            >
                                <Upload
                                    disabled
                                    style={{ width: '100%' }}
                                    type='file'
                                    name='file'
                                    multiple={false}
                                    beforeUpload={() => false}
                                    accept='image/png, image/jpeg'
                                    onChange={normFile}
                                    fileList={fileList}
                                >
                                    <Button disabled style={{ width: '100%' }} icon={<UploadOutlined />}></Button>
                                </Upload>

                            </Form.Item>

                            <Form.Item
                                name='membershipType'
                                label={language?.membershipType}
                                initialValue={membershipType}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseSelectType,
                                    },
                                ]}
                            >
                                <Select placeholder={typess}
                                    defaultValue={typess}
                                    onChange={handleChange}>
                                    {language?.login === "Login" ?
                                        types?.map((v, i) => (<Option key={i} value={v?.eng}>{v?.eng}</Option>)) :
                                        types?.map((v, i) => (
                                            <Option key={i} value={v?.eng}>{v?.kr}</Option>
                                        ))
                                    }
                                </Select>

                            </Form.Item>

                            <Form.Item
                                name='joiningFee'
                                label={language?.entrancefee}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputJoiningFee
                                    },
                                ]}
                            >
                                {/* <div> */}
                                <Input
                                    suffix={language?.KRW}
                                    placeholder={language?.entrancefee}
                                    maxLength={10}
                                    value={joiningFee}
                                    onChange={(e) => setjoiningFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                />
                                {/* </div> */}
                            </Form.Item>

                            <Form.Item name='joiningDate' label={language?.Timeperiod} {...rangeConfig}>
                                <RangePicker disabled style={{ width: '100%' }} placeholder={[language?.Startdate, language?.Enddate]} />
                            </Form.Item>

                            <Form.Item
                                name='specialGift'
                                label={language?.SpecialGift}
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_, names) => {
                                            if (!weekday) {
                                                return Promise.reject(new Error(language?.PleaseEnterWeekday))
                                            }
                                            if (!weekdayFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterWeekdayFee))
                                            }
                                            if (!weekend) {
                                                return Promise.reject(new Error(language?.PleaseEnterWeekend))
                                            }
                                            if (!weekendFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterWeekendFee))
                                            }
                                        }
                                    }
                                ]}
                            >
                                <div className='holdingDiv' style={{ display: 'flex' }}>
                                    <div className='holding_member_fee'  >

                                        <div style={{ width: 'auto', display: 'flex' }}>
                                            <p className='p_weekday' >{language?.Weekday}</p>
                                            <Input
                                                placeholder={language?.episode}
                                                className='input_weekday'
                                                maxLength={3}
                                                value={weekday}
                                                onChange={(e) => setWeekday(e.target.value.replace(/[^0-9]/g, ''))}
                                            />
                                            <Input
                                                suffix={language?.KRW}
                                                className='input_weekend'
                                                value={weekdayFee}
                                                onChange={(e) => setWeekdayFee(e.target.value.replace(/[^0-9]/g, ''))}
                                                maxLength={10}
                                            />
                                        </div>
                                    </div>
                                    <div className='holding_member_fee'>
                                        <div style={{ width: 'auto', display: 'flex' }}>
                                            <p className='p_weekday'>{language?.Weekend}</p>

                                            <Input
                                                placeholder={language?.episode}
                                                className='input_weekday'
                                                maxLength={4}
                                                value={weekend}
                                                onChange={(e) => setWeekend(e.target.value.replace(/[^0-9]/g, ''))}
                                            />
                                            <Input
                                                suffix={language?.KRW}
                                                className='input_weekend'
                                                maxLength={9}
                                                value={weekendFee}
                                                onChange={(e) => setWeekendFee(e.target.value.replace(/[^0-9]/g, ''))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='fee'
                                label={language?.usageFee}
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_, names) => {
                                            if (!memberFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterMemberFee))
                                            }
                                            if (!nonMemberFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterNon_MemberFee))
                                            }
                                        }
                                    }
                                ]}
                            >
                                <div className='usageFeeMemberNonmember' style={{ display: 'flex' }}>
                                    <div style={{ width: '50%', textAlign: 'center' }}>
                                        <label style={{ marginRight: '4%' }} htmlFor=''>{language?.Member}</label>
                                        <Input
                                            suffix={language?.KRW}
                                            style={{
                                                width: 'auto',
                                                // marginLeft: '4%',
                                            }}
                                            maxLength={10}
                                            value={memberFee}
                                            onChange={(e) => setMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                    </div>

                                    <div style={{ width: '50%', marginLeft: 'auto', textAlign: 'center' }}>
                                        <label htmlFor='' style={{ marginRight: '4%' }}>{language?.NonMember}</label>

                                        <Input
                                            suffix={language?.KRW}
                                            style={{
                                                width: 'auto',
                                                // marginLeft: '4%',

                                            }}
                                            maxLength={10}
                                            value={nonMemberFee}
                                            onChange={(e) => setNonMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                    </div>
                                </div>
                            </Form.Item>

                            <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                            <h2>{language?.GolfCourseInformation}</h2>

                            <Form.Item
                                name='address'
                                label={language?.addressMyPage}
                            >
                                <div className='row_fields1'>

                                    <Input
                                        disabled
                                        type='text'
                                        style={{ width: '100%' }}
                                        value={ticketInfo?.golfClub?.clubAddress}
                                    />

                                </div>
                            </Form.Item>
                            <Form.Item name='openingDate' label={language?.OpeningDate}{...config}>
                                <DatePicker disabled placeholder={language?.Selectdate} style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item
                                name='greenFee'
                                label={language?.GreenFee}
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_, names) => {
                                            if (!greenFeeWeekday) {
                                                return Promise.reject(new Error(language?.Pleaseentergreenfeeweekday))
                                            }
                                            if (!greenFeeWeekend) {
                                                return Promise.reject(new Error(language?.Pleaseentergreenfeeweekend))
                                            }
                                        }
                                    }
                                ]}
                            >
                                <div style={{ display: 'flex' }}>
                                    <Input
                                        style={{ width: '49.5%', marginRight: '0.5%' }}
                                        suffix={language?.KRW}
                                        addonBefore={language?.Weekday}
                                        maxLength={7}
                                        value={greenFeeWeekday}
                                        onChange={(e) => setGreenFeeWeekday(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                    <Input
                                        style={{ width: '49.5%', marginLeft: '0.5%' }}
                                        suffix={language?.KRW}
                                        addonBefore={language?.Weekend}
                                        maxLength={7}
                                        value={greenFeeWeekend}
                                        onChange={(e) => setGreenFeeWeekend(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item
                                name='caddyFee'
                                label={language?.Caddy}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputCaddyFee,
                                    },
                                ]}
                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        placeholder={language?.Caddy}
                                        maxLength={7}
                                        value={caddyFee}
                                        onChange={(e) => setCaddyFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item
                                name='cartFee'
                                label={language?.CartFee}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputCartFee,
                                    },
                                ]}
                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        placeholder={language?.CartFee}
                                        maxLength={7}
                                        value={cartFee}
                                        onChange={(e) => setcartFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item
                                name='phoneNumber'
                                label={language?.PhoneNumber}
                                rules={[
                                    {
                                        required: true,
                                        message: requiredMessage(language?.PhoneNumber)
                                    }
                                ]}
                            >
                                <div>
                                    <Input
                                        required
                                        maxLength={13}
                                        minLength={13}
                                        value={phoneValue}
                                        onChange={handlePhoneInput}
                                        placeholder={language?.phonePlaceholder}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item
                                name='homepage'
                                label={language?.HomePage}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.Pleaseinputyourhomepage,
                                    }
                                ]}
                            >
                                <div>
                                    <Input
                                        placeholder={language?.HomePage}
                                        onChange={(e) => { setHomepage(e.target.value) }}
                                        value={homepage}
                                    />
                                </div>
                            </Form.Item>
                            <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />
                            <h2>{language?.Additionalbenefits}</h2>

                            <Form.List name='Benefits' /* initialValue={[{benefitName: 'abc',benefitContent:'def',_id:'123'}]} */ /* initialValue={ticketInfo?.additionalBenefits} */>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            < Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: 8,
                                                    width: '100%'
                                                }}
                                                align='baseline'
                                            >
                                                <Form.Item
                                                    wrapperCol={{ span: 24 }}
                                                    {...restField}
                                                    // label='Benefits'
                                                    name={[name, 'benefitName']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: language?.Missingfirstname,
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={language?.BenefitName} />
                                                </Form.Item>
                                                <Form.Item
                                                    wrapperCol={{ span: 24 }}

                                                    {...restField}
                                                    name={[name, 'benefitContent']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: language?.Missinglastname,
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={language?.BenefitContents} />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </Space>
                                        ))}

                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <Button className='req_exc_btn1' onClick={() => add()} block>
                                                {language?.EnterAdditionalBenefits}
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Button
                                loading={loading}
                                className='req_exc_btn'
                                type='primary'
                                htmlType='submit'>{language?.Completed}
                            </Button>
                        </Form>
                    </div>

                    {/* old _ Code */}

                    {/* <div className='modal_main_div' >
                        <center>
                            <h2>{language?.editMembershipTicket}</h2>
                        </center>

                        <Form
                            initialValues={{
                                modifier: 'public',
                            }}
                            name='time_related_controls'  {...formItemLayout} onFinish={onFinish}>
                            <Form.Item
                                name='golfClub'
                                label={language?.GolfClubName}
                            >
                                <div>
                                    <Input
                                        disabled
                                        value={ticketInfo?.golfClub?.clubName}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='type'
                                label={language?.Type}
                                initialValue={typess}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please required type !',
                                    },
                                ]}
                            >
                                <Select placeholder={language?.Type}
                                    defaultValue={typess}
                                    onChange={handleChange}
                                >
                                    {types?.map((v, i) => (<Option key={i} value={v?.eng}>{lanKorCondition ? v?.eng : v?.kr}</Option>))}
                                </Select>

                            </Form.Item>



                            <Form.Item
                                name='memberFee'
                                label={language?.MemberFee}

                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        maxLength={10}
                                        value={memberFee}
                                        onChange={(e) => setMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='nonMemberFee'
                                label={language?.NonMemberFee}
                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        maxLength={10}
                                        value={nonMemberFee}
                                        onChange={(e) => setNonMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='rightPrice'
                                label={<h4><PlusCircleFilled className='plus_with_text' />{language?.RightPrice}</h4>}
                            >
                                <div>
                                    <Input

                                        suffix={language?.KRW}
                                        maxLength={10}
                                        value={rightPrice}
                                        onChange={(e) => setRightPrice(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>


                            <Form.Item name='reservedDate'
                                label={<h4><PlusCircleFilled className='plus_with_text' />{language?.ReservedDate}</h4>}
                                {...config}>
                                <DatePicker required={false} defaultValue={moment(D1AndD2?.date1)} style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item name='expirationDate'
                                label={<h4><PlusCircleFilled className='plus_with_text' />{language?.ExpirationDate}</h4>}
                                {...config}>
                                <DatePicker defaultValue={moment(D1AndD2?.date2)} style={{ width: '100%' }} />
                            </Form.Item>


                            <Button
                                loading={loading}
                                className='req_exc_btn'
                                type='primary'
                                htmlType='submit'>{language?.Completed}</Button>

                        </Form>
                    </div> */}
                </Modal>
            </center>
        </>
    )
}
export default RevisionHoldingModal;