import React, { useEffect, useState } from 'react'
import { Select, Button, Input, Table, Divider, Modal, DatePicker, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { ButtonLogout } from '../../Components'
import { FiSearch } from 'react-icons/fi'
import { HiPlusCircle } from 'react-icons/hi'
import { RiCheckboxBlankFill } from 'react-icons/ri';
import { FcCancel } from "react-icons/fc"
import axios from 'axios'
import { convertTitle, errorMessage, infoMessage, stringLimiter, successNotification } from '../../utils/helpers'
import { useSelector } from 'react-redux'
import { ADMIN } from '../../utils/apis'
import moment from 'moment-timezone'
import { allPaths, regionsKr, types, typesKr } from '../../utils/constants';
import AddTicketShare from './AddModal/AddTicketShare'
import EditTicketShare from './EditModal/EditTicketShare'
import RentalTicketInfo from './EditModal/RentalTicket'

const { Option } = Select
const { TextArea } = Input

const SharedRental = (props) => {
  const { user } = props
  const userSelectedLanguage = props?.language
  const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const lanKorCondition = SelectedLanguage === 'english'
  const history = props?.history
  const [dropDown, setdropDown] = useState(6 + '개 보기')
  const [tableRow, setTableRow] = useState(6)
  const [modalOpen, setModalOpen] = useState(false)
  const [showAdminData, setShowAdminData] = useState(false)
  const [AddNewShareTicket, setAddNewShareTicket] = useState(false)
  const [sharedRent, setSharedRentData] = useState([])
  const [sharedRentAdminData, setSharedRentAdminData] = useState([])
  const [loading, setLoading] = useState(false)
  const [approveLoader, setApproveLoader] = useState({})
  const [deleteLoader, setDeleteLoader] = useState({})
  const [cancelLoader, setCancelLoader] = useState({})
  const [editTicketShareData, setEditTicketShareData] = useState({})
  const [editTicketRantalData, setEditTicketRantalData] = useState({})
  const [search, setSearch] = useState(null)
  const [filteredData, setFilteredData] = useState([])
  const [adminFilteredData, setAdminFilteredData] = useState([])

  useEffect(() => {
    getTicketRent()
  }, [])

  useEffect(() => {
    // if (search) {
    //   let filterAdminData = (RentalAdminData || [])?.filter((v) => v?.NameIDphoneOfUploader?.[0]?.includes(search) || v?.NameIDphoneOfUploader?.[1]?.includes(search) || v?.NameIDphoneOfUploader?.[2]?.includes(search)) || []
    //   setAdminFilteredData(filterAdminData)
    // }else{
    // }
    setAdminFilteredData(RentalAdminData)
    
    if (search) {
      let filterData = (RentalData || [])?.filter((v) => v?.NameIDphoneOfUploader?.[0]?.includes(search) || v?.NameIDphoneOfUploader?.[1]?.includes(search) || v?.NameIDphoneOfUploader?.[2]?.includes(search)) || []
      setFilteredData(filterData)
    }else{
      setFilteredData(RentalData)
    }
  }, [sharedRent,sharedRentAdminData,search])

  const getTicketRent = () => {
    setLoading(true)
    axios.get(`${ADMIN?.GET_TICKET_SHARE_RENT_REQ}`)
      .then((res) => {
        const { data } = res
        console.log('data**>',data)
        setLoading(false)
        if (data?.success) {

          let userData = data?.uploadTicketShareReq?.filter((v) => v?.userId || v?.requestType === "Request Rent")
          let adminData = data?.uploadTicketShareReq?.filter((v) => v?.adminId)
          console.log('userData',userData)
          setSharedRentData(userData || [])
          setSharedRentAdminData(adminData || [])
          // console.log("uploadTicketShareReq=====>>>", data)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setLoading(false)
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }


  const ApproveTicketShareRent = (id, requestType) => {
    setApproveLoader({ [id]: true })
    let obj = {
      id: id,
      requestType: requestType
    }
    axios.post(ADMIN?.APPROVE_TICKET_SHARE_RENT_REQ, obj)
      .then((res) => {
        const { data } = res
        setApproveLoader({})
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          getTicketRent()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setApproveLoader({})
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }

  const cancelTicketShareRent = (id, requestType) => {
    setCancelLoader({ [id]: true })
    let obj = {
      id: id,
      requestType: requestType
    }
    axios.post(ADMIN?.CANCEL_TICKET_SHARE_RENT_REQ, obj)
      .then((res) => {
        const { data } = res
        setCancelLoader({})
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          getTicketRent()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setCancelLoader({})
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }

  const DeleteTicketShareRent = (id, requestType) => {
    setDeleteLoader({ [id]: true })
    let obj = {
      id: id,
      requestType: requestType
    }
    axios.post(ADMIN?.DELETE_TICKET_SHARE_RENT_REQ, obj)
      .then((res) => {
        const { data } = res
        setDeleteLoader({})
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          getTicketRent()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
          setDeleteLoader({})
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setDeleteLoader({})
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }

  const editingFunction = (data, type) => {
    if (type === 'SHARE TICKET') {
      setEditTicketShareData(data)
    } else {
      setEditTicketRantalData(data)
    }
  }

  const closeEditModal = () => {
    setEditTicketRantalData({})
    setEditTicketShareData({})
  }


  let RentalData = sharedRent?.length && sharedRent?.map((v, i) => {
    return {
      TransactionNumber: v?._id,
      NameIDphoneOfUploader: v?.requestType === 'Request Rent' ? [v?.buyerId?.fullName, v?.buyerId?._id, v?.buyerId?.telNo] : [v?.userId?.fullName, v?.userId?._id, v?.userId?.telNo],
      RightPrice: v?.ticketShareId?.rightPrice || v?.rightPrice,
      Type: v?.userTicketId?.membershipType || v?.ticketId?.membershipType,
      ExpirationDate: v?.expirationDate || v?.ticketShareId?.expirationDate,
      ViewMembership: v,
      Status: v,
      Action: v,
    }
  })
  console.log('RentalData',RentalData)
  let RentalAdminData = sharedRentAdminData?.length && sharedRentAdminData?.map((v, i) => {
    return {
      TransactionNumber: v?._id,
      RightPrice: v?.ticketShareId?.rightPrice || v?.rightPrice,
      Type: v?.userTicketId?.membershipType || v?.ticketId?.membershipType,
      ExpirationDate: v?.expirationDate || v?.ticketShareId?.expirationDate,
      ViewMembership: v,
      Status: v,
      Action: v,
    }
  })
  // console.log('RentalData', RentalData)



  let arr = [{
    value: 10, Viewby: '10' + '개 보기'
  }, {
    value: 8, Viewby: ' 8' + '개 보기'
  }, {
    value: 6, Viewby: ' 6' + '개 보기'
  },]
  const columns = [
    {
      title: userSelectedLanguage?.TransactionNumber,
      dataIndex: 'TransactionNumber',
      key: 'Transaction number',
      render: (text) =>
        <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
          <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 13)}</Button>
        </Tooltip>,
    },
    {
      title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
      dataIndex: 'NameIDphoneOfUploader',
      key: 'Name and Phone Number',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          {text?.map((v, i) => {
            return (
              <Tooltip placement='top' title={convertTitle(v || '----')} color={'#5EA29F'}>
                <p className='namePhoneAndID'>{stringLimiter(convertTitle(v || '----'), 14)}</p>
              </Tooltip>
            )
          })}
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.RightPrice,
      dataIndex: 'RightPrice',
      key: 'Right price',
      render: (text) => <p className='tableColoumWidthBig'>{text ? text + ' ' + userSelectedLanguage?.KRW : '--'}</p>,
    },
    {
      title: userSelectedLanguage?.Type,
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => <p className='tableColoumWidth'>{lanKorCondition ? text : typesKr[types?.indexOf(text)]?.typesKr || '----'}</p>,
    },
    {
      title: userSelectedLanguage?.ExpirationDate,
      dataIndex: 'ExpirationDate',
      key: 'Expiration Date',
      render: (text) => <p className='tableColoumWidth'>{text ? moment(text).format('YYYY-MM-DD') : '----'}</p>,
    },
    {
      title: userSelectedLanguage?.ViewMembership,
      dataIndex: 'ViewMembership',
      key: 'View Membership',
      render: (text) => (
        <>
          <div className='tableColoumWidthBig'>
            {text?.isCanceled ?
              <div className='searchIconDiv'>
                <FcCancel />
              </div>
              :
              <div className='searchIconDiv'
                onClick={() => {
                  text?.type === 'SHARE TICKET' ?
                    history.push({ pathname: allPaths?.TICKETSVIEWINFO, state: [text, { title: 'Ticket sharing' }] })
                    : history.push({ pathname: allPaths?.TICKETSVIEWINFO, state: [text, { title: 'Ticket sharing rent' }] })
                }}
              >
                <FiSearch />
              </div>}
          </div>

        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        <div
          style={{ width: '200px', }}
        >
          {
            text?.isCanceled ?
              lanKorCondition ? 'Cancel Request' : '공유취소' :
              text?.isSaled && text?.type === 'SHARE TICKET' ?
                lanKorCondition ? 'Completed sharing request ' : '공유완료' :
                text?.isCompleted && text?.requestType === 'Request Rent' ?
                  lanKorCondition ? 'Completed rent request ' : '대여요청 완료' :
                  !text?.isAdminApproved && !text?.isCompleted ?
                    lanKorCondition ? 'Request Sell' : '모집요청' :
                    lanKorCondition ? 'Selling' : '모집중'
          }
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        <div className='tableBtnsDiv' >
          <>
            <Button
              // disabled={text?.isSaled /* || text?.isCompleted || text?.isCanceled */}
              className='editBtn'
              onClick={() => { editingFunction(text, text?.type ? text?.type : 'RequestRent') }}
            >
              {userSelectedLanguage?.Edit}
            </Button>
            <Button loading={approveLoader[text?._id]} onClick={() => { ApproveTicketShareRent(text?._id, text?.requestType) }} disabled={text?.isAdminApproved || text?.isCanceled} className='postApproval'>{!text?.isAdminApproved ? userSelectedLanguage?.PostApproval : userSelectedLanguage?.Approved}</Button>
            <Button loading={cancelLoader[text?._id]} disabled={text?.isAdminApproved || text?.isSaled || text?.isCompleted || text?.isCanceled} className='editBtn' onClick={() => { cancelTicketShareRent(text?._id, text?.requestType) }}> {userSelectedLanguage?.CancelRequest}</Button>
            <Button loading={deleteLoader[text?._id]}
              // disabled={text?.isSaled || text?.isCompleted}
              onClick={() => { DeleteTicketShareRent(text?._id, text?.requestType) }} className='DeleteBtn'> {userSelectedLanguage?.Delete} </Button>
          </>
        </div>
      ),
    },

  ]
  const columnsAdmin = [
    {
      title: userSelectedLanguage?.TransactionNumber,
      dataIndex: 'TransactionNumber',
      key: 'Transaction number',
      render: (text) =>
        <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
          <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 13)}</Button>
        </Tooltip>,
    },
    // {
    //   title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
    //   dataIndex: 'NameIDphoneOfUploader',
    //   key: 'Name and Phone Number',
    //   render: (text) => (
    //     <div className='namePhoneAndIDiv'>
    //       {text?.map((v, i) => {
    //         return (
    //           <Tooltip placement='top' title={convertTitle(v || '----')} color={'#5EA29F'}>
    //             <p className='namePhoneAndID'>{stringLimiter(convertTitle(v || '----'), 14)}</p>
    //           </Tooltip>
    //         )
    //       })}
    //     </div>
    //   ),
    // },
    {
      title: `${userSelectedLanguage?.NameOfUploader}`,
      dataIndex: 'NameIDphoneOfUploader',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          <p className='namePhoneAndID'>{lanKorCondition ? 'admin' : '어드민'}</p>
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.RightPrice,
      dataIndex: 'RightPrice',
      key: 'Right price',
      render: (text) => <p className='tableColoumWidthBig'>{text ? text + ' ' + userSelectedLanguage?.KRW : '--'}</p>,
    },
    {
      title: userSelectedLanguage?.Type,
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => <p className='tableColoumWidth'>{lanKorCondition ? text : typesKr[types?.indexOf(text)]?.typesKr || '----'}</p>,
    },
    {
      title: userSelectedLanguage?.ExpirationDate,
      dataIndex: 'ExpirationDate',
      key: 'Expiration Date',
      render: (text) => <p className='tableColoumWidth'>{text ? moment(text).format('YYYY-MM-DD') : '----'}</p>,
    },
    {
      title: userSelectedLanguage?.ViewMembership,
      dataIndex: 'ViewMembership',
      key: 'View Membership',
      render: (text) => (
        <>
          <div className='tableColoumWidthBig'>
            {text?.isCanceled ?
              <div className='searchIconDiv'>
                <FcCancel />
              </div>
              :
              <div className='searchIconDiv'
                onClick={() => {
                  text?.type === 'SHARE TICKET' ?
                    history.push({ pathname: allPaths?.TICKETSVIEWINFO, state: [text, { title: 'Ticket sharing' }] })
                    : history.push({ pathname: allPaths?.TICKETSVIEWINFO, state: [text, { title: 'Ticket sharing rent' }] })
                }}
              >
                <FiSearch />
              </div>}
          </div>

        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        <div
          style={{ width: '200px', }}
        >
          {
            text?.isCanceled ?
              lanKorCondition ? 'Cancel Request' : '공유취소' :
              text?.isSaled && text?.type === 'SHARE TICKET' ?
                lanKorCondition ? 'Completed sharing request ' : '공유완료' :
                text?.isCompleted && text?.requestType === 'Request Rent' ?
                  lanKorCondition ? 'Completed rent request ' : '대여요청 완료' :
                  !text?.isAdminApproved && !text?.isCompleted ?
                    lanKorCondition ? 'Request Sell' : '모집요청' :
                    lanKorCondition ? 'Selling' : '모집중'
          }
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        < div className='tableBtnsDiv' >
          <>
            <Button
              // disabled={text?.isSaled || text?.isCompleted || text?.isCanceled}
              className='editBtn' onClick={() => { editingFunction(text, text?.type ? text?.type : 'RequestRent') }}> {userSelectedLanguage?.Edit}</Button>
            <Button loading={approveLoader[text?._id]} onClick={() => { ApproveTicketShareRent(text?._id, text?.requestType) }} disabled={text?.isAdminApproved || text?.isCanceled} className='postApproval'>{!text?.isAdminApproved ? userSelectedLanguage?.PostApproval : userSelectedLanguage?.Approved}</Button>
            <Button loading={cancelLoader[text?._id]} disabled={text?.isAdminApproved || text?.isSaled || text?.isCompleted || text?.isCanceled} className='editBtn' onClick={() => { cancelTicketShareRent(text?._id, text?.requestType) }}> {userSelectedLanguage?.CancelRequest}</Button>
            <Button loading={deleteLoader[text?._id]}
              // disabled={text?.isSaled || text?.isCompleted}
              onClick={() => { DeleteTicketShareRent(text?._id, text?.requestType) }} className='DeleteBtn'> {userSelectedLanguage?.Delete} </Button>
          </>
        </div>
      ),
    },

  ]
  return (
    <>
      <ButtonLogout props={props} />
      <div className='membershipBox'>
        <div className='goldReservationHeader'>
          <div className='golfHeaderText'>
            <h3>{userSelectedLanguage?.TicketSalesManagement}</h3>
            <p>{'>'}{userSelectedLanguage?.SharedRentalRequestSharedRentalPost}</p>

          </div>
          <div className='headersearch'>
            <Select
              className='dropdown'
              defaultValue={dropDown}
              onChange={(e) => {
                setTableRow(e)
              }}
            >
              {arr.map((v, i) => {
                return <Option key={v.value}>{v?.Viewby}</Option>
              })}
            </Select>
            <Input
              className='searchgolfReservation'
              placeholder={userSelectedLanguage?.PleaseSearchATerm}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearch(e?.target?.value)}

            />
          </div>
        </div>
        <div
          style={{
            margin: '0px auto',
            display: 'flex',
            width: '95%',
            justifyContent: 'flex-end'
          }}>
          {/* {showAdminData && */}
          <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }}
            onClick={() => setAddNewShareTicket(true)}
          >
            {lanKorCondition ? 'Add Share Rental ticket' : '공유대여 회원권 게시'}
          </Button>
          {showAdminData ?
            <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }}
              onClick={() => setShowAdminData(false)}>
              {lanKorCondition ? 'all user tickets share' : '공유대여 회원권 보기'}
            </Button>
            :
            <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }}
              onClick={() => setShowAdminData(true)}>
              {lanKorCondition ? 'Admin share rental' : '어드민 공유대여'}
            </Button>}
        </div>
        <div className='tableDivManagement'>
          <Divider />
          <Table
            loading={loading}
            pagination={{ pageSize: tableRow }}
            dataSource={showAdminData ? adminFilteredData : filteredData}
            columns={showAdminData ? columnsAdmin : columns}
            scroll={{ x: 700 }}
            size='middle'
          />
        </div>
        <AddTicketShare open={AddNewShareTicket}
          handleCancel={() => setAddNewShareTicket(false)}
          language={userSelectedLanguage}
          fun={getTicketRent}
          {...props}
        />
        {editTicketShareData?._id &&
          <EditTicketShare
            open={editTicketShareData?._id ? true : false}
            editData={editTicketShareData}
            handleCancel={() => closeEditModal()}
            language={userSelectedLanguage}
            fun={getTicketRent}
            {...props}
          />}
        {editTicketRantalData?._id &&
          <RentalTicketInfo
            open={editTicketRantalData?._id ? true : false}
            editData={editTicketRantalData}
            handleCancel={() => closeEditModal()}
            language={userSelectedLanguage}
            fun={getTicketRent}
            {...props}
          />}
      </div>
    </>
  )
}
export default SharedRental
