import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import moment from 'moment-timezone'
import { HiCheckCircle } from 'react-icons/hi';
import axios from 'axios';
import { errorMessage, MyInfoAlert, successMessage } from '../../../utils/helpers';
import { POST } from '../../../utils/apis';
import { useHistory } from 'react-router-dom';
import { allPaths, regionsKr, types, typesKr, typesOnlyKr } from '../../../utils/constants';
const RentalTicketInfo = (props) => {
    const { editData, user, language, lanKorCondition,handleCancel ,open} = props
    console.log('editData',editData)
    return (
        <>
            <Modal
                open={open}
                onCancel={handleCancel}
                footer={null}
                className='responsiveModal'
                style={{ maxWidth: 800, top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <h2 style={{ textAlign: 'center' }}>{language?.Rent}</h2>
                    <div className='main_modal_con' /* style={{ padding: 10 }} */>
                        <p>
                            {language?.MembershipWishTo}
                        </p>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.GolfCourseName}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{editData?.golfClub?.clubName || editData?.userTicketId?.golfClub?.clubName}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.Type}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{
                                    editData?.membershipType ?
                                        editData?.membershipType ? lanKorCondition ? editData?.membershipType :
                                            typesKr[types?.indexOf(editData?.membershipType)]?.typesKr : '----'
                                        :
                                        editData?.userTicketId?.membershipType ? lanKorCondition ? editData?.userTicketId?.membershipType :
                                            typesKr[typesOnlyKr?.indexOf(editData?.userTicketId?.membershipType)]?.typesKr ?
                                                typesKr[typesOnlyKr?.indexOf(editData?.userTicketId?.membershipType)]?.typesKr :
                                                typesKr[types?.indexOf(editData?.userTicketId?.membershipType)]?.typesKr
                                            : '----'
                                    // editData?.membershipType
                                    // || editData?.userTicketId?.membershipType
                                }</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{lanKorCondition ? 'Member' : language?.MemberFee}/{lanKorCondition ? 'nonMember' : language?.NonMemberFee}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{language?.MemberFee} {editData && editData?.memberFee || editData?.userTicketId?.memberFee} {language?.MillonWon}/ {language?.NonMemberFee} {editData && editData?.NONmemberFee || editData?.userTicketId?.NONmemberFee} {language?.MillonWon}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.InstantPrice}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{editData ? editData?.ticketShareId?.rightPrice + '만원' : '--'}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.Reserveddate}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{moment(editData && editData?.joiningDate?.date1 || editData?.userTicketId?.joiningDate?.date2).format('YYYY-MM-DD')}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.ExpirationDate}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{moment(editData && editData?.ticketShareId?.expirationDate).format('YYYY-MM-DD')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default RentalTicketInfo;