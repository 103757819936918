import { Button, Checkbox, DatePicker, Form, Image, Input, Modal, Select } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ADMIN } from '../../utils/apis';
import { errorMessage, successNotification } from '../../utils/helpers';
import moment from 'moment-timezone'
import CoustomRangePicker from '../../Components/RangePicker/RengePicker';

const { RangePicker } = DatePicker
const { Option } = Select

const { TextArea } = Input

const dateFormat = 'YYYY-MM-DD';


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 18,
        },
    },
}

const rangeConfig = {
    rules: [
        {
            type: 'array',
            required: true,
            message: 'Please select date!',
        },
    ],
}


const EditGolfReservation = (props) => {
    const { user, selectReservation, getAllGolfReservation, onCancel, userSelectedLanguage } = props
    const [loading, setLoading] = useState(false)
    const [greenFee, setGreenFee] = useState(selectReservation?.allData?.greenFee)
    const [am, setAm] = useState(selectReservation?.allData?.reservedTime?.slice(-2) || 'am')
    const [hr, setHr] = useState(selectReservation?.allData?.reservedTime?.split(':')[0] || '2')
    const [min, setMin] = useState(selectReservation?.allData?.reservedTime?.split(':')[1].slice(0, -3) || '1')
    const [totalTime, setTotalTime] = useState(hr + ':' + min + ' ' + am)
    const [transferFee, setTransferFee] = useState(selectReservation?.allData?.transferFee)


    const hrs = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    const mins = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60']
    const [form] = Form.useForm()

    const handleCancel = () => {
        onCancel()
        form.resetFields()
    }

    const editReservation = (values) => {
        // setLoading(true)
        const rangeValue = values['reservedDate']
        values.reservedDate = { date1: rangeValue[0].format('YYYY-MM-DD'), date2: rangeValue[1].format('YYYY-MM-DD') }
        values.greenFee = Number(values.greenFee)
        values.reservedTime = totalTime
        values.address = selectReservation?.allData?.golfClub?.clubAddress
        values._id = selectReservation?._id
        values.adminId = user?._id

        console.log('values', values)
        // return true
        axios.post(ADMIN?.EDIT_GOLF_RESERVATION, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    getAllGolfReservation()
                    successNotification('Successfully Edited!')
                    handleCancel()
                } else {
                    errorMessage(data.message)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                setLoading(false)
                errorMessage()
            })
    }
    console.log('dfghjkl',selectReservation?.allData?.reservedDate)

    useEffect(() => {
        form.setFieldsValue({
            'reservedDate': [moment(selectReservation?.allData?.reservedDate?.date1, dateFormat), moment(selectReservation?.allData?.reservedDate?.date2, dateFormat)],
            'greenFee': greenFee,
            'transferFee': transferFee,
            'memo': selectReservation?.allData?.memo
        })
    }, [])
    useEffect(() => {
        setTotalTime(hr + ':' + min + ' ' + am)
    }, [am, min, hr])

    const setDateRange = (e) => {
        let date1 = moment(e[0]?._d)?.format('YYYY-MM-DD')
        let date2 = moment(e[1]?._d)?.format('YYYY-MM-DD')
        form.setFieldValue('reservedDate', [moment(date1,'YYYY-MM-DD'), moment(date2,'YYYY-MM-DD')])
    }

    return (
        <Modal
            open={selectReservation?._id}
            onCancel={handleCancel}
            className='paymentModal'
            footer={null}
        >
            <div className='modal_main_div'>
                <center><h2>{userSelectedLanguage?.EditGolfReservation}</h2></center>

                <div className='row_fields' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                    <p style={{ marginLeft: 10 }}>{selectReservation?.golfClub}</p>

                </div>

                <div className='row_fields1' style={{ maxWidth: 300, marginBottom: 20 }}>
                    <Image width={100} height={100} src={selectReservation?.allData?.golfClub?.imgUrl} />
                    <div style={{ marginTop: -10 }}>
                        <h2>{selectReservation?.golfClub}</h2>
                        <p>{selectReservation?.allData?.golfClub?.clubAddress}</p>
                        {/* <Button>Delete</Button> */}
                    </div>
                </div>

                <Form name='time_related_controls' form={form} requiredMark={false} {...formItemLayout} onFinish={editReservation}>

                    <Form.Item name='reservedDate' label={userSelectedLanguage?.ReservedDate} {...rangeConfig}>
                        {/* <RangePicker style={{ width: '100%' }} defaultValue={[moment(selectReservation?.allData?.reservedDate?.date1, dateFormat), moment(selectReservation?.allData?.reservedDate?.date2, dateFormat)]} /> */}
                        <CoustomRangePicker selectReservation={selectReservation} format={'YYYY-MM-DD'} /* date={dateRange1} */ setDate={setDateRange} />
                    </Form.Item>
                    <Form.Item
                        name='reservedTime'
                        label={userSelectedLanguage?.ReservationTime}
                    >
                        <div className='row_fields1'>
                            <Select
                                defaultValue={selectReservation?.allData?.reservedTime?.slice(4)}
                                className='special_width'
                                value={am}
                                onChange={(value) => { setAm(value); setTotalTime(hr + ':' + min + ' ' + am) }}
                            >
                                <Option value='am'>A.M.</Option>
                                <Option value='pm'>P.M.</Option>
                            </Select>
                            <Select
                                style={{
                                    width: '35%'
                                }}
                                value={hr}
                                onChange={(value) => { setHr(value); setTotalTime(hr + ':' + min + ' ' + am) }}
                            >
                                {hrs?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                            </Select>
                            <Select
                                style={{
                                    width: '35%'
                                }}
                                value={min}
                                onChange={(value) => { setMin(value); setTotalTime(hr + ':' + min + ' ' + am) }}
                            >
                                {mins?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                            </Select>
                        </div>
                    </Form.Item>

                    <Form.Item
                        name='greenFee'
                        label={userSelectedLanguage?.GreenFee}
                        rules={[
                            {
                                required: true,
                                message: userSelectedLanguage?.GreenFeeRequired,
                            }
                        ]}

                    >
                        <div>
                            <Input
                                suffix={userSelectedLanguage?.KRW}
                                min={0}
                                maxLength={10}
                                value={greenFee}
                                onChange={(e) => setGreenFee(e.target.value?.replace(/[^0-9]/g, ''))}
                            />
                        </div>
                    </Form.Item>

                    <Form.Item
                        name='transferFee'
                        label={userSelectedLanguage?.TransferFee}
                        rules={[
                            {
                                required: true,
                                message: userSelectedLanguage?.TransferFeeRequired,
                            }
                        ]}
                    >
                        <div className='row_fields1'>

                            <Input
                                suffix={userSelectedLanguage?.KRW}
                                min={0}
                                maxLength={7}
                                value={transferFee}
                                onChange={(e) => setTransferFee(e.target.value?.replace(/[^0-9]/g, ''))}
                            />
                            <button style={{ width: 150, marginLeft: 10, border: 'none' }}>{userSelectedLanguage?.NoTransferFee}</button>

                        </div>
                    </Form.Item>

                    <Form.Item
                        name='address'
                        label={userSelectedLanguage?.address}
                    >
                        <div className='row_fields1'>

                            <Input
                                type='text'
                                style={{ width: '100%' }}
                                value={selectReservation?.allData?.golfClub?.clubAddress}
                                disabled
                            // onChange={(e) => setAddress(e?.target?.value)}
                            />
                        </div>
                    </Form.Item>

                    <Form.Item
                        name='memo'
                        label={userSelectedLanguage?.Memo}
                        rules={[
                            {
                                required: true,
                                message: userSelectedLanguage?.MemoRequired,
                            }
                        ]}
                    >
                        <div className='row_fields1'>
                            <TextArea rows={4} defaultValue={selectReservation?.allData?.memo} />
                        </div>
                    </Form.Item>

                    <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit'>{userSelectedLanguage?.Update}</Button>
                </Form>


            </div>
        </Modal>
    )
}
export default EditGolfReservation
