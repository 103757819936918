import React from 'react'
import { Button, Table, Tooltip } from 'antd'
import { ButtonLogout } from '../../Components'
import moment from 'moment-timezone'
import { convertTitle, errorMessage, stringLimiter, successNotification, infoMessage } from '../../utils/helpers'
import { BsCheckCircleFill } from 'react-icons/bs'
import { FiSearch } from 'react-icons/fi'
import { FcCancel } from "react-icons/fc"
import { allPaths, regions, regionsKr } from '../../utils/constants'
import axios from 'axios'
import { ADMIN } from '../../utils/apis'
import { useDispatch, useSelector } from 'react-redux'
import { setAuction } from '../../Redux/actions/genralAction'

const GolfBidDetail = (props) => {
    const { user } = props
    const history = props?.history
    const dispatch = useDispatch()
    const userSelectedLanguage = props?.language
    let Data = props?.history?.location?.state
    const language = useSelector(state => state?.languageReducer?.language)
    const auction = useSelector(state => state?.setAuction?.val)
    const lanKorCondition = language === 'english'




    let ReserveData = Data?.reservations?.length && Data?.reservations?.map((v, i) => {
        return {
            TransactionNumber: v?._id,
            Name: v?.buyerId?.fullName,
            iD: v?.buyerId?._id,
            PhoneNumber: v?.buyerId?.telNo,
            Status: v,
            ViewDetail: v,
            Action: v,
        }
    })


    const DeleteRequest = (id,) => {
        let obj = {
            adminId: user?._id, requestId: id
        }
        axios.post(ADMIN?.DELETE_RESERVATION_REQUEST, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successNotification(lanKorCondition ? data.message : data.messageKr)
                    // history.push(allPaths?.GOLF_AUCTION_POST_MANAGEMENT)
                } else {
                    errorMessage(data.message)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                errorMessage(userSelectedLanguage?.OppsError)
            })
    }

    const CancelRequest = (id,) => {
        let obj = {
            adminId: user?._id, requestId: id
        }
        axios.post(ADMIN?.CANCEL_RESERVATION_REQUEST, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successNotification(lanKorCondition ? data.message : data.messageKr)
                    // history.push(allPaths?.GOLF_AUCTION_POST_MANAGEMENT)
                } else {
                    errorMessage(data.message)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                errorMessage(userSelectedLanguage?.OppsError)
            })
    }











    const columns = [
        {
            title: userSelectedLanguage?.TransactionNumber,
            dataIndex: 'TransactionNumber',
            key: 'Transaction number',
            render: (text) => <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
                <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 12)}</Button>
            </Tooltip>
        },
        {
            title: userSelectedLanguage?.NameOfUploader,
            dataIndex: 'Name',
            key: 'Name',
            render: (text) => (
                <div className='namePhoneAndIDiv'>
                    <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
                        <p className='namePhoneAndID'>{stringLimiter(convertTitle(text || '----'), 12)}</p>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: userSelectedLanguage?.ID,
            dataIndex: 'iD',
            key: 'iD',
            render: (text) => (
                <div className='namePhoneAndIDiv'>
                    <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
                        <p className='namePhoneAndID'>{stringLimiter(convertTitle(text || '----'), 12)}</p>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: userSelectedLanguage?.PhoneNumber,
            dataIndex: 'PhoneNumber',
            key: 'PhoneNumber',
            render: (text) => (
                <div className='namePhoneAndIDiv'>
                    <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
                        <p className='namePhoneAndID'>{stringLimiter(convertTitle(text || '----'), 12)}</p>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: lanKorCondition ? 'ViewDetail' : '거래정보 상세보기',
            dataIndex: 'ViewDetail',
            key: 'ViewDetail',
            render: (text) => <>
                <div className='tableColoumWidth' style={{ width: '180px' }}>
                    <div className='searchIconDiv' style={{ cursor: 'pointer' }}>
                        {text?.isCanceled ?
                            <FcCancel /> : <FiSearch
                                onClick={() => {
                                    history.push({ pathname: allPaths?.TICKETSINFO, state: [text, Data, { Bank: 'Hide', title: 'Golf Reservation' }] })
                                }}
                            />}
                    </div>
                </div>
            </>
        },
        {
            title: userSelectedLanguage?.Status,
            dataIndex: 'Status',
            key: 'Status',
            render: (text) => (
                <>
                    <>
                        {text?.isCanceled ?
                            <Button className='Button-cancel'>{lanKorCondition ? 'Transaction canceled' : '거래취소'}</Button>
                            : text?.reservationAccepted ?
                                <Button style={{ width: '250px' }} className='postApprovalButton-new'>{lanKorCondition ? 'reservation confirmed(payment completed)' : '예약확정(결제완료)'}</Button>
                                :
                                <Button style={{ width: '250px' }} className='waitingBtn-new' >{lanKorCondition ? 'Waiting for reservation' : '예약 대기중'}</Button>
                        }
                    </>
                </>
            ),
        },
        {
            title: userSelectedLanguage?.Action,
            dataIndex: 'Action',
            key: 'Action',
            render: (text) => (
                console.log(text, "============reservationAccepted"),
                <div className='tableBtnsDiv'>
                    <>
                        <Button className='postApproval'
                            disabled={text?.reservationAccepted || text?.isCanceled}
                            onClick={() => { CancelRequest(text?._id) }}
                        >
                            {userSelectedLanguage?.Cancel}
                        </Button>
                        <Button
                            disabled={text?.reservationAccepted}
                            onClick={() => { DeleteRequest(text?._id) }}
                            className='btnTableDelete' type='primary'>
                            {userSelectedLanguage?.Delete}
                        </Button>
                    </>
                </div>
            ),
        },
    ]





    return (
        <>
            <ButtonLogout props={props} />
            <div className='settingDiv'>
                <div className='nameEmailDiv'>
                    <div className='divHeader'>
                        <h3 className='headerText'>{lanKorCondition ? 'Golf reservation ticket Details' : '골프예약 회원권 상세보기'}</h3>
                    </div>
                    <div className='divHeader detailMemberInfo'>
                        <div className='MemberInfo' >
                            <h4>{lanKorCondition ? 'Ticket Info' : '회원권 정보'}</h4>
                            <div className='participantdetailMemberInfo'>
                                <div className='participantdetailMember'>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.GolfClubName}</h5>
                                        <h5 className='participantKey'>{Data?.golfClub?.clubName || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.address}</h5>
                                        <h5 className='participantKey'>{Data?.golfClub?.clubAddress || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.Region}</h5>
                                        <h5 className='participantKey '>{lanKorCondition ? Data?.golfClub?.region : regionsKr[regions?.indexOf(Data?.golfClub?.region)]?.TitleKr || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.Memo}</h5>
                                        <h5 className='participantKey'>{Data?.memo || '----'}</h5>
                                    </div>
                                </div>
                                <div className='participantdetailMember extraMargin'  >
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Reservation Date' : '예약일자'}</h5>
                                        <h5 className='participantKey'>{moment(Data?.reservedDate?.date1).format('YYYY-MM-DD') + ' ' + '|' + ' ' + moment(Data?.reservedDate?.date2).format('YYYY-MM-DD')}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Reservation Time' : '예약시간'}</h5>
                                        <h5 className='participantKey'>{Data?.reservedTime || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Green fee' : '그린피'}</h5>
                                        <h5 className='participantKey'>{Data?.greenFee ? Data?.greenFee + ' ' + userSelectedLanguage?.KRW : '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Transfer fee' : '양도비'}</h5>
                                        <h5 className='participantKey'>{Data?.transferFee ? Data?.transferFee + ' ' + userSelectedLanguage?.KRW : '----'}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Password Confirm Password etc */}

                <div className='nameEmailDiv'>
                    <div className='divHeader '>
                        <h3 className='headerText'>{userSelectedLanguage?.ParticipantDetail}</h3>
                    </div>
                    <div className='divHeader detailMemberInfo borderNone'>
                        <Table
                            pagination={{ pageSize: 500 }}
                            dataSource={ReserveData}
                            columns={columns}
                            scroll={{ x: 700 }}
                            size='middle'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default GolfBidDetail
