import { PlusCircleFilled } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, InputNumber, Modal, Radio } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { HiCheckCircle } from "react-icons/hi"
import { useHistory } from 'react-router-dom'
import { ADMIN, POST } from '../../../utils/apis'
import { allPaths, types, typesKr } from '../../../utils/constants'
import englishLanguage from '../../../utils/englishLanguage.json'
import { errorMessage, loginAlert, MyInfoAlert, successMessage } from '../../../utils/helpers'
import ChooseTicket from '../../../Components/ChooseTicket/ChooseTicket'

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 12,
        },
        md: {
            span: 10,
        }
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 12,
        },
        md: {
            span: 14,
        },
    },
}

const EditTicketSell = (props) => {
    const { user, fun, language, lanKorCondition, handleOk, open, editData } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState(editData?.ticketId)
    const [initialValueCateg, setInitialValueCateg] = useState(editData?.sellingType?.length === 1 ? 'normal' : 'both')
    const [show, setShow] = useState(editData?.sellingType?.length === 1 ? false : true)

    const onFinish = (fieldsValue) => {
        setLoading(true)

        fieldsValue._id = editData?._id
        fieldsValue.adminId = user?._id
        fieldsValue.ticketId = selectedTicket?._id

        console.log('fieldsValue', fieldsValue)

        axios.post(ADMIN.EDIT_TICKET_SALE_NEW, fieldsValue)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    fun()
                    handleOk()
                    form.resetFields()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    return (
        <>
            <Modal
                open={open}
                onCancel={handleOk}
                footer={null}
                className='responsiveModal'
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div>
                        <h1 style={{ textAlign: 'center' }}>{lanKorCondition ? 'Ticket Sales' : '판매 신청'}</h1>
                        <h5 style={{ textAlign: 'center', color: '#2196f3' }}>{language?.EnterMembershipInformation}</h5>

                        <div className='paddongThirty'>
                            <Form
                                requiredMark={false}
                                name='time_related_controls'
                                //    {...formItemLayout} 
                                onFinish={onFinish}>

                                <Form.Item
                                    name='membership'
                                    label={lanKorCondition ? 'Membership' : '회원권 선택'}
                                    initialValue={editData?.ticketId?.membershipCardName}
                                >

                                    <Input
                                        disabled
                                    />
                                </Form.Item>

                                <div className='disabled_text scroll_class'>
                                    <table>
                                        <tr>
                                            <td>{language?.GolfClubName}</td>
                                            <td>{selectedTicket && selectedTicket?.golfClub?.clubName || '-----------------'}</td>
                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.addresss}</td>
                                            <td>{selectedTicket && selectedTicket?.golfClub?.clubAddress || '-----------------'}</td>
                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.Type}</td>
                                            {/* <td>{selectedTicket && selectedTicket?.membershipType || '-----------------'}</td> */}
                                            <td>{selectedTicket?.membershipType ? `${lanKorCondition ? selectedTicket?.membershipType : typesKr[types?.indexOf(selectedTicket?.membershipType)]?.typesKr}` : `-----------------`}</td>

                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.JoiningFee} </td>
                                            <td>{selectedTicket && selectedTicket?.joiningFee || '-----------------'}</td>
                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.JoiningDate}</td>
                                            {/* <td>{selectedTicket && (selectedTicket?.joiningDate?.date1)|| '-----------------'}</td> */}
                                            <td>{selectedTicket?._id ? `${moment(selectedTicket?.joiningDate?.date1).format('YYYY-MM-DD')} / ${moment(selectedTicket?.joiningDate?.date2).format('YYYY-MM-DD')}` : '-----------------'}</td>
                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.SpecialGift}</td>
                                            {selectedTicket?._id ?
                                                // 주중  2회  50 만원  | 주말  1회  80만원
                                                // Weekday   2times 50만원  | weekend c 80만원
                                                lanKorCondition ?
                                                    <td>Weekday {selectedTicket && selectedTicket?.specialGiftWeekDay} times  {selectedTicket && selectedTicket?.specialGiftWeekDayKRW} milion won | weekend {selectedTicket && selectedTicket?.specialGiftWeekEnd} times  {selectedTicket && selectedTicket?.specialGiftWeekEndKRW} million won</td> :
                                                    <td>주중 {selectedTicket && selectedTicket?.specialGiftWeekDay} 회    {selectedTicket && selectedTicket?.specialGiftWeekDayKRW} 만원  | 주말  {selectedTicket && selectedTicket?.specialGiftWeekEnd}  회 {selectedTicket && selectedTicket?.specialGiftWeekEndKRW} 만원</td>
                                                :
                                                <td>----------------</td>}
                                        </tr>
                                    </table>
                                </div>
                                <br />
                                <Form.Item
                                    name='category'
                                    initialValue={initialValueCateg}
                                >
                                    <Radio.Group
                                        disabled
                                        className='ticketsellRadio'
                                        buttonStyle='solid' onChange={(e) => {
                                            if (e?.target?.value === 'both' || e?.target?.value === 'group') {
                                                setShow(true)
                                            } else {
                                                setShow(false)
                                            }
                                        }}>
                                        <Radio.Button value={'both'} style={{ marginRight: 10, borderRadius: 8 }}>{lanKorCondition ? 'Both' : '일반 판매와 공동구매로 판매'}</Radio.Button>
                                        <Radio.Button value={'normal'} style={{ marginRight: 10, borderRadius: 8 }}>{language?.NormalSell}</Radio.Button>
                                        <Radio.Button value={'group'} style={{ marginRight: 10, borderRadius: 8 }}>{lanKorCondition ? 'Group Buy Sell' : '공동구매 '}</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    name='sellingPrice'
                                    initialValue={editData?.ticketId?.sellingPrice}
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />{lanKorCondition ? 'Wanted Selling Price for this ticket ' : '회원권 판매 희망 금액'}</h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.PleaseInputSellingPrice,
                                        },
                                    ]}
                                >
                                    <Input
                                        type='number'
                                        suffix={language?.KRW}
                                        s maxLength={10}
                                        style={{ width: '100%' }} />
                                </Form.Item>


                                {show ?
                                    <>
                                        <Form.Item
                                            style={{ display: show ? '' : 'none' }}
                                            initialValue={editData?.ticketId?.totalSeats}
                                            name='totalSeats'
                                            label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />{language?.TotalDesiredAccount}</h4>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: language?.PleaseinputSeats
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                type='number'
                                                min={1}
                                                max={100}
                                                maxLength={7}
                                                style={{ width: '100%' }}
                                                placeholder={language?.TotalDesiredAccount}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ display: show ? '' : 'none' }}
                                            initialValue={editData?.ticketId?.seatPrice}
                                            name='seatPrice'
                                            label={<h4 style={{ marginBottom: 0 }}>
                                                <PlusCircleFilled className='plus_with_text' />{lanKorCondition ? 'Seat Price' : '구좌당 금액'}</h4>}
                                        >
                                            <Input
                                                type='number'
                                                style={{ width: '100%' }}
                                                suffix={language?.KRW}
                                                placeholder={language?.AmoutperAccount}
                                                maxLength={7}
                                            />
                                        </Form.Item>
                                    </>
                                    : ''}
                                <Form.Item
                                    name='TermAndConditions'
                                    label={language?.TermCondition}
                                    valuePropName='checked'
                                    initialValue={true}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                        }
                                    ]}
                                >
                                    <Checkbox />
                                </Form.Item>

                                <div className='agreement scroll_class' style={{ marginBottom: 20 }}>
                                    <p>{language?.TAC1}</p>
                                    <p>{language?.TAC2}</p>
                                    <p>{language?.TAC3}</p>
                                    <p>{language?.TAC4}</p>
                                    <p>{language?.TAC5}</p>
                                    <p>{language?.TAC6}</p>
                                    <p>{language?.TAC7}</p>
                                </div>
                                <Button loading={loading} htmlType='submit' className='req_exc_btn' type="primary">{language?.RequestSell}</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default EditTicketSell