import React, { useEffect, useState } from 'react'
import { Button, Modal, Popconfirm, Select, Tabs, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { ButtonLogout } from '../../Components'
import { Table, Divider } from 'antd'
import { FiSearch } from 'react-icons/fi'
import { convertTitle, errorMessage, stringLimiter, successNotification } from '../../utils/helpers'
import { ADMIN } from '../../utils/apis'
import axios from 'axios'
import moment from 'moment-timezone'
import { allPaths, regions, regionsKr, types, typesKr } from '../../utils/constants'
import RentalEdit from './RentalEdit'
import GolfReservationEdit from './GolfReservationAuction'
import SaleEdit from './SaleEdit'
import { useSelector } from 'react-redux'
import AddShareAuction from './AddShareAuction'
import AddSaleAuction from './AddSaleAuction'
import AddGolfReservation from './AddGolfReservationAuction'

const { Option } = Select
const GolfAuction = (props) => {
  const userSelectedLanguage = props?.language
  const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const auction = useSelector(state => state?.setAuction?.val)
  const lanKorCondition = SelectedLanguage === 'english'
  const { user } = props
  const history = props?.history
  const [dropDown, setdropDown] = useState(6 + '개 보기')
  const [tableRow, setTableRow] = useState(6)
  const [showAdminShareData, setShowAdminShareData] = useState(false)
  const [showAdminSaleData, setShowAdminSaleData] = useState(false)
  const [showAdminResData, setShowAdminResData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [adminRentalAuctionData, setAdminRentalAuctionData] = useState([])
  const [adminSaleAuctionData, setAdminSaleAuctionData] = useState([])
  const [adminGolfReservationAuctionData, setAdminGolfReservationAuctionData] = useState([])
  const [rentalAuctionData, setRentalAuctionData] = useState([])
  const [salesAuctionData, setSalesAuctionData] = useState([])
  const [golfReservationAuctionData, setGolfReservationAuctionData] = useState([])
  const [shareRentalModal, setShareRentalModal] = useState(false);
  const [saleAuctionModal, setSaleAuctionModal] = useState(false);
  const [golfResModal, setGolfResModal] = useState(false);
  const [editRentalData, setEditRentalData] = useState({});
  const [editGolfResAuctionData, setEditGolfResAuctionData] = useState({});
  const [editSaleData, setEditSaleData] = useState({});
  const [uploadGolfResRequest, setUploadGolfResRequest] = useState(false);
  const [filteredgolfReservationData, setgolfReservationFilteredData] = useState([])
  const [filteredsaleAuctionData, setsaleAuctionFilteredData] = useState([])
  const [filteredrentalAuctionData, setrentalAuctionFilteredData] = useState([])
  const [search, setSearch] = useState(null)

  let arr = [{
    value: 10, Viewby: '10' + '개 보기'
  }, {
    value: 8, Viewby: ' 8' + '개 보기'
  }, {
    value: 6, Viewby: ' 6' + '개 보기'
  },]
  useEffect(() => {
    getShareRentalAuction()
    salesAuction()
    golfReservationAuction()
  }, [auction])

  useEffect(() => {
    if (search) {
      let filterData = golfReservation?.filter((v) => v?.NameIDphoneOfUploader?.[0]?.includes(search) || v?.NameIDphoneOfUploader?.[1]?.includes(search) || v?.NameIDphoneOfUploader?.[2]?.includes(search)) || []
      setgolfReservationFilteredData(filterData)
    } else {
      setgolfReservationFilteredData(golfReservation)
    }

    if (search) {
      let filterData = saleAuction?.filter((v) => v?.NameIDphoneOfUploader?.[0]?.includes(search) || v?.NameIDphoneOfUploader?.[1]?.includes(search) || v?.NameIDphoneOfUploader?.[2]?.includes(search)) || []
      setsaleAuctionFilteredData(filterData)
    } else {
      setsaleAuctionFilteredData(saleAuction)
    }

    if (search) {
      let filterData = rentalAuction?.filter((v) => v?.NameIDphoneOfUploader?.[0]?.includes(search) || v?.NameIDphoneOfUploader?.[1]?.includes(search) || v?.NameIDphoneOfUploader?.[2]?.includes(search)) || []
      setrentalAuctionFilteredData(filterData)
    } else {
      setrentalAuctionFilteredData(rentalAuction)
    }
  }, [golfReservationAuctionData, search, salesAuctionData, rentalAuctionData])

  const getAllAuction = () => {
    getShareRentalAuction()
    salesAuction()
    golfReservationAuction()
  }


  const getShareRentalAuction = () => {
    setLoading(true)
    axios.get(`${ADMIN?.GET_SHARE_RENTAL_AUCTION}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data?.success) {
          let userData = data?.data?.filter((v) => v?.userId)
          let adminData = data?.data?.filter((v) => v?.adminId)
          setRentalAuctionData(userData || [])
          setAdminRentalAuctionData(adminData || [])
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setLoading(false)
        errorMessage(userSelectedLanguage?.OppsError)

      })
  }
  const salesAuction = () => {

    setLoading(true)
    axios.get(`${ADMIN?.GET_SALE_AUCTION}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data?.success) {
          let userData = data?.data?.filter((v) => v?.userId)
          let adminData = data?.data?.filter((v) => v?.adminId)
          setSalesAuctionData(userData)
          setAdminSaleAuctionData(adminData)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setLoading(false)
        errorMessage(userSelectedLanguage?.OppsError)

      })
  }
  const golfReservationAuction = () => {

    setLoading(true)
    axios.get(`${ADMIN?.GET_GOLF_RES_AUCTION}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data?.success) {
          let userData = data?.data?.filter((v) => v?.userId)
          let adminData = data?.data?.filter((v) => v?.adminId)
          setGolfReservationAuctionData(userData)
          setAdminGolfReservationAuctionData(adminData)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setLoading(false)
        errorMessage(userSelectedLanguage?.OppsError)

      })
  }
  const deleteRentalAuction = (id) => {
    setDeleteLoader(true)
    let obj = {
      _id: id, adminId: user?._id
    }
    axios.post(ADMIN?.DELETE_SHARE_RENTAL_AUCTION, obj)
      .then((res) => {
        const { data } = res
        setDeleteLoader(false)
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          getShareRentalAuction()
        } else {
          errorMessage(data.message)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setDeleteLoader(false)
        errorMessage(userSelectedLanguage?.OppsError)

      })
  }
  const deleteSaleAuction = (id) => {
    setDeleteLoader(true)
    let obj = {
      _id: id, adminId: user?._id
    }
    axios.post(ADMIN?.DELETE_SALE_AUCTION, obj)
      .then((res) => {
        const { data } = res
        setDeleteLoader(false)
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          salesAuction()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setDeleteLoader(false)
        errorMessage(userSelectedLanguage?.OppsError)

      })
  }
  const deleteGolfReservationAuction = (id) => {
    setDeleteLoader(true)
    let obj = {
      _id: id, adminId: user?._id
    }
    axios.post(ADMIN?.DELETE_GOLF_RES_AUCTION, obj)
      .then((res) => {
        const { data } = res
        setDeleteLoader(false)
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          golfReservationAuction()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setDeleteLoader(false)
        errorMessage(userSelectedLanguage?.OppsError)

      })
  }
  const adminRentalAuction = adminRentalAuctionData?.length && adminRentalAuctionData?.map((v, i) => {
    return {
      TransactionNumber: v?._id?.slice(-7),
      //NameIDphoneOfUploader: [v?.userId?.fullName, v?.userId?._id, v?.userId?.telNo],
      RightPrice: v?.rightPrice,
      Type: v?.ticketId?.membershipType,
      ReservationDate: v?.ticketId?.openingDate,
      Viewbid: v,
      Status: v?.isPayment,
      Action: v,
      AdvanceFee: v
    }
  })
  const rentalAuction = rentalAuctionData?.length && rentalAuctionData?.map((v, i) => {
    return {
      TransactionNumber: v?._id?.slice(-7),
      NameIDphoneOfUploader: [v?.userId?.fullName, v?.userId?._id, v?.userId?.telNo],
      RightPrice: v?.rightPrice,
      Type: v?.ticketId?.membershipType,
      ReservationDate: v?.ticketId?.openingDate,
      Viewbid: v,
      Status: v?.isPayment,
      Action: v,
      AdvanceFee: v
    }
  })
  const adminSaleAuction = adminSaleAuctionData?.length && adminSaleAuctionData?.map((v, i) => {
    return {
      TransactionNumber: v?._id?.slice(-7),
      //NameIDphoneOfUploader: [v?.userId?.fullName, v?.userId?._id, v?.userId?.telNo],
      joiningFee: v?.ticketId?.joiningFee,
      Type: v?.ticketId?.membershipType,
      joiningDate: v?.ticketId?.joiningDate,
      Viewbid: v,
      Status: v?.isPayment,
      Action: v,
      AdvanceFee: v

    }
  })
  const saleAuction = salesAuctionData?.length && salesAuctionData?.map((v, i) => {
    return {
      TransactionNumber: v?._id?.slice(-7),
      NameIDphoneOfUploader: [v?.userId?.fullName, v?.userId?._id, v?.userId?.telNo],
      joiningFee: v?.ticketId?.joiningFee,
      Type: v?.ticketId?.membershipType,
      joiningDate: v?.ticketId?.joiningDate,
      Viewbid: v,
      Status: v?.isPayment,
      Action: v,
      AdvanceFee: v

    }
  })
  const adminGolfReservation = adminGolfReservationAuctionData?.length && adminGolfReservationAuctionData?.map((v, i) => {
    return {
      TransactionNumber: v?._id?.slice(-7),
      //NameIDphoneOfUploader: [v?.userId?.fullName, v?.userId?._id, v?.userId?.telNo],
      Region: v?.golfClub?.region,
      GolfCourse: v?.golfClub?.clubName,
      ReservationDate: v?.reservedDate,
      Viewbid: v,
      Status: v?.isPayment,
      Action: v,
      AdvanceFee: v

    }
  })
  const golfReservation = golfReservationAuctionData?.length && golfReservationAuctionData?.map((v, i) => {
    return {
      TransactionNumber: v?._id?.slice(-7),
      NameIDphoneOfUploader: [v?.userId?.fullName, v?.userId?._id, v?.userId?.telNo],
      Region: v?.golfClub?.region,
      GolfCourse: v?.golfClub?.clubName,
      ReservationDate: v?.reservedDate,
      Viewbid: v,
      Status: v?.isPayment,
      Action: v,
      AdvanceFee: v

    }
  })

  const adminRentalAuctioncolumns = [
    {
      title: userSelectedLanguage?.TransactionNumber,
      dataIndex: 'TransactionNumber',
      key: 'Transaction number',
      render: (text) => <Tooltip placement='top' title={convertTitle(text) || '----'} color={'#5EA29F'}>
        <Button className='tableBtn'>{stringLimiter(convertTitle(text), 12) || '----'}</Button>
      </Tooltip>
    },
    /* {
      title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
      dataIndex: 'NameIDphoneOfUploader',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          {text?.map((v, i) => {
            return (
              <Tooltip placement='top' title={convertTitle(v?.toString() || '----')} color={'#5EA29F'}>
                <p className='namePhoneAndID'>{stringLimiter(convertTitle(v?.toString() || '----'), 12)}</p>
              </Tooltip>
            )
          })}
        </div>
      ),
    }, */
    {
      title: `${userSelectedLanguage?.NameOfUploader}`,
      dataIndex: 'NameIDphoneOfUploader',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          <p className='namePhoneAndID'>{lanKorCondition ? 'admin' : '어드민'}</p>
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.RightPrice,
      dataIndex: 'RightPrice',
      key: 'Right price',
      render: (text) => <p className='tableColoumWidthBig'>{text ? text + '  ' + userSelectedLanguage?.Won : '----'}</p>,
    },
    {
      title: lanKorCondition ? 'Advance Fee' : '수수료',
      dataIndex: 'AdvanceFee',
      key: 'AdvanceFee',
      render: (text) => <p className='tableColoumWidth'>{text?.advanceFee ? text?.advanceFee + '  ' + userSelectedLanguage?.Won : '----'}</p>,
    },
    {
      title: userSelectedLanguage?.Type,
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => <p className='tableColoumWidth'>{lanKorCondition ? text : typesKr[types?.indexOf(text)]?.typesKr || '----'}</p>,
    },
    {
      title: lanKorCondition ? 'ReservationDate' : '마감일자',
      dataIndex: 'ReservationDate',
      key: 'ReservationDate',
      render: (text) => <p className='tableColoumWidth'>{text ? moment(text).format('YYYY-MM-DD') : '----'}</p>,
    },
    {
      title: lanKorCondition ? 'Viewbid' : '경매보기',
      dataIndex: 'Viewbid',
      key: 'Viewbid',
      render: (text) => (
        <>
          <div className='tableColoumWidth'>
            <div className='searchIconDiv'>
              <FiSearch onClick={() => { history.push({ pathname: allPaths?.AUCTIONBIDDETAIL, state: text }) }} />
            </div>
          </div>
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        <>
          {text ?
            <button className='soldOutAuctionTableBtn'>{lanKorCondition ? 'Sold out' : '낙찰 완료'}</button>
            :
            <button className='waitingAuctionTableBtn'>{lanKorCondition ? 'Posted' : '게시 완료'}</button>
          }
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        <div className='tableBtnsDiv'>
          <>
            <Button className='btnTableEdit' type='primary' onClick={() => { setEditRentalData(text) }}> {userSelectedLanguage?.Edit}</Button>
            <Popconfirm
              title={`${text?.userId?.fullName} 티켓을 삭제하시겠습니까?`}
              okText={userSelectedLanguage?.Delete}
              cancelText={userSelectedLanguage?.Cancel}
              okButtonProps={{
                type: 'primary',
                className: 'form-button'
              }}
              cancelButtonProps={{ style: { borderRadius: 4 } }}
              onConfirm={() => deleteRentalAuction(text?._id)}
            >
              <Button loading={deleteLoader} className='DeleteBtn' type='primary' > {userSelectedLanguage?.Delete}</Button>
            </Popconfirm>
          </>
        </div>
      ),
    },
  ]
  const rentalAuctioncolumns = [
    {
      title: userSelectedLanguage?.TransactionNumber,
      dataIndex: 'TransactionNumber',
      key: 'Transaction number',
      render: (text) => <Tooltip placement='top' title={convertTitle(text) || '----'} color={'#5EA29F'}>
        <Button className='tableBtn'>{stringLimiter(convertTitle(text), 12) || '----'}</Button>
      </Tooltip>
    },
    {
      title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
      dataIndex: 'NameIDphoneOfUploader',
      key: 'Name and Phone Number',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          {text?.map((v, i) => {
            return (
              <Tooltip placement='top' title={convertTitle(v || '----')} color={'#5EA29F'}>
                <p className='namePhoneAndID'>{stringLimiter(convertTitle(v || '----'), 12)}</p>
              </Tooltip>
            )
          })}
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.RightPrice,
      dataIndex: 'RightPrice',
      key: 'Right price',
      render: (text) => <p className='tableColoumWidthBig'>{text ? text + '  ' + userSelectedLanguage?.Won : '----'}</p>,
    },
    {
      title: lanKorCondition ? 'Advance Fee' : '수수료',
      dataIndex: 'AdvanceFee',
      key: 'AdvanceFee',
      render: (text) => <p className='tableColoumWidth'>{text?.advanceFee ? text?.advanceFee + '  ' + userSelectedLanguage?.Won : '----'}</p>,
    },
    {
      title: userSelectedLanguage?.Type,
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => <p className='tableColoumWidth'>{lanKorCondition ? text : typesKr[types?.indexOf(text)]?.typesKr || '----'}</p>,
    },
    {
      title: lanKorCondition ? 'ReservationDate' : '마감일자',
      dataIndex: 'ReservationDate',
      key: 'ReservationDate',
      render: (text) => <p className='tableColoumWidth'>{text ? moment(text).format('YYYY-MM-DD') : '----'}</p>,
    },
    {
      title: lanKorCondition ? 'Viewbid' : '경매보기',
      dataIndex: 'Viewbid',
      key: 'Viewbid',
      render: (text) => (
        <>
          <div className='tableColoumWidth'>
            <div className='searchIconDiv'>
              <FiSearch onClick={() => { history.push({ pathname: allPaths?.AUCTIONBIDDETAIL, state: text }) }} />
            </div>
          </div>
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        <>
          {text ?
            <button className='soldOutAuctionTableBtn'>{lanKorCondition ? 'Sold out' : '낙찰 완료'}</button>
            :
            <button className='waitingAuctionTableBtn'>{lanKorCondition ? 'Posted' : '게시 완료'}</button>
          }
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        <div className='tableBtnsDiv'>
          <>
            <Button className='btnTableEdit' type='primary' onClick={() => { setEditRentalData(text) }}> {userSelectedLanguage?.Edit}</Button>
            <Popconfirm
              title={`${text?.userId?.fullName} 티켓을 삭제하시겠습니까?`}
              okText={userSelectedLanguage?.Delete}
              cancelText={userSelectedLanguage?.Cancel}
              okButtonProps={{
                type: 'primary',
                className: 'form-button'
              }}
              cancelButtonProps={{ style: { borderRadius: 4 } }}
              onConfirm={() => deleteRentalAuction(text?._id)}
            >
              <Button loading={deleteLoader} className='DeleteBtn' type='primary' > {userSelectedLanguage?.Delete}</Button>
            </Popconfirm>
          </>
        </div>
      ),
    },
  ]
  const adminSaleAuctioncolumns = [
    {
      title: userSelectedLanguage?.TransactionNumber,
      dataIndex: 'TransactionNumber',
      key: 'Transaction number',
      render: (text) => <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
        <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 12)}</Button>
      </Tooltip>
    },
    /* {
     title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
     dataIndex: 'NameIDphoneOfUploader',
     render: (text) => (
       <div className='namePhoneAndIDiv'>
         {text?.map((v, i) => {
           return (
             <Tooltip placement='top' title={convertTitle(v?.toString() || '----')} color={'#5EA29F'}>
               <p className='namePhoneAndID'>{stringLimiter(convertTitle(v?.toString() || '----'), 12)}</p>
             </Tooltip>
           )
         })}
       </div>
     ),
   }, */
    {
      title: `${userSelectedLanguage?.NameOfUploader}`,
      dataIndex: 'NameIDphoneOfUploader',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          <p className='namePhoneAndID'>{lanKorCondition ? 'admin' : '어드민'}</p>
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.entrancefee,
      dataIndex: 'joiningFee',
      key: 'joiningFee',
      render: (text) => <p className='tableColoumWidthBig'>{text ? text + '  ' + userSelectedLanguage?.Won : '----'}</p>,
    },
    {
      title: lanKorCondition ? 'Advance Fee' : '수수료',
      dataIndex: 'AdvanceFee',
      key: 'AdvanceFee',
      render: (text) => <p className='tableColoumWidth'>{text?.advanceFee ? text?.advanceFee + '  ' + userSelectedLanguage?.Won : '----'}</p>,
    },
    {
      title: userSelectedLanguage?.Type,
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => <p className='tableColoumWidth'>{lanKorCondition ? text : typesKr[types?.indexOf(text)]?.typesKr || '----'}</p>,
    },
    {
      title: lanKorCondition ? 'JoiningDate' : '입회기간',
      dataIndex: 'joiningDate',
      key: 'joiningDate',
      render: (text) => {
        return (<p className='tableColoumWidth'>{text?.date1 || text?.date2 ? moment(text?.date1).format('YYYY-MM-DD') + '/' + moment(text?.date2).format('YYYY-MM-DD') : '----'}</p>)
      },
    },
    {
      title: lanKorCondition ? 'Viewbid' : '경매보기',
      dataIndex: 'Viewbid',
      key: 'Viewbid',
      render: (text) => (
        <>
          <div className='tableColoumWidth'>
            <div className='searchIconDiv'>
              <FiSearch onClick={() => { history.push({ pathname: allPaths?.AUCTIONBIDDETAIL, state: text }) }} />
            </div>
          </div>
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        <>
          {text ?
            <button className='soldOutAuctionTableBtn'>{lanKorCondition ? 'Sold out' : '낙찰 완료'}</button>
            :
            <button className='waitingAuctionTableBtn'>{lanKorCondition ? 'Posted' : '게시 완료'}</button>
          }
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        <div className='tableBtnsDiv'>
          <>
            <Button className='btnTableEdit' type='primary' onClick={() => setEditSaleData(text)}> {userSelectedLanguage?.Edit}</Button>
            <Popconfirm
              title={`${text?.userId?.fullName} 티켓을 삭제하시겠습니까?`}
              okText={userSelectedLanguage?.Delete}
              cancelText={userSelectedLanguage?.Cancel}
              okButtonProps={{
                type: 'primary',
                className: 'form-button'
              }}
              cancelButtonProps={{ style: { borderRadius: 4 } }}
              onConfirm={() => deleteSaleAuction(text?._id)}
            >
              <Button loading={deleteLoader} className='DeleteBtn' type='primary' > {userSelectedLanguage?.Delete}</Button>
            </Popconfirm>
          </>
        </div>
      ),
    },
  ]
  const saleAuctioncolumns = [
    {
      title: userSelectedLanguage?.TransactionNumber,
      dataIndex: 'TransactionNumber',
      key: 'Transaction number',
      render: (text) => <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
        <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 12)}</Button>
      </Tooltip>
    },
    {
      title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
      dataIndex: 'NameIDphoneOfUploader',
      key: 'Name and Phone Number',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          {text?.map((v, i) => {
            return (
              <Tooltip placement='top' title={convertTitle(v || '----')} color={'#5EA29F'}>
                <p className='namePhoneAndID'>{stringLimiter(convertTitle(v || '----'), 12)}</p>
              </Tooltip>
            )
          })}
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.entrancefee,
      dataIndex: 'joiningFee',
      key: 'joiningFee',
      render: (text) => <p className='tableColoumWidthBig'>{text ? text + '  ' + userSelectedLanguage?.Won : '----'}</p>,
    },
    {
      title: lanKorCondition ? 'Advance Fee' : '수수료',
      dataIndex: 'AdvanceFee',
      key: 'AdvanceFee',
      render: (text) => <p className='tableColoumWidth'>{text?.advanceFee ? text?.advanceFee + '  ' + userSelectedLanguage?.Won : '----'}</p>,
    },
    {
      title: userSelectedLanguage?.Type,
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => <p className='tableColoumWidth'>{lanKorCondition ? text : typesKr[types?.indexOf(text)]?.typesKr || '----'}</p>,
    },
    {
      title: lanKorCondition ? 'JoiningDate' : '입회기간',
      dataIndex: 'joiningDate',
      key: 'joiningDate',
      render: (text) => {
        return (<p className='tableColoumWidth'>{text?.date1 || text?.date2 ? moment(text?.date1).format('YYYY-MM-DD') + '/' + moment(text?.date2).format('YYYY-MM-DD') : '----'}</p>)
      },
    },
    {
      title: lanKorCondition ? 'Viewbid' : '경매보기',
      dataIndex: 'Viewbid',
      key: 'Viewbid',
      render: (text) => (
        <>
          <div className='tableColoumWidth'>
            <div className='searchIconDiv'>
              <FiSearch onClick={() => { history.push({ pathname: allPaths?.AUCTIONBIDDETAIL, state: text }) }} />
            </div>
          </div>
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        <>
          {text ?
            <button className='soldOutAuctionTableBtn'>{lanKorCondition ? 'Sold out' : '낙찰 완료'}</button>
            :
            <button className='waitingAuctionTableBtn'>{lanKorCondition ? 'Posted' : '게시 완료'}</button>
          }
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        <div className='tableBtnsDiv'>
          <>
            <Button className='btnTableEdit' type='primary' onClick={() => setEditSaleData(text)}> {userSelectedLanguage?.Edit}</Button>
            <Popconfirm
              title={`${text?.userId?.fullName} 티켓을 삭제하시겠습니까?`}
              okText={userSelectedLanguage?.Delete}
              cancelText={userSelectedLanguage?.Cancel}
              okButtonProps={{
                type: 'primary',
                className: 'form-button'
              }}
              cancelButtonProps={{ style: { borderRadius: 4 } }}
              onConfirm={() => deleteSaleAuction(text?._id)}
            >
              <Button loading={deleteLoader} className='DeleteBtn' type='primary' > {userSelectedLanguage?.Delete}</Button>
            </Popconfirm>
          </>
        </div>
      ),
    },
  ]
  const adminGolfResAuctioncolumns = [
    {
      title: userSelectedLanguage?.TransactionNumber,
      dataIndex: 'TransactionNumber',
      key: 'Transaction number',
      render: (text) => <Tooltip placement='top' title={convertTitle(text)} color={'#5EA29F'}>
        <Button className='tableBtn'>{stringLimiter(convertTitle(text), 12)}</Button>
      </Tooltip>
    },
    /* {
     title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
     dataIndex: 'NameIDphoneOfUploader',
     render: (text) => (
       <div className='namePhoneAndIDiv'>
         {text?.map((v, i) => {
           return (
             <Tooltip placement='top' title={convertTitle(v?.toString() || '----')} color={'#5EA29F'}>
               <p className='namePhoneAndID'>{stringLimiter(convertTitle(v?.toString() || '----'), 12)}</p>
             </Tooltip>
           )
         })}
       </div>
     ),
   }, */
    {
      title: `${userSelectedLanguage?.NameOfUploader}`,
      dataIndex: 'NameIDphoneOfUploader',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          <p className='namePhoneAndID'>{lanKorCondition ? 'admin' : '어드민'}</p>
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.Region,
      dataIndex: 'Region',
      key: 'Region',
      render: (text) => <p className='tableColoumWidthBig'>{lanKorCondition ? text : regionsKr[regions.indexOf(text)]?.TitleKr || '----'}</p>,
    },
    {
      title: userSelectedLanguage?.GolfCourse,
      dataIndex: 'GolfCourse',
      key: 'GolfCourse',
      render: (text) => <p className='tableColoumWidthBig'>{text}</p>,
    },
    {
      title: lanKorCondition ? 'Advance Fee' : '수수료',
      dataIndex: 'AdvanceFee',
      key: 'AdvanceFee',
      render: (text) => <p className='tableColoumWidth'>{text?.advanceFee ? text?.advanceFee + '  ' + userSelectedLanguage?.Won : '----'}</p>,
    },
    {
      title: lanKorCondition ? 'ReservationDate' : '예약 일시',
      dataIndex: 'ReservationDate',
      key: 'ReservationDate',
      render: (text) => <p className='tableColoumWidth'>{text?.date1 || text?.date2 ? moment(text?.date1).format('YYYY-MM-DD') + '/' + moment(text?.date2).format('YYYY-MM-DD') : '----'}</p>,
    },
    {
      title: lanKorCondition ? 'Viewbid' : '경매보기',
      dataIndex: 'Viewbid',
      key: 'Viewbid',
      render: (text) => (
        <>
          <div className='tableColoumWidth'>
            <div className='searchIconDiv'>
              <FiSearch onClick={() => { history.push({ pathname: allPaths?.AUCTIONBIDDETAIL, state: text }) }} />
            </div>
          </div>
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        <>
          {text ?
            <button className='soldOutAuctionTableBtn'>{lanKorCondition ? 'Sold out' : '낙찰 완료'}</button>
            : <button className='waitingAuctionTableBtn'>{lanKorCondition ? 'posted' : '게시 완료'}</button>}
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        <div className='tableBtnsDiv'>
          <>
            <Button className='btnTableEdit' type='primary' onClick={() => { setEditGolfResAuctionData(text) }}> {userSelectedLanguage?.Edit}</Button>
            <Popconfirm
              title={`${text?.userId?.fullName} 티켓을 삭제하시겠습니까?`}
              okText={userSelectedLanguage?.Delete}
              cancelText={userSelectedLanguage?.Cancel}
              okButtonProps={{
                type: 'primary',
                className: 'form-button'
              }}
              cancelButtonProps={{ style: { borderRadius: 4 } }}
              onConfirm={() => deleteGolfReservationAuction(text?._id)}
            >
              <Button loading={deleteLoader} className='DeleteBtn' type='primary' > {userSelectedLanguage?.Delete}</Button>
            </Popconfirm>
          </>
        </div>
      ),
    },
  ]
  const golfResAuctioncolumns = [
    {
      title: userSelectedLanguage?.TransactionNumber,
      dataIndex: 'TransactionNumber',
      key: 'Transaction number',
      render: (text) => <Tooltip placement='top' title={convertTitle(text)} color={'#5EA29F'}>
        <Button className='tableBtn'>{stringLimiter(convertTitle(text), 12)}</Button>
      </Tooltip>
    },
    {
      title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
      dataIndex: 'NameIDphoneOfUploader',
      key: 'Name and Phone Number',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          {text?.map((v, i) => {
            return (
              <Tooltip placement='top' title={convertTitle(v)} color={'#5EA29F'}>
                <p className='namePhoneAndID'>{stringLimiter(convertTitle(v), 12)}</p>
              </Tooltip>
            )
          })}
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.Region,
      dataIndex: 'Region',
      key: 'Region',
      render: (text) => <p className='tableColoumWidthBig'>{lanKorCondition ? text : regionsKr[regions.indexOf(text)]?.TitleKr || '----'}</p>,
    },
    {
      title: userSelectedLanguage?.GolfCourse,
      dataIndex: 'GolfCourse',
      key: 'GolfCourse',
      render: (text) => <p className='tableColoumWidthBig'>{text}</p>,
    },
    {
      title: '주소',
      dataIndex: ['Action','golfClub','clubAddress'],
      key: 'GolfCourse',
      render: (text) => <p className='tableColoumWidthBig'>{text}</p>,
    },
    
    {
      title: lanKorCondition ? 'Advance Fee' : '수수료',
      dataIndex: 'AdvanceFee',
      key: 'AdvanceFee',
      render: (text) => <p className='tableColoumWidth'>{text?.advanceFee ? text?.advanceFee + '  ' + userSelectedLanguage?.Won : '----'}</p>,
    },
    {
      title: lanKorCondition ? 'Green Fee' : '그린피',
      dataIndex: ['AdvanceFee','greenFee'],
      key: 'greenFee',
      render: (text) => <p className='tableColoumWidth'>{!!text ? text + '  ' + userSelectedLanguage?.Won : '----'}</p>,
    },
    {
      title: lanKorCondition ? 'Transfer Fee' : '양도비(팀)',
      dataIndex: ['AdvanceFee','transferFee'],
      key: 'transferFee',
      render: (text) => <p className='tableColoumWidth'>{!!text ? text + '  ' + userSelectedLanguage?.Won : '----'}</p>,
    },

    {
      title: lanKorCondition ? 'ReservationDate' : '예약 일시',
      dataIndex: 'ReservationDate',
      key: 'ReservationDate',
      render: (text) => <p className='tableColoumWidth'>{text?.date1 || text?.date2 ? moment(text?.date1).format('YYYY-MM-DD') + '/' + moment(text?.date2).format('YYYY-MM-DD') : '----'}</p>,
    },
    {
      title: lanKorCondition ? 'Viewbid' : '경매보기',
      dataIndex: 'Viewbid',
      key: 'Viewbid',
      render: (text) => (
        <>
          <div className='tableColoumWidth'>
            <div className='searchIconDiv'>
              <FiSearch onClick={() => { history.push({ pathname: allPaths?.AUCTIONBIDDETAIL, state: text }) }} />
            </div>
          </div>
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        <>
          {text ?
            <button className='soldOutAuctionTableBtn'>{lanKorCondition ? 'Sold out' : '낙찰 완료'}</button>
            : <button className='waitingAuctionTableBtn'>{lanKorCondition ? 'posted' : '게시 완료'}</button>}
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        <div className='tableBtnsDiv'>
          <>
            <Button className='btnTableEdit' type='primary' onClick={() => { setEditGolfResAuctionData(text) }}> {userSelectedLanguage?.Edit}</Button>
            <Popconfirm
              title={`${text?.userId?.fullName} 티켓을 삭제하시겠습니까?`}
              okText={userSelectedLanguage?.Delete}
              cancelText={userSelectedLanguage?.Cancel}
              okButtonProps={{
                type: 'primary',
                className: 'form-button'
              }}
              cancelButtonProps={{ style: { borderRadius: 4 } }}
              onConfirm={() => deleteGolfReservationAuction(text?._id)}
            >
              <Button loading={deleteLoader} className='DeleteBtn' type='primary' > {userSelectedLanguage?.Delete}</Button>
            </Popconfirm>
          </>
        </div>
      ),
    },
  ]

  console.log('filteredgolfReservationData', filteredgolfReservationData)
  return (
    <>
      <ButtonLogout props={props} />
      <div className='membershipBox'>
        <div className='goldReservationHeader'>
          <div className='golfHeaderText'>
            <h3>{userSelectedLanguage?.GolfAuctionPostManagement}</h3>
          </div>
          <div className='headersearch'>
            <Select
              className='dropdown'
              defaultValue={dropDown}
              onChange={(e) => {
                setTableRow(e)
              }}
            >
              {arr?.map((v, i) => {
                return <Option key={v.value}>{v?.Viewby}</Option>
              })}
            </Select>
            <Input
              className='searchgolfReservation'
              placeholder={userSelectedLanguage?.PleaseSearchATerm}
              onChange={(e) => setSearch(e?.target?.value)}
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
        <Tabs defaultActiveKey='1'>
          <Tabs.TabPane tab={userSelectedLanguage?.SharedRentalAuction} key='1'>
            <>
              <div
                style={{
                  margin: '0px auto',
                  display: 'flex',
                  width: '95%',
                  justifyContent: 'flex-end'
                }}>
                <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => setShareRentalModal(true)}>{lanKorCondition ? 'Add share rental auction' : '공유대여 경매 추가'}</Button>
                <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => setShowAdminShareData(true)}>{lanKorCondition ? 'Admin Share Rental auction' : '어드민 공유대여 경매'}</Button>
                <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => setShowAdminShareData(false)}>{lanKorCondition ? `User's Share Rental auction` : '공유대여 경매 리스트 '}</Button>
              </div>
              <div className='tableDivManagement'>
                <Table
                  pagination={{ pageSize: tableRow }}
                  dataSource={showAdminShareData ? adminRentalAuction : filteredrentalAuctionData}
                  columns={showAdminShareData ? adminRentalAuctioncolumns : rentalAuctioncolumns}
                  scroll={{ x: 700 }}
                  size='middle'
                />
              </div>
            </>
          </Tabs.TabPane>
          <Tabs.TabPane tab={userSelectedLanguage?.SaleAuction} key='2'>
            <>
              <div
                style={{
                  margin: '0px auto',
                  display: 'flex',
                  width: '95%',
                  justifyContent: 'flex-end'
                }}>
                <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => setSaleAuctionModal(true)}>{lanKorCondition ? 'Add sale auction' : '매매경매 추가'}</Button>
                <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => setShowAdminSaleData(true)}>{lanKorCondition ? 'Admin Sale auction' : '어드민 매매경매'}</Button>
                <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => setShowAdminSaleData(false)}>{lanKorCondition ? `User's Sale auction` : '매매경매 리스트 보기'}</Button>
              </div>
              <div className='tableDivManagement'>
                <Table
                  pagination={{ pageSize: tableRow }}
                  dataSource={showAdminSaleData ? adminSaleAuction : filteredsaleAuctionData}
                  columns={showAdminSaleData ? adminSaleAuctioncolumns : saleAuctioncolumns}
                  scroll={{ x: 700 }}
                  size='middle'
                />
              </div>
            </>
          </Tabs.TabPane>
          <Tabs.TabPane tab={userSelectedLanguage?.GolfReservationAuction} key='3'>
            <>
              <div
                style={{
                  margin: '0px auto',
                  display: 'flex',
                  width: '95%',
                  justifyContent: 'flex-end'
                }}>
                <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => setUploadGolfResRequest(true)}>{lanKorCondition ? 'Add Golf Reservation auction' : '골프예약 경매 추가'}</Button>
                <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => setShowAdminResData(true)}>{lanKorCondition ? 'Admin Golf reservation auction' : '어드민 골프예약 경매'}</Button>
                <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => setShowAdminResData(false)}>{lanKorCondition ? `User's Golf reservation auction` : '골프예약 리스트 보기'}</Button>
              </div>
              <div className='tableDivManagement'>
                <Table
                  pagination={{ pageSize: tableRow }}
                  dataSource={showAdminResData ? adminGolfReservationAuctionData : filteredgolfReservationData}
                  columns={showAdminResData ? adminGolfResAuctioncolumns : golfResAuctioncolumns}
                  // dataSource={showAdminSaleData ? adminSaleAuction : saleAuction}
                  // columns={showAdminSaleData ? adminSaleAuctioncolumns : saleAuctioncolumns}
                  scroll={{ x: 700 }}
                  size='middle'
                />
              </div>
            </>
          </Tabs.TabPane>
        </Tabs>
      </div>

      {(editRentalData?._id) ?
        <RentalEdit shareRentalModal={shareRentalModal} getShareRentalAuction={getShareRentalAuction} onCancel={() => setEditRentalData({})} editRentalData={editRentalData}  {...props} lanKorCondition={lanKorCondition} />
        : null}
      {(shareRentalModal) ?
        <AddShareAuction shareRentalModal={shareRentalModal} onCancel={() => setShareRentalModal(false)}  {...props} />
        : null}
      {saleAuctionModal ?
        <AddSaleAuction saleAuctionModal={saleAuctionModal} onCancel={() => setSaleAuctionModal(false)}   {...props} />
        : null}
      {(editSaleData?._id) ?
        <SaleEdit onCancel={() => setEditSaleData({})} editSaleData={editSaleData} salesAuction={salesAuction}  {...props} lanKorCondition={lanKorCondition} />
        : null}
      {uploadGolfResRequest &&
        <AddGolfReservation uploadGolfResRequest={uploadGolfResRequest} golfReservationAuction={golfReservationAuction} onCancel={() => setUploadGolfResRequest(false)} {...props} />
      }
      {(editGolfResAuctionData?._id) ?
        <GolfReservationEdit golfReservationAuction={golfReservationAuction} onCancel={() => setEditGolfResAuctionData({})} editGolfResAuctionData={editGolfResAuctionData}  {...props} lanKorCondition={lanKorCondition} />
        : null}
    </>
  )
}
export default GolfAuction
