import { PlusCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input, Modal } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ADMIN } from '../../../utils/apis';
import { types, typesKr, typesOnlyKr } from '../../../utils/constants';
import { errorMessage, successMessage } from '../../../utils/helpers';
import DatePickerTicketShare from '../AddModal/DatePickerTicketShare';

const EditExchange = (props) => {
    const { user, fun, language, lanKorCondition, handleCancel, open, editData } = props
    const userID = user?._id
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [memberFee, setMemberFee] = useState(editData?.buyerTicketId?.memberFee)
    const [nonMemberFee, setNonMemberFee] = useState(editData?.buyerTicketId?.NONmemberFee)
    const [rightPrice, setRightPrice] = useState(editData?.rightPrice)
    // const [expiry, setExpiry] = useState(moment().format('YYYY-MM-DD'))
    const [expiry, setExpiry] = useState(null)

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: language?.Pleaseselectdate,
            }
        ]
    }
   
    useEffect(() => {
        setExpiryDate(editData?.usableDate)
    }, [])
    const onFinish = (fieldsValue) => {

        if (!nonMemberFee) {
            return errorMessage(language?.PleaseEnterNon_MemberFee)
        }
        if (!memberFee) {
            return errorMessage(language?.PleaseEnterMemberFee)
        }

        setLoading(true)
        let joinDate = {
            date1: fieldsValue['usableDate']?.format('YYYY-MM-DD'),
            date2: fieldsValue['expirationDate']?.format('YYYY-MM-DD')
        }
        fieldsValue._id = editData?._id
        fieldsValue.adminId = userID
        fieldsValue.rightPrice = (+fieldsValue.rightPrice)
        fieldsValue.usableDate = joinDate.date1
        fieldsValue.expirationDate = expiry
        delete fieldsValue.Fee
        console.log('fieldsValue', fieldsValue)
        // return true

        axios.post(ADMIN.EDIT_TICKET_EXCHANGE, fieldsValue)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    form.resetFields()
                    handleCancel()
                    setRightPrice('')
                    fun()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const setExpiryDate = (e) => {
        // console.log(e)
        let date = moment(e?._d).add(-1, 'days').format('YYYY-MM-DD HH:MM')
        // console.log('date', date)
        form.setFieldValue('usableDate', moment(date, 'YYYY-MM-DD HH:MM'))
        const weekday = moment(date).format('dddd')
        const isWeekend = weekday === 'Sunday' || weekday === 'Saturday' || weekday === '토' || weekday === '일' ? true : false
        // console.log('*****', isWeekend, weekday)
        let setExpDate
        if (!isWeekend) {
            setExpDate = moment(date).subtract(3, 'days').format('YYYY-MM-DD HH:MM')
            form.setFieldValue('expirationDate', moment(setExpDate, 'YYYY-MM-DD HH:MM'))
            setExpiry(setExpDate)
        } else {
            setExpDate = moment(date).subtract(7, 'days').format('YYYY-MM-DD HH:MM')
            form.setFieldValue('expirationDate', moment(setExpDate, 'YYYY-MM-DD HH:MM'))
            setExpiry(setExpDate)
        }
    }


    useEffect(() => {
        setMemberFee(editData?.buyerTicketId?.memberFee)
        setNonMemberFee(editData?.buyerTicketId?.NONmemberFee)
    }, [])
    console.log('editData', editData)
    return (
        <>
            <center>
                <Modal
                    open={open}
                    onCancel={handleCancel}
                    className='responsiveModal'
                    footer={null}
                    style={{ maxWidth: 800, top: '10px', }}
                >
                    <div
                        className='scroll_class'
                        style={{
                            height: 'auto',
                            width: '100%',
                            maxHeight: '85vh',
                            overflowY: 'scroll',
                            marginTop: '12px'
                        }}>
                        <div className='modal_main_div' >
                            <center><h2>{lanKorCondition ? 'Edit Ticket Sharing....' : 'Edit Ticket Sharing....'}</h2></center>
                            <br />

                            <div className='paddongThirty'>
                                <Form requiredMark={false}
                                    form={form}
                                    name='time_related_controls'
                                    // {...formItemLayout}
                                    onFinish={onFinish}>

                                    <Form.Item
                                        name='membership'
                                        label={language?.MembershipTicketsharing}
                                        initialValue={editData?.buyerTicketId?.membershipCardName}
                                    // rules={[
                                    //     {
                                    //         validator: async (names) => {

                                    //             if (!selectedTicket?.golfClub?.clubName) {
                                    //                 return Promise.reject(new Error(language?.ChooseYourticket))
                                    //             }
                                    //         }
                                    //     }
                                    // ]}

                                    >
                                        <Input
                                            disabled
                                        />
                                    </Form.Item>


                                    <div className='disabled_text scroll_class'>
                                        <table>
                                            <tr style={{ height: 30 }}>
                                                <td>{language?.GolfClubName}</td>
                                                <td>{editData?.buyerTicketId?.golfClub?.clubName || '-----------------'}</td>
                                            </tr>
                                            <br />
                                            <tr style={{ height: 30 }}>
                                                <td>{language?.golfclubAddress}</td>
                                                <td>{editData?.buyerTicketId?.golfClub?.clubAddress || '-----------------'}</td>
                                            </tr>
                                            <br />
                                            <tr style={{ height: 30 }}>
                                                <td>{language?.Type}</td>
                                                {editData?.buyerTicketId?.membershipType ?
                                                    lanKorCondition ? editData?.buyerTicketId?.membershipType :
                                                        typesKr[typesOnlyKr?.indexOf(editData?.buyerTicketId?.membershipType)]?.typesKr ?
                                                            typesKr[typesOnlyKr?.indexOf(editData?.buyerTicketId?.membershipType)]?.typesKr :
                                                            typesKr[types?.indexOf(editData?.buyerTicketId?.membershipType)]?.typesKr :
                                                    '-----------------'
                                                }
                                            </tr>

                                            <tr>
                                                <td>{language?.MemberFeeORNonFee}</td>
                                                <td>{editData?.buyerTicketId?.memberFee ? editData?.buyerTicketId?.memberFee + ' ' + language?.Won : '-------'}  / {editData?.buyerTicketId?.NONmemberFee ? editData?.buyerTicketId?.NONmemberFee + ' ' + language?.Won : '------'}</td>
                                            </tr>
                                            <br />

                                        </table>
                                    </div>

                                    <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />
                                    <Form.Item
                                        name='rightPrice'
                                        initialValue={rightPrice}
                                        label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.RightPrice}</h4>}
                                        rules={[
                                            {
                                                required: true,
                                                message: language?.PleaseInputRightPrice,
                                            },
                                        ]}
                                    >
                                        <div>
                                            <Input
                                                suffix={language?.KRW}
                                                maxLength={7}
                                                value={rightPrice}
                                                onChange={(e) => setRightPrice(e.target.value?.replace(/[^0-9]/g, ''))}
                                            />
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        name='usableDate'
                                        label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.UsableDate}</h4>}
                                        {...config}
                                    >
                                        <DatePickerTicketShare
                                            defaultValue={editData?.usableDate}
                                            placeholder={language?.Usabledate}
                                            setDate={setExpiryDate}
                                            showTime={true}
                                            format={' YYYY-MM-DD h:mm a'} />
                                    </Form.Item>

                                    <Form.Item
                                        name='termsConditions1'
                                        label={language?.TermCondition}
                                        valuePropName='checked'
                                        initialValue={true}
                                        hasFeedback
                                        rules={[
                                            {
                                                validator: (_, value) =>
                                                    value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                            }
                                        ]}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                    <div className='agreement scroll_class' style={{ width: '100%' }}>
                                        <p>{language?.TAC1}</p>
                                        <p>{language?.TAC2}</p>
                                        <p>{language?.TAC3}</p>
                                        <p>{language?.TAC4}</p>
                                        <p>{language?.TAC5}</p>
                                        <p>{language?.TAC6}</p>
                                        <p>{language?.TAC7}</p>
                                    </div>
                                    <br />
                                    <Button loading={loading}
                                        className='req_exc_btn'
                                        type='primary'
                                        htmlType='submit'>{lanKorCondition ? 'Submit' : 'Submit'}</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal>
            </center>

        </>
    );
};

export default EditExchange;