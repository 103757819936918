import { Button, Form, Input, Modal, Upload, Switch } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { ADMIN } from '../../utils/apis'
import { errorMessage, successNotification } from '../../utils/helpers'
import { UploadOutlined } from '@ant-design/icons'

const formLayout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
}

const EditBanners = (props) => {
    const { user, editData, onCancel, language, lanKorCondition } = props
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [fileList, setFileList] = useState([])
    const [isActive, setIsActive] = useState(editData?.active || false)


    const handleCancel = () => {
        onCancel()
        form.resetFields()
    }
    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(lanKorCondition ? 'You can only upload Images!' : '이미지만 업로드 해주세요')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const onFinishBanners = (values) => {
        // values._id = editData?._id,
        //     values.adminId = user?._id
        let formData = new FormData()
        formData.append('adminId', user?._id)
        formData.append('_id', editData?._id)
        formData.append('file', values?.file?.file)
        formData.append('active', isActive)

        // return true
        setLoading(true)
        axios.post(ADMIN?.EDIT_BANNER, formData)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(lanKorCondition ? data.message : data.messageKr)
                    handleCancel()
                } else {
                    errorMessage(lanKorCondition ? data.message : data.messageKr)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                setLoading(false)
                errorMessage(language?.OppsError)
            })
    }

    return (
        <Modal footer={null} title={language?.UpdateBanner} open={editData?._id} onCancel={handleCancel}>
            <Form
                form={form}
                {...formLayout}
                onFinish={onFinishBanners}
                requiredMark={false}
            >
                <Form.Item
                    label={language?.File}
                    name='file'
                    // rules={[{ required: true, message: 'Please input  File!' }]}
                    initialValue={editData?._id ? editData?.file : null}
                >
                    <Upload
                        name='file'
                        multiple={false}
                        beforeUpload={() => false}
                        accept='image/png, image/jpeg'
                        onChange={normFile}
                        fileList={fileList}
                    >
                        <Button className='GolfClubInputFeild BtnUpload' icon={<UploadOutlined color='#5EA29F' />}>Upload</Button>
                        {editData?._id && !fileList?.length ? editData?.fileName : null}
                    </Upload>
                </Form.Item>
                <Form.Item
                    label={language?.activeBanner}
                    name='active'>
                    <Switch onChange={(e) => setIsActive(e)} checkedChildren={lanKorCondition ? 'activated ' : '활성'} unCheckedChildren={lanKorCondition ? 'unactivated ' : '비활성 '} defaultChecked={isActive} />
                </Form.Item>
                <Button className='req_exc_btn1' htmlType='submit' loading={loading} type='primary'>{lanKorCondition ? 'Add' : '추가'}</Button>
            </Form>
        </Modal>
    )
}
export default EditBanners