import React, { useEffect, useState } from 'react'
import { Select, Button, Input, Table, Divider, Modal, DatePicker, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { ButtonLogout } from '../../Components'
import { FiSearch } from 'react-icons/fi'
import { HiPlusCircle } from 'react-icons/hi'
import { RiCheckboxBlankFill } from 'react-icons/ri';
import axios from 'axios'
import { ADMIN } from '../../utils/apis'
import { convertTitle, errorMessage, stringLimiter, successNotification } from '../../utils/helpers'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { allPaths, types, typesKr } from '../../utils/constants'
import { FcCancel } from "react-icons/fc"
import AddTicketSellModal from './AddModal/AddTicketSale'
import EditTicketSell from './EditModal/EditTicketSell'
import EditTicketRequestBuy from './EditModal/EditTicketRequestBuy'
const { Option } = Select
const { TextArea } = Input;

const MembershipSales = (props) => {
  const userSelectedLanguage = props?.language
  const history = props?.history
  const [dropDown, setdropDown] = useState(6 + '개 보기')
  const [tableRow, setTableRow] = useState(6)
  const [modalOpen, setModalOpen] = useState(false)
  const [ticketSellReq, setTicketSellReq] = useState([])
  const [adminTicketSellReq, setAdminTicketSellReq] = useState([])
  const [addTicketSale, setAddTicketSale] = useState(false)
  const [loading, setLoading] = useState(false)
  const [approveLoader, setApproveLoader] = useState({})
  const [cancelLoader, setCancelLoader] = useState({})
  const [deleteLoader, setDeleteLoader] = useState({})
  const [adminData, setAdminData] = useState(false)
  const [editTicketSellData, setEditTicketSellData] = useState({})
  const [editTicketSellReq, setEditTicketSellReq] = useState({})
  const [filteredData, setFilteredData] = useState([])
  const [search, setSearch] = useState(null)

  const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const lanKorCondition = SelectedLanguage === 'english'
  let arr = [{ value: 10, Viewby: userSelectedLanguage?.Viewby + ' 10' }, { value: 8, Viewby: userSelectedLanguage?.Viewby + ' 8' }, { value: 6, Viewby: 6 + '개 보기' },]

  useEffect(() => {
    if (search) {
      let filterData = TicketSellData?.filter((v) => v?.NameIDphoneOfUploader?.[0]?.includes(search) || v?.NameIDphoneOfUploader?.[1]?.includes(search) || v?.NameIDphoneOfUploader?.[2]?.includes(search)) || []
      setFilteredData(filterData)
    }else{
      setFilteredData(TicketSellData)
    }
  }, [ticketSellReq,search])

  const getTicketSellReq = () => {
    setLoading(true)
    axios.get(`${ADMIN?.GET_TICKET_SELL_REQ}`)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data?.success) {
          console.log(data?.uploadTicketSale,"=data?.data")
          let userData = data?.uploadTicketSale?.filter((v) => v?.userId||v?.requestType==="Request Buy")
          let adminData = data?.uploadTicketSale?.filter((v) => v?.adminId)
          setTicketSellReq(userData)
          setAdminTicketSellReq(adminData)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setLoading(false)
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }
  useEffect(() => {
    getTicketSellReq()
  }, [])


  const ApproveTicketSell = (id, type) => {
    setApproveLoader({ [id]: true })
    let obj = {
      id: id,
      type: type
    }
    console.log('onj', obj)
    axios.post(ADMIN?.APPROVE_TICKET_SELL_REQ, obj)
      .then((res) => {
        const { data } = res
        setApproveLoader({})
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          getTicketSellReq()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setApproveLoader({})
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }
  const CancelTicketSell = (id, type) => {
    setCancelLoader({ [id]: true })
    let obj = {
      id: id,
      type: type
    }
    console.log('onj', obj)
    axios.post(ADMIN?.CANCEL_TICKET_SELL_REQ, obj)
      .then((res) => {
        const { data } = res
        setCancelLoader({})
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          getTicketSellReq()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setCancelLoader({})
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }

  const DeleteTicketSell = (id, type) => {
    setDeleteLoader({ [id]: true })
    let obj = {
      id: id,
      type: type
    }
    axios.post(ADMIN?.DELETE_TICKET_SELL_REQ, obj)
      .then((res) => {
        const { data } = res
        setDeleteLoader({})
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          getTicketSellReq()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setDeleteLoader({})
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }

  const editingFunction = (data, type) => {
    if (type === 'SALE TICKET') {
      setEditTicketSellData(data)
    } else {
      setEditTicketSellReq(data)
    }
  }


  let TicketSellData = ticketSellReq && ticketSellReq?.map((v, i) => {
    return {
      TransactionNumber: v?._id,
      NameIDphoneOfUploader: v?.requestType === 'Request Buy' ? [v?.buyerId?.fullName, v?.buyerId?._id, v?.buyerId?.telNo] : [v?.userId?.fullName, v?.userId?._id, v?.userId?.telNo],
      // RightPrice: v?.ticketId?.rightPrice || v?.userTicketId?.rightPrice,
      // ExpirationDate: v?.ticketId?.expirationDate || v?.userTicketId?.expirationDate,
      JoiningFee: v?.userTicketId?.joiningFee || v?.ticketId?.joiningFee,
      joiningDate: v?.userTicketId ? [v?.userTicketId?.joiningDate?.date1, v?.userTicketId?.joiningDate?.date2] : [v?.ticketId?.joiningDate?.date1, v?.ticketId?.joiningDate?.date2],
      Type: v?.ticketId?.membershipType || v?.userTicketId?.membershipType,
      ViewMembership: v,
      Status: v,
      Action: v,

    }
  })
  let TicketSellAdminData = adminTicketSellReq && adminTicketSellReq?.map((v, i) => {
    return {
      TransactionNumber: v?._id,
      // NameIDphoneOfUploader: [v?.userId?.fullName, v?.userId?._id, v?.userId?.telNo],
      // RightPrice: v?.ticketId?.rightPrice || v?.userTicketId?.rightPrice,
      // ExpirationDate: v?.ticketId?.expirationDate || v?.userTicketId?.expirationDate,
      JoiningFee: v?.userTicketId?.joiningFee || v?.ticketId?.joiningFee,
      joiningDate: v?.userTicketId ? [v?.userTicketId?.joiningDate?.date1, v?.userTicketId?.joiningDate?.date2] : [v?.ticketId?.joiningDate?.date1, v?.ticketId?.joiningDate?.date2],
      Type: v?.ticketId?.membershipType || v?.userTicketId?.membershipType,
      ViewMembership: v,
      Status: v,
      Action: v,

    }
  })



  const columns = [
    {
      title: userSelectedLanguage?.TransactionNumber,
      dataIndex: 'TransactionNumber',
      key: 'Transaction number',
      render: (text) =>
        <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
          <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 13)}</Button>
        </Tooltip>,
    },
    {
      title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
      dataIndex: 'NameIDphoneOfUploader',
      key: 'Name and Phone Number',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          {text?.map((v, i) => {
            return (
              <Tooltip placement='top' title={convertTitle(v || '----')} color={'#5EA29F'}>
                <p className='namePhoneAndID'>{stringLimiter(convertTitle(v || '----'), 14)}</p>
              </Tooltip>
            )
          })}
        </div>
      ),
    },
    /*  {
       title: userSelectedLanguage?.RightPrice,
       dataIndex: 'RightPrice',
       key: 'Right price',
       render: (text) => <p className='tableColoumWidthBig'>{text ? text + ' ' + userSelectedLanguage?.KRW : '----'}</p>,
     },
     {
       title: userSelectedLanguage?.Type,
       dataIndex: 'Type',
       key: 'Type',
       render: (text) => <p className='tableColoumWidth'>{lanKorCondition ? text : typesKr[types?.indexOf(text)]?.typesKr || '----'}</p>,
     },
     {
       title: lanKorCondition ? 'ExpirationDate' : '입회기간',
       dataIndex: 'ExpirationDate',
       key: 'Expiration Date',
       render: (text) => <p className='tableColoumWidth'>{text ? moment(text).format('YYYY-MM-DD') : '----'}</p>,
     }, */
    {
      title: userSelectedLanguage?.JoiningFee,
      dataIndex: 'JoiningFee',
      key: 'Joining fee',
      render: (text) => <p className='tableColoumWidthBig'>{text ? text + ' ' + userSelectedLanguage?.KRW : '---'}</p>,
    },
    {
      title: userSelectedLanguage?.Type,
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => <p className='tableColoumWidth'>{lanKorCondition ? text : typesKr[types?.indexOf(text)]?.typesKr || '----'}</p>,
    },
    {
      title: userSelectedLanguage?.JoiningDate,
      dataIndex: 'joiningDate',
      key: 'Joining Date',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          {text?.map((v, i) => {
            return (
              <p className='namePhoneAndID'>{v ? moment(v).format('YYYY-MM-DD') : '----'}</p>
            )
          })}
        </div>
      ),
    },
    {
      title: lanKorCondition ? 'View' : '회원권 보기',
      dataIndex: 'ViewMembership',
      key: 'View Membership',
      render: (text) => (
        <>
          <div className='tableColoumWidthBig'>
            {text?.isCanceled ?
              <div className='searchIconDiv'>
                <FcCancel />
              </div>
              :
              <div className='searchIconDiv'
                onClick={() => {
                  text?.type === 'SALE TICKET' ?
                    history.push({ pathname: allPaths?.TICKETSVIEWINFO, state: [text, { title: 'Ticket sales' }] }) :
                    history.push({ pathname: allPaths?.TICKETSVIEWINFO, state: [text, { title: 'Ticket sales Request Buy' }] })
                }}
              >
                <FiSearch />
              </div>}
          </div>
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        <div style={{ width: '200px' }}>
          {
            text?.isCanceled && text?.type === 'SALE TICKET' ?
              lanKorCondition ? 'Canceled Request' : '매매취소' :
              text?.isCanceled && text?.requestType === 'Request Buy' ?
                lanKorCondition ? 'Canceled buy request' : '거래 취소' :
                text?.isSaled && text?.type === 'SALE TICKET' ?
                  lanKorCondition ? 'Completed sell request' : '매매완료' :
                  text?.isCompleted && text?.requestType === 'Request Buy' ?
                    lanKorCondition ? 'Completed Request buy ' : '교환완료 ' :
                    !text?.isAdminApproved && !text?.isCompleted && text?.type === 'SALE TICKET' ?
                      lanKorCondition ? 'Request Sell' : '모집요청' :
                      !text?.isAdminApproved && !text?.isCompleted && text?.requestType === 'Request Buy' ?
                        lanKorCondition ? 'Request buy' : '구매요청' :
                        text?.type === 'SALE TICKET' ?
                          lanKorCondition ? 'Selling ticket sell' : '모집중' :
                          lanKorCondition ? 'Selling request buy' : '문의중'
          }
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        < div className='tableBtnsDiv' >
          {/* {console.log('_______',text)} */}
          <>
            <Button
              disabled={text?.isSaled || text?.isCompleted || text?.isCanceled}
              className='editBtn' onClick={() => { editingFunction(text, text?.type ? text?.type : 'RequestBuy') }}> {'Edit'}</Button>

            <Button loading={approveLoader[text?._id]}
              disabled={text?.isAdminApproved || text?.isCanceled}
              onClick={() => { ApproveTicketSell(text?._id, text?.requestType || text?.type) }}
              className='postApproval'>{!text?.isAdminApproved ? userSelectedLanguage?.PostApproval : userSelectedLanguage?.Approved}</Button>
            <Button
              loading={cancelLoader[text?._id]}
              onClick={() => { CancelTicketSell(text?._id, text?.requestType || text?.type) }}
              disabled={text?.isAdminApproved || text?.isSaled || text?.isCompleted || text?.isCanceled} className='editBtn' >
              {userSelectedLanguage?.CancelRequest}</Button>

            <Button loading={deleteLoader[text?._id]}
              onClick={() => { DeleteTicketSell(text?._id, text?.requestType || text?.type) }}
              // disabled={text?.isSaled || text?.isCompleted}
              className='DeleteBtn'> {userSelectedLanguage?.Delete} </Button>
          </>
        </div >
      ),
    },
  ]
  const adminColumns = [
    {
      title: userSelectedLanguage?.TransactionNumber,
      dataIndex: 'TransactionNumber',
      key: 'Transaction number',
      render: (text) =>
        <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
          <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 13)}</Button>
        </Tooltip>,
    },
    /* {
     title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
     dataIndex: 'NameIDphoneOfUploader',
     render: (text) => (
       <div className='namePhoneAndIDiv'>
         {text?.map((v, i) => {
           return (
             <Tooltip placement='top' title={convertTitle(v?.toString() || '----')} color={'#5EA29F'}>
               <p className='namePhoneAndID'>{stringLimiter(convertTitle(v?.toString() || '----'), 12)}</p>
             </Tooltip>
           )
         })}
       </div>
     ),
   }, */
    {
      title: `${userSelectedLanguage?.NameOfUploader}`,
      dataIndex: 'NameIDphoneOfUploader',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          <p className='namePhoneAndID'>{lanKorCondition ? 'admin' : '어드민'}</p>
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.JoiningFee,
      dataIndex: 'JoiningFee',
      key: 'Joining fee',
      render: (text) => <p className='tableColoumWidthBig'>{text ? text + ' ' + userSelectedLanguage?.KRW : '---'}</p>,
    },
    {
      title: userSelectedLanguage?.Type,
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => <p className='tableColoumWidth'>{lanKorCondition ? text : typesKr[types?.indexOf(text)]?.typesKr || '----'}</p>,
    },
    {
      title: userSelectedLanguage?.JoiningDate,
      dataIndex: 'joiningDate',
      key: 'Joining Date',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          {text?.map((v, i) => {
            return (
              <p className='namePhoneAndID'>{v ? moment(v).format('YYYY-MM-DD') : '----'}</p>
            )
          })}
        </div>
      ),
    },
    {
      title: lanKorCondition ? 'View' : '회원권 보기',
      dataIndex: 'ViewMembership',
      key: 'View Membership',
      render: (text) => (
        <>
          <div className='tableColoumWidthBig'>
            {text?.isCanceled ?
              <div className='searchIconDiv'>
                <FcCancel />
              </div>
              :
              <div className='searchIconDiv'
                onClick={() => {
                  text?.type === 'SALE TICKET' ?
                    history.push({ pathname: allPaths?.TICKETSVIEWINFO, state: [text, { title: 'Ticket sales' }] }) :
                    history.push({ pathname: allPaths?.TICKETSVIEWINFO, state: [text, { title: 'Ticket sales Request Buy' }] })
                }}
              >
                <FiSearch />
              </div>}
          </div>
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        <div style={{ width: '200px' }}>
          {
            text?.isCanceled && text?.type === 'SALE TICKET' ?
              lanKorCondition ? 'Canceled Request' : '매매취소' :
              text?.isCanceled && text?.requestType === 'Request Buy' ?
                lanKorCondition ? 'Canceled buy request' : '거래 취소' :
                text?.isSaled && text?.type === 'SALE TICKET' ?
                  lanKorCondition ? 'Completed sell request' : '매매완료' :
                  text?.isCompleted && text?.requestType === 'Request Buy' ?
                    lanKorCondition ? 'Completed Request buy ' : '교환완료 ' :
                    !text?.isAdminApproved && !text?.isCompleted && text?.type === 'SALE TICKET' ?
                      lanKorCondition ? 'Request Sell' : '모집요청' :
                      !text?.isAdminApproved && !text?.isCompleted && text?.requestType === 'Request Buy' ?
                        lanKorCondition ? 'Request buy' : '구매요청' :
                        text?.type === 'SALE TICKET' ?
                          lanKorCondition ? 'Selling ticket sell' : '모집중' :
                          lanKorCondition ? 'Selling request buy' : '문의중'
          }
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        < div className='tableBtnsDiv' >
          {/* {console.log('_______',text)} */}
          <>
            <Button
              disabled={text?.isSaled || text?.isCompleted || text?.isCanceled}
              className='editBtn' onClick={() => { editingFunction(text, text?.type ? text?.type : 'RequestBuy') }}> {'Edit'}</Button>

            <Button loading={approveLoader[text?._id]}
              disabled={text?.isAdminApproved || text?.isCanceled}
              onClick={() => { ApproveTicketSell(text?._id, text?.requestType || text?.type) }}
              className='postApproval'>{!text?.isAdminApproved ? userSelectedLanguage?.PostApproval : userSelectedLanguage?.Approved}</Button>
            <Button
              loading={cancelLoader[text?._id]}
              onClick={() => { CancelTicketSell(text?._id, text?.requestType || text?.type) }}
              disabled={text?.isAdminApproved || text?.isSaled || text?.isCompleted || text?.isCanceled} className='editBtn' >
              {userSelectedLanguage?.CancelRequest}</Button>

            <Button loading={deleteLoader[text?._id]}
              onClick={() => { DeleteTicketSell(text?._id, text?.requestType || text?.type) }}
              // disabled={text?.isSaled || text?.isCompleted}
              className='DeleteBtn'> {userSelectedLanguage?.Delete} </Button>
          </>
        </div >
      ),
    },
  ]
  return (
    <>
      <ButtonLogout props={props} />
      <div className='membershipBox'>
        <div className='goldReservationHeader'>
          <div className='golfHeaderText'>
            <h3>{userSelectedLanguage?.TicketSalesManagement}</h3>
            <p>{'>'}{userSelectedLanguage?.MembershipSalesRequestMembershipPurchaseRequest}</p>
          </div>
          <div className='headersearch'>
            <Select
              className='dropdown'
              defaultValue={dropDown}
              onChange={(e) => {
                setTableRow(e)
              }}
            >
              {arr.map((v, i) => {
                return <Option key={v.value}>{v?.Viewby}</Option>
              })}
            </Select>
            <Input
              className='searchgolfReservation'
              placeholder={userSelectedLanguage?.PleaseSearchATerm}
              onChange={(e) => setSearch(e?.target?.value)}
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
        <div
          style={{
            margin: '0px auto',
            display: 'flex',
            width: '95%',
            justifyContent: 'flex-end'
          }}>
          <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }}
            onClick={() => setAddTicketSale(true)}
          >
            {lanKorCondition ? 'Add Tickets Sales' : '어드민 회원권판매 게시'}
          </Button>
          <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }}
            onClick={() => setAdminData(true)}>
            {lanKorCondition ? 'Admin Tickets Sales' : '어드민 회원권 판매'}
          </Button>
          <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }}
            onClick={() => setAdminData(false)}>
            {lanKorCondition ? 'all user tickets sales' : '회원권 보기'}
          </Button>
        </div>
        <div className='tableDivManagement'>
          <Divider />
          <Table
            loading={loading}
            pagination={{ pageSize: tableRow }}
            dataSource={!adminData ? filteredData : TicketSellAdminData}
            columns={!adminData ? columns : adminColumns}
            scroll={{ x: 700 }}
            size='middle'
          />
        </div>
        <AddTicketSellModal
          open={addTicketSale}
          handleOk={() => setAddTicketSale(false)}
          fun={getTicketSellReq}
          {...props} />

        {editTicketSellData?._id &&
          <EditTicketSell
            editData={editTicketSellData}
            open={editTicketSellData?._id ? true : false}
            handleOk={() => setEditTicketSellData({})}
            fun={getTicketSellReq}
            {...props} />
        }
        {editTicketSellReq?._id &&
          <EditTicketRequestBuy
            editData={editTicketSellReq}
            open={editTicketSellReq?._id ? true : false}
            handleOk={() => setEditTicketSellReq({})}
            fun={getTicketSellReq}
            {...props} />
        }
      </div >
    </>
  )
}
export default MembershipSales
