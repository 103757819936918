let apiUrl

if (process.env.NODE_ENV === 'development') {
    apiUrl = `http://localhost:3000`
    // apiUrl = `https://securetech.filemodifier.com`
}
else {
    apiUrl = `https://securetech.filemodifier.com`
}

export default apiUrl