import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { setActiveMenu, setKeyMenu } from '../../utils/helpers'
import { allPaths, drawerRoutes, koreanDrawerRoutes } from '../../utils/constants'
import SidebarLogo from '../../assets/images/logoVMGreen.png'
import SidebarMobileLogo from '../../assets/images/logoVMGreen.png'
import { useDispatch, useSelector } from 'react-redux'
import { SideMenuDrawer } from '../../Redux/actions/genralAction'
const { Sider } = Layout
const { SubMenu } = Menu

const SideMenu = (props) => {
  const { location, language } = props
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const [collapsed, setCollapsed] = useState()
  const userSelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const userSelectedDrawerRoutes = userSelectedLanguage && userSelectedLanguage === 'english' ? drawerRoutes : koreanDrawerRoutes

  useEffect(() => {
    setCollapsed(state?.SideMenuDrawerReducer)
  })

  return (
    <Layout className='sidebar-scroll_class' style={{
      position: 'fixed', zIndex: 9999, height: `calc(100vh - 50px)`, overflowY: 'scroll',
      paddingBottom: '20px',backgroundColor:'white'
    }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => { dispatch(SideMenuDrawer(value)); setCollapsed(value) }}
        background='red'
        width={330}
      >
        {!collapsed ? (
          <div className='logo topHeader' style={{ height: 150 }}>
            <img src={SidebarLogo} className='logoImg' alt='SidebarLogo' />
            <p className='logoImgText'>{language?.VALUEMARKET}</p>
          </div>
        ) : (
          <div className='logo topHeader'>
            <img
              src={SidebarMobileLogo}
              className='SidebarMobileLogo'
              alt='SidebarMobileLogo'
            />
          </div>
        )}

        <Menu
          mode='inline'
          defaultOpenKeys={[`${setKeyMenu(location?.pathname)}`]}
          defaultSelectedKeys={[`${setActiveMenu(location?.pathname)}`]}
        // style={{ height: `calc(100vh - 45px)` }}
        >
          {userSelectedDrawerRoutes?.map((v, i) => {
            if (v?.children?.length) {
              return (
                <>
                  <SubMenu key={i} icon={v.icon} title={v?.title}>
                    {v?.children?.map((y, j) => {
                      return (
                        <Menu.Item key={y?.route} icon={y.icon}
                        >
                          <Link
                            to={y?.route}
                            onClick={() => {
                              return false
                            }}
                            className='side-list'
                          >
                            {y.title}
                          </Link>
                        </Menu.Item>
                      )
                    })}
                  </SubMenu>
                </>
              )
            }
            return (
              <Menu.Item key={v.route} icon={v.icon}>
                <Link
                  to={v?.route}
                  onClick={() => {
                    return false
                  }}
                  className='side-list'
                >
                  {v.title}
                </Link>
              </Menu.Item>
            )
          })}
        </Menu>
      </Sider>
    </Layout >
  )
}

export default SideMenu
