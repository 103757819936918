import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { HiOutlineUserCircle } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from '../../Redux/actions/genralAction'
import { TreeSelect } from 'antd';
import { removeUser } from '../../Redux/actions/authActions'
import { allPaths } from '../../utils/constants'

const { TreeNode } = TreeSelect;
const ButtonLogout = (props) => {
  const AdminName = props?.props?.user?.userName
  const { history } = props
  const userSelectedLanguage = props?.props?.language
  const dispatch = useDispatch()
  const [dropDown, setdropDown] = useState(AdminName?.slice(0, 12))
  const onChange = (newValue) => {
    setdropDown(AdminName)
    if (newValue === 'english' || newValue === 'korean') {
      dispatch(setLanguage({ language: newValue }))

    } else if (newValue === 'Logout') {
      dispatch(removeUser())
      history.push(allPaths?.LOGIN)
    }
  };

  return (
    <div className='buttonUpperDiv'>
      <div className='buttonDiv'>
        <span className='iconDiv'>
          <HiOutlineUserCircle size={30} className='logoutIcon' />
        </span>
        <TreeSelect
          style={{ width: '100%' }}
          value={dropDown}
          onChange={onChange}
        >
          <TreeNode value='Logout' title={userSelectedLanguage?.Logout} />
          {process.env.NODE_ENV === 'development' ?
            <TreeNode value='null' title={userSelectedLanguage?.Language} >
              <TreeNode value='english' title={userSelectedLanguage?.English} />
              <TreeNode value='korean' title={userSelectedLanguage?.Korean} />
            </TreeNode>
            :
            null
          }
        </TreeSelect>
      </div>
    </div>
  )
}
export default ButtonLogout