import React, { useEffect, useState, useRef } from 'react'
import { ButtonLogout } from '../../Components'
import { Radio, DatePicker, Table, Divider, Button, Tooltip, Popconfirm, Input } from 'antd'
import { FiSearch } from 'react-icons/fi'
import { allPaths } from '../../utils/constants'
import axios from 'axios'
import { ADMIN, AUTH, POST } from '../../utils/apis'
import moment from 'moment-timezone'
import { requiredMessage, errorMessage, stringLimiter, errorNotification, successNotification, convertTitle } from '../../utils/helpers'
import { useSelector } from 'react-redux'
import { SearchOutlined } from '@ant-design/icons'
import { MdOutlineMore } from 'react-icons/md'
import UserInfoModal from './UserInfo'
const { Search } = Input
let passVerOrigin = `https://securetech.filemodifier.com`
if (process.env.NODE_ENV === 'development') {
  passVerOrigin = `http://localhost:3000`
}
const passMID = 'INIiasTest'

const ManagementMembership = (props) => {
  const userSelectedLanguage = props?.language
  const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const lanKorCondition = SelectedLanguage === 'english'
  const history = props?.history
  const { user } = props
  const [userData, setUserData] = useState([])
  const [radio, setRadio] = useState('All')
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState({})
  const [userDetail, setUserDetail] = useState({})
  const [stopLoader, setStopLoader] = useState(false)
  const [instaId, setInstaId] = useState(null)
  const formData = useRef(null)

  useEffect(() => {
    if (instaId) {
      const domain = 'https://kssa.inicis.com'
      formData.current.action = `${domain}/init`
      formData.current.method = 'get'
      formData.current.submit()
    }
  }, [instaId])

  useEffect(() => {
    getAllUsers()
  }, [])

  const getAllUsers = () => {

    setLoading(true)
    axios.get(`${ADMIN?.GET_ALL_USERS}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data?.success) {
          setUserData(data?.data)
        }
        setLoading(false)

      })
      .catch((e) => {
        console.log('e====>', e)
        setLoading(false)
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }

  const deleteUser = (id, deleteTrue) => {
    setDeleteLoader({ [id]: true })
    let obj = {
      _id: id, adminId: user?._id, deleteTrue
    }
    axios.post(ADMIN?.DELETE_USERS, obj)
      .then((res) => {
        const { data } = res
        if (data.success) {
          successNotification(lanKorCondition ? data?.message : data?.messageKr)
          getAllUsers()
          setDeleteLoader({})
        } else {
          errorMessage(lanKorCondition ? data?.message : data?.messageKr)
          console.log(data.message)
          setDeleteLoader({})
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setDeleteLoader({})
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }

  const stopUser = (id, isBlock) => {
    setStopLoader({ [id]: true })

    let obj = {
      _id: id, adminId: user?._id, isBlock: isBlock ? false : true
    }
    axios.post(ADMIN?.BLOCK_OR_UNBLOCK_USERS, obj)
      .then((res) => {
        const { data } = res
        setStopLoader({})
        if (data.success) {
          successNotification(lanKorCondition ? data?.message : data?.messageKr)
          getAllUsers()
        } else {
          setStopLoader({})
          errorMessage(lanKorCondition ? data?.message : data?.messageKr)
          console.log(data.message)
        }
      })
      .catch((e) => {
        setStopLoader({})
        console.log('e====>', e)
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }

  const columns = [
    {
      title: `${lanKorCondition ? 'User name' : '이름'}`,
      key: 'action',
      dataIndex: 'action',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          <Tooltip placement='top' title={convertTitle(text?.fullName || '---')} color={'#5EA29F'}>
            <p className='namePhoneAndID'>{stringLimiter(convertTitle(text?.fullName || '---'), 14)}</p>
          </Tooltip>
        </div>
      ),
    },
    // {
    //   title: `${userSelectedLanguage?.ID}`,
    //   key: 'action',
    //   dataIndex: 'action',
    //   render: (text) => (
    //     <div className='namePhoneAndIDiv'>
    //       <Tooltip placement='top' title={convertTitle(text?._id || '---')} color={'#5EA29F'}>
    //         <p className='namePhoneAndID'>{stringLimiter(convertTitle(text?._id || '----'), 14)}</p>
    //       </Tooltip>
    //     </div>
    //   ),
    // },
    {
      title: `${userSelectedLanguage?.PhoneNumber} `,
      key: 'action',
      dataIndex: 'action',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          <Tooltip placement='top' title={convertTitle(text?.telNo || '---')} color={'#5EA29F'}>
            <p className='namePhoneAndID'>{stringLimiter(convertTitle(text?.telNo || '----'), 14)}</p>
          </Tooltip>

        </div>
      ),
    },
    {
      title: `${userSelectedLanguage?.ID}`,
      dataIndex: 'email',
      key: 'E-mail',
      render: (text) => <a className='tableEmail'>{text ? text : '----'}</a>,
    },
    {
      title: userSelectedLanguage?.JoiningDateMemberShip,
      dataIndex: 'created',
      key: 'Joining Date',
      render: (text) => (
        <div>
          <p className='tableColoumWidth'>{moment(text).format('YYYY-MM-DD') || 'null'}</p>
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.Veiwmembership,
      key: 'Veiw Membership',
      dataIndex: 'action',
      render: (text) => (
        <>
          {text ?
            <div className='tableColoumWidth'>
              <div className='searchIconDiv' style={{ cursor: 'pointer' }} onClick={() => {
                // history?.push(allPaths?.MY_MEMBERSHIP)
                history.push({ pathname: allPaths?.MY_MEMBERSHIP, state: text })
              }}>
                <FiSearch />
              </div>
            </div> : null
          }
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Management,
      key: 'Management',
      dataIndex: 'action',
      render: (e) => (
        console.log(e),
        <div className='tableBtnsDiv'>
          <Popconfirm
            title={`${convertTitle(e?.fullName ? e?.fullName : e?.email)} ${!e?.isBlock ? lanKorCondition ? 'Should I Stop using' : '계정 사용을 중단할까요' : lanKorCondition ? 'Should I UnStop using' : '계정 사용을 중단할까요'} ?`}
            okText={`${!e?.isBlock ? userSelectedLanguage?.Stop : userSelectedLanguage?.UnStop}`}
            cancelText={userSelectedLanguage?.Cancel}
            okButtonProps={{
              type: 'primary',
              className: 'form-button'
            }}
            cancelButtonProps={{ style: { borderRadius: 4 } }}
            onConfirm={() => stopUser(e?._id, e?.isBlock)}
          >
            <Button className='btnTableStop' loading={stopLoader[e?._id]} type='primary'>{`${!e?.isBlock ? userSelectedLanguage?.StopUsing : userSelectedLanguage?.UnStopUsing}`}</Button>
          </Popconfirm>
          {
            e?.isDeleted ?
              <Popconfirm
                title={e?.fullName ? `${convertTitle(e?.fullName) + ' '} 계정을 복구할까요?` : `${convertTitle(e?.email) + ' '} 계정을 복구할까요 ?`}
                okText={"복구"}
                cancelText={userSelectedLanguage?.Cancel}
                okButtonProps={{
                  type: 'primary',
                  className: 'form-button'
                }}
                cancelButtonProps={{ style: { borderRadius: 4 } }}
                onConfirm={() => deleteUser(e?._id, false)}
              >
                <Button loading={deleteLoader[e?._id]} className='btnTableDelete' type='primary'>
                  {/* {userSelectedLanguage?.Delete} */}
                  {lanKorCondition ? 'restore' : '계정복구'}

                </Button>
              </Popconfirm>
              :
              <Popconfirm
                title={`${convertTitle(e?.fullName ? e?.fullName : e?.email) + ' '} 탈퇴하시겠습니까 ?`}
                okText={userSelectedLanguage?.Delete}
                cancelText={userSelectedLanguage?.Cancel}
                okButtonProps={{
                  type: 'primary',
                  className: 'form-button'
                }}
                cancelButtonProps={{ style: { borderRadius: 4 } }}
                onConfirm={() => deleteUser(e?._id, true)}
              >
                <Button loading={deleteLoader[e?._id]} className='btnTableDelete' type='primary'>
                  {userSelectedLanguage?.Delete}</Button>
              </Popconfirm>
          }

        </div >
      ),
    },
    {
      title: lanKorCondition ? 'info' : '정보',
      dataIndex: 'action',
      render: (text) => (
        <>
          <div className='tableColoumWidth'>
            <div className='searchIconDiv' style={{ cursor: 'pointer' }}
              onClick={() => { setUserDetail(text) }}
            >
              <MdOutlineMore />
            </div>
          </div>
        </>
      ),
    },
  ]

  let filterUserData = userData?.length && userData?.filter((val, i) => {
    if (searchValue === '') {
      return val
    } else if (val?.fullName?.toLowerCase()?.includes(searchValue?.toLowerCase()?.trim()) || val?.email?.toLowerCase()?.includes(searchValue?.toLowerCase()?.trim())) {
      return val
    }
  })?.map((v) => {
    return {
      action: v,
      email: v?.email,
      created: v?.created,
    }
  })

  const callPassVerificationAuth = () => {
    let obj = {
      successUrl: `${passVerOrigin}${AUTH?.GET_PASS_VERIFICATION_RES}`
    }

    axios.post(POST?.PASS_VERIFICATION, obj)
      .then((res) => {
        const { data } = res
        if (data?.success) {
          let result = data?.result
          var parser = new DOMParser()
          var doc = parser.parseFromString(result, 'text/html')
          setInstaId(doc?.querySelector('#txId')?.value)
        }
        else {
          errorMessage()
        }
      })
      .catch((e) => console.log('e', e))
  }
  return (
    <>
      <ButtonLogout props={props} />
      <div className='membershipBox'>
        <div className='goldReservationHeader'>
          <div className='golfHeaderText'>
            <h3> {userSelectedLanguage?.MembershipList}</h3>
          </div>
          <div className='headersearch'>
            <Input
              placeholder={userSelectedLanguage?.SearchUserbyEmailOrName}
              className='noti_search '
              // style={{ paddingRight: '30%' }}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchValue(e?.target?.value)}
            />
          </div>
        </div>
        <div
          style={{
            margin: '0px auto',
            display: 'flex',
            width: '95%',
            justifyContent: 'flex-end'
          }}>
          <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => history.push({ pathname: allPaths?.ADMIN_MEMBERSHIP })}>{lanKorCondition ? 'Admin Data' : '어드민 회원권 보기'}</Button>
          <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => window.location.href = 'https://securetech.filemodifier.com/admin/individual-signup'}>{lanKorCondition ? 'Add New User' : '회원 추가'}</Button>
        </div>

        <div className='tableDiv'>
          <Divider />
          <Table
            loading={loading}
            className='scrollClass'
            pagination={true} /*for temprery*/
            dataSource={filterUserData}
            columns={columns}
            scroll={{ x: 700 }}
          />
        </div>
        <UserInfoModal visible={userDetail?._id} data={userDetail} handleCancel={() => { setUserDetail({}) }} SelectedLanguage={SelectedLanguage} lanKorCondition={lanKorCondition} {...props} />
      </div>
      <form name="requestForm" action="" ref={formData}>
        <input type="hidden" name="mid" value={passMID} />
        <input type="hidden" name="reqSvcCd" value="01" />
        <input type="hidden" name="mTxId" value="mTxId" />
        <input type="hidden" name="nmComp" value="KG이니시스" />
        <input type="hidden" name="noComp" value="2208155597" />
        <input type="hidden" id="myOS" name="myOS" value="" />
        <input type="hidden" id="myBrowser" name="myBrowser" value="" />
        < input type="hidden" name="successUrl" value={passVerOrigin + AUTH.GET_PASS_VERIFICATION_RES} />
        <input type="hidden" name="failUrl" value={passVerOrigin + AUTH.FAIL_URL} />
        <input type="hidden" name="identifier" value="" />

        <input type="hidden" id="txId" name="txId" value={instaId} />
        <input type="hidden" id="flgFixedUser" name="flgFixedUser" value="N" />
        <input type="hidden" id="userName" name="userName" value="" />
        <input type="hidden" id="userPhone" name="userPhone" value="" />
        <input type="hidden" id="userBirth" name="userBirth" value="" />
        <input type="hidden" id="userHash" name="userHash" value="" />

        <input type="hidden" id="agencyList" name="agencyList" value="TOSS,PASS,SHINHAN,SAMSUNG,KB,NAVER,KAKAO,PAYCO,KFTC" />
        <input type="hidden" id="agencyListSize" name="agencyListSize" value="9" />

        <input type="hidden" id="isDirect" name="isDirect" value="false" />
        <input type="hidden" id="directAgency" name="directAgency" value="" />
        <input type="hidden" id="req_url" name="req_url" value={passVerOrigin + '/login'} />
      </form>
    </>
  )
}
export default ManagementMembership
