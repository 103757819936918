import React from 'react'
import { Button, Table, Tooltip } from 'antd'
import { ButtonLogout } from '../../Components'
import moment from 'moment-timezone'
import { convertTitle, errorMessage, stringLimiter, successNotification, infoMessage } from '../../utils/helpers'
import { BsCheckCircleFill } from 'react-icons/bs'
import { FiSearch } from 'react-icons/fi'
import { allPaths, regions, regionsKr, types, typesKr } from '../../utils/constants'
import axios from 'axios'
import { ADMIN } from '../../utils/apis'
import { useDispatch, useSelector } from 'react-redux'
import { setAuction } from '../../Redux/actions/genralAction'
import { FcCancel } from "react-icons/fc"

const AuctionBidDetail = (props) => {
    const { user } = props
    const history = props?.history
    const dispatch = useDispatch()
    const userSelectedLanguage = props?.language
    let Data = props?.history?.location?.state
    const language = useSelector(state => state?.languageReducer?.language)
    const auction = useSelector(state => state?.setAuction?.val)
    const lanKorCondition = language === 'english'

    let bidsData = Data?.bids?.length && Data?.bids?.map((v, i) => {
        return {
            TransactionNumber: v?.auctionId,
            NameIDphoneOfUploader: [v?.buyerId?.fullName, v?.buyerId?._id, v?.buyerId?.telNo],
            bidAmount: v?.price,
            Status: v,
            Payment: v,
            ViewDetail: v,
            AdvanceFeePayment: v
        }
    })


    const AdvanceFeeApprove = (id, type) => {
        let obj = {
            _id: id, adminId: user?._id, auctionType: type
        }
        axios.post(ADMIN?.ACCEPT_AUCTION_ADVANCE_FEE, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successNotification(lanKorCondition ? data.message : data.messageKr)
                    history.push(allPaths?.GOLF_AUCTION_POST_MANAGEMENT)
                } else {
                    errorMessage(data.message)
                }
            })
            .catch((e) => {
                console.log('e====>', e)
                errorMessage(userSelectedLanguage?.OppsError)
            })
    }











    const columns = [
        {
            title: userSelectedLanguage?.TransactionNumber,
            dataIndex: 'TransactionNumber',
            key: 'Transaction number',
            render: (text) => <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
                <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 12)}</Button>
            </Tooltip>
        },
        {
            title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
            dataIndex: 'NameIDphoneOfUploader',
            key: 'Name and Phone Number',
            render: (text) => (
                <div className='namePhoneAndIDiv'>
                    {text?.map((v, i) => {
                        return (
                            <Tooltip placement='top' title={convertTitle(v || '----')} color={'#5EA29F'}>
                                <p className='namePhoneAndID'>{stringLimiter(convertTitle(v || '----'), 12)}</p>
                            </Tooltip>
                        )
                    })}
                </div>
            ),
        },
        {
            title: userSelectedLanguage?.BidAmount,
            dataIndex: 'bidAmount',
            key: 'BidAmount',
            render: (text) => <p className='tableColoumWidth'>{text ? text + ' ' + userSelectedLanguage?.Won : '----'}</p>,
        },
        {
            title: lanKorCondition ? 'ViewDetail' : '상세보기',
            dataIndex: 'ViewDetail',
            key: 'ViewDetail',
            render: (text) => <>
                <div className='tableColoumWidth'>
                    {
                        !text?.isAccepted ?
                            <div className='searchIconDiv' style={{ cursor: 'pointer' }}>
                                <FcCancel />
                            </div> :
                            <div className='searchIconDiv' style={{ cursor: 'pointer' }}>
                                <FiSearch
                                    onClick={() => {
                                        history.push({ pathname: allPaths?.TICKETSINFO, state: [text, Data, { Bank: 'Hide', title: Data?.auctionType }] })
                                    }} />
                            </div>}
                </div>
                {/* <div style={{ height: 30, width: 30, backgroundColor: 'white', borderRadius: 8, boxShadow: ' 2px 2px #888888', textAlign: 'center', paddingTop: '5px' }}>
                    <BsCheckCircleFill />
                </div> */}
            </>
        },
        {
            title: lanKorCondition ? 'Advance fee payment' : '수수료 지불여부',
            dataIndex: 'AdvanceFeePayment',
            key: 'AdvanceFeePayment',
            render: (text) => <>
                {text?.isParticipationAccepted ?
                    <div style={{ display: 'flex', flexDirection: 'row', width: '250px', justifyContent: 'space-around' }}>
                        <div style={{ height: 30, width: 30, backgroundColor: 'white', borderRadius: 8, boxShadow: ' 2px 2px #888888', textAlign: 'center', paddingTop: '5px' }}>
                            <BsCheckCircleFill color='#B07FEF' />
                        </div>
                        <Button className='paidBtn marginLeft' >{lanKorCondition ? 'Advance fee paid' : '선금 입금완료'}</Button>
                    </div>
                    :
                    <div style={{ display: 'flex', flexDirection: 'row', width: '250px', justifyContent: 'space-around' }}>
                        <div
                            onClick={() => { AdvanceFeeApprove(text?._id, Data?.auctionType) }}
                            style={{ height: 30, width: 30, backgroundColor: 'white', borderRadius: 8, boxShadow: ' 2px 2px #888888', textAlign: 'center', paddingTop: '5px' }}>
                            <BsCheckCircleFill color='grey' />
                        </div>
                        <Button className='waitingBtn marginLeft' >{lanKorCondition ? 'Not paid Advance fee ' : '수수료 미납'}</Button>
                    </div>
                }
            </>
        },
        {
            title: lanKorCondition ? 'Bid Choosed' : '경매 선택여부',
            dataIndex: 'Status',
            key: 'Status',
            render: (text) => (
                <>
                    <div style={{ width: '120px' }}>
                        {text?.isAccepted ?
                            <div style={{ height: 30, width: 30, backgroundColor: 'white', borderRadius: 8, boxShadow: ' 2px 2px #888888', textAlign: 'center', paddingTop: '5px' }}>
                                <BsCheckCircleFill color='#6AD49B' />
                            </div>
                            // < Button className='Participationconfirmed'>{userSelectedLanguage?.BidChoosed}</Button>
                            :
                            <div style={{ height: 30, width: 30, backgroundColor: 'white', borderRadius: 8, boxShadow: ' 2px 2px #888888', textAlign: 'center', paddingTop: '5px' }}>
                                <BsCheckCircleFill color='grey' />
                            </div>
                            // {/* <Button className='soldOutAuctionTableBtn'>{userSelectedLanguage?.WaitingLine}</Button> */}
                        }
                    </div>
                </>
            ),
        },
        {
            title: userSelectedLanguage?.Payment,
            dataIndex: 'Payment',
            key: 'Payment',
            render: (text) => (
                <div className='tableBtnsDiv'>
                    <>
                        {text?.isPayment ?
                            <Button className='postApprovalButton'>{lanKorCondition ? 'Bid chosen, payment details' : '낙찰,결제내역 상세보기'}</Button>
                            : text?.isAccepted ?
                                <Button className='postApprovalButton'>{lanKorCondition ? 'Bid chosen' : '낙찰'}</Button>
                                :
                                <Button className='waitingBtn' >{lanKorCondition ? 'Waiting for chosen' : '선택 대기중'}</Button>
                        } </>
                </div>
            ),
        },
    ]
    return (
        <>
            <ButtonLogout props={props} />
            <div className='settingDiv'>
                <div className='nameEmailDiv'>
                    <div className='divHeader'>
                        <h3 className='headerText'>{userSelectedLanguage?.BidDetails}</h3>
                    </div>
                    <div className='divHeader detailMemberInfo'>
                        <div className='MemberInfo' >
                            <h4>{userSelectedLanguage?.TicketInfo}</h4>
                            <div className='participantdetailMemberInfo'>
                                <div className='participantdetailMember'  >
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.GolfClubName}</h5>
                                        <h5 className='participantKey'>{Data?.ticketId?.golfClub?.clubName || Data?.golfClub?.clubName || '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{Data?.ticketId?.membershipType ? userSelectedLanguage?.Type : userSelectedLanguage?.Region}</h5>
                                        <h5 className='participantKey'>{
                                            Data?.ticketId?.membershipType ?
                                                lanKorCondition ? Data?.ticketId?.membershipType : typesKr[types?.indexOf(Data?.ticketId?.membershipType)]?.typesKr
                                                : lanKorCondition ? Data?.golfClub?.region : regionsKr[regions?.indexOf(Data?.ticketId?.membershipType)]?.TitleKr}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{
                                            Data?.ticketId?.joiningFee ? userSelectedLanguage?.JoiningFee : userSelectedLanguage?.ReservationTime}</h5>
                                        <h5 className='participantKey tableEmail'>{Data?.ticketId?.joiningFee ? Data?.ticketId?.joiningFee + ' ' + userSelectedLanguage?.KRW : Data?.reservedTime
                                            ? Data?.reservedTime : '----'}</h5>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{
                                            Data?.ticketId?.joiningDate.date1 || Data?.ticketId?.joiningDate.date2 ? userSelectedLanguage?.JoiningDate : userSelectedLanguage?.ReservationDate}</h5>
                                        <h5 className='participantKey'>{Data?.ticketId?.joiningDate.date1 || Data?.ticketId?.joiningDate.date2 ? moment(Data?.ticketId?.joiningDate.date1).format('YYYY-MM-DD') + ' / ' + moment(Data?.ticketId?.joiningDate.date2).format('YYYY-MM-DD') : Data?.reservedDate?.date1 || Data?.reservedDate?.date2 ? moment(Data?.reservedDate?.date1).format('YYYY-MM-DD') + '/' + moment(Data?.reservedDate?.date2).format('YYYY-MM-DD') : '----'}</h5>
                                    </div>
                                </div>
                                <div className='participantdetailMember extraMargin'  >
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{userSelectedLanguage?.SpecialGift}</h5>
                                        {userSelectedLanguage?.Name === 'Name' ?
                                            <h5 className='participantKey tableEmail'>
                                                Weekday {Data?.ticketId?.specialGiftWeekDay || '--'} times a week (2 times) / {Data?.ticketId?.specialGiftWeekDay ? Data?.ticketId?.specialGiftWeekDay : '--'}KRW
                                                <br />
                                                Weekend {Data?.ticketId?.specialGiftWeekEnd || '--'} times (2 times) / {Data?.ticketId?.specialGiftWeekEnd ? Data?.ticketId?.specialGiftWeekEnd : '--'} KRW
                                            </h5> :
                                            <h5 className='participantKey tableEmail'>
                                                주중 {Data?.ticketId?.specialGiftWeekDay || '--'}8회 (2회) / {Data?.ticketId?.specialGiftWeekDay ? Data?.ticketId?.specialGiftWeekDay : '--'}만원
                                                <br />
                                                주말 {Data?.ticketId?.specialGiftWeekEnd || '--'}8회 (2회) / {Data?.ticketId?.specialGiftWeekEnd ? Data?.ticketId?.specialGiftWeekEnd : '--'} 만원
                                            </h5>}
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <h5 className='participantKeyBold'>{lanKorCondition ? 'Advance fee' : '수수료'}</h5>
                                        <h5 className='participantKey'>{Data?.advanceFee ? Data?.advanceFee + ' ' + userSelectedLanguage?.KRW : '----'}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* Password Confirm Password etc */}

                <div className='nameEmailDiv'>
                    <div className='divHeader '>
                        <h3 className='headerText'>{userSelectedLanguage?.BidParticipantsDetails}</h3>
                    </div>
                    <div className='divHeader detailMemberInfo borderNone'>
                        <Table
                            pagination={{ pageSize: 500 }}
                            dataSource={bidsData}
                            columns={columns}
                            scroll={{ x: 700 }}
                            size='middle'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AuctionBidDetail
