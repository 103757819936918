import React from 'react'
import { ButtonLogout } from '../../Components'

const VeiwDetailMembershipOnline = (props) => {
  const userSelectedLanguage = props?.language
  return (
    <>
      <ButtonLogout props={props} />
      <div className='settingDiv'>
        {/* Name Email ID etc */}
        <div className='nameEmailDiv'>
          <div className='divHeader'>
            <h3 className='headerText'>
              {userSelectedLanguage?.ViewDetailsofMembershipWithConfirmedTransactionOnline}
            </h3>
            <div className='borderBottom'></div>
          </div>

          <div className='ViewTransactionCardsDiv'>
            <div className='ViewTransactionCard'>
              <h5 className='ViewTransactionCardTextBold'>
                {userSelectedLanguage?.PostedByMemberName}
              </h5>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.GolfClubName}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBold'>
                    Hong Gil Dong
                  </h5>
                </div>
              </div>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.Type}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBold'>Individual</h5>
                </div>
              </div>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.Email}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBoldEmail '>
                    abc@gmail.com
                  </h5>
                </div>
              </div>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.PhoneNumber}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBold'>
                    010-1234-5678
                  </h5>
                </div>
              </div>
            </div>
            <div className='ViewTransactionCardCenter'>
              <h5 className='ViewTransactionCardTextBold'>
                {userSelectedLanguage?.PurchaseMemberName}
              </h5>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.GolfClubName}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBold'>
                    Hong Gil Dong
                  </h5>
                </div>
              </div>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.Type}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBold'>Individual</h5>
                </div>
              </div>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.Email}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBoldEmail '>
                    abc@gmail.com
                  </h5>
                </div>
              </div>
              <div className='ViewTransactionCardTextDiv'>
                <div className='ViewTransactionCardTextBoldDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.PhoneNumber}
                  </h5>
                </div>
                <div className='ViewTransactionCardTextNotBoldDiv'>
                  <h5 className='ViewTransactionCardTextNotBold'>
                    010-1234-5678
                  </h5>
                </div>
              </div>
            </div>
            {/* <div className='ViewTransactionCardLast'>
              <div className='TransactionAmountInputDiv'>
                <div className='inputTransactionAmountLabelDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.TransactionAmount}
                  </h5>
                </div>
                <div className='inputTransactionAmountDiv'>
                  <input className='inputTransactionAmount' />
                </div>
              </div>
              <div className='TransactionAmountInputDiv'>
                <div className='inputTransactionAmountLabelDiv'>
                  <h5 className='ViewTransactionCardTextBold'>
                    {userSelectedLanguage?.Memo}
                  </h5>
                </div>
                <div className='inputTransactionAmountDiv'>
                  <input className='inputTransactionAmount' />
                </div>
              </div>
              <div className='TransactionAmountInputDiv'>
                <div className='saveTransactionButtonDiv'>
                  <button className='saveTransactionBtn'>
                    {userSelectedLanguage?.Save}
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className='nameEmailDiv'>
          <div>
            <h2 className='membershipDetailText'>
              {userSelectedLanguage?.paymentDetail}
            </h2>
           

            <div className='membershipDetailCard'>
              <div className='DetailCardTextDiv'>
                <div className='DetailCardInnerDiv'>
                  <p className='DetailCardTextBold'>
                    {userSelectedLanguage?.TransactionNumber}
                  </p>
                </div>
                <div className='DetailCardInnerDiv'>
                  <p className='DetailCardText'>23767466383483</p></div>
              </div>
              <div className='DetailCardTextDiv'>
                <div className='DetailCardInnerDiv'>
                  <p className='DetailCardTextBold'>
                    {userSelectedLanguage?.ProductName}
                  </p>
                </div>
                <div className='DetailCardInnerDiv'>
                  <p className='DetailCardText'>Shared rental Ticket</p></div>
              </div>
              <div className='DetailCardTextDiv'>
                <div className='DetailCardInnerDiv'>
                  <p className='DetailCardTextBold'>{userSelectedLanguage?.TotalPrice}</p>
                </div>
                <div className='DetailCardInnerDiv'>
                  <p className='DetailCardText'>50,000 </p></div>
              </div>
              <div className='DetailCardTextDiv'>
                <div className='DetailCardInnerDiv'>
                  <p className='DetailCardTextBold'>
                    {userSelectedLanguage?.PaymentMethod}
                  </p>
                </div>
                <div className='DetailCardInnerDiv'>
                  <p className='DetailCardText'>Card</p></div>
              </div>
              <div className='DetailCardTextDiv'>
                <div className='DetailCardInnerDiv'>
                  <p className='DetailCardTextBold'>
                    {userSelectedLanguage?.Date}
                  </p>
                </div>
                <div className='DetailCardInnerDiv'>
                  <p className='DetailCardText'>2022.4.22 |13:30</p></div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VeiwDetailMembershipOnline
