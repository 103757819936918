import { LANGUAGE, SHOW_SIDE_MENU_DRAWER, GET_ALL_AUCTION } from "../types"
const SideMenuDrawer = Boolean => {
    return {
        type: SHOW_SIDE_MENU_DRAWER,
        payload: Boolean,
    }
}



const setLanguage = (language) => {
    return {
        type: LANGUAGE,
        payload: language
    }
}

const setAuction = (data) => {
    return {
        type: GET_ALL_AUCTION,
        payload: data
    }
}



export {
    SideMenuDrawer,
    setLanguage,
    setAuction
}