import axios from 'axios'
import React, { useState } from 'react'
import { ButtonLogout } from '../../Components'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { requiredMessage, errorMessage, errorNotification, successNotification, successMessage } from '../../utils/helpers'
import { allPaths } from '../../utils/constants'
import { ADMIN } from '../../utils/apis'
import { loginUser } from '../../Redux/actions/authActions'

const BankInfo = (props) => {
    const { user } = props
    const dispatch = useDispatch()
    const userSelectedLanguage = props?.language
    const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
    const lanKorCondition = SelectedLanguage === 'english'
    let [bankName, setBankName] = useState(user?.bankName)
    let [bankAccName, setBankAccName] = useState(user?.bankAccount)
    let [bankAccNumber, setBankAccNumber] = useState(user?.bankAccountNumber)
    let [loading, setLoading] = useState(false)


    const updateAdminBankinfo = () => {
        setLoading(true)
        if (bankName || bankAccName || bankAccNumber) {
            let obj = {
                bankName,
                bankAccount: bankAccName,
                bankAccountNumber: bankAccNumber,
                _id: user?._id
            }
            axios.post(ADMIN?.UPDATE_ADMIN_BANK_INFO, obj)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        successMessage(lanKorCondition ? data.message : data.messageKr)
                        dispatch(loginUser(data?.admin))
                    } else {
                        errorMessage(lanKorCondition ? data.message : data.messageKr)
                    }
                })
                .catch((e) => {
                    console.log('e====>', e)
                    setLoading(false)
                    errorMessage(userSelectedLanguage?.OppsError)
                })
        } else {
            return errorMessage(lanKorCondition ? 'Please provide All fields!' : '모든 항목을 작성해주세요!')
        }

    }

    return (
        <>
            <ButtonLogout props={props} />
            <div className='settingDiv'>
                {/* Name Email ID etc */}
                <div className='nameEmailDiv'>
                    <div className='divHeader'>
                        <h3 className='headerText'>{lanKorCondition ? 'Admin bank info for auction ' : '관리자 경매 계좌정보'}</h3>
                        <div className='borderBottom'></div>
                    </div>
                    <div className='divHeader'>
                        <h3 className='headerText'>{lanKorCondition ? 'Bank Details ' : '계좌정보'}</h3>
                    </div>
                    <div className='textFeildsDiv'>
                        <div className='textFeildDivBank'>
                            <h5>{lanKorCondition ? 'Bank name' : '은행명'}</h5>
                            <input onChange={(e) => setBankName(e?.target?.value)} value={bankName} className='inputFeild' />
                        </div>
                    </div>
                    <div className='textFeildsDiv'>
                        <div className='textFeildDivBank'>
                            <h5>{lanKorCondition ? 'Name of bank account' : ' 예금주'}</h5>
                            <input onChange={(e) => setBankAccName(e?.target?.value)} value={bankAccName} className='inputFeild' />
                        </div>
                    </div>
                    <div className='textFeildsDiv'>
                        <div className='textFeildDivBank'>
                            <h5>{lanKorCondition ? 'Bank account number' : '계좌번호'}</h5>
                            <input onChange={(e) => setBankAccNumber(e?.target?.value)} value={bankAccNumber} className='inputFeild' />
                        </div>
                    </div>
                    <div className='saveButtonDiv'>
                        <Button loading={loading} onClick={updateAdminBankinfo} className='saveBtn'>{userSelectedLanguage?.Save}</Button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default BankInfo
