import React, { useEffect, useState } from 'react'
import { Select, Button, Input, Table, Divider, Modal, DatePicker, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { ButtonLogout } from '../../Components'
import { FiSearch } from 'react-icons/fi'
import { HiPlusCircle } from 'react-icons/hi'
import { RiCheckboxBlankFill } from 'react-icons/ri';
import axios from 'axios'
import { ADMIN } from '../../utils/apis'
import { convertTitle, errorMessage, stringLimiter, successNotification } from '../../utils/helpers'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { allPaths, types, typesKr } from '../../utils/constants'
import { FcCancel } from "react-icons/fc"
import EditUsersTicket from './EditUsersTicket'
import AddGroupBuyModal from './AddModal/AddGroupBuyModal'
const { Option } = Select
const { TextArea } = Input;

const GroupBuyPurchase = (props) => {
  const userSelectedLanguage = props?.language
  const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const lanKorCondition = SelectedLanguage === 'english'
  const history = props?.history
  const [dropDown, setdropDown] = useState(6 + '개 보기')
  const [tableRow, setTableRow] = useState(6)
  const [loading, setLoading] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [approveLoader, setApproveLoader] = useState(false)
  const [cancelLoader, setCancelLoader] = useState(false)
  const [closedLoader, setClosedLoading] = useState(false)
  const [showAdminData, setShowAdminData] = useState(false)
  const [addNewGroupBuy, setAddNewGroupBuy] = useState(false)
  const [ticketData, setTicketData] = useState({})
  const [groupBuyReq, setGroupBuyReq] = useState([])
  const [adminGroupBuyReq, setAdminGroupBuyReq] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [search, setSearch] = useState(null)
  // const [adminGroupBuyReq, setAdminGroupBuyReq] = useState([])

  let arr = [{
    value: 10, Viewby: '10' + '개 보기'
  }, {
    value: 8, Viewby: ' 8' + '개 보기'
  }, {
    value: 6, Viewby: ' 6' + '개 보기'
  },]

  useEffect(() => {
    if (search) {
      let filterData = GroupBuyData?.filter((v) => v?.NameIDphoneOfUploader?.[0]?.includes(search) || v?.NameIDphoneOfUploader?.[1]?.includes(search) || v?.NameIDphoneOfUploader?.[2]?.includes(search)) || []
      setFilteredData(filterData)
    }else{
      setFilteredData(GroupBuyData)
    }
  }, [groupBuyReq,search])


  const getGroupBuyReq = () => {
    setLoading(true)
    axios.get(`${ADMIN?.GET_GROUP_BUY_REQ}`)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data?.success) {
          let userData = data?.uploadGroupBuyReq?.filter((v) => v?.userId)
          let adminData = data?.uploadGroupBuyReq?.filter((v) => v?.adminId)
          setGroupBuyReq(userData || [])
          setAdminGroupBuyReq(adminData || [])
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setLoading(false)
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }

  useEffect(() => {
    getGroupBuyReq()
  }, [])
  let GroupBuyData = groupBuyReq && groupBuyReq?.map((v, i) => {
    return {
      TransactionNumber: v?._id,
      NameIDphoneOfUploader: v?.buyerId ? [v?.buyerId?.fullName, v?.buyerId?._id, v?.buyerId?.telNo] : [v?.userId?.fullName, v?.userId?._id, v?.userId?.telNo],
      JoiningFee: v?.joiningFee || v?.joiningFee,
      Type: v?.membershipType || v?.membershipType,
      joiningDate: [v?.joiningDate?.date1, v?.joiningDate?.date2],
      ViewTicket: v,
      Status: v,
      Action: v,
    }
  })
  let GroupBuyAdminData = adminGroupBuyReq && adminGroupBuyReq?.map((v, i) => {
    return {
      TransactionNumber: v?._id,
      NameIDphoneOfUploader: v?.buyerId ? [v?.buyerId?.fullName, v?.buyerId?._id, v?.buyerId?.telNo] : [v?.userId?.fullName, v?.userId?._id, v?.userId?.telNo],
      JoiningFee: v?.joiningFee || v?.joiningFee,
      Type: v?.membershipType || v?.membershipType,
      joiningDate: [v?.joiningDate?.date1, v?.joiningDate?.date2],
      ViewTicket: v,
      Status: v,
      Action: v,
    }
  })

  const DeleteGroupBuyReq = (id, requestType) => {
    setDeleteLoader(true)
    let obj = {
      id: id,
      requestType: requestType
    }
    axios.post(ADMIN?.DELETE_GROUP_BUY_REQ, obj)
      .then((res) => {
        const { data } = res
        setDeleteLoader(false)
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          getGroupBuyReq()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
          setDeleteLoader(false)

        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setDeleteLoader(false)
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }

  const ApproveGroupBuyReq = (id, requestType) => {

    setApproveLoader(true)
    let obj = {
      id: id,
      requestType: requestType
    }
    axios.post(ADMIN?.APPROVE_GROUP_BUY_REQ, obj)
      .then((res) => {
        const { data } = res
        setApproveLoader(false)
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          getGroupBuyReq()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setApproveLoader(false)
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }
  const CancelGroupBuyReq = (id, requestType) => {
    setCancelLoader(true)
    let obj = {
      id: id,
      requestType: requestType
    }
    axios.post(ADMIN?.CANCEL_GROUP_BUY_REQ, obj)
      .then((res) => {
        const { data } = res
        setCancelLoader(false)
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          getGroupBuyReq()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setCancelLoader(false)
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }
  const ClosedGroupBuyReq = (id) => {
    setClosedLoading(true)
    let obj = {
      id: id,
    }
    axios.post(ADMIN?.CLOSED_GROUP_BUY_REQ, obj)
      .then((res) => {
        const { data } = res
        setClosedLoading(false)
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          getGroupBuyReq()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setClosedLoading(false)
        errorMessage(userSelectedLanguage?.OppsError)
      })
  }

  const columns = [
    {
      title: userSelectedLanguage?.TransactionNumber,
      dataIndex: 'TransactionNumber',
      key: 'Transaction number',
      render: (text) =>
        <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
          <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 13)}</Button>
        </Tooltip>,
    },
    {
      title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
      dataIndex: 'NameIDphoneOfUploader',
      key: 'Name and Phone Number',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          {text?.map((v, i) => {
            return (
              <Tooltip placement='top' title={convertTitle(v || '----')} color={'#5EA29F'}>
                <p className='namePhoneAndID'>{stringLimiter(convertTitle(v || '----'), 14)}</p>
              </Tooltip>
            )
          })}
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.JoiningFee,
      dataIndex: 'JoiningFee',
      key: 'Joining fee',
      render: (text) => <p className='tableColoumWidthBig'>{text ? text + ' ' + userSelectedLanguage?.KRW : '---'}</p>,
    },
    {
      title: userSelectedLanguage?.Type,
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => <p className='tableColoumWidth'>{lanKorCondition ? text : typesKr[types?.indexOf(text)]?.typesKr || '----'}</p>,
    },
    {
      title: userSelectedLanguage?.JoiningDate,
      dataIndex: 'joiningDate',
      key: 'Joining Date',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          {text?.map((v, i) => {
            return (
              <p className='namePhoneAndID'>{v ? moment(v).format('YYYY-MM-DD') : '----'}</p>
            )
          })}
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.ViewTicket,
      dataIndex: 'ViewTicket',
      key: 'View Ticket',
      render: (text) => (
        <>
          <div className='tableColoumWidthBig'>
            {text?.isCanceled ?
              <div className='searchIconDiv'>
                <FcCancel />
              </div>
              : <div className='searchIconDiv'
                onClick={() => {
                  history.push({ pathname: allPaths?.GROUP_BUY_DETAIL, state: [text, { title: 'Group buy Detail' }] })
                }}
              >
                <FiSearch />
              </div>}
          </div>
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        < div style={{ width: '200px' }}>
          {text?.isClosed ?
            lanKorCondition ? 'group buy not available' : '동구매 불가' :
            text?.isCanceled ?
              lanKorCondition ? 'closed Request' : '공동구매 마감' :
              text?.isCompleted ?
                lanKorCondition ? 'Completed group buy ' : '거래완료 ' :
                !text?.isAdminApproved && !text?.isCompleted ?
                  lanKorCondition ? 'Requested group  ticket' : '진행 요청 중 ' :
                  lanKorCondition ? 'group buy available' : '공동구매 가능'
          }
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        <div className='tableBtnsDiv'>
          <Button onClick={() => setTicketData(text)} className='postApproval'>{userSelectedLanguage?.Edit}</Button>
          {!text?.membershipCardName ?
            <>
              < Button
                // disabled={text?.isAdminApproved || text?.isCanceled || text?.isSaled || text?.isCompleted || text?.isCanceled}
                loading={deleteLoader}
                onClick={() => { DeleteGroupBuyReq(text?._id, text?.buyerId?._id ? 'Participate' : 'UploadGroupBuy') }}
                className='DeleteBtn'> {userSelectedLanguage?.Delete}
              </Button>
            </>
            :
            <>
            
              <Button disabled={text?.isAdminApproved || text?.isCanceled} loading={approveLoader} onClick={() => { ApproveGroupBuyReq(text?._id, text?.buyerId?._id ? 'Participate' : 'UploadGroupBuy') }} className='postApproval'>{lanKorCondition ? 'Post Approve' : '승인'}</Button>
              <Button
                loading={cancelLoader}
                onClick={() => { CancelGroupBuyReq(text?._id, text?.buyerId?._id ? 'Participate' : 'UploadGroupBuy') }}
                disabled={text?.isAdminApproved || text?.isSaled || text?.isCompleted || text?.isCanceled} className='editBtn' >
                {userSelectedLanguage?.CancelRequest}</Button>
              <Button
                loading={closedLoader}
                onClick={() => { ClosedGroupBuyReq(text?._id,) }}
                disabled={text?.isCanceled || text?.isClosed}
                className='postApproval-na'>{lanKorCondition ? 'Group buy N/A' : '공동구매 불가'}
              </Button>

              < Button
                // disabled={text?.isAdminApproved || text?.isCanceled || text?.isSaled || text?.isCompleted || text?.isCanceled}
                loading={deleteLoader}
                onClick={() => { DeleteGroupBuyReq(text?._id, text?.buyerId?._id ? 'Participate' : 'UploadGroupBuy') }}
                className='DeleteBtn'> {userSelectedLanguage?.Delete}
              </Button>
            </>
          }
        </div>
      ),
    },
  ]
  const adminColumns = [
    {
      title: userSelectedLanguage?.TransactionNumber,
      dataIndex: 'TransactionNumber',
      key: 'Transaction number',
      render: (text) =>
        <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
          <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 13)}</Button>
        </Tooltip>,
    },
    // {
    //   title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
    //   dataIndex: 'NameIDphoneOfUploader',
    //   key: 'Name and Phone Number',
    //   render: (text) => (
    //     <div className='namePhoneAndIDiv'>
    //       {text?.map((v, i) => {
    //         return (
    //           <Tooltip placement='top' title={convertTitle(v || '----')} color={'#5EA29F'}>
    //             <p className='namePhoneAndID'>{stringLimiter(convertTitle(v || '----'), 14)}</p>
    //           </Tooltip>
    //         )
    //       })}
    //     </div>
    //   ),
    // },
    {
      title: `${userSelectedLanguage?.NameOfUploader}`,
      dataIndex: 'NameIDphoneOfUploader',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          <p className='namePhoneAndID'>{lanKorCondition ? 'admin' : '어드민'}</p>
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.JoiningFee,
      dataIndex: 'JoiningFee',
      key: 'Joining fee',
      render: (text) => <p className='tableColoumWidthBig'>{text ? text + ' ' + userSelectedLanguage?.KRW : '---'}</p>,
    },
    {
      title: userSelectedLanguage?.Type,
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => <p className='tableColoumWidth'>{lanKorCondition ? text : typesKr[types?.indexOf(text)]?.typesKr || '----'}</p>,
    },
    {
      title: userSelectedLanguage?.JoiningDate,
      dataIndex: 'joiningDate',
      key: 'Joining Date',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          {text?.map((v, i) => {
            return (
              <p className='namePhoneAndID'>{v ? moment(v).format('YYYY-MM-DD') : '----'}</p>
            )
          })}
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.ViewTicket,
      dataIndex: 'ViewTicket',
      key: 'View Ticket',
      render: (text) => (
        <>
          <div className='tableColoumWidthBig'>
            {text?.isCanceled ?
              <div className='searchIconDiv'>
                <FcCancel />
              </div>
              : <div className='searchIconDiv'
                onClick={() => {
                  history.push({ pathname: allPaths?.GROUP_BUY_DETAIL, state: [text, { title: 'Group buy Detail' }] })
                }}
              >
                <FiSearch />
              </div>}
          </div>
        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        < div style={{ width: '200px' }}>
          {text?.isClosed ?
            lanKorCondition ? 'group buy not avilible' : '동구매 불가' :
            text?.isCanceled ?
              lanKorCondition ? 'closed Request' : '공동구매 마감' :
              text?.isCompleted ?
                lanKorCondition ? 'Completed group buy ' : '거래완료 ' :
                !text?.isAdminApproved && !text?.isCompleted ?
                  lanKorCondition ? 'Requested group  ticket' : '진행 요청 중 ' :
                  lanKorCondition ? 'group buy avilible' : '공동구매 가능'
          }
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (text) => (
        <div className='tableBtnsDiv'>
          <Button onClick={() => setTicketData(text)} className='postApproval'>{userSelectedLanguage?.Edit}</Button>
          {!text?.membershipCardName ?
            <>
              < Button
                // disabled={text?.isAdminApproved || text?.isCanceled || text?.isSaled || text?.isCompleted || text?.isCanceled}
                loading={deleteLoader}
                onClick={() => { DeleteGroupBuyReq(text?._id, text?.buyerId?._id ? 'Participate' : 'UploadGroupBuy') }}
                className='DeleteBtn'> {userSelectedLanguage?.Delete}
              </Button>
            </>
            :
            <>
            
              <Button disabled={text?.isAdminApproved || text?.isCanceled} loading={approveLoader} onClick={() => { ApproveGroupBuyReq(text?._id, text?.buyerId?._id ? 'Participate' : 'UploadGroupBuy') }} className='postApproval'>{lanKorCondition ? 'Post Approve' : '승인'}</Button>
              <Button
                loading={cancelLoader}
                onClick={() => { CancelGroupBuyReq(text?._id, text?.buyerId?._id ? 'Participate' : 'UploadGroupBuy') }}
                disabled={text?.isAdminApproved || text?.isSaled || text?.isCompleted || text?.isCanceled} className='editBtn' >
                {userSelectedLanguage?.CancelRequest}</Button>
              <Button
                loading={closedLoader}
                onClick={() => { ClosedGroupBuyReq(text?._id,) }}
                disabled={text?.isCanceled || text?.isClosed}
                className='postApproval-na'>{lanKorCondition ? 'Group buy N/A' : '공동구매 불가'}
              </Button>

              < Button
                // disabled={text?.isAdminApproved || text?.isCanceled || text?.isSaled || text?.isCompleted || text?.isCanceled}
                loading={deleteLoader}
                onClick={() => { DeleteGroupBuyReq(text?._id, text?.buyerId?._id ? 'Participate' : 'UploadGroupBuy') }}
                className='DeleteBtn'> {userSelectedLanguage?.Delete}
              </Button>
            </>
          }
        </div>
      ),
    },
  ]

  console.log('GroupBuyData', GroupBuyData)
  return (
    <>
      {ticketData?._id &&
        <EditUsersTicket getTickets={getGroupBuyReq} ticketData={ticketData} onCancel={() => setTicketData({})} {...props} />}
      {addNewGroupBuy &&
        <AddGroupBuyModal Open={addNewGroupBuy} onCancel={() => setAddNewGroupBuy(false)} {...props} />
      }
      <ButtonLogout props={props} />
      <div className='membershipBox'>
        <div className='goldReservationHeader'>
          <div className='golfHeaderText'>
            <h3>{userSelectedLanguage?.TicketSalesManagement}</h3>
            <p>{'>'}{userSelectedLanguage?.GroupBuyPurchaseRequestGroupSalesPurchaseRequest}</p>
          </div>
          <div className='headersearch'>
            <Select
              className='dropdown'
              defaultValue={dropDown}
              onChange={(e) => {
                setTableRow(e)
              }}
            >
              {arr.map((v, i) => {
                return <Option key={v.value}>{v?.Viewby}</Option>
              })}
            </Select>
            <Input
              className='searchgolfReservation'
              placeholder={userSelectedLanguage?.PleaseSearchATerm}
              onChange={(e) => setSearch(e?.target?.value)}
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
        <div
          style={{
            margin: '0px auto',
            display: 'flex',
            width: '95%',
            justifyContent: 'flex-end'
          }}>
          <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }}
            onClick={() => setAddNewGroupBuy(true)}
          >
            {lanKorCondition ? 'Add Group buy Ticket' : '공동구매 회원권 게시'}
          </Button>

          <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }}
            onClick={() => setShowAdminData(true)}
          >
            {userSelectedLanguage?.AdminGroupbuytickets}
          </Button>
          <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }}
            onClick={() => setShowAdminData(false)}
          >
            {userSelectedLanguage?.allusergroupbuytickets}
          </Button>
        </div>
        <div className='tableDivManagement'>
          <Divider />
          <Table
            loading={loading}
            pagination={{ pageSize: tableRow }}
            dataSource={showAdminData ? GroupBuyAdminData : filteredData}
            columns={showAdminData ? adminColumns : columns}
            scroll={{ x: 700 }}
            size='middle'
          />
        </div>
      </div>
    </>
  )
}
export default GroupBuyPurchase
