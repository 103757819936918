import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Select, Tooltip, Popconfirm, Modal, Form, DatePicker, Image } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { ButtonLogout } from '../../Components'
import { Table, Divider } from 'antd'
import { FiSearch } from 'react-icons/fi'
import axios from 'axios'
import { allPaths } from '../../utils/constants'
import { ADMIN } from '../../utils/apis'
import { convertTitle, errorMessage, formatTime, stringLimiter, successNotification } from '../../utils/helpers'
import moment from 'moment-timezone'
import EditGolfReservation from './EditGolfReservation'
import { useSelector } from 'react-redux'
import EditGolfReservationModal from './EditGolfReservationModal'
import AddGolfReservationModal from './AddGolfReservaion'

const { RangePicker } = DatePicker
const { Option } = Select

const GolfReservation = (props) => {
  const history = props?.history
  const userSelectedLanguage = props?.language
  const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const lanKorCondition = SelectedLanguage === 'english'
  const { user } = props
  const [dropDown, setdropDown] = useState(6 + '개 보기')
  const [tableRow, setTableRow] = useState(6)
  const [loading, setLoading] = useState(false)
  const [reservationData, setReservationData] = useState([])
  const [correction, setCorrection] = useState(false)
  const [myReservation, setMyReservation] = useState(false)
  const [addGolfReservation, setAddGolfReservation] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [search, setSearch] = useState(null)

  let arr = [{
    value: 10, Viewby: '10' + '개 보기'
  }, {
    value: 8, Viewby: ' 8' + '개 보기'
  }, {
    value: 6, Viewby: ' 6' + '개 보기'
  },]


  useEffect(() => {
    if (search) {
      let filterData = ReservationDataIndex?.filter((v) => v?.nameOfuploader?.name?.includes(search) || v?.nameOfuploader?.Tell?.includes(search) || v?.nameOfuploader?.id?.includes(search)) || []
      setFilteredData(filterData)
    } else {
      setFilteredData(ReservationDataIndex)
    }
  }, [reservationData, search])

  const closeAddGolfForm = () => {
    setAddGolfReservation(false)

  }
  const [deleteLoader, setDeleteLoader] = useState({})
  const [selectReservation, setSelectReservation] = useState({})

  useEffect(() => {
    getAllGolfReservation()
  }, [])
  const getAllGolfReservation = (props) => {
    setLoading(true)
    axios.get(`${ADMIN?.GET_ALL_GOLF_RESERVATION}/${user?._id}`)
      .then((res) => {
        const { data } = res
        setLoading(false)
        if (data?.success) {
          setReservationData(data?.data)
          console.log(data?.data)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setLoading(false)
        errorMessage()
      })
  }
  const ReservationDataIndex = reservationData?.length && reservationData.filter((v) => v?.userId?._id).map((v, i) => {
    return {
      _id: v?._id,
      golfClub: v?.golfClub?.clubName,
      reservedDate: `${moment(v?.reservedDate).format('YYYY-MM-DD') || '----'}`,
      nameOfuploader: {
        name: v?.userId?.fullName,
        id: v?.userId?._id,
        Tell: v?.userId?.telNo,
      },
      reservedTime:v?.reservedTime,
      transferFee: v?.transferFee,
      Status: v?.isReserved,
      allData: v,
      Viewticket: v
    }
  })

  const ReservationDataAdmin = reservationData?.length && reservationData.filter((v) => !v?.userId?._id).map((v, i) => {
    
    return {
      _id: v?._id,
      golfClub: v?.golfClub?.clubName,
      reservedDate: `${moment(v?.reservedDate).format('YYYY-MM-DD') || '----'}`,
      nameOfuploader: {
        name: v?.userId?.fullName,
        id: v?.userId?._id,
        Tell: v?.userId?.telNo,
      },
      transferFee: v?.transferFee,
      reservedTime:v?.reservedTime,
      Status: v?.isReserved,
      allData: v,
      Viewticket: v
    }
  })


  const columns = [
    {
      title: lanKorCondition ? 'membership number' : '거래번호',
      dataIndex: '_id',
      key: 'Membership Number',
      render: (text) => <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
        <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 12)}</Button>
      </Tooltip>
    },
    {
      title: `${userSelectedLanguage?.NameOfUploader} & ${userSelectedLanguage?.ID} & ${userSelectedLanguage?.PhoneNumber} `,
      dataIndex: 'nameOfuploader',
      key: 'Name and Phone Number',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          <Tooltip placement='top' title={convertTitle(text?.name || '----')} color={'#5EA29F'}>
            <p className='namePhoneAndID'>{stringLimiter(convertTitle(text?.name || ' null'), 12)}</p>
          </Tooltip>
          <Tooltip placement='top' title={convertTitle(text?.id || '----')} color={'#5EA29F'}>
            <p className='namePhoneAndID'>{stringLimiter(convertTitle(text?.id || '----'), 12)}</p>
          </Tooltip>
          <Tooltip placement='top' title={convertTitle(text?.Tell || '----')} color={'#5EA29F'}>
            <p className='namePhoneAndID'>{stringLimiter(convertTitle(text?.Tell || '----'), 12)}</p>
          </Tooltip>

        </div>
      )
    },
    {
      title: userSelectedLanguage?.GolfClubName,
      dataIndex: 'golfClub',
      key: 'Golf Club Name',
      render: (text) => <p className='tableColoumWidth'>{text || '----'}</p>,
    },
    {
      title: lanKorCondition ? 'ReservationDate' : '예약일시',
      dataIndex: 'reservedDate',
      key: 'Reservation Date',
      render: (text) => <p className='tableColoumWidth'>{text || '----'}</p>,
    },
    {
      title: '예약 일시',
      dataIndex: 'reservedTime',
      key: 'Reservation Time',
      render: (text) => <p className='tableColoumWidth'>{ formatTime(text) || '----'}</p>,
    },
    {
      title: userSelectedLanguage?.Fee,
      dataIndex: 'transferFee',
      key: 'Fee',
      render: (text) => <p className='tableColoumWidth'>{text ? text + '' + userSelectedLanguage?.KRW : '----'}</p>,
    },
    {
      title: userSelectedLanguage?.Viewticket,
      dataIndex: 'Viewticket',
      key: 'Viewticket',
      render: (text) => (
        <>
          <div className='tableColoumWidth'>
            <div className='searchIconDiv'>
              <FiSearch
                style={{ cursor: 'pointer' }}
                onClick={() => { history.push({ pathname: allPaths?.GOLFBIDDETAIL, state: text }) }}
              />
            </div>
          </div>

        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        <div className='extreWidth'>
          {!text
            ?
            <button className='reservationCompt'>{userSelectedLanguage?.Pending}</button>
            :
            <button className='postCompt'>{lanKorCondition ? 'PostCompleted' : '예약완료'}</button>
          }
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      // dataIndex: 'Action',
      key: 'Action',
      render: (e) => (
        <div className='tableBtnsDiv'>
          {e ? (
            <>
              <button className='postApproval' onClick={() => {
                setSelectReservation(e),
                  setCorrection(true)
              }}>
                {userSelectedLanguage?.Edit}
              </button>
              <Popconfirm
                title={`${convertTitle(e?.golfClub)} 티켓을 삭제하시겠습니까?`}
                okText='Delete'
                cancelText='Cancel'
                okButtonProps={{
                  type: 'primary',
                  className: 'form-button'
                }}
                cancelButtonProps={{ style: { borderRadius: 4 } }}
                onConfirm={() => deleteRes(e?._id)}
              >
                <Button loading={deleteLoader[e?._id]} className='btnTableDelete' type='primary'>
                  {userSelectedLanguage?.Delete}</Button>
              </Popconfirm>
            </>
          ) : null}
        </div>
      ),
    },
  ]
  const columnsAdmin = [
    {
      title: lanKorCondition ? 'membership number' : '거래번호',
      dataIndex: '_id',
      key: 'Membership Number',
      render: (text) => <Tooltip placement='top' title={convertTitle(text || '----')} color={'#5EA29F'}>
        <Button className='tableBtn'>{stringLimiter(convertTitle(text || '----'), 12)}</Button>
      </Tooltip>
    },
    {
      title: `${userSelectedLanguage?.NameOfUploader}`,
      dataIndex: 'nameOfuploader',
      key: 'Name and Phone Number',
      render: (text) => (
        <div className='namePhoneAndIDiv'>
          <p className='namePhoneAndID'>{lanKorCondition ? 'admin' : '어드민'}</p>
        </div>
      )
    },
    {
      title: userSelectedLanguage?.GolfClubName,
      dataIndex: 'golfClub',
      key: 'Golf Club Name',
      render: (text) => <p className='tableColoumWidth'>{text || '----'}</p>,
    },
    {
      title: lanKorCondition ? 'ReservationDate' : '예약일시',
      dataIndex: 'reservedDate',
      key: 'Reservation Date',
      render: (text) => <p className='tableColoumWidth'>{text || '----'}</p>,
    },
    {
      title: '예약 일시',
      dataIndex: 'reservedTime',
      key: 'Reservation Time',
      render: (text) => <p className='tableColoumWidth'>{formatTime(text) || '----'}</p>,
    },
    {
      title: userSelectedLanguage?.Fee,
      dataIndex: 'transferFee',
      key: 'Fee',
      render: (text) => <p className='tableColoumWidth'>{text ? text + '' + userSelectedLanguage?.KRW : '----'}</p>,
    },
    {
      title: userSelectedLanguage?.Viewticket,
      dataIndex: 'Viewticket',
      key: 'Viewticket',
      render: (text) => (
        <>
          <div className='tableColoumWidth'>
            <div className='searchIconDiv'>
              <FiSearch
                style={{ cursor: 'pointer' }}
                onClick={() => { history.push({ pathname: allPaths?.GOLFBIDDETAIL, state: text }) }}
              />
            </div>
          </div>

        </>
      ),
    },
    {
      title: userSelectedLanguage?.Status,
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => (
        <div className='extreWidth'>
          {!text
            ?
            <button className='reservationCompt'>{userSelectedLanguage?.Pending}</button>
            :
            <button className='postCompt'>{lanKorCondition ? 'PostCompleted' : '예약완료'}</button>
          }
        </div>
      ),
    },
    {
      title: userSelectedLanguage?.Action,
      // dataIndex: 'Action',
      key: 'Action',
      render: (e) => (
        <div className='tableBtnsDiv'>
          {e ? (
            <>
              <button className='postApproval' onClick={() => {
                setIsAdmin(true); setSelectReservation(e), setCorrection(true)
              }}>
                {userSelectedLanguage?.Edit}
              </button>
              <Popconfirm
                title={`${convertTitle(e?.golfClub)} 티켓을 삭제하시겠습니까?`}
                okText='Delete'
                cancelText='Cancel'
                okButtonProps={{
                  type: 'primary',
                  className: 'form-button'
                }}
                cancelButtonProps={{ style: { borderRadius: 4 } }}
                onConfirm={() => deleteRes(e?._id)}
              >
                <Button loading={deleteLoader[e?._id]} className='btnTableDelete' type='primary'>
                  {userSelectedLanguage?.Delete}</Button>
              </Popconfirm>
            </>
          ) : null}
        </div>
      ),
    },
  ]
  const deleteRes = (id) => {
    setDeleteLoader({ [id]: true })

    let obj = {
      _id: id, adminId: user?._id
    }
    axios.post(ADMIN?.DELETE_GOLF_RESERVATION, obj)
      .then((res) => {
        const { data } = res
        setDeleteLoader({})
        if (data.success) {
          successNotification('Successfully Deleted!')
          getAllGolfReservation()
        } else {
          errorMessage(data.message)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setDeleteLoader({})
        errorMessage()
      })
  }

  return (
    <>

      <ButtonLogout props={props} />

      {/* {selectReservation?._id ?
        <EditGolfReservation userSelectedLanguage={userSelectedLanguage} getAllGolfReservation={getAllGolfReservation} selectReservation={selectReservation} onCancel={() => setSelectReservation({})}  {...props} /> : null
      } */}

      {selectReservation?._id ?
        <EditGolfReservationModal
          lanKorCondition={lanKorCondition}
          isAdmin={isAdmin}
          setIsAdmin={setIsAdmin}
          language={userSelectedLanguage}
          fun={getAllGolfReservation}
          setGolf={setSelectReservation}
          golfData={selectReservation?.allData}
          correction={correction}
          setCorrection={setCorrection}
          {...props} />
        : null}
      {addGolfReservation &&
        <AddGolfReservationModal
          language={userSelectedLanguage}
          handleCancel={closeAddGolfForm}
          addGolfReservation={addGolfReservation}
          // fun={getAllGolfReservation}
          // setGolf={setSelectReservation}
          // golfData={selectReservation?.allData}
          // correction={correction}
          // setCorrection={setCorrection}
          {...props} />}

      <div className='membershipBox'>
        <div className='goldReservationHeader'>
          <div className='golfHeaderText'>
            <h3>{myReservation ?
              lanKorCondition ? 'Admin golf reservation transaction management ' : '골프예약 회원권 관리'
              : userSelectedLanguage?.GolfReservationPostManagement}</h3>
          </div>
          <div className='headersearch'>
            <Select
              className='dropdown'
              defaultValue={dropDown}
              onChange={(e) => {
                setTableRow(e)
              }}
            >
              {arr.map((v, i) => {
                return <Option key={v.value}>{v?.Viewby}</Option>
              })}
            </Select>
            <Input
              className='searchgolfReservation'
              placeholder={userSelectedLanguage?.PleaseSearchATerm}
              onChange={(e) => setSearch(e?.target?.value)}
              prefix={<SearchOutlined />}
            />
          </div>
        </div>



        <div
          style={{
            margin: '0px auto',
            display: 'flex',
            width: '95%',
            justifyContent: 'flex-end'
          }}>
          <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => setAddGolfReservation(true)}>
            {lanKorCondition ? 'Register Golf Reservation Ticket' : '골프예약 회원권 등록'}
          </Button>
          <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => setMyReservation(false)}>
            {lanKorCondition ? `Back to User's Reservations` : '골프예약 리스트 보기'}
          </Button>

          <Button type='primary' className='AddClubCardBtn' style={{ margin: '2px', }} onClick={() => setMyReservation(true)}>{lanKorCondition ? 'Admin Golf Reservations' : '어드민 골프 예약'}</Button>
        </div>

        {myReservation ?
          <div className='tableDivManagement'>
            <Divider />
            <Table
              loading={loading}
              pagination={{ pageSize: tableRow }}
              dataSource={ReservationDataAdmin}
              columns={columnsAdmin}
              scroll={{ x: 700 }}
              size='middle'
            />
          </div>
          :
          <div className='tableDivManagement'>
            <Divider />
            <Table
              loading={loading}
              pagination={{ pageSize: tableRow }}
              dataSource={filteredData}
              columns={columns}
              scroll={{ x: 700 }}
              size='middle'
            />
          </div>}
      </div >
    </>
  )
}
export default GolfReservation
