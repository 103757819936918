import { UploadOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Image, Input, Modal, Popconfirm, Select, Table, Upload } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import DaumPostcodeEmbed from 'react-daum-postcode'
import { useSelector } from 'react-redux'
import { ButtonLogout } from '../../Components'
import { ADMIN } from '../../utils/apis'
import { regions, regionsKr } from '../../utils/constants'
import { convertTitle, errorMessage, successNotification, warningMessage } from '../../utils/helpers'
import ClubEditModal from './ClubEditModal'
import { SearchOutlined } from '@ant-design/icons'
import { Map, MapMarker } from 'react-kakao-maps-sdk'

const { Option } = Select
const GolfCLub = (props) => {
  const { user, language } = props
  const [allRegions, setAllRegions] = useState(regionsKr)
  const [selectedRegion, setselectedRegion] = useState('')
  const userSelectedLanguage = props?.language
  const [golfClub, setGolfClub] = useState([])
  const [loading, setLoading] = useState(false)
  const [clubLoading, setClubLoading] = useState(false)
  const [fileList, setFileList] = useState([])
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [selectedClub, setSelectedClub] = useState({})
  const [searchAddress, setSearchAddress] = useState('')
  const [showAddressApi, setShowAddressApi] = useState(false)
  const SelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const lanKorCondition = SelectedLanguage === 'english'
  let geocoder = new kakao.maps.services.Geocoder()
  const [searchValue, setSearchValue] = useState('')

  const handleChange = (value) => {
    setselectedRegion(value)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    getGolfClubs()
  }, [])
  const onCancel = () => {
    setSelectedClub({})
  }
  const onSuccess = () => {
    getGolfClubs()
  }


  const getGolfClubs = () => {
    setClubLoading(true)
    axios.get(`${ADMIN?.GET_ALL_GOLF_CLUB}`)
      .then((res) => {
        const { data } = res
        setClubLoading(false)

        if (data?.success) {
          setGolfClub(data?.data)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setClubLoading(false)
        errorMessage(language?.OppsError)
      })
  }
  const deleteGolfClub = (id) => {
    setDeleteLoader(true)
    let obj = {
      _id: id, adminId: user?._id
    }
    axios.post(ADMIN?.DELETE_GOLF_CLUB, obj)
      .then((res) => {
        const { data } = res
        setDeleteLoader(false)
        if (data.success) {
          successNotification(lanKorCondition ? data.message : data.messageKr)
          getGolfClubs()
        } else {
          errorMessage(lanKorCondition ? data.message : data.messageKr)
          console.log(data.message)
        }
      })
      .catch((e) => {
        console.log('e====>', e)
        setDeleteLoader(false)
        errorMessage(language?.OppsError)
      })
  }

  const handleComplete = (data) => {
    let fullAddress = data?.address
    let extraAddress = ''
    if (data?.addressType === 'R') {
      if (data?.bname !== '') {
        extraAddress += data?.bname
      }
      if (data?.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data?.buildingName}` : data?.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }

    setSearchAddress(fullAddress)
    setShowAddressApi(false)
    // onFinishEditAdd(fullAddress)
  }

  const handleClose = () => {
    setShowAddressApi(false)

  }


  const tableData = golfClub?.length && golfClub?.filter((val, i) => {
    if (searchValue === '') {
      return val
    } else if (val?.clubName?.toLowerCase()?.includes(searchValue?.toLowerCase()?.trim()) || val?.clubAddress?.toLowerCase()?.includes(searchValue?.toLowerCase()?.trim())) {
      return val
    }
  }).map((v, i) => {
    return {
      ClubImage: v?.imgUrl,
      clubName: v?.clubName,
      clubAddress: v?.clubAddress,
      region: `${lanKorCondition ? v?.region : regionsKr[regions?.indexOf(v?.region)]?.TitleKr}`,
      Action: v

    }
  })

  const columns = [

    {
      title: userSelectedLanguage?.ClubImage,
      dataIndex: 'ClubImage',
      key: 'ClubImage',
      render: (text) => (
        <div >
          <Image style={{ height: '100px', width: '200px' }} src={text} />
        </div>
      ),
    },
    {
      title: language?.ClubName,
      dataIndex: 'clubName',
      key: 'ClubName',
      render: (text) =>
        <p >{text}</p>,
    },
    {
      title: language?.Clubaddress,
      dataIndex: 'clubAddress',
      key: 'ClubAddress',
      render: (text) => (
        <p >{text}</p>
      ),
    },
    {
      title: lanKorCondition ? 'CreatedAt' : '업로드 날짜',
      dataIndex: 'Created_at',
      key: 'Created_at',
      render: (text) => (
        <p>{moment(text).format('YYYY-MM-DD')}</p>
      ),
    },
    {
      title: language?.Region,
      dataIndex: 'region',
      key: 'Region',
    },
    {
      title: language?.Action,
      dataIndex: 'Action',
      key: 'Action',
      render: (e) => (
        <div className='tableBtnsDiv'>
          <Button onClick={() => setSelectedClub(e)} className='btnTableEdit' type='primary'>
            {userSelectedLanguage?.Edit}
          </Button>
          <Popconfirm
            title={`Delete ${convertTitle(e?.clubName)}?`}
            okText='Delete'
            cancelText='Cancel'
            okButtonProps={{
              type: 'primary',
              className: 'form-button'
            }}
            cancelButtonProps={{ style: { borderRadius: 4 } }}
            onConfirm={() => deleteGolfClub(e?._id)}
          >
            <Button loading={deleteLoader} className='btnTableDelete' type='primary'>
              {lanKorCondition ? 'Delete' : '삭제'}
            </Button>
          </Popconfirm>

        </div>
      ),
    },


  ]
  const [form] = Form.useForm()
  const onReset = () => {
    form.resetFields()
  }
  const onFinish = (values) => {

    if (!values?.file) {
      return warningMessage(lanKorCondition ? 'Please Upload Image' : '파일을 업로드 해주세요')
    }
    // setLoading(true)

    let formData = new FormData()

    formData.append('clubName', values?.clubName)
    formData.append('clubAddress', searchAddress)
    geocoder.addressSearch(searchAddress, (result, status) => {
      if (status === kakao.maps.services.Status.OK) {
        formData.append('latitude', result[0]?.y || 0)
        formData.append('logitude', result[0]?.x || 0)
        formData.append('file', values?.file?.file)
        formData.append('region', values?.region)

        axios.post(ADMIN?.CREATE_GOLF_CLUB, formData)
          .then((res) => {
            const { data } = res
            setLoading(false)
            if (data.success) {
              successNotification(lanKorCondition ? data.message : data.messageKr)
              getGolfClubs()
              onReset()
              setFileList([])
            } else {
              errorMessage(lanKorCondition ? data.message : data.messageKr)
            }
          })
          .catch((e) => {
            console.log('e====>', e)
            setLoading(false)
            errorMessage(language?.OppsError)
          })

      }
    })
  }

  const normFile = (e) => {
    setFileList([])

    if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
      errorMessage(lanKorCondition ? 'You can only upload Images!' : '이미지만 업로드 해주세요')
      return []
    }

    if (Array.isArray(e)) {
      return e
    }

    e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
    return e && [e.file]
  }

  return (
    <>
      <ButtonLogout props={props} />
      <div className='settingDiv'>
        <div className='nameEmailDiv'>
          <div className='divHeader'>
            <h3 className='headerText'>{language?.AddgolfClub}</h3>
            <div className='borderBottom'></div>
          </div>
          <div className='GolfClubForm' >
            <Form
              form={form}
              name='basic'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
            >
              <div className='TwoInputDiv'>
                <div className='GolfClubFeildDiv'>
                  <Form.Item
                    label={language?.ClubName}
                    name='clubName'
                    rules={[{ required: true, message: userSelectedLanguage?.PleaseinputClubName }]}
                  >
                    <Input className='GolfClubInputFeild' />
                  </Form.Item>
                </div>

                <div className='GolfClubFeildDiv'>
                  <Form.Item
                    initialValue={searchAddress}
                    label={language?.Clubaddress}
                    name='clubAddress'
                  >
                    <div>
                      <Input value={searchAddress} onClick={() => setShowAddressApi(true)} />
                    </div>
                  </Form.Item>
                </div>
              </div>

              <div className='TwoInputDiv'>
                <div className='GolfClubFeildDiv'>
                  <Form.Item
                    label={language?.Region}
                    name='region'
                    rules={[{ required: true, message: userSelectedLanguage?.Pleaseinputregion }]}
                  >
                    <Select style={{ width: '100%' }} defaultValue={lanKorCondition ? allRegions[0].TitleEn : allRegions[0].TitleKr} className='GolfClubInputFeild SelectDropDown' onChange={handleChange}>
                      {
                        allRegions?.map((v, i) => {
                          return (
                            <Option value={v?.TitleEn}>{lanKorCondition ? v?.TitleEn : v?.TitleKr}</Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </div>
                <div className='GolfClubFeildDiv'>
                  <Form.Item
                    label={language?.File}
                    name='file'
                  // rules={[{ required: true, message: 'Please input  File!' }]}
                  >
                    <Upload
                      name='file'
                      multiple={false}
                      beforeUpload={() => false}
                      accept='image/png, image/jpeg'
                      onChange={normFile}
                      fileList={fileList}
                    >
                      <Button className='GolfClubInputFeild BtnUpload' icon={<UploadOutlined color='#5EA29F' />}>Upload</Button>
                    </Upload>
                  </Form.Item>
                </div>
              </div>

              {/* <Form.Item wrapperCol={{ offset: 8, span: 16 }}> */}
              <div className='ButtonDiv'>
                <Button loading={loading} className='AddClubCardBtn' htmlType='submit'>{language?.AddgolfClub}</Button>
              </div>
              {/* </Form.Item> */}
            </Form>
          </div>
        </div>
        <Modal title={language?.ChangeAddress} open={showAddressApi} footer={null} onCancel={handleClose}>
          <DaumPostcodeEmbed autoClose={false} loading={true} style={{ width: '100%', height: 400 }} onClose={handleClose} onCancel={handleClose} onComplete={handleComplete} {...props} />
        </Modal>
        
        <div className='headersearch' style={{ marginLeft: '12px' }}>
          <Input
            placeholder={userSelectedLanguage?.SearchGolfClubByNameorAddress}
            className='noti_search'
            prefix={<SearchOutlined />}
            onChange={(e) => setSearchValue(e?.target?.value)}
          />
        </div>
        <div className='nameEmailDiv'>
          <Divider />
          <Table
            loading={clubLoading}
            dataSource={tableData}
            columns={columns}
            scroll={{ x: 700 }}
            size='middle'
          />
        </div>
        {selectedClub?._id ? <ClubEditModal lanKorCondition={lanKorCondition} {...props} onCancel={onCancel} selectedClub={selectedClub} onSuccess={onSuccess} /> : null}
      </div>
    </>
  )
}

export default GolfCLub
