import { Button, Checkbox, Form, Image, Input, Modal, Select } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CoustomDatePicker2 from '../../Components/DatePicker/DatePicker2'
import SearchGolfClub from '../../Components/SearchGolfClub/SearchGolfClub'
import { POST } from '../../utils/apis'
import { errorMessage, successMessage } from '../../utils/helpers'

const { TextArea } = Input
const { Option } = Select

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 16,
        },
    },
}

const AddGolfReservationModal = (props) => {

    const { language, handleCancel, addGolfReservation, golfData, user, lanKorCondition } = props
    console.log(user, "=user")
    const nums = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    const mins = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60']
    const [form] = Form.useForm();
    const [am, setAm] = useState(golfData?.reservedTime?.slice(-2) || 'am')
    const [hr, setHr] = useState(golfData?.reservedTime?.slice(0, -3).split(':')[0] || '1')
    const [min, setMin] = useState(golfData?.reservedTime?.slice(0, -3).split(':')[1] || '1')
    const [totalTime, setTotalTime] = useState(hr + ':' + min + ' ' + am)
    const [showGolfClub, setShowGolfClub] = useState(false)
    const [golfClubData, setGolfClubData] = useState(null)
    console.log(golfClubData, "=golfClubData")
    const [greenFee, setGreenFee] = useState(null)
    const [transferFee, setTransferFee] = useState(null)
    const [address, setAddress] = useState(null)
    const [loading, setLoading] = useState(false)

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: language?.Pleaseselectdate,
            }
        ]
    }
    const handleClose = () => {
        handleCancel()

    }

    // const handleClose = () => {
    //     setIsModalOpen(false)
    //     setCorrection(false)
    //     setGolf({})
    // }

    const onFinish = (fieldsValue) => {
        setLoading(true)

        // Should format date value before submit.
        const values = {
            ...fieldsValue
        }
        values.userId = user?._id
        values.adminId = user?._id
        values.address = address
        values.region = golfClubData?.region
        values.golfClub = golfClubData?._id
        values.greenFee = +greenFee
        values.memo = fieldsValue?.memo
        values.reservedTime = totalTime
        values.transferFee = +transferFee

        console.log('values', values)
        axios.post(POST?.GOLF_RESERVATION_REGISTRATION, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(data?.message ? data?.message : data?.messageKr)
                    setGolfClubData(null)
                    setGreenFee(null)
                    setTransferFee(null)
                    setMin('')
                    setHr('')
                    setAm('')
                    handleClose()
                    form.resetFields()

                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const setExpiryDate = (e) => {
        let date = moment(e?._d).add(1, 'days').format('YYYY-MM-DD')
        form.setFieldValue('reservedDate', moment(date, 'YYYY-MM-DD'))
    }

    return (
        <>
            <Modal
                open={addGolfReservation}
                onCancel={handleClose}
                className='responsiveModal'
                footer={null}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div'>
                        <center><h2>{language?.GolfReservationCorrection}</h2></center>

                        <div className='row_fields' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                            <p style={{ marginLeft: 10 }}>{language?.GolfClubName}</p>
                            <Button
                                className='input_field_buttons2'
                                size='middle'
                                onClick={() => setShowGolfClub(true)}
                            >{language?.Search}</Button>
                        </div>

                        <SearchGolfClub
                            setAddress={setAddress}
                            golfClubData={golfClubData}
                            setGolfClubData={setGolfClubData}
                            showGolfClub={showGolfClub}
                            setShowGolfClub={setShowGolfClub} />

                        {golfClubData?._id ?
                            <div className='row_fields1' style={{ maxWidth: 300, marginBottom: 20 }}>
                                <Image width={100} height={100} src={golfClubData?.imgUrl} />
                                <div style={{ marginTop: -10 }}>
                                    <h2>{golfClubData?.clubName}</h2>
                                    <p>{golfClubData?.region}</p>
                                    <Button
                                        onClick={() => { setGolfClubData(null); setAddress(null) }}
                                    >{language?.Delete}</Button>
                                </div>
                            </div> : null}


                        <div className='editModal'>
                            <Form
                                name='time_related_controls'
                                form={form}
                                requiredMark={false}
                                {...formItemLayout}
                                onFinish={onFinish}>
                                <Form.Item name='reservedDate' label={language?.ReservedDate}{...config}>
                                    <CoustomDatePicker2
                                        placeholder={language?.Selectdate}
                                        setDate={setExpiryDate} />
                                </Form.Item>

                                <Form.Item
                                    name='reservedTime'
                                    label={language?.ReservedTime}
                                    initialValue={totalTime}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <div className='row_fields1'>
                                        <Select
                                            defaultValue='A.M'
                                            className='special_width'
                                            value={am}
                                            onChange={(value) => {
                                                setAm(value);
                                                setTotalTime(hr + ':' + min + ' ' + value)
                                            }}
                                        >
                                            <Option value='am'>A.M.</Option>
                                            <Option value='pm'>P.M.</Option>
                                        </Select>
                                        <Select
                                            style={{
                                                width: '35%'
                                            }}
                                            value={hr}
                                            onChange={(value) => { setHr(value); setTotalTime(value + ':' + min + ' ' + am) }}
                                        >
                                            {nums?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                                        </Select>
                                        <Select
                                            style={{
                                                width: '35%'
                                            }}
                                            value={min}
                                            onChange={(value) => { setMin(value); setTotalTime(hr + ':' + value + ' ' + am) }}
                                        >
                                            {mins?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                                        </Select>
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name='greenFee'
                                    label={language?.GreenFee}
                                >
                                    <div>
                                        <Input
                                            suffix={language?.KRW}
                                            min={0}
                                            maxLength={10}
                                            value={greenFee}
                                            onChange={(e) => setGreenFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name='transferFee'
                                    label={language?.TransferFee}
                                >
                                    <div className='row_fields1'>

                                        <Input
                                            suffix={language?.KRW}
                                            min={0}
                                            maxLength={7}
                                            value={transferFee}
                                            onChange={(e) => setTransferFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                        <button onClick={() => setTransferFee('0')} style={{ width: 150, marginLeft: 10, border: 'none', cursor: 'pointer' }}>{language?.NoTransferFee}</button>

                                    </div>
                                </Form.Item>


                                <Form.Item
                                    name='address'
                                    label={language?.Address}
                                    initialValue={address}
                                >
                                    <div className='row_fields1'>

                                        <Input
                                            type='text'
                                            style={{ width: '100%' }}
                                            value={address}
                                            disabled
                                        />

                                    </div>
                                </Form.Item>

                                <Form.Item
                                    initialValue={golfData?.memo}
                                    name='memo'
                                    label={language?.Memo}
                                >
                                    <TextArea defaultValue={golfData?.memo} rows={4} />
                                </Form.Item>

                                <Form.Item
                                    name='TermAndConditions'
                                    label={language?.TermCondition}
                                    valuePropName='checked'
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                        }
                                    ]}
                                >
                                    <Checkbox />
                                </Form.Item>

                                <div className='agreement scroll_class' style={{ marginBottom: 20 }}>
                                    <p>{language?.TAC1}</p>
                                    <p>{language?.TAC2}</p>
                                    <p>{language?.TAC3}</p>
                                    <p>{language?.TAC4}</p>
                                    <p>{language?.TAC5}</p>
                                    <p>{language?.TAC6}</p>
                                    <p>{language?.TAC7}</p>
                                </div>
                                <Button
                                    loading={loading}
                                    className='req_exc_btn'
                                    type='primary'
                                    htmlType='submit'>
                                    {language?.Register}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AddGolfReservationModal