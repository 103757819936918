import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 14,
            color: 'white'
        }}
        spin
    />
)

const Loader = () => <Spin indicator={antIcon} />
export default Loader