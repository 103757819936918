import { Button, Checkbox, Form, Image, Input, Modal, Select, Upload } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CoustomDatePicker2 from '../../Components/DatePicker/DatePicker2'
import SearchGolfClub from '../../Components/SearchGolfClub/SearchGolfClub'
import { ADMIN, POST } from '../../utils/apis'
import { errorMessage, successMessage, successNotification, warningMessage } from '../../utils/helpers'
import DaumPostcodeEmbed from 'react-daum-postcode'
import { regionsKr } from '../../utils/constants'
import { MinusCircleOutlined, PlusCircleFilled, UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input
const { Option } = Select

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 16,
        },
    },
}

const EditGolfReservationModal = (props) => {

    let geocoder = new kakao.maps.services.Geocoder()
    const { language, setCorrection, correction, golfData, fun, setGolf, isAdmin, user, setIsAdmin, lanKorCondition } = props
    const nums = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    const mins = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60']
    const [form] = Form.useForm();
    const [form2] = Form.useForm()
    const [allRegions, setAllRegions] = useState(regionsKr)
    const [selectedRegion, setselectedRegion] = useState('')
    const types = ['Individual member', 'Family member', 'Individual weekday', 'Family weekday', 'Company']
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [am, setAm] = useState(golfData?.reservedTime?.slice(-2) || 'am')
    const [hr, setHr] = useState(golfData?.reservedTime?.slice(0, -3).split(':')[0] || '1')
    const [min, setMin] = useState(golfData?.reservedTime?.slice(0, -3).split(':')[1] || '1')
    const [totalTime, setTotalTime] = useState(hr + ':' + min + ' ' + am)
    const [showGolfClub, setShowGolfClub] = useState(false)
    const [golfClubData, setGolfClubData] = useState(null)
    const [golfDatas, setGolfData] = useState(golfData)
    const [greenFee, setGreenFee] = useState(golfDatas?.greenFee)
    const [transferFee, setTransferFee] = useState(golfDatas?.transferFee)
    const [address, setAddress] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingClub, setLoadingClub] = useState(false)
    const [searchAddress, setSearchAddress] = useState('')
    const [showAddressApi, setShowAddressApi] = useState(false)
    const [fileList, setFileList] = useState([])

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage('You can only upload Images!')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }
        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }
    const handleComplete = (data) => {
        let fullAddress = data?.address
        let extraAddress = ''
        if (data?.addressType === 'R') {
            if (data?.bname !== '') {
                extraAddress += data?.bname
            }
            if (data?.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data?.buildingName}` : data?.buildingName
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
        }

        setSearchAddress(fullAddress)
        setShowAddressApi(false)
        // onFinishEditAdd(fullAddress)
    }


    const handleChange = (value) => {
        setselectedRegion(value)
    }

    const handleClose = () => {
        setShowAddressApi(false)
    }

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: language?.Pleaseselectdate,
            }
        ]
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        setCorrection(false)
        setGolf({})
        setIsAdmin(false)
    }
    const onFinishGolfClub = (values) => {

        if (!values?.file) {
            return warningMessage(lanKorCondition ? 'Please Upload Image' : '파일을 업로드 해주세요')
        }
        setLoadingClub(true)

        let formData = new FormData()


        formData.append('clubName', values?.clubName)
        formData.append('clubAddress', searchAddress)
        geocoder.addressSearch(searchAddress, (result, status) => {
            if (status === kakao.maps.services.Status.OK) {
                formData.append('latitude', result[0]?.y || 0)
                formData.append('logitude', result[0]?.x || 0)
                formData.append('file', values?.file?.file)
                formData.append('region', values?.region)

                axios.post(ADMIN?.CREATE_GOLF_CLUB, formData)
                    .then((res) => {
                        const { data } = res
                        console.log(data)
                        setLoadingClub(false)
                        if (data.success) {
                            successNotification(lanKorCondition ? data.message : data.messageKr)
                            // ticketData.golfClub = data?.data
                            // getTickets()
                            // onCancel()
                            setGolfClubData(data?.data)
                            setAddress(data?.data?.clubAddress)
                            // setFileList([])
                        } else {
                            errorMessage(lanKorCondition ? data.message : data.messageKr)
                        }
                    })
                    .catch((e) => {
                        console.log('e====>', e)
                        setLoadingClub(false)
                        errorMessage(language?.OppsError)
                    })

            }
            else {
                setLoadingClub(false)
                return warningMessage(lanKorCondition ? 'please select Address' : 'please select Address')
            }
        })
    }

    const onFinish = (fieldsValue) => {
        setLoading(true)
        // Should format date value before submit.
        const values = {
            ...fieldsValue
        }
        values.userId = isAdmin ? user?._id : golfData?.userId?._id
        values.address = address
        values.region = golfClubData?.region
        values.golfClub = golfClubData?._id
        values.greenFee = +greenFee
        values.memo = fieldsValue?.memo
        values._id = golfData?._id
        values.reservedTime = totalTime
        values.transferFee = transferFee

        console.log('values', values)
        // return true
        axios.post(POST?.EDIT_GOLF_RESERVATION, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(data?.message)
                    setIsModalOpen(false)
                    setGolfClubData(null)
                    setGreenFee(null)
                    setTransferFee(null)
                    setMin('')
                    setHr('')
                    setAm('')
                    handleCancel()
                    form.resetFields()
                    fun()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const setExpiryDate = (e) => {
        let date = moment(e?._d).add(1, 'days').format('YYYY-MM-DD')
        form.setFieldValue('reservedDate', moment(date, 'YYYY-MM-DD'))
    }

    useEffect(() => {
        let date = moment(golfData?.reservedDate).format('YYYY-MM-DD')
        form.setFieldValue('reservedDate', moment(date, 'YYYY-MM-DD'))
        setFileList([])
        setGolfData(golfData)
        setGolfClubData(golfData?.golfClub)
        setAddress(golfData?.golfClub?.clubAddress)
        setGreenFee(golfData?.greenFee)
        setTransferFee(golfData?.transferFee)
        setAm(golfData?.reservedTime?.split(" ")[1])
        setHr(golfData?.reservedTime?.split(":")[0])
        setMin(golfData?.reservedTime?.split(":")[1]?.split(' ')[0])
    }, [golfData?._id])
    console.log(fileList, "==fileList")
    return (
        <>
            <Modal
                open={isModalOpen || correction}
                onCancel={handleCancel}
                className='responsiveModal'
                footer={null}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div'>
                        <center><h2>{language?.GolfReservationCorrection}</h2></center>
                        {golfClubData?._id &&
                            <div className='row_fields' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                                <p style={{ marginLeft: 10 }}>{language?.GolfClubName}</p>
                                <Button
                                    className='input_field_buttons2'
                                    size='middle'
                                    onClick={() => setShowGolfClub(true)}
                                >{language?.Search}</Button>
                            </div>
                        }

                        <SearchGolfClub setAddress={setAddress} golfClubData={golfClubData} d={golfData?.golfClub} setGolfClubData={setGolfClubData} showGolfClub={showGolfClub} setShowGolfClub={setShowGolfClub} />

                        {golfClubData?._id ?
                            <div className='row_fields1' style={{ maxWidth: 300, marginBottom: 20 }}>
                                <Image width={100} height={100} src={golfClubData?.imgUrl} />
                                <div style={{ marginTop: -10 }}>
                                    <h2>{golfClubData?.clubName}</h2>
                                    <p>{golfClubData?.region}</p>
                                    <Button onClick={() => { setGolfClubData(null); setAddress(null) }}>{language?.Delete}</Button>
                                </div>
                            </div> : null}
                        <div className='editModal'>

                            <Modal title={language?.ChangeAddress} open={showAddressApi} footer={null} onCancel={handleClose}>
                                <DaumPostcodeEmbed autoClose={false} loading={true} style={{ width: '100%', height: 400 }} onClose={handleClose} onCancel={handleClose} onComplete={handleComplete} {...props} />
                            </Modal>
                            {!golfClubData?._id &&
                                <Form
                                    {...formItemLayout}
                                    form={form2}
                                    name='basic'
                                    initialValues={{ remember: true }}
                                    onFinish={onFinishGolfClub}
                                    autoComplete='off'
                                >
                                    <h2>{lanKorCondition?'Golf Club Request':'골프예약 신청건'}</h2>
                                    <Form.Item
                                        label={language?.ClubName}
                                        name='clubName'
                                        initialValue={golfData?.golfClubRequest}
                                        rules={[{ required: true, message: language?.PleaseinputClubName }]}
                                    >
                                        <Input /* className='GolfClubInputFeild' style={}  */ />
                                    </Form.Item>
                                    <Form.Item
                                        initialValue={searchAddress}
                                        label={language?.Clubaddress}
                                        name='clubAddress'
                                    >
                                        <div>
                                            <Input value={searchAddress} onClick={() => setShowAddressApi(true)} />
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        label={language?.Region}
                                        name='region'
                                        rules={[{ required: true, message: language?.Pleaseinputregion }]}
                                        initialValue={allRegions[0]?.TitleEn}
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            defaultValue={lanKorCondition ? allRegions[0].TitleEn : allRegions[0].TitleKr}
                                            // className='SelectDropDown'
                                            onChange={handleChange}>
                                            {
                                                allRegions?.map((v, i) => {
                                                    return (
                                                        <Option value={v?.TitleEn}>{lanKorCondition ? v?.TitleEn : v?.TitleKr}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label={language?.File}
                                        name='file'
                                    // rules={[{ required: true, message: 'Please input  File!' }]}
                                    >
                                        <Upload
                                            name='file'
                                            multiple={false}
                                            beforeUpload={() => false}
                                            accept='image/png, image/jpeg'
                                            onChange={normFile}
                                            fileList={fileList}
                                        >
                                            <Button style={{ width: '100%' }} className='BtnUpload' icon={<UploadOutlined color='#5EA29F' />}>Upload</Button>
                                        </Upload>
                                    </Form.Item>

                                    <Button style={{ width: '100%' }} block loading={loadingClub} className='AddClubCardBtn' htmlType='submit'>{language?.AddgolfClub}</Button>

                                </Form>
                            }
                            <br />

                            <Form name='time_related_controls' disabled={!golfClubData?._id} form={form} requiredMark={false} {...formItemLayout} onFinish={onFinish}>

                                <Form.Item name='reservedDate' label={language?.ReservedDate}{...config}>
                                    <CoustomDatePicker2 defaultValue={moment(golfData?.reservedDate, 'YYYY-MM-DD')} placeholder={language?.Selectdate} setDate={setExpiryDate} />
                                </Form.Item>

                                <Form.Item
                                    name='reservedTime'
                                    label={language?.ReservedTime}
                                    initialValue={totalTime}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <div className='row_fields1'>
                                        <Select
                                            className='special_width'
                                            value={am}
                                            onChange={(value) => { setAm(value); setTotalTime(hr + ':' + min + ' ' + value) }}
                                        >
                                            <Option value='am'>A.M.</Option>
                                            <Option value='pm'>P.M.</Option>
                                        </Select>
                                        <Select
                                            style={{
                                                width: '35%'
                                            }}
                                            value={hr}
                                            onChange={(value) => { setHr(value); setTotalTime(value + ':' + min + ' ' + am) }}
                                        >
                                            {nums?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                                        </Select>
                                        <Select
                                            style={{
                                                width: '35%'
                                            }}
                                            value={min}
                                            onChange={(value) => { setMin(value); setTotalTime(hr + ':' + value + ' ' + am) }}
                                        >
                                            {mins?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                                        </Select>
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name='greenFee'
                                    label={language?.GreenFee}
                                >
                                    <div>
                                        <Input
                                            suffix={language?.KRW}
                                            min={0}
                                            maxLength={10}
                                            value={greenFee}
                                            onChange={(e) => setGreenFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name='transferFee'
                                    label={language?.TransferFee}
                                >
                                    <div className='row_fields1'>

                                        <Input
                                            suffix={language?.KRW}
                                            min={0}
                                            maxLength={7}
                                            value={transferFee}
                                            onChange={(e) => setTransferFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                        <button onClick={() => setTransferFee('0')} style={{ width: 150, marginLeft: 10, border: 'none', cursor: 'pointer' }}>{language?.NoTransferFee}</button>

                                    </div>
                                </Form.Item>

                                {golfClubData?._id &&
                                    <Form.Item
                                        name='address'
                                        label={language?.Address}
                                        initialValue={address}
                                    >
                                        <div className='row_fields1'>

                                            <Input
                                                type='text'
                                                style={{ width: '100%' }}
                                                value={address}
                                                disabled
                                            />

                                        </div>
                                    </Form.Item>
                                }

                                <Form.Item
                                    initialValue={golfData?.memo}
                                    name='memo'
                                    label={language?.Memo}
                                >
                                    <TextArea defaultValue={golfData?.memo} rows={4} />
                                </Form.Item>

                                <Form.Item
                                    name='TermAndConditions'
                                    label={language?.TermCondition}
                                    valuePropName='checked'
                                    initialValue={golfDatas?.TermAndConditions}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                        }
                                    ]}
                                >
                                    <Checkbox />
                                </Form.Item>

                                <div className='agreement scroll_class' style={{ marginBottom: 20 }}>
                                    <p>{language?.TAC1}</p>
                                    <p>{language?.TAC2}</p>
                                    <p>{language?.TAC3}</p>
                                    <p>{language?.TAC4}</p>
                                    <p>{language?.TAC5}</p>
                                    <p>{language?.TAC6}</p>
                                    <p>{language?.TAC7}</p>
                                </div>
                                <Button loading={loading}
                                    className='req_exc_btn'
                                    type='primary'
                                    htmlType='submit'
                                >{'Save Changes'}</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default EditGolfReservationModal