import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import moment from 'moment-timezone'
import { allPaths, types, typesKr, typesOnlyKr } from '../../../utils/constants'

const EditTicketRequestBuy = (props) => {
    const { user, fun, language, lanKorCondition, handleOk, open, editData } = props

    const userId = user?._id
  
    return (
        <>
            <Modal
                   open={open}
                   onCancel={handleOk}
                   footer={null}
                   className='responsiveModal'
                   style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <p style={{ textAlign: 'center' }}>
                        {lanKorCondition ? 'Membership wish to trade' : '구매 신청'}
                    </p>
                    <div className='main_modal_con' style={{ padding: 10 }}>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.GolfClubName}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{editData?.golfClub?.clubName || editData?.userTicketId?.golfClub?.clubName}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.Type}</h4>
                            </div>
                            <div className='column-2 box'>
                                {/* <p>{editData && editData?.membershipType || editData?.userTicketId?.membershipType}</p> */}
                                <p>{lanKorCondition ? (editData?.membershipType || editData?.userTicketId?.membershipType) :
                                    typesKr[typesOnlyKr?.indexOf(editData?.membershipType || editData?.userTicketId?.membershipType)]?.typesKr ?
                                        typesKr[typesOnlyKr?.indexOf(editData?.membershipType || editData?.userTicketId?.membershipType)]?.typesKr :
                                        typesKr[types?.indexOf(editData?.membershipType || editData?.userTicketId?.membershipType)]?.typesKr
                                }</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.JoiningFee}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{editData && editData?.userTicketId?.joiningFee + ' ' + language?.KRW}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.JoiningDate}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{`${moment(editData && editData?.userTicketId?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(editData && editData?.userTicketId?.joiningDate?.date2).format('YYYY-MM-DD')}`}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.SpecialGift}</h4>
                            </div>
                            <div className='column-2 box'>
                                {lanKorCondition ?
                                    <p>
                                        Weekday {editData?.userTicketId?.specialGiftWeekDay} times / {editData?.userTicketId?.specialGiftWeekDayKRW} KRW
                                        Weekend {editData?.userTicketId?.specialGiftWeekEnd} times / {editData?.userTicketId?.specialGiftWeekEndKRW} KRW
                                    </p>
                                    :
                                    <p>
                                        주중 {editData?.userTicketId?.specialGiftWeekDay} 회 / {editData?.userTicketId?.specialGiftWeekDayKRW} 만원
                                        주말 {editData?.userTicketId?.specialGiftWeekEnd} 회 / {editData?.userTicketId?.specialGiftWeekEndKRW} 만원
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default EditTicketRequestBuy