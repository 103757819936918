import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Typography, Radio } from 'antd'
import englishLanguage from '../../utils/englishLanguage.json'
import koreanLanguage from '../../utils/koreanLanguage.json'
import { Header } from '../../Components'
import { setLanguage } from '../../Redux/actions/genralAction'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { requiredMessage, errorMessage, errorNotification, successNotification } from '../../utils/helpers'
import { allPaths } from '../../utils/constants'
import { ADMIN } from '../../utils/apis'
import axios from 'axios'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
const { Title } = Typography

const Login = (props) => {
    const history = props?.history
    const dispatch = useDispatch()
    const userSelectedLanguage = useSelector(state => state?.languageReducer?.language)
    const language = userSelectedLanguage && userSelectedLanguage === 'english' ? englishLanguage[0] : koreanLanguage[0]
    const lanKorCondition = userSelectedLanguage === 'english'

    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        setLoading(true)
        axios.post(ADMIN?.LOGIN, values)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(language?.SuccessfullyLoggedIn)
                    dispatch(loginUser(data?.admin))
                    return setTimeout(() => {
                        history?.push(allPaths?.MEMBERSHIP)
                    }, 300)
                }
                errorMessage(lanKorCondition ? data.message : data.messageKr)
            })
            .catch((e) => {
                console.log('e====>', e)
                setLoading(false)
                errorMessage(language?.OppsError)
            })
    }

    return (
        <div className='LoginDiv' >
            <Header {...props} />

            <div className='LoginFormDiv' >
                <p className='LoginLogo'>{language?.Login}</p>

                <Form
                    name='form'
                    onFinish={onFinish}
                    layout={'vertical'}
                    requiredMark={false}
                >
                    <Form.Item
                        name='email'
                        rules={[
                            {
                                required: true,
                                message: language?.InputyourEmail

                            }
                        ]}
                    >
                        <Input
                            prefix={<Title level={5} className='prifix'>ID</Title>}
                            className='form-input remover-border'
                            placeholder={language?.ID}
                            size='large'
                            type='email'
                        />
                    </Form.Item>
                    <Form.Item
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: language?.InputyourPassword

                            },
                            {
                                required: true,
                                message: lanKorCondition ? 'Please Enter Password At Least 5 Characters' : '비밀번호는 최소 5자리 이상으로 입력해주세요'
                            }
                        ]}
                    >
                        <Input.Password
                            size='large'
                            visibilityToggle={false}
                            prefix={<Title level={5} className='prifix'>PW</Title>}
                            className='form-input remover-border'
                            placeholder={language?.Password}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading={loading}
                            type='primary'
                            className='loginButton'
                            block
                            htmlType='submit'
                        >
                            {language?.Login}
                        </Button>
                    </Form.Item>

                </Form>

                {process.env.NODE_ENV === 'development'?
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Title level={5} className='prifix' style={{ minWidth: '80px' }}>{language?.Language}</Title>
                    <div style={{ marginLeft: '10px' }}>
                        <Radio.Group defaultValue={userSelectedLanguage} onChange={(e) => { dispatch(setLanguage({ language: e?.target?.value })) }} buttonStyle='solid'>
                            <Radio.Button value='english'>{language?.English}</Radio.Button>
                            <Radio.Button value='korean'>{language?.Korean}</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>:null}
            </div>

        </div>
    )
}

export default Login
