import React, { useEffect, useState } from 'react'
import SideMenu from '../SideMenu/SideMenu'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import englishLanguage from '../../utils/englishLanguage.json'
import koreanLanguage from '../../utils/koreanLanguage.json'
import axios from 'axios'
import { ADMIN } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import apiUrl from '../../Config/api'
import { removeUser, loginUser } from '../../Redux/actions/authActions'
import { useDispatch } from 'react-redux'

const MenuLayout = ({ component: Component, ...rest }) => {
  const user = useSelector(state => state?.authReducer?.user)
  const userSelectedLanguage = useSelector(state => state?.languageReducer?.language)
  const language = userSelectedLanguage && userSelectedLanguage === 'english' ? englishLanguage[0] : koreanLanguage[0]
  const lanKorCondition = userSelectedLanguage === 'english'

  const dispatch = useDispatch()

  if (!user) {
    return (
      <Redirect to={allPaths?.LOGIN} />
    )
  }
  return (

    <Route
      {...rest}
      render={(props) => <AddMenu {...props} dispatch={dispatch} component={Component} language={language} lanKorCondition={lanKorCondition} user={user} />}
    />
  )
}

const AddMenu = ({ component: Component, ...props }) => {
  const { user, dispatch, history, location } = props
  const state = useSelector((state) => state)
  const [collapsed, setCollapsed] = useState()

  useEffect(() => {
    if (user) {
      getAdmin()
    }

    try {
      axios.get(`${apiUrl}/api/get/test`)
    }
    catch (e) {
    }
  }, [props?.location?.pathname])

  const getAdmin = () => {
    axios.get(`${ADMIN.GET_ADMIN}/${user._id}`)
      .then((res) => {
        const { data } = res
        if (data.success) {
          dispatch(loginUser(data?.admin))
        }
        else {
          history?.replace(allPaths?.LOGIN)
          dispatch(removeUser())
        }
      })
      .catch((e) => {
        dispatch(removeUser())
      })
  }

  if (!user) {
    return (
      <Redirect to={allPaths?.LOGIN} />
    )
  }

  useEffect(() => {
    setCollapsed(state?.SideMenuDrawerReducer)
  })
  return (
    <div className='helper-main'>
      <div className='menu-flex'>
        <div className='side-menu1'>
          <div className='side_menu_theme_updated'>
            <SideMenu {...props} />
          </div>
        </div>
        <div
          className='helper-comp'
          style={{ marginLeft: window.innerWidth > 500 ? !collapsed ? 325 : 80 : 90 }}>
          <Component {...props} />
        </div>
      </div>
    </div>
  )
}

export { MenuLayout }
