import { GET_ALL_AUCTION, LANGUAGE, SHOW_SIDE_MENU_DRAWER } from '../types'

const languageReducer = (state = { language: 'korean' }, action) => {
    switch (action.type) {
        case LANGUAGE: {
            return (state = action.payload)
        }
        default: {
            return state
        }
    }
}
const setAuction = (state = { val: 1 }, action) => {
    switch (action?.type) {
        case GET_ALL_AUCTION: {
            return (state = action.payload)
        }
        default: {
            return state
        }
    }
}
const SideMenuDrawerReducer = (state = false, action) => {
    switch (action.type) {
        case SHOW_SIDE_MENU_DRAWER: {
            return (state = action.payload)
        }
        default: {
            return state
        }
    }
};




export {
    languageReducer,
    SideMenuDrawerReducer,
    setAuction
}