import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Select, Upload } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import DaumPostcodeEmbed from 'react-daum-postcode'
import { ADMIN } from '../../utils/apis'
import { regionsKr } from '../../utils/constants'
import { errorMessage, successMessage } from '../../utils/helpers'

const { TextArea } = Input

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 8,
        },
        lg: {
            span: 8,
        }
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 16,
        },
        lg: {
            span: 16,
        },
    },
}

const ClubEditModal = (props) => {
    const { user, language, onCancel, selectedClub, onSuccess, lanKorCondition } = props
    const [loading, setLoading] = useState(false);
    const [allRegions, setAllRegions] = useState(regionsKr)
    const [selectedRegion, setselectedRegion] = useState('')
    const [clubAddress, setClubAddress] = useState(selectedClub?.clubAddress)
    const [clubName, setClubName] = useState(selectedClub && selectedClub?.clubName)
    const [searchAddress, setSearchAddress] = useState(selectedClub?.clubAddress)
    const [showAddressApi, setShowAddressApi] = useState(false)
    const [fileList, setFileList] = useState([])
    let geocoder = new kakao.maps.services.Geocoder()
    const [form] = Form.useForm()
    const onReset = () => {
        form.resetFields()
    }
    const handleComplete = (data) => {
        let fullAddress = data?.address
        let extraAddress = ''
        if (data?.addressType === 'R') {
            if (data?.bname !== '') {
                extraAddress += data?.bname
            }
            if (data?.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data?.buildingName}` : data?.buildingName
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
        }

        setSearchAddress(fullAddress)
        setShowAddressApi(false)
    }
    const handleChange = (value) => {
        setselectedRegion(value)
    };

    const handleCancel = () => {
        onCancel()
    }
    const getAllClub = () => {
        onSuccess()
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    const onFinish = (fieldsValue) => {
        // setLoading(true)

        let formData = new FormData()
        if (fieldsValue?.file) {
            formData.append('file', fieldsValue?.file?.file)
        }
        formData.append('clubName', fieldsValue?.clubName)
        formData.append('clubAddress', searchAddress)
        formData.append('region', fieldsValue?.region)
        formData.append('_id', selectedClub?._id)
        formData.append('adminId', user?._id)
        geocoder.addressSearch(searchAddress, (result, status) => {
            if (status === kakao.maps.services.Status.OK) {
                formData.append('latitude', result[0]?.y || 0)
                formData.append('logitude', result[0]?.x) || 0
                axios.post(ADMIN.EDIT_GOLF_CLUB, formData)
                    .then((res) => {
                        const { data } = res
                        if (data.success) {
                            successMessage(lanKorCondition ? data?.message : data?.messageKr)
                            onCancel()
                            getAllClub()
                        }
                        else {
                            errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                        }
                        setLoading(false)
                    })
                    .catch((e) => {
                        setLoading(false)
                        errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : ' 문제가 발생했습니다!')
                    })
            }
        })
    }


    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage(lanKorCondition ? 'You can only upload Images!' : '이미지만 업로드 해주세요')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    return (
        <>
            <center >
                <Modal
                    open={selectedClub?._id}
                    onCancel={handleCancel}
                    className='responsiveModal'
                    footer={null}

                >
                    <div className='modal_main_div' >
                        <center><h2>{language?.EditGolfClub}</h2></center>
                        <br />
                        <div className='editModal'>
                            <Form
                                //  form={form}
                                //  labelCol={{ span: 8 }}
                                //  wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                //  onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete='off'
                                form={form}
                                requiredMark={false}
                                name='time_related_controls basic'
                                {...formItemLayout}
                                onFinish={onFinish}>
                                <div>
                                    <h4>{lanKorCondition ? 'Club Image' : '골프장 이미지'}</h4>
                                    <center>
                                        <div className='GolfClubFeildDiv'>
                                            <img style={{ height: '120px', width: '280px', marginBottom: '20px' }} src={selectedClub?.imgUrl} />
                                        </div>
                                    </center>
                                </div>


                                <div className='GolfClubFeildDiv'>
                                    <Form.Item
                                        label={lanKorCondition ? 'Change Course Image' : '골프장 이미지 변경'}
                                        name='file'
                                    // rules={[{ required: true, message: 'Please input  File!' }]}
                                    >
                                        <Upload
                                            name='file'
                                            multiple={false}
                                            beforeUpload={() => false}
                                            accept='image/png, image/jpeg'
                                            onChange={normFile}
                                            fileList={fileList}
                                        >
                                            <Button className='GolfClubInputFeild BtnUpload' icon={<UploadOutlined color='#5EA29F' />}>Upload</Button>
                                        </Upload>
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    name='clubName'
                                    label={<h4 style={{ marginBottom: 0 }}>{language?.ClubName}</h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.PleaseinputClubName,
                                        },
                                    ]}
                                    initialValue={selectedClub?.clubName}
                                >
                                    <div>
                                        <Input
                                            value={clubName}
                                            onChange={(e) => { setClubName(e?.target?.value) }}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name='clubAddress'
                                    label={<h4 style={{ marginBottom: 0 }}>{language?.ClubAddress}</h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.ClubAddress,
                                        },
                                    ]}
                                    initialValue={selectedClub?.clubAddress}
                                >
                                    <div>
                                        <Input
                                            value={searchAddress}
                                            onClick={() => setShowAddressApi(true)}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label={language?.Region}
                                    name='region'
                                    initialValue={selectedClub && selectedClub?.region}
                                    rules={[{ required: true, message: language?.Region }]}
                                >
                                    <Select defaultValue={selectedClub && selectedClub?.region} onChange={handleChange}>
                                        {
                                            allRegions?.map((v, i) => {
                                                return (
                                                    <Option value={v?.TitleEn}>{lanKorCondition ? v?.TitleEn : v?.TitleKr}</Option>

                                                    // <Option value={v}>{v}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Modal title={language?.ChangeAddress} open={showAddressApi} footer={null} onCancel={() => setShowAddressApi(false)}>

                                    <DaumPostcodeEmbed autoClose={false} loading={true} style={{ width: '100%', height: 400 }} onClose={() => setShowAddressApi(false)} onCancel={() => setShowAddressApi(false)} onComplete={handleComplete} {...props} />
                                </Modal>

                                <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit'>
                                    {language?.Update}
                                </Button>
                                <Button style={{ width: '100%', marginTop: 10 }} onClick={handleCancel}>{language?.Cancel}</Button>
                            </Form>
                        </div>

                    </div>
                </Modal>
            </center>
        </>
    )
}

export default ClubEditModal