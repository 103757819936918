import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Event, MenuLayout, Notice } from '../Components'
import allPaths from './paths'
import { Result, Button } from 'antd'
import LogoutButton from '../Components/LogoutButton/LogoutButton'

import { GolfAuction, GolfReservation, GroupBuyPurchase, Login, ManagementMembership, MembershipSales, MyMembership, OfflineTransactionManagement, OnlineTransactionManagement, ParticipantsDetail, Setting, SharedExchange, SharedRental, VeiwDetailMembershipOffline, VeiwDetailMembershipOnline, GolfCLub, AuctionBidDetail, BankInfo, TicketsInfo, GolfBidDetail, Banner, EventBoard, NoticeBoard, TicketsVeiwDetail, Faq, GroupBuyDetail, CompanyIntro, AdminMembership } from '../Screens'
import { useSelector } from 'react-redux'

const Page404 = (props) => {
  const { history } = props
  return (
    <Result
      status='404'
      title='404'
      subTitle='Sorry, the page you visited does not exist.'
      extra={
        <Button
          type='primary'
          className='form-button'
          onClick={() => history.push('/')}
        >
          Back Home
        </Button>
      }
    />
  )
}

const Routes = () => {
  // const userSelectedLanguage = useSelector(state => state?.generalReducer?.language)
  // const language = userSelectedLanguage && userSelectedLanguage === 'english' ? englishLanguage[0] : koreanLanguage[0]
  // const lanKorCondition = userSelectedLanguage === 'english'

  return (
    <Router>
      <Switch>
        <Route path={allPaths?.LOGIN} exact component={Login} />
        <MenuLayout path={allPaths?.MEMBERSHIP} exact component={ManagementMembership} />
        <MenuLayout path={allPaths?.MY_MEMBERSHIP} exact component={MyMembership} />
        <MenuLayout path={allPaths?.ADMIN_MEMBERSHIP} exact component={AdminMembership} />
        <MenuLayout path={allPaths?.OFFLINE_TRANSACTION} exact component={OfflineTransactionManagement} />
        <MenuLayout path={allPaths?.ONLINE_TRANSACTION} exact component={OnlineTransactionManagement} />
        <MenuLayout path={allPaths?.GOLF_AUCTION_POST_MANAGEMENT} exact component={GolfAuction} />
        <MenuLayout path={allPaths?.GOLF_RESERVATION_POST_MANAGEMENT} exact component={GolfReservation} />
        <MenuLayout path={allPaths?.OFFLINE_TRANSACTION_VIEW_TRADING} exact component={VeiwDetailMembershipOffline} />
        <MenuLayout path={allPaths?.ONLINE_TRANSACTION_VIEW_TRADING} exact component={VeiwDetailMembershipOnline} />
        <MenuLayout path={allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_GROUP_BUY_PURCHASE_REQUEST} exact component={GroupBuyPurchase} />
        <MenuLayout path={allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_RENTAL_REQUEST} exact component={SharedRental} />
        <MenuLayout path={allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_MEMBERSHIP_SALES_REQUEST} exact component={MembershipSales} />
        <MenuLayout path={allPaths?.MEMBERSHIP_TRANSACTION_MANAGEMENT_SHARED_EXCHANGE_REQUEST} exact component={SharedExchange} />
        <MenuLayout path={allPaths?.PARTICIPANTSDETAILS} exact component={ParticipantsDetail} />
        <MenuLayout path={allPaths?.AUCTIONBIDDETAIL} exact component={AuctionBidDetail} />
        <MenuLayout path={allPaths?.GROUP_BUY_DETAIL} exact component={GroupBuyDetail} />
        <MenuLayout path={allPaths?.GOLFBIDDETAIL} exact component={GolfBidDetail} />
        <MenuLayout path={allPaths?.TICKETSVIEWINFO} exact component={TicketsVeiwDetail} />
        <MenuLayout path={allPaths?.SETTINGS} exact component={Setting} />
        <MenuLayout path={allPaths?.GOLFCLUB} exact component={GolfCLub} />
        <MenuLayout path={allPaths?.BANKINFO} exact component={BankInfo} />
        <MenuLayout path={allPaths?.TICKETSINFO} exact component={TicketsInfo} />
        <MenuLayout path={allPaths?.NOTICE} exact component={NoticeBoard} />
        <MenuLayout path={allPaths?.EVENT} exact component={EventBoard} />
        <MenuLayout path={allPaths?.BANNER} exact component={Banner} />
        <MenuLayout path={allPaths?.FAQ} exact component={Faq} />
        <MenuLayout path={allPaths?.COMPANY_INTRO} exact component={CompanyIntro} />
        {/* <MenuLayout path={allPaths?.BOARDMANAGEMENT} exact component={BoardManagement} />
         <Route path={allPaths?.EVENT} exact component={Event} />
        <Route path={allPaths?.NOTICE} exact component={Notice} /> */}
        <Route path='/:page404' exact component={Page404} />
      </Switch>
    </Router>
  )
}

export { Routes, Page404 }
