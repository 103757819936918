import React, { useEffect } from "react"
import { hot } from "react-hot-loader/root"
import AOS from "aos"
import { Routes } from "./Config/routes"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import "./App.css"

AOS.init()

const App = () => {
  return (
    <div >
        <Routes />
    </div>
  )
}

export default hot(App)



